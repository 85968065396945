// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3653
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { OnboardingStep } from "../enums/onboarding_step";

// Event_name: 'select.dash_self_serve_onboarding_step_skip'
// Description: Event fired when a user clicks the skip button
// Owner: otc
export type Select_DashSelfServeOnboardingStepSkip = PAPEventGeneric<
  'dash',
  'select',
  'dash_self_serve_onboarding_step_skip',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Name of the onboarding step event corresponds to
    onboardingStep?: OnboardingStep;
    // Set to true if user going through Self-serve onboarding is an admin
    selfServeIsTeamAdmin?: boolean;
  }
>;

export function PAP_Select_DashSelfServeOnboardingStepSkip(properties?: Select_DashSelfServeOnboardingStepSkip['properties']): Select_DashSelfServeOnboardingStepSkip {
  return <Select_DashSelfServeOnboardingStepSkip>{
    class: 'dash',
    action: 'select',
    object: 'dash_self_serve_onboarding_step_skip',
    properties,
  };
}
