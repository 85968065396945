import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashStartPage } from '@mirage/analytics/events/types/view_dash_start_page';
import { UserActivityFeedModule } from '@mirage/feed/components/UserActivityFeedModule/UserActivityFeedModule';
import { QuickLinksModule } from '@mirage/mosaics/QuickLinks/QuickLinksModule';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useIsActivityFeedEnabled } from '@mirage/service-feed/hooks/features';
import {
  DismissWithBackoff,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import {
  ONE_DAY_IN_MILLIS,
  ONE_HOUR_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { useMountEffect } from '@react-hookz/web';
import { useMemo } from 'react';
import { useConnectedCalendars } from '../WorkingSet/Calendar/useConnectedCalendars';
import { CalendarModule } from './Calendar';
import { PrivacyToggle } from './PrivacyToggle';
import { RecentsModule } from './RecentsModule';
import { TabbedModule } from './TabbedModule/TabbedModule';

const CALENDAR_DISMISSED_EMPTY_STATE_KEY =
  'dismissedStartPageCalendarEmptyState';

function useReportViewDashStartPage() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useMountEffect(() => {
    reportPapEvent(
      PAP_View_DashStartPage({
        featureLine: 'session_tracking',
      }),
    );
  });
}

export const StartPage = () => {
  useReportViewDashStartPage();
  const showStacksOnStartPage = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_23_stacks_on_start_page'),
  );

  const isActivityFeedEnabled = useIsActivityFeedEnabled();
  const { connections: calendarConnections } = useConnectedCalendars();
  const calendarDismissBehavior = useMemo(
    () =>
      new DismissWithBackoff((n: number): number =>
        Math.min(2 ** n * ONE_HOUR_IN_MILLIS, 7 * ONE_DAY_IN_MILLIS),
      ),
    [],
  );

  const { dismissed: calendarDismissed, setDismissed: setCalendarDismissed } =
    useDismissableModule(
      CALENDAR_DISMISSED_EMPTY_STATE_KEY,
      calendarDismissBehavior,
    );

  const showCalendarModule =
    calendarConnections.length > 0 || !calendarDismissed;

  const showQuickLinksModule = convertFeatureValueToBool(
    useFeatureFlagValue('dash_search_2025_02_04_quick_links_section'),
  );

  const sidebarChildren =
    showCalendarModule || showQuickLinksModule ? (
      <>
        {showQuickLinksModule && <QuickLinksModule />}
        {showCalendarModule && (
          <CalendarModule onDismiss={setCalendarDismissed} />
        )}
      </>
    ) : undefined;

  return (
    <>
      <TwoColumnGrid
        mainRowGap="Macro Large"
        mainChildren={
          <>
            {showStacksOnStartPage ? (
              <TabbedModule maxCardsPerRow={showCalendarModule ? 4 : 6} />
            ) : (
              <RecentsModule />
            )}
            {isActivityFeedEnabled && <UserActivityFeedModule />}
          </>
        }
        sidebarChildren={sidebarChildren}
      />
      <PrivacyToggle />
    </>
  );
};
