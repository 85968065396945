import { createContext, useContext } from 'react';

export type OnboardingCompanyInfo = {
  companyName: string;
  companyLogo: string;
};

export type OnboardingResponsesContextType = {
  companyInfo: OnboardingCompanyInfo;
  setCompanyName: (name: string) => void;
};

const OnboardingResponsesContext = createContext<
  OnboardingResponsesContextType | undefined
>(undefined);

export const useOnboardingResponses = (): OnboardingResponsesContextType => {
  const context = useContext(OnboardingResponsesContext);
  if (!context) {
    throw new Error('Missing OnboardingResponsesProvider wrapper in JSX tree');
  }
  return context;
};

export const OnboardingResponsesProvider: React.FC<
  OnboardingResponsesContextType
> = ({ children, ...props }) => {
  return (
    <OnboardingResponsesContext.Provider value={props}>
      {children}
    </OnboardingResponsesContext.Provider>
  );
};
