import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableCellNodeFromLexicalNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableSelection,
  TableCellNode,
  TableNode,
  TableRowNode,
} from '@lexical/table';
import { getTableElements } from '@mirage/mosaics/ComposeAssistant/components/editor/table/CustomTableNode';
import {
  AddColumnButtonHandle,
  AddRowButtonHandle,
  CellDispatchActionArg,
  ColumnActionHandle,
  ColumnDividerHandle,
  RowActionHandle,
  RowDividerHandle,
} from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableHoverActionsHandles';
import { tagged } from '@mirage/service-logging';
import {
  $getNodeByKey,
  $getSelection,
  $hasAncestor,
  $isRangeSelection,
  $nodesOfType,
  LexicalEditor,
} from 'lexical';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getRowsFromTable, moveTableNodes } from './tableUtils';

const logger = tagged('TableHoverActionsPlugin');

export const TableHoverActionsPlugin = memo(() => {
  const isEditable = useLexicalEditable();
  const tableSelection = useTableSelection();
  return isEditable && tableSelection
    ? createPortal(
        <TableHoverActionsContainer tableSelection={tableSelection} />,
        tableSelection?.controlsContainer,
      )
    : null;
});
TableHoverActionsPlugin.displayName = 'TableHoverActionsPlugin';

interface CellNodeInfo {
  cellNode: TableCellNode;
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  isInFirstRow: boolean;
  isInFirstColumn: boolean;
  rowIndex: number;
  columnIndex: number;
}

interface TableHoverActionsContainerProps {
  tableSelection: TableSelection;
}
export const TableHoverActionsContainer = memo(
  ({ tableSelection }: TableHoverActionsContainerProps) => {
    const [editor] = useLexicalComposerContext();
    const [tableCellNodesReversed, setTableCellNodesReversed] = useState<
      CellNodeInfo[]
    >([]);
    const [tableDimensions, setTableDimensions] = useState({
      rowCount: 0,
      columnCount: 0,
    });

    const resetTableState = useCallback(() => {
      setTableCellNodesReversed([]);
      setTableDimensions({ rowCount: 0, columnCount: 0 });
    }, []);

    useEffect(() => {
      if (!tableSelection?.table) {
        resetTableState();
        return;
      }

      editor.getEditorState().read(() => {
        const rows = getRowsFromTable(tableSelection.table);
        if (rows.length === 0) {
          resetTableState();
          return;
        }

        setTableDimensions({
          rowCount: rows.length,
          columnCount: rows[0].getChildren().length,
        });

        const getCellInfo = (cellNode: TableCellNode): CellNodeInfo | null => {
          const parentKey = cellNode.getParent()?.getKey();
          if (!parentKey) {
            logger.error('Parent key not found for table cell node');
            return null;
          }

          const row = $getNodeByKey(parentKey);
          if (!row || !(row instanceof TableRowNode)) {
            logger.error('Row not found for table cell node');
            return null;
          }

          const rowIndex = rows.indexOf(row);
          const columnIndex = row.getChildren().indexOf(cellNode);
          const tableContainerElement = editor.getElementByKey(
            tableSelection.table.getKey(),
          )!;
          const tableElements = getTableElements(tableContainerElement)!;

          return {
            cellNode,
            cellElement: editor.getElementByKey(cellNode.getKey())!,
            tableElement: tableElements.tableElement,
            isInFirstRow: $isCellInFirstRow(cellNode),
            isInFirstColumn: $isCellInFirstColumn(cellNode),
            rowIndex,
            columnIndex,
          };
        };

        const validCells = $nodesOfType(TableCellNode)
          .filter((cellNode) => $hasAncestor(cellNode, tableSelection.table))
          .map(getCellInfo)
          .filter((info): info is CellNodeInfo => info !== null)
          .reverse();

        setTableCellNodesReversed(validCells);
      });
    }, [editor, tableSelection, resetTableState]);

    // NOTE: tableCellNodesReversed is reversed to ensure that the first row/column's handles are inserted
    // last in the DOM, so that they are rendered on top of the other handles to make sure their right-borders
    // are not covered by the other handles.
    return (
      <>
        {tableSelection && (
          <>
            <AddRowButtonHandle
              tableNode={tableSelection.table}
              tableElement={tableSelection.tableElement}
              anchorElement={tableSelection.containerElement}
            />
            <AddColumnButtonHandle
              tableNode={tableSelection.table}
              tableElement={tableSelection.tableElement}
              anchorElement={tableSelection.containerElement}
            />
          </>
        )}
        {tableCellNodesReversed.map(
          ({
            cellNode,
            cellElement,
            tableElement,
            isInFirstColumn,
            isInFirstRow,
            rowIndex,
            columnIndex,
          }) => {
            const handleDispatchAction = (arg: CellDispatchActionArg) => {
              editor.update(() => {
                cellNode?.selectEnd();
                switch (arg.action) {
                  case 'addColumn':
                    $insertTableColumn__EXPERIMENTAL(arg.order === 'after');
                    break;
                  case 'addRow': {
                    const table = $getTableNodeFromLexicalNodeOrThrow(cellNode);
                    const rows = getRowsFromTable(table);
                    const firstRow = rows.length > 0 ? rows[0] : undefined;
                    const newRow = $insertTableRow__EXPERIMENTAL(
                      arg.order === 'after',
                    );
                    if (firstRow) {
                      // set widths of all newRow's cells to match firstRows' cells widths
                      firstRow.getChildren().forEach((firstRowCell, index) => {
                        if (!(firstRowCell && $isTableCellNode(firstRowCell))) {
                          logger.error('First row contains non-cell children');
                          return;
                        }
                        const newCell = newRow?.getChildAtIndex(index);
                        if (newCell && $isTableCellNode(newCell)) {
                          newCell.setWidth(firstRowCell.getWidth());
                        }
                      });
                    } else {
                      logger.error('First row not found when adding row');
                    }
                    break;
                  }
                  case 'deleteColumn':
                    $deleteTableColumn__EXPERIMENTAL();
                    break;
                  case 'deleteRow':
                    $deleteTableRow__EXPERIMENTAL();
                    break;
                  case 'moveRow':
                  case 'moveColumn':
                    if (!tableSelection?.table) {
                      const message =
                        'Table not found when updating via moveRow or moveColumn action';
                      logger.error(message);
                      throw new Error(message);
                    }
                    moveTableNodes(
                      tableSelection.table,
                      arg.action === 'moveRow' ? 'row' : 'column',
                      arg.sourceIndex,
                      arg.targetIndex,
                    );
                    // Force the table to re-render
                    tableSelection.table.selectStart();
                    break;
                }
              });
            };
            return (
              <Fragment key={cellNode.getKey()}>
                {isInFirstRow && (
                  <>
                    <ColumnActionHandle
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={tableSelection.containerElement}
                      dispatchAction={handleDispatchAction}
                      index={columnIndex}
                      totalCount={tableDimensions.columnCount}
                    />
                    {isInFirstColumn && (
                      <ColumnDividerHandle
                        placement="left"
                        cellElement={cellElement}
                        tableElement={tableElement}
                        anchorElement={tableSelection.containerElement}
                        dispatchAction={handleDispatchAction}
                      />
                    )}
                    <ColumnDividerHandle
                      placement="right"
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={tableSelection.containerElement}
                      dispatchAction={handleDispatchAction}
                    />
                  </>
                )}
                {isInFirstColumn && (
                  <>
                    <RowActionHandle
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={tableSelection.containerElement}
                      dispatchAction={handleDispatchAction}
                      index={rowIndex}
                      totalCount={tableDimensions.rowCount}
                    />
                    <RowDividerHandle
                      placement="below"
                      cellElement={cellElement}
                      tableElement={tableElement}
                      anchorElement={tableSelection.containerElement}
                      dispatchAction={handleDispatchAction}
                    />
                  </>
                )}
              </Fragment>
            );
          },
        )}
      </>
    );
  },
);
TableHoverActionsContainer.displayName = 'TableHoverActionsContainer';

interface TableSelection {
  table: TableNode;
  tableElement: HTMLElement;
  controlsContainer: HTMLElement;
  containerElement: HTMLElement;
  cells: TableCellNode[];
}
function useTableSelection() {
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState<TableSelection | undefined>(
    undefined,
  );

  const updateSelectedTableCell = useCallback(() => {
    const selectedCells = getTableCellNodesFromSelection(editor);
    const table =
      selectedCells.length > 0
        ? $getTableNodeFromLexicalNodeOrThrow(selectedCells[0])
        : undefined;
    const containerElement = table
      ? editor.getElementByKey(table.getKey())
      : undefined;

    const tableElements = containerElement
      ? getTableElements(containerElement)
      : undefined;

    if (
      table &&
      containerElement &&
      tableElements?.controlsContainer &&
      tableElements?.tableElement &&
      selectedCells.length > 0
    ) {
      setSelection({
        table: table,
        containerElement,
        tableElement: tableElements.tableElement,
        controlsContainer: tableElements.controlsContainer,
        cells: selectedCells,
      });
    } else {
      setSelection(undefined);
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(updateSelectedTableCell);
    });
  }, [editor, updateSelectedTableCell]);

  return selection;
}

function getTableCellNodesFromSelection(editor: LexicalEditor) {
  const selection = $getSelection();
  const nativeSelection = window.getSelection();

  if (selection == null) {
    return [];
  }
  const rootElement = editor.getRootElement();
  if ($isTableSelection(selection)) {
    return selection.getNodes().filter($isTableCellNode);
  }
  if (
    $isRangeSelection(selection) &&
    rootElement !== null &&
    nativeSelection !== null &&
    rootElement.contains(nativeSelection.anchorNode)
  ) {
    const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
      selection.anchor.getNode(),
    );

    if (tableCellNodeFromSelection == null) {
      return [];
    }

    const tableCellParentNodeDOM = editor.getElementByKey(
      tableCellNodeFromSelection.getKey(),
    );

    if (tableCellParentNodeDOM == null) {
      return [];
    }

    return [tableCellNodeFromSelection];
  }
  return [];
}

function $isCellInFirstRow(node: TableCellNode) {
  const row = node.getParent();
  if (row && row instanceof TableRowNode) {
    return row.getPreviousSibling() === null;
  }
  return false;
}

function $isCellInFirstColumn(node: TableCellNode) {
  return node.getPreviousSibling() === null;
}
