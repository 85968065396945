import classnames from 'classnames';
import styles from './Stepper.module.css';

interface Props {
  currentStep: number;
  totalSteps: number;
}
export const Stepper: React.FC<Props> = ({ currentStep, totalSteps }) => {
  return (
    <div className={styles.stepperContainer}>
      {new Array(totalSteps).fill(null).map((_, ind) => {
        const stepNum = ind + 1;
        const isCurrentStep = stepNum === currentStep;
        const isCompletedStep = stepNum < currentStep;

        return (
          <div
            className={classnames(
              styles.step,
              (isCompletedStep || isCurrentStep) && styles.filled,
            )}
            key={ind}
          />
        );
      })}
    </div>
  );
};
