import { Metadata } from '@dropbox/dash-component-library';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import styles from './StackCardPopoverSectionHeader.module.css';

export const StackCardPopoverSectionHeader = ({
  title,
  count,
}: {
  title: string;
  count: number;
}) => {
  return (
    <List.Item tabIndex={0} className={styles.sectionHeaderContainer}>
      <List.Content>
        <Metadata size="medium" withDividers="bullet">
          <Metadata.Item>
            <Metadata.Label>
              <Text variant="label">{title}</Text>
            </Metadata.Label>
          </Metadata.Item>
          <Metadata.Item>
            <Metadata.Label>
              {i18n.t('num_items', {
                count,
              })}
            </Metadata.Label>
          </Metadata.Item>
        </Metadata>
      </List.Content>
    </List.Item>
  );
};
