import { stacks } from '@dropbox/api-v2-client';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  CreateStackLine,
  ListViewLine,
  RotateRightLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Refresh_DashSuggestedLink } from '@mirage/analytics/events/types/refresh_dash_suggested_link';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { Link, ListItemSize } from '@mirage/link-list/types';
import {
  MetricPageName,
  StackDetailsModule,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getStacksDataCachedTags,
  useStacksDataCachedTags,
} from '@mirage/service-operational-metrics/module/tags';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
} from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import {
  activeStackActionAtom,
  activeStackAtom,
  activeStackItemShortcutsAtom,
  activeStackSectionIdAtom,
  activeStackSectionsAtom,
  activeStackSessionIdAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { getTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DraggableAccessoryIcon } from '../DragAndDrop/DraggableStackLink';
import { DraggableSuggestion } from '../DragAndDrop/DraggableSuggestion';
import { useAddLinkToStack } from '../hooks';
import { SectionsActionType } from '../types';
import {
  useContentSuggestions,
  useOpenSuggestedLink,
  useShownContentSuggestions,
} from './hooks';
import styles from './Navigation.module.css';

export const Suggestions = () => {
  const isMobileSize = useIsMobileSize();
  const handleShowContentSuggestions = useShownContentSuggestions();
  const handleOpenSuggestedLink = useOpenSuggestedLink();
  const { allSections: sections } = useAtomValue(activeStackSectionsAtom);
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const addLinkToStack = useAddLinkToStack();
  const setCurrentAction = useSetAtom(activeStackActionAtom);
  const setCurrentSectionId = useSetAtom(activeStackSectionIdAtom);
  const { contentSuggestions, isFetchingContentSuggestions } =
    useContentSuggestions();
  const stack = useAtomValue(activeStackAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const sessionId = useAtomValue(activeStackSessionIdAtom);
  const isDarkMode = useTheme();

  const markSuggestionsLoadComplete = useSuggestionsPerformanceTracking();

  const existingItemUrls = useMemo(
    () => (items ? new Set(items.map((item) => item.url)) : new Set()),
    [items],
  );
  const [index, setIndex] = useState(0);

  const addLinkToSection = useCallback(
    (sectionId: string, item: Link) => {
      setCurrentAction(SectionsActionType.ADD_ITEM_FROM_SUGGESTIONS);
      addLinkToStack(sectionId, item);
      setCurrentSectionId(sectionId);
    },
    [addLinkToStack, setCurrentSectionId, setCurrentAction],
  );

  const [actionMenuOpenForItemId, setActionMenuOpenForItemId] = useState<
    string | null
  >(null);

  const linksToShow = useMemo(
    () =>
      contentSuggestions
        .filter((link) => !existingItemUrls.has(link.url))
        .slice(index, index + 4),
    [contentSuggestions, existingItemUrls, index],
  );

  useEffect(() => {
    if (isFetchingContentSuggestions) {
      // Reset index when the suggestions are re-fetched.
      setIndex(0);
    }
  }, [isFetchingContentSuggestions]);

  useEffect(() => {
    if (linksToShow.length > 0 && !isFetchingContentSuggestions) {
      handleShowContentSuggestions(linksToShow);
    }
  }, [handleShowContentSuggestions, isFetchingContentSuggestions, linksToShow]);

  useEffect(() => {
    if (!isFetchingContentSuggestions) {
      markSuggestionsLoadComplete();
    }
  }, [isFetchingContentSuggestions, markSuggestionsLoadComplete]);

  if (
    (contentSuggestions.length === 0 && !isFetchingContentSuggestions) ||
    isMobileSize
  ) {
    return null;
  }

  const stackProps = stack?.namespace_id && stackDerivePAPProps(stack);

  const fetchingSuggestionsLinks = Array.from({ length: 4 }, (_, mapIndex) => (
    <LinkComponent
      key={mapIndex}
      link={{ url: '', title: '' }}
      listItemSize={ListItemSize.Small}
      isLoading
    />
  ));

  return (
    <TwoColumnGridCard
      settingId={`stack_suggestions:${stack?.namespace_id}`}
      showDividerLine={false}
      cardTypeProps={{
        cardType: CardHeaderType.STACKED_SUBTITLE,
        title: i18n.t('suggestions_box_title'),
        featureLine: 'content_suggestions',
        dashCardType: 'content_suggestions',
      }}
      subtitle={i18n.t('suggestions_subtitle')}
      theme={getTheme(stack?.stack_data?.color_index).subtle}
      headerActions={
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('refresh'),
          }}
          variant="borderless"
        >
          <UIIcon
            src={RotateRightLine}
            onClick={() => {
              reportPapEvent(
                PAP_Refresh_DashSuggestedLink({
                  featureLine: 'content_suggestions',
                  createStackSessionId: sessionId ?? undefined,
                  ...stackProps,
                }),
              );
              setIndex((prevIndex) => prevIndex + 4);
            }}
          />
        </IconButtonWithTooltip>
      }
    >
      <LinkList listItemSize={ListItemSize.Small}>
        {isFetchingContentSuggestions
          ? fetchingSuggestionsLinks
          : linksToShow.map((link, mapIndex) => (
              <LinkComponent
                key={mapIndex}
                link={link}
                onOpenLink={handleOpenSuggestedLink}
                enableDrag
                shouldShowHoverState={actionMenuOpenForItemId === link.url}
                listItemSize={ListItemSize.Small}
                icon={createElement(FavIcon, {
                  src: faviconSrcForSrcUrl(link.url, 32, isDarkMode),
                  size: ListItemSize.Small,
                })}
                accessoryComponent={
                  <>
                    {sections && sections.length > 1 ? (
                      <SuggestionsActionMenu
                        item={link}
                        sections={sections}
                        moveToSection={addLinkToSection}
                        setActionMenuOpen={(isOpen: boolean) =>
                          setActionMenuOpenForItemId(isOpen ? link.url : null)
                        }
                      />
                    ) : (
                      <>
                        <IconButtonWithTooltip
                          tooltipProps={{
                            title: i18n.t('add_to_stack'),
                          }}
                          variant="transparent"
                          size="standard"
                          className={styles.pointerEventReset}
                          onClick={() =>
                            addLinkToSection(DEFAULT_SECTION_ID, link)
                          }
                        >
                          <UIIcon src={AddLine} />
                        </IconButtonWithTooltip>
                      </>
                    )}
                    <DraggableSuggestion suggestion={link}>
                      <DraggableAccessoryIcon />
                    </DraggableSuggestion>
                  </>
                }
              />
            ))}
      </LinkList>
    </TwoColumnGridCard>
  );
};

const SuggestionsActionMenu = ({
  item,
  sections,
  moveToSection,
  setActionMenuOpen,
}: {
  item: Link;
  sections: stacks.Section[];
  moveToSection: (sectionId: string, item: Link) => void;
  setActionMenuOpen: (isOpen: boolean) => void;
}) => {
  return (
    <Menu.Wrapper onToggle={({ isOpen }) => setActionMenuOpen(isOpen)}>
      {({ getContentProps, getTriggerProps }) => (
        <>
          <IconButtonWithTooltip
            tooltipProps={{
              title: i18n.t('add_to_stack'),
            }}
            variant="transparent"
            size="standard"
            className={styles.pointerEventReset}
            {...getTriggerProps()}
          >
            <UIIcon src={AddLine} />
          </IconButtonWithTooltip>
          <Menu.Content
            {...getContentProps()}
            className={styles.pointerEventReset}
            placement="bottom-start"
          >
            <AddToSectionSubMenu
              sections={sections}
              item={item}
              moveToSection={moveToSection}
            />
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

const AddToSectionSubMenu = ({
  sections,
  item,
  moveToSection,
}: {
  sections: stacks.Section[];
  item: Link;
  moveToSection: (sectionId: string, item: Link) => void;
}) => {
  const isMobileSize = useIsMobileSize();
  const handleMoveToSection = (sectionId: string) => {
    moveToSection(sectionId, item);
  };

  return (
    <Menu.Segment>
      <Menu.ActionItem
        withTitle={i18n.t('add_to_stack')}
        withLeftAccessory={<UIIcon src={CreateStackLine} />}
        onClick={() => handleMoveToSection(DEFAULT_SECTION_ID)}
      />
      <Menu.Submenu
        withTriggerContent={i18n.t('add_to_section')}
        withLeftAccessory={<UIIcon src={ListViewLine} />}
        {...(isMobileSize ? { placement: 'bottom' } : undefined)}
      >
        <Menu.Segment>
          {sections.map((section) => (
            <Menu.ActionItem
              key={section.id}
              onClick={() =>
                handleMoveToSection(nonNil(section.id, 'sectionId'))
              }
            >
              {section.id == DEFAULT_SECTION_ID
                ? i18n.t('default_section')
                : section.title}
            </Menu.ActionItem>
          ))}
        </Menu.Segment>
      </Menu.Submenu>
    </Menu.Segment>
  );
};

function useSuggestionsPerformanceTracking() {
  const tags = useStacksDataCachedTags();

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.STACK_DETAILS,
    StackDetailsModule.SUGGESTED_ITEMS,
    tags || getStacksDataCachedTags,
  );

  return markModuleLoadComplete;
}
