import {
  getCachedOrListConnections,
  listConnections,
} from '@mirage/service-connectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import {
  getCurrentBrowser,
  useExtensionConnectionId,
} from '@mirage/shared/util/webExtensionsHelpers';
import { useEffect, useState } from 'react';

export const useCanClearBrowserHistory = () => {
  const [extensionInstalled, setExtensionInstalled] = useState<boolean>();
  const connectionId = useExtensionConnectionId();
  const clearBrowserHistoryVariant = useFeatureFlagValue(
    'dash_2024_12_09_clear_browser_history_variants',
    false,
  );

  useEffect(() => {
    const checkExtensionInstalled = async () => {
      if (clearBrowserHistoryVariant === 'CONTROL') {
        setExtensionInstalled(connectionId !== undefined);
      } else if (clearBrowserHistoryVariant === 'V1') {
        const browserExtensionConnections =
          await getFilteredBrowserExtensionConnections();
        setExtensionInstalled(browserExtensionConnections.length > 0);
      }
    };

    checkExtensionInstalled();
  }, [connectionId, clearBrowserHistoryVariant]);

  return Boolean(extensionInstalled);
};

export const getFilteredBrowserExtensionConnections = async (
  forceRefresh = false,
) => {
  const browser = getCurrentBrowser();
  const connections = await (forceRefresh
    ? listConnections()
    : getCachedOrListConnections());
  return connections.filter(
    (c) =>
      c.id_attributes?.connector?.type === 'browser_extension' &&
      // Only delete connections for the current platform, or if no platform is known
      (c.id_attributes?.connector?.id === '' ||
        c.id_attributes?.connector?.id === browser),
  );
};
