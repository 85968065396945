import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-calendar-events/service';
import * as rx from 'rxjs';

import type { dash } from '@dropbox/api-v2-client';

const service = services.get<Service>(ServiceId.CALENDAR_EVENTS);

export const calendarEventsUpdates = service.calendarEventsUpdates;

export function getCachedCalendarEvents(): Promise<
  dash.SearchResult[] | undefined
> {
  return rx.firstValueFrom(service.getCachedCalendarEvents());
}

export function refreshCalendarEvents(): Promise<void> {
  return rx.firstValueFrom(service.refreshCalendarEvents());
}

export function startRefreshCalendarEvents(): Promise<void> {
  return rx.firstValueFrom(service.startRefreshCalendarEvents());
}

export function stopRefreshCalendarEvents(): Promise<void> {
  return rx.firstValueFrom(service.stopRefreshCalendarEvents());
}
