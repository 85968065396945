import { isUserIneligibleAtom } from '@mirage/eligibility/useEligibilityCheck';
import {
  useIsUsersDay0,
  useWelcomeModalDismissed,
} from '@mirage/service-onboarding/hooks';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

export const shouldShowWelcomeModalAtom = atom<boolean | undefined>(undefined);
export function useShouldShowWelcomeModal(modalVersion: number) {
  const [shouldShowModal, setShouldShowModal] = useAtom(
    shouldShowWelcomeModalAtom,
  );
  const isUsersDay0 = useIsUsersDay0();
  const isUserIneligible = useAtomValue(isUserIneligibleAtom);
  const { dismissed } = useWelcomeModalDismissed(modalVersion);

  // Debounce to ensure shouldShowModal updates only after dependencies stabilize
  // Prevents transient states that could cause flickering or unnecessary re-renders
  const debouncedSetShowModal = useDebounce(setShouldShowModal, 1000);

  useEffect(() => {
    const shouldShow = !dismissed && isUsersDay0 && !isUserIneligible;
    debouncedSetShowModal(shouldShow);
  }, [isUsersDay0, isUserIneligible, dismissed]);

  return shouldShowModal;
}
