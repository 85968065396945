import { Box } from '@dropbox/dig-foundations';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import { DashWordmarkText } from '@mirage/mosaics/GlobalNav/DashWordmarkText';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import classnames from 'classnames';
import * as React from 'react';
import styles from './OnboardingHeader.module.css';
import { Stepper } from './Stepper';

type OnboardingHeaderProps = {
  justifyCenter?: boolean;
  displayGlyph?: boolean;
  className?: string;
};

export const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
  justifyCenter = false,
  displayGlyph = true,
  className,
}) => {
  return (
    <Box
      className={classnames(styles.header, className)}
      justifyContent={justifyCenter ? 'center' : undefined}
    >
      <DefaultOnboardingHeaderContents displayGlyph={displayGlyph} />
    </Box>
  );
};

const DefaultOnboardingHeaderContents: React.FC<{ displayGlyph: boolean }> = ({
  displayGlyph,
}) => {
  return (
    <>
      <WordmarkLogo
        color="var(--dig-color__text__base)"
        size={32}
        src={displayGlyph ? DashWordmark : DashWordmarkText}
      />
      <BetaBadge excludeDfb />
    </>
  );
};

interface Props {
  currentStep: number;
  totalSteps: number;
}
export const OnboardingHeaderWithStepper: React.FC<Props> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className={styles.header}>
      <DefaultOnboardingHeaderContents displayGlyph />
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} totalSteps={totalSteps} />
      </div>
    </div>
  );
};
