import { callApiV2 } from '@mirage/service-dbx-api';
import {
  buildConnectorInfo,
  buildFileTypeInfo,
} from '@mirage/service-dbx-api/service/utils';
import { tagged } from '@mirage/service-logging';

import type { dash, dcs } from '@dropbox/api-v2-client';
import type { SearchResult } from '@mirage/shared/search/search-result';

const logger = tagged('service-curations');

export type HydratedCuration = {
  curation?: dash.Curation;
  result: SearchResult;
};

type CurationResponse = {
  success: boolean;
  curation?: dash.Curation;
};

type GetCurationsResponse = {
  cursor?: string;
  curations: Array<HydratedCuration>;
};

/*
 * Conceptually, curations should be it's own service. However, the current logic for
 * curations does not extend beyond simple passthrough CRUD methods. Set up the
 * necessary boilerplating when the curations use case expands
 */

export async function createCuration(
  entityId: string,
  type: dash.CurationType,
  queries: Array<string> = [],
): Promise<CurationResponse> {
  try {
    const result = await callApiV2('dcsCreateCuration', {
      entity_uuid: entityId,
      curation_type: type,
      exact_queries: queries,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to create curation', e);
    return { success: false };
  }
}

export async function getCuration(
  entityId: string,
  type: dash.CurationType,
): Promise<CurationResponse> {
  try {
    const result = await callApiV2('dcsGetCuration', {
      entity_uuid: entityId,
      curation_type: type,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to retrieve curation', e);
    return { success: false };
  }
}

export async function updateCuration(
  entityId: string,
  type: dash.CurationType,
  queries: Array<string> = [],
): Promise<CurationResponse> {
  try {
    // Essentially the same as `createCuration`. No need to overengineer
    // at this point however.
    const result = await callApiV2('dcsUpdateCuration', {
      entity_uuid: entityId,
      curation_type: type,
      exact_queries: queries,
    });
    return { success: true, curation: result.curation };
  } catch (e) {
    logger.error('Failed to update curation', e);
    return { success: false };
  }
}

export async function deleteCuration(
  entityId: string,
  type: dash.CurationType,
): Promise<CurationResponse> {
  try {
    await callApiV2('dcsDeleteCuration', {
      entity_uuid: entityId,
      curation_type: type,
    });
    return { success: true };
  } catch (e) {
    logger.error('Failed to delete curation', e);
    return { success: false };
  }
}

export async function getCurations({
  curation_type,
  cursor,
}: {
  curation_type: dash.CurationType;
  cursor?: string;
}): Promise<GetCurationsResponse> {
  try {
    const result = await callApiV2('dcsGetCurations', {
      curation_type,
      cursor,
    });

    const hydratedCurations = (result.curations || [])
      ?.map(transformCurationResultToMirage)
      .filter((curation): curation is HydratedCuration => curation !== null);

    return {
      curations: hydratedCurations,
      cursor: result.cursor,
    };
  } catch (e) {
    logger.error('Failed to retrieve curations', e);
    throw e;
  }
}

export const transformCurationResultToMirage = (
  curation: dcs.HydratedCuration,
): HydratedCuration | null => {
  const { uuid, title, link = null } = curation.result ?? {};

  if (!uuid) return null;
  if (!title) return null;
  if (!link) return null;

  const fileTypeInfo = buildFileTypeInfo(curation.result?.file_type_info);
  const connectorInfo = buildConnectorInfo(curation.result?.connector_info);

  if (!connectorInfo) return null;

  return {
    curation: curation.curation,
    result: {
      uuid,
      title,
      url: link,
      fileTypeInfo,
      connectorInfo,

      // These are all fields that are required on the "SearchResult" type but we are not yet populating via the GetCurations API
      // If we need anything here, we can update GetCurations API to return this data.
      displayIconOverride: null,
      id3p: null,
      upstreamId: null,
      recordType: {
        '.tag': 'unknown_record_type',
      },
      description: null,
      additionalLinks: [],
      attachments: [],
      startTime: null,
      endTime: null,
      isAllDay: null,
      conferenceLinks: [],
      profileImageUrl: null,
      location: null,
      recurringEventId: null,
      updatedAtMs: null,
      providerUpdateAtMs: null,
      summarizable: null,
      email: null,
      relevanceScore: 0,
      highlights: null,
    },
  };
};
