import { IconButton } from '@dropbox/dig-components/buttons';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ListViewLine } from '@dropbox/dig-icons/assets';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import {
  useAllStacksAugustRevisionEnabled,
  useIsStartPageAugustRevisionEnabled,
  useStackPageAugustRevisionEnabled,
} from '@mirage/august-revision-hook';
import { MobileDownloadBanner } from '@mirage/growth/components/MobileDownloadBanner';
import {
  generateSearchURL,
  getSearchFromURLParams,
  useQueryParams,
} from '@mirage/search/hooks/useQueryParams';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { publishEvent } from '@mirage/service-feedback';
import { SurveyEvent } from '@mirage/service-feedback/types';
import { removePreviousQueryFromCache } from '@mirage/service-typeahead-search';
import { useListenForTypeaheadReset } from '@mirage/service-typeahead-search/hooks/useListenForTypeaheadReset';
import { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { usePageScrollListener } from '@mirage/shared/hooks/usePageScrollListener';
import { usePreviousPath } from '@mirage/shared/hooks/usePreviousPath';
import {
  syncFiltersWithQuery,
  syncQueryFromURL,
} from '@mirage/shared/search/search-filters';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useCurrentBackgroundTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  globalNavIsCollapsedAtom,
  globalNavIsMobileAtom,
} from '../GlobalNav/atoms';
import { SearchBarWithTypeahead } from '../SearchBarWithTypeahead';
import styles from './TopNav.module.css';

export const TopNav: React.FC = () => {
  const navigate = useNavigate();

  const [isCollapsed, setIsCollapsed] = useAtom(globalNavIsCollapsedAtom);
  const { searchAttemptSessionManager } = useMirageAnalyticsContext();
  const stackFromFullStackPage = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(stackFromFullStackPage);
  const { pageScrolled } = usePageScrollListener();

  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const isAugustAllStacksPageEnabled = useAllStacksAugustRevisionEnabled();
  const augustRevisionEnabled =
    isAugustStackPageEnabled ||
    isAugustStartPageEnabled ||
    isAugustAllStacksPageEnabled;
  const typeaheadProps = useTypeaheadSearch({
    isInstanced: false,
    featureLine: 'search',
  });
  const { previousPath } = usePreviousPath();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();

  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';

  const urlSearch = useMemo(
    () => getSearchFromURLParams(queryParams),
    [queryParams],
  );

  useListenForTypeaheadReset(() => {
    typeaheadProps.setTypeaheadQuery('');
  });

  useEffect(() => {
    const hasLeftSearchPage: boolean =
      previousPath === '/search_results' && pathname !== previousPath;

    if (!hasLeftSearchPage) return;

    typeaheadProps.setTypeaheadQuery('');

    // We only want to track setTypeaheadQuery
    // eslint-disable-next-line local-rules/warn-disable-exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, previousPath, typeaheadProps.setTypeaheadQuery]);

  // Match the sidebar.
  const globalNavIsMobile = useAtomValue(globalNavIsMobileAtom);

  const desktopExperience = EnvCtx.surface === 'desktop';

  const removePreviousQuery = (result: typeahead.ScoredPreviousQuery) => {
    removePreviousQueryFromCache(result).then(() =>
      typeaheadProps.reloadResults(),
    );
  };

  /**
   * When search is triggered from browser history or from home, we need to sync
   * the value in the typeahead with the query from browser history
   */
  const isTaggedQuery = useCallback((activeQuery: string): boolean => {
    const searchAttemptSession = searchAttemptSessionManager.getSession();
    if (!searchAttemptSession) return false;
    const oldQuery = searchAttemptSession?.properties.query;
    const oldIsTypeAhead = searchAttemptSession?.properties.isTypeahead;
    return activeQuery === oldQuery && oldIsTypeAhead;
  }, []);

  useEffect(() => {
    const nextQuery = syncQueryFromURL({
      query: urlSearch.query,
      filters: isUsingTypeaheadFiltersFeatureFlag ? urlSearch.filters : [],
    });

    if (typeaheadProps.typeaheadQuery === nextQuery) return;

    typeaheadProps.setTypeaheadQuery(nextQuery, isTaggedQuery(urlSearch.query));

    // We only want to track setTypeaheadQuery
    // eslint-disable-next-line local-rules/warn-disable-exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlSearch.query,
    urlSearch.filters,
    isUsingTypeaheadFiltersFeatureFlag,
    typeaheadProps.setTypeaheadQuery,
  ]);

  /**
   * Every time a SERP search is executed from Typeahead this function handles the initiation through navigation
   */
  const onSearchSubmit = (nextQuery: string): void => {
    typeaheadProps.setCanShowTypeahead(false);
    searchAttemptSessionManager.updateProperties({ searchSurface: 'top_nav' });
    publishEvent(SurveyEvent.SearchComplete);
    navigate(
      generateSearchURL(
        nextQuery,
        isUsingTypeaheadFiltersFeatureFlag
          ? syncFiltersWithQuery(
              nextQuery,
              typeaheadProps.filters,
              typeaheadProps.allFilters,
            )
          : [],
      ),
    );
  };

  const searchBar = (
    <SearchBarWithTypeahead
      {...typeaheadProps}
      removePreviousQuery={removePreviousQuery}
      onSearchSubmit={onSearchSubmit}
      shouldFocusOnRender
      fullWidth={desktopExperience}
      augustRevisionEnabled={augustRevisionEnabled}
      showBackground={isAugustStackPageEnabled && backgroundTheme !== undefined}
      pageScrolled={pageScrolled}
      allFilters={typeaheadProps.allFilters}
    />
  );

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        {desktopExperience && !globalNavIsMobile ? (
          searchBar
        ) : (
          <div className={classNames(styles.container)}>
            {globalNavIsMobile && <MobileDownloadBanner />}
            <div
              className={classNames(styles.navContainer, {
                [styles.mobile]: globalNavIsMobile,
                [styles.augustRevision]: augustRevisionEnabled,
                [styles.showBackground]:
                  isAugustStackPageEnabled && backgroundTheme !== undefined,
                [styles.scrollingBorder]: pageScrolled && !desktopExperience,
              })}
            >
              <div className={styles.leftContainer}>
                {globalNavIsMobile && (
                  <IconButton
                    className={styles.noShrink}
                    shape="circular"
                    variant="transparent"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    aria-label={i18n.t(
                      isCollapsed ? 'expand_nav_aria' : 'collapse_nav_aria',
                    )}
                    aria-expanded={!isCollapsed}
                  >
                    <UIIcon src={ListViewLine} />
                  </IconButton>
                )}
              </div>
              {globalNavIsMobile ? (
                <div
                  tabIndex={0}
                  className={styles.centerContainer}
                  role="button"
                  aria-label={i18n.t('home')}
                  onClick={() => navigate('/')}
                  onKeyDown={onKeyDownCommitFn(() => navigate('/'))}
                >
                  <WordmarkLogo
                    size={32}
                    src={DashWordmark}
                    color="var(--dig-color__text__base)"
                  />
                  <BetaBadge excludeDfb />
                </div>
              ) : (
                searchBar
              )}
              {/* This will appear on the second row of the grid layout */}
              {globalNavIsMobile && (
                <div className={styles.mobileSearchBar}>{searchBar}</div>
              )}
            </div>
          </div>
        )}
      </ThemeContainer>
    </ThemeProvider>
  );
};
