import {
  CURRENT_EVENTS_REFRESH_INTERVAL,
  MAX_EVENTS_TO_INCLUDE_IN_ABRIDGED,
  MAX_TIME_TO_INCLUDE_IN_ABRIDGED,
  MIN_EVENTS_TO_INCLUDE_IN_ABRIDGED,
  UPCOMING_EVENT_THRESHOLD,
} from '@mirage/service-calendar-events/constants';
import { useUpcomingCalendarEvents } from '@mirage/service-calendar-events/hooks';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import { isBefore, isFuture, isWithinInterval } from 'date-fns';
import partition from 'lodash/partition';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  CalendarEventsEmpty,
  CalendarEventsLoading,
} from './CalendarEmptyStates';
import { CalendarEventList } from './CalendarEventList';
import { convertToCalendarEvents } from './utils';

import type { CalendarEvent } from './utils';

export const useConvertedEvents = () => {
  const { syncState: connectorSyncState, upcomingCalendarEvents } =
    useUpcomingCalendarEvents();
  const [events, setEvents] = useState<CalendarEvent[] | undefined>(undefined);

  useEffect(() => {
    setEvents(convertToCalendarEvents(upcomingCalendarEvents));
  }, [upcomingCalendarEvents, setEvents]);

  return { connectorSyncState, events };
};

const useCurrentEvents = ({
  events,
}: {
  events: CalendarEvent[] | undefined;
}) => {
  const [currentEvents, setCurrentEvents] = useState<
    CalendarEvent[] | undefined
  >(undefined);

  const currentEventsChanged = useMemo(
    () => getValueChangedFunc<CalendarEvent[] | undefined>(),
    [],
  );

  useEffect(() => {
    if (!events?.length) {
      return;
    }

    function removeOldEvents() {
      const currentEvents = events?.filter((event) => {
        return event.allDay || isFuture(event.endTime);
      });
      if (currentEventsChanged(currentEvents)) {
        setCurrentEvents(currentEvents);
      }
    }

    removeOldEvents();
    const intervalId = setInterval(
      removeOldEvents,
      CURRENT_EVENTS_REFRESH_INTERVAL,
    );

    return () => clearInterval(intervalId);
  }, [events, currentEventsChanged, setCurrentEvents]);

  return { currentEvents };
};

export const useCalendarEvents = ({
  events,
}: {
  events: CalendarEvent[] | undefined;
}) => {
  const [allDayEvents, setAllDayEvents] = useState<CalendarEvent[]>([]);
  const [standardEvents, setStandardEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (!events) {
      return;
    }
    const [allDayEvents, standardEvents] = partition(
      events,
      (event) => event.allDay,
    );
    setAllDayEvents(
      allDayEvents.filter((event) => {
        return isWithinInterval(new Date(), {
          start: event.startTime,
          end: event.endTime,
        });
      }),
    );
    setStandardEvents(standardEvents);
  }, [events, setAllDayEvents, setStandardEvents]);

  return { allDayEvents, standardEvents };
};

export const useVisibleEvents = ({
  standardEvents,
  showAllStandardEvents,
}: {
  standardEvents: CalendarEvent[];
  showAllStandardEvents: boolean;
}) => {
  const [visibleEvents, setVisibleEVents] = useState<CalendarEvent[]>([]);
  const [numHiddenEvents, setNumHiddenEvents] = useState<number>(0);

  useEffect(() => {
    // We always need to compute this so we can determine whether
    // we need to show the "See all" button even when showing all
    // standard events.
    const abridgedEvents = standardEvents.filter(
      (event, i) =>
        // Attempt to include at least MIN_EVENTS_TO_INCLUDE_IN_ABRIDGED events
        i < MIN_EVENTS_TO_INCLUDE_IN_ABRIDGED ||
        // Include events that are within the next MAX_TIME_TO_INCLUDE_IN_ABRIDGED
        // up to MAX_EVENTS_TO_INCLUDE_IN_ABRIDGED
        (i < MAX_EVENTS_TO_INCLUDE_IN_ABRIDGED &&
          isBefore(
            event.startTime,
            Date.now() + MAX_TIME_TO_INCLUDE_IN_ABRIDGED,
          )),
    );
    setVisibleEVents(showAllStandardEvents ? standardEvents : abridgedEvents);
    setNumHiddenEvents(standardEvents.length - abridgedEvents.length);
  }, [
    standardEvents,
    showAllStandardEvents,
    setVisibleEVents,
    setNumHiddenEvents,
  ]);

  return { visibleEvents, numHiddenEvents };
};

const useProximateAndLaterEvents = ({
  visibleEvents,
}: {
  visibleEvents: CalendarEvent[];
}) => {
  const [proximateEvents, setProximateEvents] = useState<CalendarEvent[]>([]);
  const [laterEvents, setLaterEvents] = useState<CalendarEvent[]>([]);

  // Split the visible events into proximate and later.
  // Proximate events are those that are in-progress or starting soon.
  useEffect(() => {
    const [proximateEvents, laterEvents] = partition(
      visibleEvents,
      (event: CalendarEvent) =>
        isBefore(event.startTime, Date.now() + UPCOMING_EVENT_THRESHOLD),
    );
    setProximateEvents(proximateEvents);
    setLaterEvents(laterEvents);
  }, [visibleEvents, setProximateEvents, setLaterEvents]);

  return { proximateEvents, laterEvents };
};

export const useNoEventsComponent = ({
  connectorSyncState,
  hasEvents,
  hasStandardEvents,
}: {
  connectorSyncState: string | undefined;
  hasEvents: boolean;
  hasStandardEvents: boolean;
}) => {
  const [noEventsComponent, setNoEventsComponent] = useState<
    JSX.Element | undefined
  >(undefined);

  // Determine if we need to show an empty state component
  useEffect(() => {
    if (!hasStandardEvents) {
      if (!hasEvents || connectorSyncState === 'syncing') {
        setNoEventsComponent(
          <CalendarEventsLoading
            key="loading"
            reason={connectorSyncState === 'syncing' ? 'syncing' : 'loading'}
          />,
        );
      } else {
        // TODO: differentiate between a clear day and a completed day
        setNoEventsComponent(<CalendarEventsEmpty key="empty" />);
      }
    } else {
      setNoEventsComponent(undefined);
    }
  }, [connectorSyncState, hasEvents, hasStandardEvents]);

  return { noEventsComponent };
};

// Pipeline from dash.SearchResult[] to CalendarEvent[] lists and renderable components.
export const useCalendarEventSections = ({
  showAllDayEvents,
  showAllStandardEvents,
  expandedEventId,
  setExpandedEventId,
}: {
  showAllDayEvents: boolean;
  showAllStandardEvents: boolean;
  expandedEventId: string;
  setExpandedEventId: Dispatch<SetStateAction<string>>;
}) => {
  const [eventSections, setEventSections] = useState<JSX.Element[]>([]);

  // Convert the dash.SearchResult[] to CalendarEvent[]
  const { connectorSyncState, events } = useConvertedEvents();

  // Remove any expired events as time passes
  const { currentEvents } = useCurrentEvents({ events });

  // Split the events into allDay and traditional events
  const { allDayEvents, standardEvents } = useCalendarEvents({
    events: currentEvents,
  });

  // Determine the set of events to be rendered in the collapsed list state
  const { visibleEvents, numHiddenEvents } = useVisibleEvents({
    standardEvents,
    showAllStandardEvents,
  });

  // Of the visible events divide them into in-progress & upcoming and later groups
  const { proximateEvents, laterEvents } = useProximateAndLaterEvents({
    visibleEvents,
  });

  // Determine if they're in an empty or loading/syncing state.
  // If so, get the appropriate component to render in lieu of any events.
  const { noEventsComponent } = useNoEventsComponent({
    connectorSyncState,
    hasEvents: events !== undefined,
    hasStandardEvents: standardEvents.length > 0,
  });

  // Create the list of elements intended to be split by dividers
  // Each item in this list will be one of:
  //   - <CalendarEventsLoading>
  //   - <CalendarEventsEmpty>
  //   - <CalendarEventList>
  useEffect(() => {
    const sections: JSX.Element[] = [];

    allDayEvents.length &&
      sections.push(
        <CalendarEventList
          key="allDay"
          events={allDayEvents}
          hidden={!showAllDayEvents}
          {...{ expandedEventId, setExpandedEventId }}
        />,
      );

    if (noEventsComponent) {
      sections.push(noEventsComponent);
    } else {
      proximateEvents.length &&
        sections.push(
          <CalendarEventList
            key="proximate"
            events={proximateEvents}
            {...{ expandedEventId, setExpandedEventId }}
            isFirst
          />,
        );

      laterEvents.length &&
        sections.push(
          <CalendarEventList
            key="later"
            events={laterEvents}
            {...{ expandedEventId, setExpandedEventId }}
            isFirst={proximateEvents.length === 0}
          />,
        );
    }

    setEventSections(sections);
  }, [
    allDayEvents,
    proximateEvents,
    laterEvents,
    noEventsComponent,
    showAllDayEvents,
    expandedEventId,
    setExpandedEventId,
  ]);

  return {
    connectorSyncState,
    events,
    allDayEvents,
    standardEvents,
    numHiddenEvents,
    eventSections,
  };
};
