import { useUpcomingCalendarEvents } from '@mirage/service-calendar-events/hooks';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { isNotNull } from '@mirage/shared/util/tiny-utils';
import { nulledScoringComponents } from '../service/scoring/score';
import { typeahead } from '../service/types';

const MAX_EVENTS_TO_SHOW = 3;

export const useCalendarEventsForTypeahead = (): typeahead.ScoredResult[] => {
  const { upcomingCalendarEvents } = useUpcomingCalendarEvents();
  return (upcomingCalendarEvents ?? [])
    .map((e) => transformDashResultToMirage(e))
    .filter(isNotNull)
    .filter((e) => !e.isAllDay)
    .slice(0, MAX_EVENTS_TO_SHOW)
    .map((e) => ({
      uuid: e.uuid,
      result: { ...e },
      type: typeahead.ResultType.SearchResult,
      score: 0,
      scoringNotes: {
        pinned: false,
        scoringComponents: nulledScoringComponents(),
      },
    }));
};
