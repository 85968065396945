import { Chip } from '@dropbox/dig-components/chip';
import { Select } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import {
  OnboardingSurveyQuestion,
  OnboardingSurveyQuestionAnswer,
  OnboardingSurveyQuestionFieldType,
} from '@mirage/growth/onboarding/types/onboarding-surveys';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import styles from './ProfilingQuestions.module.css';

type ProfilingQuestionProps = {
  question: OnboardingSurveyQuestion;
  value: OnboardingSurveyQuestionAnswer | undefined;
  onChange: (value: OnboardingSurveyQuestionAnswer) => void;
};

const ProfilingQuestionContainer = ({
  question,
  value,
  onChange,
}: ProfilingQuestionProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.profilingQuestion}
    >
      <Text variant="label" size="large" isBold>
        {question.label}
      </Text>
      <ProfilingQuestion
        question={question}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
};

const ProfilingQuestion = ({
  question,
  value,
  onChange,
}: ProfilingQuestionProps) => {
  const handleValueChange = useCallback(
    (val: string) => {
      onChange({
        questionId: question.questionId,
        value: val,
      });
    },
    [onChange, question.questionId],
  );

  switch (question.questionType) {
    case OnboardingSurveyQuestionFieldType.ChipList:
      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          className={styles.questionsContainer}
        >
          {question.questionValues?.map((q) => {
            return (
              <Chip
                onClick={() => handleValueChange(q.value)}
                key={q.label}
                isSelected={value?.value === q.value}
              >
                <Chip.Content>{q.label}</Chip.Content>
              </Chip>
            );
          })}
        </Box>
      );
    case OnboardingSurveyQuestionFieldType.Select:
      return (
        <Select
          id={question.questionId}
          size="large"
          placeholder={i18n.t('jtbd_type_of_work_placeholder')}
          value={value?.value}
          onChange={(val: string) => handleValueChange(val)}
        >
          {question.questionValues?.map((q) => {
            return (
              <Select.Option key={q.label} value={q.value}>
                {q.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    default:
      return null;
  }
};

type Props = {
  questions: OnboardingSurveyQuestion[];
  values: OnboardingSurveyQuestionAnswer[];
  onChange: (values: OnboardingSurveyQuestionAnswer[]) => void;
};

export const ProfilingQuestions = ({ questions, values, onChange }: Props) => {
  const [internalValues, setInternalValues] = useState<
    OnboardingSurveyQuestionAnswer[]
  >([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<number>(0);

  useEffect(() => {
    setInternalValues(values);
    setAnsweredQuestions(values.length);
  }, [values]);

  const handleOnboardingSurveyQuestionAnswerChange = useCallback(
    (value: OnboardingSurveyQuestionAnswer) => {
      const questionValues = [...internalValues];
      const existing = questionValues.find(
        (v) => v.questionId === value.questionId,
      );
      if (existing) {
        existing.value = value.value;
      } else {
        questionValues.push(value);
      }
      setInternalValues(questionValues);
      onChange(questionValues);
    },
    [internalValues, onChange],
  );

  return (
    <>
      {questions.map((q, index) => (
        <div
          key={q.questionId}
          data-testid="profiling-question"
          className={classnames(
            styles.profilingQuestionContainer,
            answeredQuestions >= index && styles.visible,
          )}
        >
          <ProfilingQuestionContainer
            question={q}
            value={internalValues.find((v) => v.questionId === q.questionId)}
            onChange={(value) =>
              handleOnboardingSurveyQuestionAnswerChange(value)
            }
          />
        </div>
      ))}
    </>
  );
};
