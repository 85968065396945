import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text, Title } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { AiStorageMini } from '@dropbox/dig-illustrations';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { ComposeSources } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSources';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { FileError, processFile } from '../../utils/fileProcessing';
import styles from './AddSourcesModal.module.css';
import { ComposeUploadFileButton } from './ComposeUploadFileButton';

interface AddSourcesModalProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const AddSourcesModal = memo(
  ({
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onRequestClose,
    logComposeEvent,
  }: AddSourcesModalProps) => {
    const isMobile = useIsMobileSize();
    const modalContentRef = useRef<HTMLDivElement>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const dragCounter = useRef(0);
    const dragLeaveTimeout = useRef<NodeJS.Timeout>();
    const [error, setError] = useState<FileError>(null);
    const [uploadSnackbarOpen, setUploadSnackbarOpen] = useState(false);
    const { mode } = useTheme();
    const isDarkMode = mode === 'dark';
    const uploadsEnabled =
      useFeatureFlagValue('dash_2025_01_24_assist_direct_uploads') === 'ON';
    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onRequestClose();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onRequestClose]);

    const handleDragEnter = (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current += 1;
      if (dragLeaveTimeout.current) {
        clearTimeout(dragLeaveTimeout.current);
      }
      setIsDraggingOver(true);
    };

    const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      setIsDraggingOver(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        dragLeaveTimeout.current = setTimeout(() => {
          setIsDraggingOver(false);
        }, 50);
      }
    };

    const handleDrop = async (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current = 0;
      setIsDraggingOver(false);
      if (dragLeaveTimeout.current) {
        clearTimeout(dragLeaveTimeout.current);
      }
      const files = Array.from(e.dataTransfer.files);
      if (files.length === 0) return;
      const file = files[0];
      const error = await processFile(file, addSource, logComposeEvent);
      if (error) {
        setError(error);
      }
    };

    return (
      <Modal
        open
        width={694}
        isCentered
        onRequestClose={onRequestClose}
        withCloseButton="close"
        className={styles.AddSourcesModalWindow}
      >
        <DashThemeProvider>
          <Modal.Body
            ref={modalContentRef}
            className={classnames(
              styles.AddSourcesModalBody,
              isMobile && styles.AddSourcesModalBodyMobile,
              isDraggingOver && styles.AddSourcesModalBodyDragging,
            )}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className={styles.AddSourcesModalContent}>
              <Title
                size="medium"
                className={styles.AddSourcesModalTitle}
                weightVariant="emphasized"
              >
                {i18n.t('compose_sources_header')}
              </Title>
              <Text
                size="medium"
                tagName="div"
                color="subtle"
                className={styles.AddSourcesModalDescription}
              >
                {i18n.t('compose_sources_subheader')}
              </Text>
              <div className={styles.AddSourcesModalControls}>
                <ComposeSources
                  sources={sources}
                  sourcesContentCache={sourcesContentCache}
                  onAddSource={addSource}
                  onRemoveSource={removeSource}
                  logComposeEvent={logComposeEvent}
                  hasBorder
                  hideSearchResultsLabel
                  uploadButton={
                    uploadsEnabled && (
                      <ComposeUploadFileButton
                        onAddSource={(s: ComposeSource) => {
                          addSource(s);
                          setUploadSnackbarOpen(true);
                        }}
                        logComposeEvent={logComposeEvent}
                      />
                    )
                  }
                  emptyStateTitle={
                    uploadsEnabled
                      ? i18n.t('compose_sources_empty_state_sources_title')
                      : undefined
                  }
                  sourcesTitleLabel={
                    <Text
                      size="medium"
                      tagName="div"
                      color="standard"
                      isBold
                      className={styles.AddSourcesModalSourcesLabel}
                    >
                      {i18n.t('compose_settings_reference_label')}
                    </Text>
                  }
                  searchTitleLabel={
                    <Text
                      size="medium"
                      tagName="div"
                      color="standard"
                      isBold
                      className={styles.AddSourcesModalSourcesInputLabel}
                    >
                      {i18n.t('compose_settings_reference_description')}
                    </Text>
                  }
                />
              </div>
            </div>
            {uploadSnackbarOpen && (
              <Snackbar.Position>
                <Snackbar
                  open
                  preferComposition
                  timeout={5000}
                  onRequestClose={() => {
                    setUploadSnackbarOpen(false);
                  }}
                >
                  <Snackbar.Content>
                    <Snackbar.Accessory>
                      <UIIcon src={FailLine} />
                    </Snackbar.Accessory>
                    <Snackbar.Message>
                      {i18n.t(
                        'compose_assistant_file_uploader_snackbar_message',
                      )}
                    </Snackbar.Message>
                    <Snackbar.Actions>
                      <Button
                        variant="transparent"
                        size="medium"
                        tone="neutral"
                        onClick={() => setUploadSnackbarOpen(false)}
                      >
                        {i18n.t(
                          'compose_assistant_file_uploader_snackbar_dismiss',
                        )}
                      </Button>
                    </Snackbar.Actions>
                  </Snackbar.Content>
                </Snackbar>
              </Snackbar.Position>
            )}
            {error && (
              <Snackbar.Position>
                <Snackbar
                  open
                  preferComposition
                  timeout={5000}
                  onRequestClose={() => {
                    setError(null);
                  }}
                >
                  <Snackbar.Content>
                    <Snackbar.Accessory>
                      <UIIcon src={FailLine} />
                    </Snackbar.Accessory>
                    <Snackbar.Message>
                      {i18n.t('compose_assistant_file_uploader_error_title')}
                      &nbsp;
                      {error === 'size'
                        ? i18n.t('compose_assistant_file_uploader_error_size')
                        : error === 'type'
                          ? i18n.t('compose_assistant_file_uploader_error_type')
                          : i18n.t(
                              'compose_assistant_file_uploader_error_unexpected',
                            )}
                    </Snackbar.Message>
                    <Snackbar.Actions>
                      <Button
                        variant="transparent"
                        size="medium"
                        tone="neutral"
                        onClick={() => setError(null)}
                      >
                        {i18n.t('dismiss')}
                      </Button>
                    </Snackbar.Actions>
                  </Snackbar.Content>
                </Snackbar>
              </Snackbar.Position>
            )}
            <div className={styles.AddSourcesModalFooter}>
              <div className={styles.AddSourcesModalFooterLeft}>
                <Text
                  monospace
                  color="subtle"
                  size="small"
                  className={styles.AddSourcesModalEsc}
                >
                  {i18n.t('esc')}
                </Text>
                <Text monospace color="subtle" size="small">
                  {i18n.t('close')}
                </Text>
              </div>
              <div className={styles.AddSourcesModalFooterRight}>
                <Text size="small" color="subtle">
                  {i18n.t('compose_add_sources_count', {
                    count: sources.length,
                    plural: sources.length === 1 ? '' : 's',
                  })}
                </Text>
                <Button
                  size="medium"
                  variant="primary"
                  disabled={sources.length === 0}
                  onClick={onRequestClose}
                >
                  {i18n.t('done')}
                </Button>
              </div>
            </div>
            {isDraggingOver && (
              <div
                className={classnames(styles.AddSourcesModalDragOverlay, {
                  [styles.AddSourcesModalDragOverlayDark]: isDarkMode,
                })}
              >
                <AiStorageMini
                  altText=""
                  className={styles.AddSourcesModalDragIcon}
                />
                <Text className={styles.AddSourcesModalDragTitle}>
                  {i18n.t('compose_assistant_file_uploader_dnd_title')}
                </Text>
                <Text className={styles.AddSourcesModalDragDescription}>
                  {i18n.t('compose_assistant_file_uploader_dnd_subtitle')}
                </Text>
                <Text className={styles.AddSourcesModalDragSubtext}>
                  {i18n.t('compose_assistant_file_uploader_dnd_text')}
                </Text>
              </div>
            )}
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);
AddSourcesModal.displayName = 'AddSourcesModal';
