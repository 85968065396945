import { stacks } from '@dropbox/api-v2-client';
import {
  asShortcuts,
  DEFAULT_SECTION_ID,
} from '@mirage/service-stacks/service/utils';
import { useMemo } from 'react';
import { useStackByNsId } from './hooks';

/**
 * Fetches stacks items sorted by sections if included in the stack data
 */
export function useStackItemsByStackIdAndSection(stackNsId: string) {
  const { stack, items: _items } = useStackByNsId(stackNsId);
  const isLoading = !stack || !_items;
  const stackItems = asShortcuts(_items || []);
  const sections = stack?.stack_data?.section_data?.sections ?? [];

  const stackItemsBySection = useMemo(() => {
    const sectionMap: { [sectionName: string]: stacks.StackItemShortcut[] } =
      {};

    stackItems?.forEach((item) => {
      const { parent_section_id: parentSectionId = DEFAULT_SECTION_ID } = item;
      // Note: it's possible that an item has an orphaned parent section id
      // let's check if the section exists before adding the item to it
      const sectionToAddTo =
        sections.find((s) => s.id === parentSectionId)?.id ||
        DEFAULT_SECTION_ID;

      if (!sectionMap[sectionToAddTo]) {
        sectionMap[sectionToAddTo] = [item];
      } else {
        sectionMap[sectionToAddTo].push(item);
      }
    });

    return sectionMap;
  }, [sections, stackItems]);

  return { stack, sections, isLoading, stackItemsBySection };
}
