import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import type { SupportedMimeTypes } from './file-parser.worker';
import type { TextItem } from 'pdfjs-dist/types/src/display/api';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

let worker: Worker | null = null;

const PARSE_TIMEOUT = 30000;

const withTimeout = async <T>(
  promise: Promise<T>,
  timeoutMs: number,
): Promise<T> => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise<never>((_, reject) => {
    timeoutHandle = setTimeout(() => {
      reject(new Error('Operation timed out'));
    }, timeoutMs);
  });
  try {
    return await Promise.race([promise, timeoutPromise]);
  } finally {
    clearTimeout(timeoutHandle!);
  }
};

// Allow injection of worker for testing
export const createWorker = (mockWorkerUrl?: string) => {
  if (mockWorkerUrl === 'mock-worker-url') {
    return new Worker(mockWorkerUrl);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Worker(new URL('./file-parser.worker.ts', import.meta.url), {
    type: 'module',
  });
};

// Handle all supported files except pdf which is handled in its own worker
async function parseInWorker(
  file: File,
  mockWorkerUrl?: string,
): Promise<string> {
  if (!worker) {
    worker = createWorker(mockWorkerUrl);
  }

  return new Promise((resolve, reject) => {
    if (!worker) return reject(new Error('Worker failed to initialize'));

    const cleanup = () => {
      worker?.removeEventListener('message', handleMessage);
      worker?.removeEventListener('error', handleError);
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.data.error) {
        cleanup();
        reject(new Error(event.data.error));
      } else {
        cleanup();
        resolve(event.data.result);
      }
    };

    const handleError = (error: ErrorEvent) => {
      cleanup();
      reject(new Error(`Worker failed: ${error}`));
    };

    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', handleError);

    worker.postMessage({ file }, []);
  });
}

async function handlePdf(file: File): Promise<string> {
  try {
    const arrayBuffer = await withTimeout(file.arrayBuffer(), PARSE_TIMEOUT);
    const pdf = await withTimeout(
      pdfjsLib.getDocument({
        data: arrayBuffer,
        disableAutoFetch: true,
        disableStream: true,
      }).promise,
      PARSE_TIMEOUT,
    );

    if (pdf.numPages > 1000) {
      throw new Error('PDF has too many pages');
    }

    const pagePromises = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      pagePromises.push(
        withTimeout(
          pdf.getPage(i).then((page) => page.getTextContent()),
          PARSE_TIMEOUT / pdf.numPages,
        ),
      );
    }

    const pages = await Promise.all(pagePromises);
    return pages
      .map((page) =>
        page.items
          .filter((item): item is TextItem => 'str' in item)
          .map((item) => item.str)
          .join(' '),
      )
      .join('\n\n');
  } catch (error) {
    throw new Error(`PDF processing failed ${error}`);
  }
}

export async function parseFileInWorker(
  file: File,
  mockWorkerUrl?: string,
): Promise<string> {
  if (file.type === 'application/pdf') {
    return handlePdf(file);
  }
  return parseInWorker(file, mockWorkerUrl);
}

export function terminateWorker() {
  worker?.terminate();
  worker = null;
}

export function isMimeTypeSupported(type: string): type is SupportedMimeTypes {
  return [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
    'text/csv',
  ].includes(type);
}
