import { dash } from '@dropbox/api-v2-client';
import { getCurations } from '@mirage/service-curations';
import { VERIFIED_CURATION_TAG } from '@mirage/shared/curations';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { HydratedCuration } from '@mirage/service-curations';

enum FetchStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export const useGetVerifiedResults = () => {
  const [data, setData] = useState<{
    results: HydratedCuration[];
    cursor?: string;
  }>({
    results: [],
    cursor: undefined,
  });
  const [state, setState] = useState<FetchStatus>(FetchStatus.IDLE);
  const initialFetchComplete = useRef(false);

  const fetchData = useCallback(async () => {
    if (state === FetchStatus.LOADING) return;

    setState(FetchStatus.LOADING);
    try {
      const response = await getCurations({
        curation_type: VERIFIED_CURATION_TAG,
      });

      setData({
        results: response?.curations,
        cursor: response?.cursor,
      });
      setState(FetchStatus.IDLE);
    } catch (e) {
      // Keep results state as-is. Client can decide to retry by calling fetchMoreData()
      setState(FetchStatus.ERROR);
    }
  }, [state]);

  const fetchMoreData = useCallback(async () => {
    if (state === FetchStatus.LOADING || !data.cursor) return;

    setState(FetchStatus.LOADING);
    try {
      const response = await getCurations({
        curation_type: VERIFIED_CURATION_TAG,
        cursor: data.cursor,
      });

      setData((prev) => ({
        results: [...prev.results, ...(response?.curations || [])],
        cursor: response?.cursor,
      }));
      setState(FetchStatus.IDLE);
    } catch (e) {
      // Keep results state as-is. Client can decide to retry by calling fetchMoreData()
      setState(FetchStatus.ERROR);
    }
  }, [state, data.cursor]);

  useEffect(() => {
    if (initialFetchComplete.current) return;
    initialFetchComplete.current = true;
    fetchData();
  }, [fetchData]);

  // Update our "cache" with the newly edited/deleted verification
  const updateSavedVerification = useCallback(
    (uuid: string, curation: dash.Curation | null) => {
      setData((prev) => ({
        ...prev,
        // If curation exists, update the state with new curation
        results: curation
          ? prev.results.map((result) =>
              result.curation?.entity_uuid === uuid
                ? { ...result, curation }
                : result,
            )
          : // Otherwise, filter it out (user deleted it)
            prev.results.filter(
              (result) => result.curation?.entity_uuid !== uuid,
            ),
      }));
    },
    [],
  );

  return {
    data: data.results,
    loading: state === FetchStatus.LOADING,
    error: state === FetchStatus.ERROR,
    hasNextPage: !!data.cursor,
    fetchData,
    fetchMoreData,
    updateSavedVerification,
  };
};
