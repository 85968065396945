// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3674
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { FeatureLine } from "../enums/feature_line";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";

// Event_name: 'select.dash_search_results_see_more'
// Description: User Clicks "see more" button on the SERP category.
// Owner: otc
export type Select_DashSearchResultSeeMore = PAPEventGeneric<
  'dash',
  'select',
  'dash_search_results_see_more',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Boolean if the search result was displayed within a Category
    categorizedResult?: boolean;
  }
>;

export function PAP_Select_DashSearchResultSeeMore(properties?: Select_DashSearchResultSeeMore['properties']): Select_DashSearchResultSeeMore {
  return <Select_DashSearchResultSeeMore>{
    class: 'dash',
    action: 'select',
    object: 'dash_search_results_see_more',
    properties,
  };
}
