import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { KeyboardEvent, KeyboardEventHandler, useCallback } from 'react';
import styles from './SearchSpellingSuggestion.module.css';

type SearchSpellingSuggestionProps = {
  correctedQuery: string;
  originalQuery: string;
  onClickOriginalQuery: () => void;
  onClickCorrectedQuery: () => void;
};

export function SearchSpellingSuggestion({
  correctedQuery,
  originalQuery,
  onClickOriginalQuery,
  onClickCorrectedQuery,
}: SearchSpellingSuggestionProps) {
  const handleKeyDown = useCallback(
    (callback: () => void): KeyboardEventHandler<HTMLSpanElement> => {
      return (event: KeyboardEvent) => {
        if (event.key === KeyCodes.enter) {
          event.preventDefault();
          callback();
        }
      };
    },
    [],
  );

  return (
    <Box className={styles.spellingSuggestionContainer}>
      <Text
        variant="paragraph"
        color="standard"
        size="small"
        className={styles.spellingSuggestionText}
      >
        {i18n.t('spelling_suggestion_showing_results_for')}
        <span
          role="button"
          aria-label={i18n.t('spelling_suggestion_showing_results_for_label')}
          onClick={onClickCorrectedQuery}
          onKeyDown={handleKeyDown(onClickCorrectedQuery)}
          tabIndex={0}
          className={styles.spellingSuggestionButton}
        >
          <Truncate lines={1}>{correctedQuery}</Truncate>
        </span>
      </Text>
      <Text
        variant="paragraph"
        color="standard"
        size="small"
        className={styles.spellingSuggestionText}
      >
        {i18n.t('spelling_suggestion_search_instead_for')}
        <span
          role="button"
          aria-label={i18n.t('spelling_suggestion_search_instead_for_label')}
          onClick={onClickOriginalQuery}
          onKeyDown={handleKeyDown(onClickOriginalQuery)}
          tabIndex={0}
          className={styles.spellingSuggestionButton}
        >
          <Truncate lines={1}>{originalQuery}</Truncate>
        </span>
      </Text>
    </Box>
  );
}
