import type { SearchResult } from '@mirage/service-dbx-api';

export type ResultCategory = {
  title: string;
  results: SearchResult[];
  categoryType: Category;
};

type Category = 'emails' | 'most_relevant' | 'all_results';

export function categorizeResults(
  results: SearchResult[] = [],
): ResultCategory[] {
  const mostRelevantResults = results.slice(0, 3);

  const categories: Record<Category, ResultCategory> = {
    most_relevant: {
      title: 'Most relevant',
      results: mostRelevantResults,
      categoryType: 'most_relevant',
    },
    emails: {
      title: 'Emails',
      results: [],
      categoryType: 'emails',
    },
    all_results: {
      title: 'All results',
      results: [],
      categoryType: 'all_results',
    },
  };

  const remainingResults = results.slice(3);
  remainingResults.forEach((result) => {
    const fileType = result.fileTypeInfo?.id;

    if (fileType === 'email' && categories.emails.results.length < 2) {
      categories.emails.results.push(result);
    } else {
      categories.all_results.results.push(result);
    }
  });

  // Convert the map values to an array
  return [
    categories.most_relevant,
    categories.emails,
    categories.all_results,
  ].filter((category) => category.results.length !== 0);
}
