/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */
import { Button } from '@dropbox/dig-components/buttons';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { FailFill } from '@dropbox/dig-icons/assets';
import { setFeatureOverride } from '@mirage/service-experimentation';
import {
  FeatureFlag,
  FeatureValue,
  StormcrowValues,
} from '@mirage/service-experimentation/features';
import { useFeatureFlags } from '@mirage/service-experimentation/useInitFeatureFlags';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import classnames from 'classnames';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, Fragment, useState } from 'react';
import styles from './FeatureList.module.css';

type DisplayStrings = {
  short: string;
  long: string;
  options: string[];
};

const UNDEFINED_STRINGS: DisplayStrings = {
  short: 'UNDEF',
  long: 'undefined',
  options: [],
};

const TRUE_STRINGS: DisplayStrings = {
  short: 'TRUE',
  long: 'TRUE',
  options: ['FALSE', 'TRUE'],
};

const FALSE_STRINGS: DisplayStrings = {
  short: 'FALSE',
  long: 'FALSE',
  options: ['FALSE', 'TRUE'],
};

const STRING_OPTIONS: DisplayStrings['options'] = StormcrowValues.map((s) => s);

function getDisplayStrings(value: FeatureValue): DisplayStrings {
  switch (typeof value) {
    case 'undefined':
      return UNDEFINED_STRINGS;
    case 'boolean':
      return value ? TRUE_STRINGS : FALSE_STRINGS;
    case 'string':
      return {
        short: value,
        long: value,
        options: STRING_OPTIONS,
      };
    case 'number': {
      const n = String(value);
      return { short: n, long: n, options: [] };
    }
    case 'object':
      return {
        short: 'OBJECT',
        long: JSON.stringify(value, null, 2),
        options: [],
      };
    // Implicit exhaustiveness here
  }
}

function getActualValueFromOption(option: string) {
  switch (option) {
    case 'UNDEF':
      return undefined;
    case 'TRUE':
      return true;
    case 'FALSE':
      return false;
    default:
      return option;
  }
}

export const FeatureList = ({
  sortedFeatures,
}: {
  sortedFeatures: FeatureFlag[];
}) => {
  const [filter, setFilter] = useState<string>('');
  const updateInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };
  const clearInput = () => {
    setFilter('');
  };

  const filteredFeatures = sortedFeatures.filter((item) =>
    item.featureName.toLowerCase().includes(filter.toLowerCase()),
  );

  const featureFlags = useFeatureFlags();
  return (
    <div key="features" className={styles.featuresWrapper}>
      <div className={styles.featureFilterContainer}>
        <Text isBold color="subtle">
          Feature name
        </Text>
        <div className={styles.featureFilterInputContainer}>
          <TextInput
            size="small"
            value={filter}
            onChange={updateInput}
            placeholder="filter"
            withRightAccessory={
              filter && (
                <IconButton
                  variant="transparent"
                  size="small"
                  onClick={clearInput}
                >
                  <UIIcon src={FailFill} aria-label="DO_NOT_COPY-Clear" />
                </IconButton>
              )
            }
          />
        </div>
      </div>
      <Text isBold color="subtle" className={styles.centerX}>
        Effective Value
      </Text>
      <Text isBold color="subtle" className={styles.centerX}>
        Server Value
      </Text>
      <Text isBold color="subtle" className={styles.centerX}>
        Source
      </Text>
      {filteredFeatures
        .filter((feature) => !feature.userOverrideDisabled)
        .map(({ featureName, source, customVariants }) => {
          const { value: serverValue, overrideValue } =
            featureFlags[featureName];

          const resolvedValue = overrideValue ?? serverValue;
          const { short, options } = getDisplayStrings(resolvedValue);
          const { long: serverDisplayValue } = getDisplayStrings(serverValue);
          const disabled = options.length === 0;

          // Add any extra "custom variants" into the displayed options
          const optionsWithExtraVariants = [
            ...options,
            ...(customVariants || []),
          ];

          return (
            <Fragment key={featureName}>
              <Text isBold className={styles.textSelectable}>
                {featureName}
              </Text>

              <div className={styles.centerX}>
                <Menu.Wrapper>
                  {({ getTriggerProps, getContentProps }) => (
                    <div>
                      <Button
                        variant="opacity"
                        disabled={disabled}
                        {...(disabled ? undefined : getTriggerProps())}
                        withDropdownIcon={options.length > 0}
                        className={classnames(
                          styles.selectButton,
                          overrideValue !== undefined &&
                            styles.buttonWithOverride,
                        )}
                      >
                        {short}
                      </Button>
                      <Menu.Content {...getContentProps()}>
                        <Menu.Segment>
                          {optionsWithExtraVariants.map((option) => {
                            const actualValue =
                              getActualValueFromOption(option);

                            return (
                              <Menu.SelectItem
                                key={option}
                                value={option}
                                onClick={() => {
                                  // Stormcrow values are always string only.
                                  setFeatureOverride(
                                    featureName,
                                    actualValue as FeatureValue,
                                  );
                                }}
                                selected={actualValue === resolvedValue}
                              >
                                {option}
                              </Menu.SelectItem>
                            );
                          })}
                        </Menu.Segment>
                      </Menu.Content>
                    </div>
                  )}
                </Menu.Wrapper>
              </div>

              <div className={styles.centerX}>
                <Text
                  color="faint"
                  className={classnames(styles.serverDisplayValue, {
                    [styles.serverDisplayFormatObject]: short === 'OBJECT',
                  })}
                >
                  {serverDisplayValue}
                  {overrideValue !== undefined && (
                    <DigTooltip title="Locally overridden">
                      <div className={styles.overrideAsterisk}>*</div>
                    </DigTooltip>
                  )}
                </Text>
              </div>

              <Text className={styles.centerX}>{capitalize(source)}</Text>
            </Fragment>
          );
        })}
    </div>
  );
};
