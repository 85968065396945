import { forwardRef, useImperativeHandle, useRef } from 'react';
import { OnboardingSurveyQuestionAnswer } from '../types/onboarding-surveys';
import { OnboardingCompanyInfoStep } from './OnboardingCompanyInfoStep';
import { OnboardingJobsToBeDoneStep } from './OnboardingJobsToBeDoneStep';
import { OnboardingLargeTeams } from './OnboardingLargeTeams';
import { OnboardingProfilingStep } from './OnboardingProfilingStep';

type SelfServeOnboardingStepProps = {
  step: SelfServeOnboardingStep | undefined;
  isAdmin: boolean;
  onValidationChange: (
    valid: boolean,
    answers: OnboardingSurveyQuestionAnswer[],
  ) => void;
};

export enum SelfServeOnboardingStep {
  COMPANY_INFO = 'company-info',
  PROFILING = 'profiling',
  LARGE_TEAM = 'large-team',
  JTBD = 'jtbd',
  ORGANIZATION_CONNECTORS = 'org-connectors',
  CONNECTORS = 'connectors',
  TEAM_INVITE = 'team-invite',
  DOWNLOAD_UPSELL = 'download',
}

export const SelfServeOnboardingItem = forwardRef(
  (
    { step, isAdmin, onValidationChange }: SelfServeOnboardingStepProps,
    ref,
  ) => {
    const onboardingStepRef = useRef();

    useImperativeHandle(ref, () => ({
      submitSurvey: () => {
        if (
          !onboardingStepRef.current ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !(onboardingStepRef.current as any).submitSurvey
        )
          return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (onboardingStepRef.current as any).submitSurvey();
      },
    }));

    switch (step) {
      case SelfServeOnboardingStep.COMPANY_INFO:
        return (
          <OnboardingCompanyInfoStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
          />
        );
        return null;
      case SelfServeOnboardingStep.PROFILING:
        return (
          <OnboardingProfilingStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.JTBD:
        return (
          <OnboardingJobsToBeDoneStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.LARGE_TEAM:
        return <OnboardingLargeTeams />;
      default:
        return null;
    }
  },
);

SelfServeOnboardingItem.displayName = 'SelfServeOnboardingItem';
