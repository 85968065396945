import { stacks } from '@dropbox/api-v2-client';
import { IconButton, IconButtonSize } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon, UIIconSize } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import React from 'react';
import styles from './AddToSectionMenu.module.css';

interface AddToSectionMenuProps {
  disabled?: boolean;
  // Only allow sizes that can be applied to both IconButton and UIIcon
  iconSize?: Extract<IconButtonSize, UIIconSize>;
  onSelectSection: (sectionId: string) => void;
  sections: stacks.Section[];
}

export const AddToSectionMenu: React.FC<AddToSectionMenuProps> = ({
  disabled,
  iconSize = 'small',
  onSelectSection,
  sections,
}) => {
  const isDisabled = disabled || sections.length === 0;

  return (
    <Menu.Wrapper
      onClick={(e) => {
        // This menu may be nested inside of a larger clickable element (e.g. LinkSuggestion)
        // Keeping the events contained within the Menu ensures clicks don't trigger parent listeners
        // without impacting any menu functionality (e.g. click outside, trigger, select)
        e.stopPropagation();
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        // Same comment as above. We only do it on "trigger" keys to avoid things like focus
        // trapping keyboard navigation.
        if (e.key === KeyCodes.enter || e.key === KeyCodes.space) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onSelection={onSelectSection}
    >
      {({ getContentProps, getTriggerProps, openMenu, triggerRef }) => (
        <>
          <DigTooltip
            title={i18n.t('stacks_add_to_section')}
            triggerRef={triggerRef}
          >
            {isDisabled ? (
              <Box
                as="span"
                className={styles.buttonContainer}
                data-testid="Stacks-addtoSectionBtnWrapper"
                outlineWidth="0"
                tabIndex={0}
              >
                <IconButton
                  aria-label={i18n.t('stacks_add_to_section')}
                  data-testid="Stacks-addtoSectionBtn"
                  disabled
                  size={iconSize}
                  style={{ pointerEvents: 'none' }}
                  variant="borderless"
                >
                  <UIIcon size={iconSize} src={ChevronDownLine} />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                {...getTriggerProps({
                  onKeyDown: (e) => {
                    // Enter key is getting swallowed for some reason. This ensures the menu opens as expected.
                    // Note that this doesn't override behavior for other keys, it is called in addition.
                    if (e.key === KeyCodes.enter) {
                      openMenu({});
                    }
                  },
                })}
                aria-label={i18n.t('stacks_add_to_section')}
                data-testid="Stacks-addtoSectionBtn"
                size={iconSize}
                variant="borderless"
              >
                <UIIcon size={iconSize} src={ChevronDownLine} />
              </IconButton>
            )}
          </DigTooltip>
          <Menu.Content
            {...getContentProps()}
            data-testid="Stacks-addtoSectionMenu"
          >
            <Menu.Segment withLabel={i18n.t('stacks_add_to_section')}>
              {sections.map((section) => (
                <Menu.ActionItem key={section.id} value={section.id}>
                  {section.title}
                </Menu.ActionItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
