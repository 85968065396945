import { Text } from '@dropbox/dig-components/typography';
import { BoxEmptySpot } from '@dropbox/dig-illustrations';
import { useEffect, useRef } from 'react';
import styles from './PublishedContentEmptyState.module.css';

export const PublishedContentEmptyState = ({
  text,
  onShownEmptyState,
}: {
  text: string;
  onShownEmptyState: () => void;
}) => {
  const loggedExposure = useRef(false);

  useEffect(() => {
    if (!loggedExposure.current) {
      loggedExposure.current = true;
      onShownEmptyState();
    }
  }, [onShownEmptyState]);

  return (
    <div className={styles.container}>
      <div className={styles.assetContainer}>
        <div className={styles.iconContainer}>
          <BoxEmptySpot />
        </div>
      </div>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.message} size="medium" tagName="p">
            {text}
          </Text>
        </div>
      </div>
    </div>
  );
};
