import { useEffect, useState } from 'react';
import { observeConversation } from '..';
import { ConversationMessage } from '../types';

/**
 * Hook to subscribe to conversation updates.
 * @param conversationId The ID of the conversation to observe
 * @returns The current state of the conversation messages
 */
export default function useConversation(conversationId: string | undefined) {
  const [conversation, setConversation] = useState<ConversationMessage[]>([]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }

    const subscription = observeConversation(conversationId).subscribe(
      (messages) => {
        setConversation(messages);
      },
    );

    return () => subscription.unsubscribe();
  }, [conversationId]);

  return conversation;
}
