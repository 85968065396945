import { TableNode } from '@lexical/table';
import { EditorConfig, LexicalEditor, setDOMUnmanaged } from 'lexical';

/**
 * Custom Table Node mainly intended to inject a table-controls container inside of Lexical tables'
 * scrollable containers.
 */
export class CustomTableNode extends TableNode {
  static getType(): string {
    return 'custom-table';
  }

  static clone(node: CustomTableNode): CustomTableNode {
    return new CustomTableNode(node.__key);
  }

  static importJSON(): CustomTableNode {
    return new CustomTableNode();
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor) {
    const dom = super.createDOM(config, editor);

    const controlsContainer = document.createElement('div');
    controlsContainer.className = TABLE_CONTROLS_CONTAINER_CLASS;

    // NOTE: this helps avoid Lexical from trying to change selection state when clicking on the controls
    controlsContainer.addEventListener('pointerdown', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    controlsContainer.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    controlsContainer.setAttribute(
      'style',
      'user-select: none; cursor: not-allowed;',
    );
    controlsContainer.contentEditable = 'false';
    setDOMUnmanaged(controlsContainer);
    dom.appendChild(controlsContainer);

    return dom;
  }
}

const TABLE_CONTROLS_CONTAINER_CLASS = 'table-controls';

function getTableControlsContainer(
  tableContainerElement: HTMLElement,
): HTMLElement | undefined {
  const tableControlsContainer = tableContainerElement.querySelector(
    `.${TABLE_CONTROLS_CONTAINER_CLASS}`,
  );
  return (tableControlsContainer as HTMLElement) || undefined;
}

export function getTableElements(containerElement: HTMLElement) {
  const tableElement = containerElement.querySelector('table');
  const controlsContainer = getTableControlsContainer(containerElement);
  if (tableElement && controlsContainer) {
    return { tableElement, controlsContainer, containerElement };
  } else {
    return undefined;
  }
}
