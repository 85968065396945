import { stacks } from '@dropbox/api-v2-client';
import i18n from '@mirage/translations';
import { getTimeAgoString } from './time';
import { urlToDomainName, urlToService } from './urlRulesUtil';

export const getStackItemMetadata = (
  item: {
    url?: string;
    last_modified_time_utc_sec?: number;
    creator?: stacks.StackItemUser;
  },
  showCreatorName?: boolean,
): string[] | undefined => {
  const metadata = [];

  // Service/Domain name is being replaced by creator name behind a flag
  if (!showCreatorName && item.url) {
    metadata.push(urlToService(item.url) || urlToDomainName(item.url));
  }

  if (item.last_modified_time_utc_sec) {
    metadata.push(
      i18n.t('stack_item_updated_ago', {
        timeAgo: getTimeAgoString(
          item.last_modified_time_utc_sec * 1000,
          false,
          true,
        ),
      }),
    );
  }

  if (showCreatorName && item.creator) {
    metadata.push(
      i18n.t('stack_item_added_by', {
        name: item.creator.display_name,
      }),
    );
  }

  return metadata ?? undefined;
};
