import { useTheme } from '@dropbox/dig-foundations';
import { useIsSmallSizeForSidebar } from '@mirage/shared/responsive/mobile';
import classnames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './SideBarContainer.module.css';
import { SideBarFooter } from './SideBarFooter';

interface SideBarContainerProps {
  headerActionLeft?: React.ReactNode;
  headerActionRight?: React.ReactNode;
  keepExpanded?: boolean;
  children: React.ReactNode;
  rightPaneExpanded?: boolean;
  footer?: React.ReactNode;
}

const hasOverflow = (element: HTMLDivElement): boolean => {
  return element.scrollHeight > element.clientHeight;
};

export const SideBarContainer = memo(
  ({
    headerActionLeft,
    headerActionRight,
    keepExpanded,
    children,
    rightPaneExpanded,
    footer,
  }: SideBarContainerProps) => {
    const theme = useTheme();
    const contentRef = useRef<HTMLDivElement>(null);
    const [showBorder, setShowBorder] = useState(false);

    useEffect(() => {
      const currentContentRef = contentRef.current;

      if (!currentContentRef) {
        return;
      }
      const handler = () => {
        setShowBorder(hasOverflow(currentContentRef));
      };

      window.addEventListener('resize', handler);
      currentContentRef.addEventListener('scroll', handler);

      const observer = new MutationObserver(handler);

      observer.observe(currentContentRef, {
        childList: true,
        subtree: true,
        attributes: true,
      });

      handler();
      return () => {
        window.removeEventListener('resize', handler);
        currentContentRef.removeEventListener('scroll', handler);
        observer.disconnect();
      };
    }, []);

    const isSmallSize = useIsSmallSizeForSidebar();
    return (
      <div
        className={classnames(styles.SideBarContainer, {
          [styles.SideBarContainerDark]: theme.mode === 'dark',
          [styles.SideBarContainerExpanded]: keepExpanded,
          [styles.SideBarContainerDarkAndRightPaneExpanded]:
            theme.mode === 'dark' && rightPaneExpanded,
          [styles.SideBarContainerMobileSize]: isSmallSize && keepExpanded,
        })}
      >
        <div className={styles.SideBarHeader}>
          <div>{headerActionLeft}</div>
          <div>{headerActionRight}</div>
        </div>
        <div className={styles.SideBarContent} ref={contentRef}>
          {children}
        </div>
        {!!footer && (
          <SideBarFooter showBorder={showBorder}>{footer}</SideBarFooter>
        )}
      </div>
    );
  },
);
SideBarContainer.displayName = 'SideBarContainer';
