import i18n from '@mirage/translations';
import { ONBOARDING_VERSION } from '../flows/constants';
import {
  ActionButtonType,
  OnboardingStepItem,
  SelfServeOnboarding,
} from './SelfServeOnboarding';
import { SelfServeOnboardingStep } from './SelfServeOnboardingItem';

const ONBOARDING_STEPS: OnboardingStepItem[] = [
  {
    step: SelfServeOnboardingStep.COMPANY_INFO,
    title: i18n.t('self_serve_company_info_title'),
    description: i18n.t('self_serve_company_info_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.PROFILING,
    title: i18n.t('self_serve_profiling_title'),
    description: i18n.t('self_serve_profiling_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.JTBD,
    title: i18n.t('self_serve_jtbd_title'),
    description: i18n.t('self_serve_jtbd_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.ORGANIZATION_CONNECTORS,
    title: i18n.t('self_serve_org_connectors_title'),
    description: i18n.t('self_serve_org_connectors_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.CONNECTORS,
    title: i18n.t('self_serve_connectors_title'),
    secondaryAction: ActionButtonType.Back,
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.TEAM_INVITE,
    title: i18n.t('self_serve_team_invite_title'),
    description: i18n.t('self_serve_team_invite_description'),
    primaryActions: [ActionButtonType.Skip, ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.DOWNLOAD_UPSELL,
    title: i18n.t('self_serve_download_upsell_title'),
    description: i18n.t('self_serve_download_upsell_description'),
    primaryActions: [ActionButtonType.Later, ActionButtonType.Continue],
  },
];

type Props = {
  routeParams?: Record<string, string | undefined>;
};

export const AdminSelfServeOnboarding = ({ routeParams }: Props) => {
  return (
    <SelfServeOnboarding
      steps={ONBOARDING_STEPS}
      routeParams={routeParams}
      onboardingVariant={ONBOARDING_VERSION.ADMIN_SELF_SERVE_V1}
    />
  );
};
