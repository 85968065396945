// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3647
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { OnboardingStep } from "../enums/onboarding_step";

// Event_name: 'view.dash_self_serve_onboarding'
// Description: Event fired when a self serve onboarding step is viewed
// Owner: otc
export type View_DashSelfServeOnboarding = PAPEventGeneric<
  'dash',
  'view',
  'dash_self_serve_onboarding',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Name of the onboarding step event corresponds to
    onboardingStep?: OnboardingStep;
    // Set to true if user going through Self-serve onboarding is an admin
    selfServeIsTeamAdmin?: boolean;
  }
>;

export function PAP_View_DashSelfServeOnboarding(properties?: View_DashSelfServeOnboarding['properties']): View_DashSelfServeOnboarding {
  return <View_DashSelfServeOnboarding>{
    class: 'dash',
    action: 'view',
    object: 'dash_self_serve_onboarding',
    properties,
  };
}
