import type { users } from '@dropbox/api-v2-client';
import type authentication from '@mirage/service-auth/service';

export type RedirectState = {
  redirectToPathEncoded: string | null;
};

export enum ApiEnvironment {
  PROD = 'prod',
  STAGE = 'stage',
  VALIDATION = 'validation',
}

export type DropboxAuthData = {
  sessionId: string;
  codeVerifier: string;
  connectionState: {
    service: string;
    context: string;
  };
};

export type DropboxAuthQuery = {
  state: string;
  code: string;
};

export enum AuthenticationError {
  AuthenticationNotInProgress = 'authentication-not-in-progress',
  CouldNotRefreshToken = 'could-not-refresh-token',
  CouldNotVerifyToken = 'could-not-verify-token',
  InvalidResponse = 'invalid-response',
  RefreshTokenMissing = 'refresh-token-missing',
}

export enum AuthenticationStatus {
  None = 'none',
  InProgress = 'in-progress',
  Initializing = 'initializing',
  Error = 'error',
  Finishing = 'finishing',
  Complete = 'complete',
}

export type AuthenticationData = {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: number;
  uid: string | undefined;
};

export type AccountIds = {
  uid: users.FullAccount['uid'];
  account_id: users.FullAccount['account_id'];
};

export type Service = Awaited<ReturnType<typeof authentication>>;

type LoginRedirectConfiguration = {
  redirectToPathURLParam: string;
  rootRoutePath: string;
  loginRoutePath: string;
};

export type DropboxAuthenticationConfiguration = {
  clientId: string;
  domain?: string;
  clientSecret?: string;
  oauthRedirectURL: string;
  openURL: (url: string) => void;
  loginRedirectConfig?: LoginRedirectConfiguration;
  apiEnvironment?: ApiEnvironment;
};

export type AuthenticationStorage = {
  installId: string;
  codeVerifier: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
  uid: string | undefined;
  currentAccount: users.FullAccount | undefined;
  apiEnvironment: ApiEnvironment;
  forceReauthentication: boolean;
  sessionId: string;
  shouldTraceRequests: boolean;
};

export type AuthenticationState = {
  status: AuthenticationStatus;
  error?: AuthenticationError;
};
