import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import { useStackItemsByStackIdAndSection } from '@mirage/stacks/useStackItemsByStackIdAndSection';
import { WorkingSetCardData } from '@mirage/working-set';
import { StackSectionWithItems } from './StackCardPopoverSectionItem';

export const StackCardPopover = ({
  stackData,
}: {
  stackData: WorkingSetCardData;
}) => {
  const { stackItemsBySection, sections } = useStackItemsByStackIdAndSection(
    stackData.namespaceId,
  );

  return (
    <div>
      {/* Display stack items not in a section first */}
      {
        <StackSectionWithItems
          sectionItems={stackItemsBySection[DEFAULT_SECTION_ID]}
          stackData={stackData}
        />
      }

      {/* Display all other stack items tack items with a section */}
      {Object.keys(stackItemsBySection)
        .filter((s) => s !== DEFAULT_SECTION_ID)
        .map((sectionId) => {
          const sectionName =
            sections.find((s) => s.id === sectionId)?.title || '';

          return (
            <StackSectionWithItems
              key={sectionId}
              sectionName={sectionName}
              sectionItems={stackItemsBySection[sectionId]}
              stackData={stackData}
            />
          );
        })}
    </div>
  );
};
