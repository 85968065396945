import { context_engine } from '@dropbox/api-v2-client';
import { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getResponse = async (
  callApiV2: APIv2Callable,
  newMessage: context_engine.ConversationsUserMessage,
  conversationId: string,
  history?: context_engine.ConversationsChatMessage[],
  documentIds?: string[],
): Promise<context_engine.ConversationsChatMessage[]> => {
  const response = await callApiV2('contextEngineConversationsGetResponse', {
    conversation_id: conversationId ?? '',
    history,
    user_message: newMessage,
    document_ids: documentIds,
  });

  return response.messages ?? [];
};
