import { useEffect, useState } from 'react';
import { getRoutePathname } from '../util/route-pathname';

import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';

export function useDashActionSurface() {
  // We can't use useLocation here as this is always `/` in Scout.
  const pathname = getRoutePathname();
  const [dashActionSurface, setDashActionSurface] =
    useState<DashActionSurface>();

  useEffect(() => {
    const surface = getDashActionSurface(pathname);
    setDashActionSurface(surface);
  }, [pathname]);

  return { dashActionSurface };
}

export const getDashActionSurface = (
  pathname: string,
): DashActionSurface | undefined => {
  // Allow paths like `/stacks/` to match.
  if (pathname.length > 1 && pathname.endsWith('/')) {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  switch (pathname) {
    case '':
    case '/':
      return 'start_page';
    case '/launcher':
      return 'launcher';
    case '/stacks':
      return 'stacks_page';
    case '/search_results':
      return 'serp';
    case '/chat':
      return 'compose_page';
    case '/embed/open':
    case '/embed/pre_warm':
      return 'embedded';
    case '/sidePanel':
      return 'side_panel';
    case '/login':
    case '/oauth':
    case '/logout':
      // These are intentionally undefined; there is no obvious action surface at this time.
      return undefined;
    default:
      if (pathname.startsWith('/initial-sync')) {
        return 'initial_sync';
      } else if (
        pathname.startsWith('/setup') ||
        pathname.startsWith('/onboarding')
      ) {
        return 'setup';
      } else if (pathname.startsWith('/settings')) {
        return 'settings_page';
      } else if (pathname.startsWith('/stacks/')) {
        if (pathname.endsWith('/new')) {
          return 'new_stack_page';
        }
        return 'stack_details_page';
      } else {
        return undefined;
      }
  }
};
