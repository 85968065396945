import { Button, IconButton } from '@dropbox/dig-components/buttons';
import {
  Carousel,
  CarouselButton,
  CarouselContainer,
  CarouselSlide,
  CarouselSlideActions,
  CarouselSlideColumn,
  CarouselSlideColumnContent,
} from '@dropbox/dig-components/carousel';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { Menu } from '@dropbox/dig-components/menu';
import { Modal } from '@dropbox/dig-components/modal';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DeleteLine, MoreHorizontalLine } from '@dropbox/dig-icons/assets';
import { KeyCapSequence } from '@mirage/search/General/KeyCap';
import { openURL } from '@mirage/service-platform-actions';
import { getConnectorNameForAttr } from '@mirage/shared/connectors';
import { isBrowserExtension } from '@mirage/shared/connectors/util';
import { UIConnection, UIWebExtension } from '@mirage/shared/types';
import { isEmail } from '@mirage/shared/util/tiny-utils';
import i18n, { Trans } from '@mirage/translations';
import classnames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import styles from './DisconnectModal.module.css';

const SAFARI_VIDEO_INSTRUCTIONS_URL =
  'https://assets.dropbox.com/images/dashweb/safari-disconnect.mp4';

type SafariDisconnectModalProps = {
  isOpen: boolean;
  onCancel: () => void;
};

const SafariDisconnectModal = ({
  isOpen,
  onCancel,
}: SafariDisconnectModalProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const slide = useRef<HTMLDivElement | null>(null);

  if (!isOpen) {
    return null;
  }

  const stepOneInstructions = (
    <div className={styles.safariStepOne}>
      <Trans
        i18nKey="disconnect_safari_modal_step_1"
        components={{
          strong: <Text className={styles.safariStepOneBold} isBold />,
          keys: <KeyCapSequence keys={['⌘', ',']} />,
        }}
      />
    </div>
  );
  const stepTwoInstructions = (
    <Trans
      i18nKey="disconnect_safari_modal_step_2"
      components={{
        strong: <Text isBold />,
      }}
    />
  );

  const stepThreeInstructions = (
    <Trans
      i18nKey="disconnect_safari_modal_step_3"
      components={{
        strong: <Text isBold />,
      }}
    />
  );

  return (
    <Modal
      open={isOpen}
      isCentered
      aria-labelledby="disconnect-modal-open-button"
    >
      <Carousel width={700} height={420} totalSlides={1}>
        {() => (
          <CarouselContainer>
            <CarouselSlide ref={slide} primaryColor="white">
              <CarouselSlideColumn className={styles.safariModalLeft}>
                <CarouselSlideColumnContent>
                  <Stack
                    gap={'Macro Small'}
                    className={styles.safariDisconnectLeftStack}
                  >
                    <Title
                      weightVariant="emphasized"
                      color="standard"
                      className={styles.safariDisconnectTitle}
                    >
                      {i18n.t('disconnect_safari_modal_title')}
                    </Title>
                    <Stack gap="10px">
                      <Text variant="label" size="medium" isBold>
                        {i18n.t('disconnect_safari_modal_steps_subtitle')}
                      </Text>
                      <LabelGroup
                        withLeftAccessory={
                          <IconButton
                            variant="opacity"
                            shape="standard"
                            size="small"
                            tone="neutral"
                          >
                            <span className={styles.safariInstructionNumber}>
                              1
                            </span>
                          </IconButton>
                        }
                        direction="vertical"
                        align="center"
                        size="medium"
                        withText={stepOneInstructions}
                      />
                      <LabelGroup
                        withLeftAccessory={
                          <IconButton
                            variant="opacity"
                            shape="standard"
                            size="small"
                            tone="neutral"
                          >
                            <span className={styles.safariInstructionNumber}>
                              2
                            </span>
                          </IconButton>
                        }
                        direction="vertical"
                        align="center"
                        size="medium"
                        withText={stepTwoInstructions}
                      />
                      <LabelGroup
                        withLeftAccessory={
                          <IconButton
                            variant="opacity"
                            shape="standard"
                            size="small"
                            tone="neutral"
                          >
                            <span className={styles.safariInstructionNumber}>
                              3
                            </span>
                          </IconButton>
                        }
                        direction="vertical"
                        align="center"
                        size="medium"
                        withText={stepThreeInstructions}
                      />
                    </Stack>
                  </Stack>
                </CarouselSlideColumnContent>
                <CarouselSlideActions
                  className={styles.safariDisconnectButtons}
                >
                  <CarouselButton
                    onClick={onCancel}
                    variant="primary"
                    size="medium"
                  >
                    {i18n.t('disconnect_safari_modal_done_button_text')}
                  </CarouselButton>
                </CarouselSlideActions>
              </CarouselSlideColumn>
              <CarouselSlideColumn className={styles.safariModalRight}>
                <CarouselSlideColumnContent
                  className={styles.safariModalRightContent}
                >
                  <video
                    className={classnames(styles.safariVideo, {
                      [styles.safariVideoLoaded]: isVideoLoaded,
                    })}
                    autoPlay
                    muted
                    playsInline
                    loop
                    onCanPlayThrough={() => setIsVideoLoaded(true)}
                  >
                    <source
                      src={SAFARI_VIDEO_INSTRUCTIONS_URL}
                      type={'video/mp4'}
                    />
                  </video>
                  {!isVideoLoaded && (
                    <Skeleton.Box className={styles.safariVideoLoading} />
                  )}
                </CarouselSlideColumnContent>
              </CarouselSlideColumn>
            </CarouselSlide>
          </CarouselContainer>
        )}
      </Carousel>
    </Modal>
  );
};

interface Props {
  label: string;
  item: UIConnection | UIWebExtension;
  onDisconnect: (item: UIConnection) => void;
}

export const DisconnectModal = ({ label, item, onDisconnect }: Props) => {
  const isWebExtension = isBrowserExtension(item);
  const [openModal, setOpenModal] = useState(false);
  const attrFriendlyName = useMemo(
    () => getConnectorNameForAttr(label || ''),
    [label],
  );
  const isSafariExtension = isWebExtension && item.id === 'safari';

  const close = () => {
    setOpenModal(false);
  };

  const confirm = () => {
    if (isWebExtension) {
      openURL(item.installUrl);
    } else {
      onDisconnect(item);
    }

    setOpenModal(false);
  };

  const handleSelection = (value: string) => {
    if (value === 'disconnect') {
      setOpenModal(true);
    }
  };

  const connectedEmail =
    !isWebExtension && isEmail(item.metadata?.display_identifier)
      ? item.metadata?.display_identifier
      : null;

  return (
    <>
      <Menu.Wrapper closeOnSelection={false} onSelection={handleSelection}>
        {({ getContentProps, getTriggerProps }) => (
          <>
            <IconButton
              data-testid="trigger-menu"
              {...getTriggerProps()}
              variant="borderless"
            >
              <Text color="faint">
                <UIIcon src={MoreHorizontalLine} />
              </Text>
            </IconButton>

            <Menu.Content {...getContentProps()}>
              <Menu.Segment>
                <Menu.ActionItem
                  key="disconnect"
                  value="disconnect"
                  withLeftAccessory={<UIIcon src={DeleteLine} />}
                >
                  {i18n.t('disconnect')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>

      <SafariDisconnectModal
        isOpen={openModal && isSafariExtension}
        onCancel={() => setOpenModal(false)}
      />
      <Modal
        open={openModal && !isSafariExtension}
        isCentered
        onRequestClose={close}
        aria-labelledby="disconnect-modal-open-button"
      >
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title id="disconnect-modal-title">
            {i18n.t('disconnect_title', { serviceName: label })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isWebExtension ? (
            <Text>
              {i18n.t('disconnect_modal_text_for_web_extension', {
                webExtensionName: label,
              })}
            </Text>
          ) : connectedEmail ? (
            <Text>
              {i18n.t('disconnect_modal_text', {
                serviceName: label,
                userEmail: connectedEmail,
              })}
            </Text>
          ) : (
            <Text>
              {i18n.t('disconnect_modal_text_without_email', {
                serviceName: label,
              })}
            </Text>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="opacity" onClick={close}>
            {i18n.t('disconnect_cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={confirm}
            data-testid={`DisconnectModal-confirm-${attrFriendlyName}`}
          >
            {i18n.t('disconnect')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
