import { stacks } from '@dropbox/api-v2-client';
import {
  ReportPapEventFn,
  useMirageAnalyticsContext,
} from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Copy_DashLink } from '@mirage/analytics/events/types/copy_dash_link';
import { PAP_Copy_DashStackSharedLink } from '@mirage/analytics/events/types/copy_dash_stack_shared_link';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useCallback } from 'react';

export function useCopyStackUrlToClipboard(
  stack: stacks.Stack | null,
  actionSurfaceComponent?: ActionSurfaceComponent,
) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const isPublicSharingAllowed = useIsPublicSharingAllowed();

  const onCopyToClipboard = useCallback(() => {
    if (!stack) {
      return;
    }

    copyToClipboard(stack.sharing_data?.shared_link?.url ?? '');
    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });

    reportPapEvent(
      PAP_Copy_DashStackSharedLink({
        ...stackDerivePAPProps(stack),
        actionSurfaceComponent: actionSurfaceComponent || 'stacks',
        featureLine: 'stacks',
        isPublicSharingAllowed: isPublicSharingAllowed,
      }),
    );
  }, [stack, reportPapEvent, isPublicSharingAllowed]);

  return onCopyToClipboard;
}

export const copyStackItemUrlToClipboardFromStack = (
  url: string,
  stack: stacks.Stack | null,
  reportPapEvent: ReportPapEventFn,
) => {
  copyToClipboard(url ?? '');
  showSnackbar({ title: i18n.t('copied_link_to_clipboard') });

  if (stack) {
    reportPapEvent(
      PAP_Copy_DashLink({
        ...stackDerivePAPProps(stack),
        featureLine: 'stacks',
      }),
    );
  }
};

export function useCopyStackItemUrlToClipboard(stack: stacks.Stack | null) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const copyStackItemUrlToClipboard = useCallback(
    (url: string) =>
      copyStackItemUrlToClipboardFromStack(url, stack, reportPapEvent),
    [stack, reportPapEvent],
  );

  return copyStackItemUrlToClipboard;
}
