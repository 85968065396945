import { tagged } from '@mirage/service-logging';
import { BrowserViewMap } from '@mirage/shared/search/scoring/browser-views';
import { ClickMap } from '@mirage/shared/search/scoring/clicks';
import {
  buildAuthorInfo,
  buildBrandedSiteInfo,
  buildConnectorInfo,
  buildFileTypeInfo,
  buildItemLocation,
  buildSummarizable,
} from '../utils';

import type { APIv2Callable } from '..';
import type { dash_typeahead } from '@dropbox/api-v2-client';
import type { Recommendation } from '@mirage/shared/search/recommendation';

const logger = tagged('dcsTypeaheadRecommendations');

export async function getTypeaheadRecommendations(
  api: APIv2Callable,
): Promise<Recommendation[]> {
  const response = await api('dcsTypeaheadRecommendations', {});

  const DEBUG = false;
  if (DEBUG) {
    if (response.recommendations) {
      logger.debug(`recommendations`, response.recommendations.slice(0, 3));
    }
  }

  if (!response.recommendations) return [];

  const converted = response.recommendations
    .map((dreco) => toMirageRecommendation(dreco))
    .filter((mreco): mreco is Recommendation => Boolean(mreco));

  return converted;
}

/**
 * Helpers
 */

function toMirageRecommendation(
  drecommendation: dash_typeahead.Recommendation,
): Recommendation | null {
  const connectorInfo = buildConnectorInfo(drecommendation.connector_info);
  if (!connectorInfo) return null;

  const {
    uuid,
    title,
    url,
    record_type: recordType,
    updated_at_3p_ms: providerUpdateAtMs, // Renaming for consistency with search results
    virtual_path: virtualPath = [],
    mime_type: mimeType = null,
    status = null,
    normalized_status: normalizedStatus = null,
    priority = null,
    category = null,
    tag: tags = [],
    reference_id: referenceId = null,
    logical_type: logicalType = null,
  } = drecommendation;
  if (!uuid) return null;
  if (!title) return null;
  if (!url) return null;
  if (!recordType) return null;

  const brandedSiteInfo = buildBrandedSiteInfo(
    drecommendation.branded_site_info,
  );
  const lastClickedMs = drecommendation.last_clicked_ms ?? null;
  const clicks = drecommendation.clicks
    ? toMirageClickMap(drecommendation.clicks)
    : null;
  const lastBrowserViewMs = drecommendation.last_browser_view_ms ?? null;
  const browserViewsHistory = drecommendation.browser_views
    ? toMirageBrowserViews(drecommendation.browser_views)
    : null;
  const fileTypeInfo = buildFileTypeInfo(drecommendation.file_type_info);
  const parent = buildItemLocation(drecommendation.parent);
  const sender = buildAuthorInfo(drecommendation.sender);
  const lastModifier = buildAuthorInfo(drecommendation.last_modifier);
  const creator = buildAuthorInfo(drecommendation.creator);
  const summarizable = buildSummarizable(drecommendation.summarizable) ?? null;
  const assignedTo = buildAuthorInfo(drecommendation.assigned_to);

  return {
    uuid,
    title,
    url,
    recordType,
    connectorInfo,
    lastClickedMs,
    clicks,
    lastBrowserViewMs,
    browserViewsHistory,
    fileTypeInfo,
    brandedSiteInfo,
    parent,
    sender,
    lastModifier,
    creator,
    providerUpdateAtMs,
    summarizable,
    virtualPath,
    mimeType,
    status: status ?? normalizedStatus,
    priority,
    category,
    assignedTo,
    tags,
    referenceId,
    logicalType,
  };
}
// Export for testing
export { toMirageRecommendation };

function toMirageClickMap(dclicks: dash_typeahead.Clicks): ClickMap | null {
  // Watch out for the value 0. In TS, !0 === true
  if (dclicks.last_1h === undefined) return null;
  if (dclicks.last_4h === undefined) return null;
  if (dclicks.last_8h === undefined) return null;
  if (dclicks.last_1d === undefined) return null;
  if (dclicks.last_3d === undefined) return null;
  if (dclicks.last_7d === undefined) return null;

  return {
    last1h: dclicks.last_1h,
    last4h: dclicks.last_4h,
    last8h: dclicks.last_8h,
    last1d: dclicks.last_1d,
    last3d: dclicks.last_3d,
    last7d: dclicks.last_7d,
  };
}
// Export for testing
export { toMirageClickMap };

function toMirageBrowserViews(
  dBrowserViews: dash_typeahead.BrowserViews,
): BrowserViewMap | null {
  // Watch out for the value 0. In TS, !0 === true
  if (dBrowserViews.last_1h === undefined) return null;
  if (dBrowserViews.last_4h === undefined) return null;
  if (dBrowserViews.last_8h === undefined) return null;
  if (dBrowserViews.last_1d === undefined) return null;
  if (dBrowserViews.last_3d === undefined) return null;
  if (dBrowserViews.last_7d === undefined) return null;
  // WARN: Server gives 30d value for browser views, but not for clicks!
  if (dBrowserViews.last_30d === undefined) return null;

  return {
    last1h: dBrowserViews.last_1h,
    last4h: dBrowserViews.last_4h,
    last8h: dBrowserViews.last_8h,
    last1d: dBrowserViews.last_1d,
    last3d: dBrowserViews.last_3d,
    last7d: dBrowserViews.last_7d,
    // WARN: Server gives 30d value for browser views, but not for clicks!
    last30d: dBrowserViews.last_30d,
  };
}
// Export for testing
export { toMirageBrowserViews };
