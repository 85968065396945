import { context_engine, ml_capabilities } from '@dropbox/api-v2-client';
import {
  ContentCacheLoaded,
  SourcesContentCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { ToolCallArgs } from '@mirage/mosaics/ComposeAssistant/data/llm/tools/tools-types';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import {
  ComposeVoicePreviewContentType,
  ComposeVoiceSample,
} from '@mirage/shared/compose/compose-voice';
import * as Runtype from 'runtypes';

const WriteDocArgs = Runtype.Record({
  content: Runtype.String,
  follow_up_suggestions: Runtype.Optional(Runtype.Array(Runtype.String)),
  draft_modifications: Runtype.Optional(Runtype.String),
});
type WriteDocArgs = Runtype.Static<typeof WriteDocArgs>;

const ReadSourceArgs = Runtype.Record({
  sourceIndex: Runtype.Number,
});
type ReadSourceArgs = Runtype.Static<typeof ReadSourceArgs>;

const ModifyVoiceArgs = Runtype.Record({
  redrafted_samples: Runtype.Array(
    Runtype.Record({
      content_type: Runtype.String,
      text: Runtype.String,
    }),
  ),
  follow_up_suggestions: Runtype.Optional(Runtype.Array(Runtype.String)),
  draft_modifications: Runtype.Optional(Runtype.String),
  voice_name: Runtype.String,
  voice_description: Runtype.String,
});
type ModifyVoiceArgs = Runtype.Static<typeof ModifyVoiceArgs>;

export function handleWriteDocCall({
  argumentsString,
  responseText,
  onResponse,
}: ToolCallArgs): ml_capabilities.ChatMessage[] {
  const { content, follow_up_suggestions, draft_modifications } =
    parseWriteDocArgs(argumentsString);
  onResponse({
    type: 'write_doc',
    content,
    followUpSuggestions: follow_up_suggestions,
    responseText: responseText,
    draftModifications: draft_modifications,
  });
  return []; // response completed
}

export function parseWriteDocArgs(argumentsString: string): WriteDocArgs {
  return WriteDocArgs.check(JSON.parse(argumentsString));
}

export function handleModifyVoiceCall({
  argumentsString,
  onResponse,
}: ToolCallArgs): ml_capabilities.ChatMessage[] {
  const {
    redrafted_samples,
    follow_up_suggestions,
    draft_modifications,
    voice_name,
    voice_description,
  } = parseModifyVoiceArgs(argumentsString);
  onResponse({
    type: 'modify_voice',
    redraftedSamples: redrafted_samples.map<ComposeVoiceSample>((s) => ({
      text: s.text,
      contentType: s.content_type as ComposeVoicePreviewContentType,
    })),
    followUpSuggestions: follow_up_suggestions,
    draftModifications: draft_modifications,
    voiceModificationHistory: [],
    voiceName: voice_name,
    voiceDescription: voice_description,
  });
  return []; // response completed
}

export function parseModifyVoiceArgs(argumentsString: string): ModifyVoiceArgs {
  return ModifyVoiceArgs.check(JSON.parse(argumentsString));
}

export function handleReadSourceCall({
  toolCallID,
  argumentsString,
  responseMessage,
  indexedCachedSources,
  onResponse,
}: ToolCallArgs): ml_capabilities.ChatMessage[] {
  const { sourceIndex } = parseReadSourceArgs(argumentsString);
  const cachedSource = indexedCachedSources.get(sourceIndex);
  if (cachedSource === undefined) {
    throw new Error(`Invalid source index: ${sourceIndex}`);
  }
  const { title, url } = getCachedSourceInfo(cachedSource);

  const toolResponseMessage: ml_capabilities.ChatMessage = {
    '.tag': 'tool_message',
    tool_call_id: toolCallID,
    content: {
      text: `Response to read_source call:
Title: ${title}:
URL: ${url}
Content:
${truncateContent(cachedSource.content)}`,
    },
  };
  onResponse({
    type: 'read_source',
    source: cachedSource,
  });
  return [responseMessage, toolResponseMessage];
}

export function parseReadSourceArgs(argumentsString: string): ReadSourceArgs {
  return ReadSourceArgs.check(JSON.parse(argumentsString));
}

export function getCachedSourceInfo(cachedSource: ContentCacheLoaded) {
  switch (cachedSource.source.type) {
    case 'dash_search_result':
      return {
        title: cachedSource.source.searchResult.title,
        url: cachedSource.source.searchResult.url,
      };
    case 'recommendation':
      return {
        title: cachedSource.source.recommendation.title,
        url: cachedSource.source.recommendation.url,
      };
    case 'transient':
      return {
        title: cachedSource.source.title,
        url: cachedSource.source.url,
      };
    case 'local_file_content':
      return {
        title: cachedSource.source.fileName,
        url: '',
      };
    default:
      cachedSource.source satisfies never;
      throw new Error(
        `Unexpected source type: ${
          (cachedSource.source as ComposeSource).type
        }`,
      );
  }
}

export function getSourceContentFromCache(
  cacheState: ContentCacheLoaded,
  onlyIncludeMetadata: boolean,
): context_engine.SourceContent {
  const { title, url } = getCachedSourceInfo(cacheState);
  return {
    title,
    url: url || undefined,
    content: onlyIncludeMetadata ? '' : truncateContent(cacheState.content),
    type: { '.tag': cacheState.source.type },
  };
}

export function getSources(
  sourcesContents: SourcesContentCache,
  onlyIncludeMetadata: boolean,
): {
  sources: context_engine.SourceContent[];
  indexedCachedSources: Map<number, ContentCacheLoaded>;
} {
  const sources: context_engine.SourceContent[] = [];
  const indexedCachedSources = new Map<number, ContentCacheLoaded>();
  for (const sourceContent of Object.values(sourcesContents)) {
    if (sourceContent.state !== 'loaded') {
      continue;
    }
    const idx = sources.length + 1;
    indexedCachedSources.set(idx, sourceContent);
    sources.push(getSourceContentFromCache(sourceContent, onlyIncludeMetadata));
  }

  return {
    sources,
    indexedCachedSources,
  };
}

// TODO:(rich) deal with cases where prompt content is too long
const MAX_CONTENT_SIZE = 32000;

export function truncateContent(content: string) {
  return content.substring(0, MAX_CONTENT_SIZE);
}
