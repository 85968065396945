import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import * as React from 'react';
import {
  LottieAnimation,
  LottieAnimationType,
} from '../lottie-animations/LottieAnimation';
import styles from './DashSpinner.module.css';

type DashSpinnerProps = { size?: number; displayLoadingMessage?: boolean };

export const DashSpinner: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > &
    DashSpinnerProps
> = ({ size, displayLoadingMessage = true }) => {
  const sizeProps = size ? { width: size, height: size } : undefined;
  const [imgReady, setImgReady] = React.useState(false);

  return (
    <div className={styles.spinnerWrapper} style={sizeProps}>
      {!!displayLoadingMessage && (
        <Text
          className={classnames(styles.placeholder, {
            [styles.visible]: !imgReady,
          })}
        >
          {i18n.t('logging_in_loading_message')}
        </Text>
      )}
      <LottieAnimation
        onLoad={() => setImgReady(true)}
        alt={i18n.t('logging_in_loading_message')}
        autoplay
        animationType={LottieAnimationType.DASH_IS_LOADING}
      />
    </div>
  );
};
