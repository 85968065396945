import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  ElementTransformer,
  Transformer,
} from '@lexical/markdown';
import {
  $createTableCellNode,
  $createTableNode,
  $createTableRowNode,
  $isTableCellNode,
  $isTableNode,
  $isTableRowNode,
  TableCellHeaderStates,
  TableCellNode,
  TableNode,
  TableRowNode,
} from '@lexical/table';
import { $isParagraphNode, $isTextNode, LexicalNode } from 'lexical';

// TABLE references the example markdown transformers in the lexical-playground package:
// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/MarkdownTransformers/index.ts#L312

// Very primitive table setup
const TABLE_ROW_REG_EXP = /^(?:\|)(.+)(?:\|)\s?$/;
const TABLE_ROW_DIVIDER_REG_EXP = /^(\| ?:?-*:? ?)+\|\s?$/;

export function createTableTransformer(
  includeSpecialAttributes: boolean,
  transformers: Array<Transformer>,
): ElementTransformer {
  return {
    dependencies: [TableNode, TableRowNode, TableCellNode],
    export: (node: LexicalNode) => {
      if (!$isTableNode(node)) {
        return null;
      }

      const output: string[] = [];

      for (const row of node.getChildren()) {
        const rowOutput = [];
        if (!$isTableRowNode(row)) {
          continue;
        }

        let isHeaderRow = false;
        for (const cell of row.getChildren()) {
          // It's TableCellNode so it's just to make flow happy
          if ($isTableCellNode(cell)) {
            let cellMarkdownString = $convertToMarkdownString(
              transformers,
              cell,
              true /* shouldPreserveNewLines */,
            ).replace(/\n/g, '\\n');

            // NOTE: width may have floating point precision in newer versions of Lexical
            const cellWidth = cell.getWidth();
            if (includeSpecialAttributes && cellWidth !== undefined) {
              cellMarkdownString = `{w=${Math.round(cellWidth)}}${cellMarkdownString}`;
            }

            rowOutput.push(cellMarkdownString);
            if (cell.__headerState === TableCellHeaderStates.ROW) {
              isHeaderRow = true;
            }
          }
        }

        output.push(`| ${rowOutput.join(' | ')} |`);
        if (isHeaderRow) {
          output.push(`| ${rowOutput.map((_) => '---').join(' | ')} |`);
        }
      }

      return output.join('\n');
    },
    regExp: TABLE_ROW_REG_EXP,
    replace: (parentNode, _1, match) => {
      // Header row
      if (TABLE_ROW_DIVIDER_REG_EXP.test(match[0])) {
        const table = parentNode.getPreviousSibling();
        if (!table || !$isTableNode(table)) {
          return;
        }

        const rows = table.getChildren();
        const lastRow = rows[rows.length - 1];
        if (!lastRow || !$isTableRowNode(lastRow)) {
          return;
        }

        // Add header state to row cells
        lastRow.getChildren().forEach((cell) => {
          if (!$isTableCellNode(cell)) {
            return;
          }
          cell.setHeaderStyles(TableCellHeaderStates.ROW);
        });

        // Remove line
        parentNode.remove();
        return;
      }

      const matchCells = mapToTableCells(match[0], transformers);

      if (matchCells == null) {
        return;
      }

      const rows = [matchCells];
      let sibling = parentNode.getPreviousSibling();
      let maxCells = matchCells.length;

      while (sibling) {
        if (!$isParagraphNode(sibling)) {
          break;
        }

        if (sibling.getChildrenSize() !== 1) {
          break;
        }

        const firstChild = sibling.getFirstChild();

        if (!$isTextNode(firstChild)) {
          break;
        }

        const cells = mapToTableCells(
          firstChild.getTextContent(),
          transformers,
        );

        if (cells == null) {
          break;
        }

        maxCells = Math.max(maxCells, cells.length);
        rows.unshift(cells);
        const previousSibling = sibling.getPreviousSibling();
        sibling.remove();
        sibling = previousSibling;
      }

      const table = $createTableNode();

      for (const cells of rows) {
        const tableRow = $createTableRowNode();
        table.append(tableRow);

        for (let i = 0; i < maxCells; i++) {
          tableRow.append(
            i < cells.length ? cells[i] : $createTableCell('', transformers),
          );
        }
      }

      const previousSibling = parentNode.getPreviousSibling();
      if (
        $isTableNode(previousSibling) &&
        getTableColumnsSize(previousSibling) === maxCells
      ) {
        previousSibling.append(...table.getChildren());
        parentNode.remove();
      } else {
        parentNode.replace(table);
      }

      table.selectEnd();
    },
    type: 'element',
  };
}

function getTableColumnsSize(table: TableNode) {
  const row = table.getFirstChild();
  return $isTableRowNode(row) ? row.getChildrenSize() : 0;
}

const $createTableCell = (
  textContent: string,
  transformers: Transformer[],
): TableCellNode => {
  // match strings like {w=100}text
  const widthParamMatch = textContent.match(/^\s*\{w=(\d+(?:\.\d+)?)\}(.*)$/);
  let width: number | undefined = undefined;
  if (widthParamMatch) {
    width = Number(widthParamMatch[1]);
    textContent = widthParamMatch[2];
  }

  textContent = textContent.replace(/\\n/g, '\n').trim();
  const cell = $createTableCellNode(TableCellHeaderStates.NO_STATUS);
  $convertFromMarkdownString(
    textContent,
    transformers,
    cell,
    true /* shouldPreserveNewLines */,
  );

  if (width) {
    cell.setWidth(width);
  }
  return cell;
};

const mapToTableCells = (
  textContent: string,
  transformers: Transformer[],
): Array<TableCellNode> | null => {
  const match = textContent.match(TABLE_ROW_REG_EXP);
  if (!match || !match[1]) {
    return null;
  }
  return match[1]
    .split('|')
    .map((text) => $createTableCell(text, transformers));
};
