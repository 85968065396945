import {
  listen,
  refreshBrowserHistory,
} from '@mirage/service-browser-history-v1';
import { useCallback, useEffect, useState } from 'react';

import type { BrowserHistorySnapshot } from '@mirage/service-stack-suggestions/service/types';

export default function useExtensionBasedBrowserHistory(
  connect: boolean,
  dbxAccountId: string,
) {
  const [browserHistory, setBrowserHistory] = useState<BrowserHistorySnapshot>({
    ongoingSessions: {},
    pastSessions: [],
    contentInfos: {},
  });

  useEffect(() => {
    if (connect && dbxAccountId) {
      const subscription = listen(dbxAccountId).subscribe(setBrowserHistory);
      return () => subscription.unsubscribe();
    }
  }, [connect, dbxAccountId]);

  const refreshBrowserHistoryGated = useCallback(() => {
    if (connect && dbxAccountId) {
      refreshBrowserHistory(dbxAccountId);
    }
  }, [connect, dbxAccountId]);

  return {
    browserHistory,
    refreshBrowserHistory: refreshBrowserHistoryGated,
  };
}
