import { Button } from '@dropbox/dig-components/buttons';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashExtensionUpsell } from '@mirage/analytics/events/types/shown_dash_extension_upsell';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { TeamOnboardingLayout } from '@mirage/growth/components/TeamOnboardingLayout';
import { openURL } from '@mirage/service-platform-actions';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import {
  browserNameForCurrentBrowser,
  openExtensionLinkForCurrentBrowser,
  SvgForCurrentBrowserWebExtension,
} from '@mirage/shared/util/webExtensionsHelpers';
import i18n from '@mirage/translations';
import { useCallback, useEffect } from 'react';
import styles from './ExtensionUpsell.module.css';

const LIGHT_VIDEO_SRC =
  'https://assets.dropbox.com/images/dashweb/onboarding-extension-upsell-light.mp4';
const DARK_VIDEO_SRC =
  'https://assets.dropbox.com/images/dashweb/onboarding-extension-upsell-dark.mp4';

type Props = {
  onStepFinish: (step: string) => void;
};

export const ExtensionUpsell = ({ onStepFinish }: Props) => {
  const isDarkMode = useTheme();
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashExtensionUpsell({
        dashActionSurface: 'setup',
        featureLine: 'extension_upsell',
      }),
    );
  }, [reportPapEvent]);

  const handleExtensionUpsellButtonClick = useCallback(() => {
    openExtensionLinkForCurrentBrowser(openURL);
  }, []);

  const handleFinishOnboarding = useCallback(async () => {
    onStepFinish('complete');
  }, [onStepFinish]);

  const actionButtons = (
    <Button
      className={styles.continueButton}
      size="xlarge"
      variant="borderless"
      onClick={handleFinishOnboarding}
    >
      {i18n.t('desktop_onboarding_skip_btn')}
    </Button>
  );

  return (
    <TeamOnboardingLayout
      title={i18n.t('team_onboarding_extension_upsell_title')}
      description={i18n.t('team_onboarding_extension_upsell_description', {
        browserName: browserNameForCurrentBrowser(),
      })}
      actionButtons={actionButtons}
    >
      <Button
        variant="primary"
        withIconStart={<SvgForCurrentBrowserWebExtension size={24} />}
        size="large"
        className={styles.extensionUpsellButton}
        onClick={handleExtensionUpsellButtonClick}
        data-uxa-log={createUxaElementId(
          'team_onboarding_extension_upsell_link',
          {},
        )}
        data-uxa-interactions="shown click"
      >
        {i18n.t('team_onboarding_extension_upsell_button', {
          browserName: browserNameForCurrentBrowser(),
        })}
      </Button>
      <video className={styles.extensionUpsellVideo} autoPlay muted loop>
        <source
          src={isDarkMode ? DARK_VIDEO_SRC : LIGHT_VIDEO_SRC}
          type="video/mp4"
        />
      </video>
    </TeamOnboardingLayout>
  );
};
