import Sentry from '@mirage/shared/sentry';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';
import {
  OnboardingSurveyQuestion,
  OnboardingSurveyQuestionAnswer,
  OnboardingSurveyQuestionFieldType,
} from '../types/onboarding-surveys';

enum ProfilingQuestionTitle {
  Executive = 'executive',
  Director = 'director',
  BusinessOwner = 'business_owner',
  Manager = 'manager',
  TeamMember = 'team_member',
  Other = 'other',
}

enum ProfilingQuestionJobsToBeDoneAnswer {
  AnswersOrContext = 'answers_or_context',
  Search = 'search',
  ContentCreation = 'content_creation',
  NotSure = 'not_sure',
}

enum ProfilingQuestionKindOfWorkAnswer {
  EngineeringProductDesign = 'engineering_product_design',
  FinancialOrStrategicAdvisor = 'financial_or_strategic_advisor',
  Human_resources = 'human_resources',
  InformationTechnology = 'information_technology',
  Marketing = 'marketing',
  Operations = 'operations',
  Sales = 'sales',
  Other = 'other',
}

export enum ProfilingQuestionIndustryAnswer {
  ArchitectureEngineeringConstruction = 'architecture_engineering_construction',
  CapitalMarkets = 'capital_markets',
  Education = 'education',
  FinancialServices = 'financial_services',
  Government = 'government',
  Healthcare = 'healthcare',
  Manufacturing = 'manufacturing',
  MediaOrSports = 'media_or_sports',
  ProfessionalServices = 'professional_services',
  Technology = 'technology',
  Other = 'other',
}

const KIND_OF_WORK_QUESTION: OnboardingSurveyQuestion = {
  questionId: 'KIND_OF_WORK_V1',
  questionType: OnboardingSurveyQuestionFieldType.Select,
  label: i18n.t('self_serve_profiling_kind_of_work_question'),
  questionValues: [
    {
      label: i18n.t(
        'self_serve_profiling_kind_of_work_answer_engineering_product_design',
      ),
      value: ProfilingQuestionKindOfWorkAnswer.EngineeringProductDesign,
    },
    {
      label: i18n.t(
        'self_serve_profiling_kind_of_work_answer_financial_or_strategic_advisor',
      ),
      value: ProfilingQuestionKindOfWorkAnswer.FinancialOrStrategicAdvisor,
    },
    {
      label: i18n.t('self_serve_profiling_kind_of_work_answer_human_resources'),
      value: ProfilingQuestionKindOfWorkAnswer.Human_resources,
    },
    {
      label: i18n.t(
        'self_serve_profiling_kind_of_work_answer_information_technology',
      ),
      value: ProfilingQuestionKindOfWorkAnswer.InformationTechnology,
    },
    {
      label: i18n.t('self_serve_profiling_kind_of_work_answer_marketing'),
      value: ProfilingQuestionKindOfWorkAnswer.Marketing,
    },
    {
      label: i18n.t('self_serve_profiling_kind_of_work_answer_operations'),
      value: ProfilingQuestionKindOfWorkAnswer.Operations,
    },
    {
      label: i18n.t('self_serve_profiling_kind_of_work_answer_sales'),
      value: ProfilingQuestionKindOfWorkAnswer.Sales,
    },
    {
      label: i18n.t('self_serve_profiling_other_answer'),
      value: ProfilingQuestionKindOfWorkAnswer.Other,
    },
  ],
};

const ADMIN_PROFILING_QUESTIONS: OnboardingSurveyQuestion[] = [
  {
    questionId: 'COMPANY_SIZE_V1',
    label: i18n.t('self_serve_profiling_company_size_question'),
    questionType: OnboardingSurveyQuestionFieldType.ChipList,
    questionValues: [
      {
        label: i18n.t('self_serve_profiling_just_me'),
        value: '1',
      },
      {
        label: '2-19',
        value: '2-19',
      },
      {
        label: '20-99',
        value: '20-99',
      },
      {
        label: '100-249',
        value: '100-249',
      },
      {
        label: '250-999',
        value: '250-999',
      },
      {
        label: '1000+',
        value: '1000+',
      },
    ],
  },
  {
    questionId: 'INDUSTRY_V1',
    questionType: OnboardingSurveyQuestionFieldType.Select,
    label: i18n.t('self_serve_profiling_industry_question'),
    questionValues: [
      {
        label: i18n.t(
          'self_serve_profiling_industry_answer_architecture_engineering_construction',
        ),
        value:
          ProfilingQuestionIndustryAnswer.ArchitectureEngineeringConstruction,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_capital_markets'),
        value: ProfilingQuestionIndustryAnswer.CapitalMarkets,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_education'),
        value: ProfilingQuestionIndustryAnswer.Education,
      },
      {
        label: i18n.t(
          'self_serve_profiling_industry_answer_financial_services',
        ),
        value: ProfilingQuestionIndustryAnswer.FinancialServices,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_government'),
        value: ProfilingQuestionIndustryAnswer.Government,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_healthcare'),
        value: ProfilingQuestionIndustryAnswer.Healthcare,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_manufacturing'),
        value: ProfilingQuestionIndustryAnswer.Manufacturing,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_media_or_sports'),
        value: ProfilingQuestionIndustryAnswer.MediaOrSports,
      },
      {
        label: i18n.t(
          'self_serve_profiling_industry_answer_professional_services',
        ),
        value: ProfilingQuestionIndustryAnswer.ProfessionalServices,
      },
      {
        label: i18n.t('self_serve_profiling_industry_answer_technology'),
        value: ProfilingQuestionIndustryAnswer.Technology,
      },
      {
        label: i18n.t('self_serve_profiling_other_answer'),
        value: ProfilingQuestionIndustryAnswer.Other,
      },
    ],
  },
  {
    ...KIND_OF_WORK_QUESTION,
  },
  {
    questionId: 'IS_IT_ADMIN_V1',
    questionType: OnboardingSurveyQuestionFieldType.ChipList,
    label: i18n.t('self_serve_profiling_it_admin_question'),
    questionValues: [
      {
        label: i18n.t('self_serve_profiling_it_admin_answer_yes'),
        value: 'true',
      },
      {
        label: i18n.t('self_serve_profiling_it_admin_answer_no'),
        value: 'false',
      },
    ],
  },
];

const TEAM_MEMBER_PROFILING_QUESTIONS: OnboardingSurveyQuestion[] = [
  {
    ...KIND_OF_WORK_QUESTION,
  },
  {
    questionId: 'TITLE_V1',
    questionType: OnboardingSurveyQuestionFieldType.ChipList,
    label: i18n.t('self_serve_profiling_title_question'),
    questionValues: [
      {
        label: i18n.t('self_serve_profiling_title_answer_executive'),
        value: ProfilingQuestionTitle.Executive,
      },
      {
        label: i18n.t('self_serve_profiling_title_answer_director'),
        value: ProfilingQuestionTitle.Director,
      },
      {
        label: i18n.t('self_serve_profiling_title_answer_business_owner'),
        value: ProfilingQuestionTitle.BusinessOwner,
      },
      {
        label: i18n.t('self_serve_profiling_title_answer_manager'),
        value: ProfilingQuestionTitle.Manager,
      },
      {
        label: i18n.t('self_serve_profiling_title_answer_team_member'),
        value: ProfilingQuestionTitle.TeamMember,
      },
      {
        label: i18n.t('self_serve_profiling_other_answer'),
        value: ProfilingQuestionTitle.Other,
      },
    ],
  },
  {
    questionId: 'JOBS_TO_BE_DONE_V1',
    questionType: OnboardingSurveyQuestionFieldType.ChipList,
    label: i18n.t('self_serve_profiling_title_jobs_to_be_done_question'),
    questionValues: [
      {
        label: i18n.t('self_serve_profiling_jtbd_lacking_answers'),
        value: ProfilingQuestionJobsToBeDoneAnswer.AnswersOrContext,
      },
      {
        label: i18n.t('self_serve_profiling_jtbd_searching_for_things'),
        value: ProfilingQuestionJobsToBeDoneAnswer.Search,
      },
      {
        label: i18n.t('self_serve_profiling_jtbd_content_creation'),
        value: ProfilingQuestionJobsToBeDoneAnswer.ContentCreation,
      },
      {
        label: i18n.t('self_serve_profiling_jtbd_checking_things_out'),
        value: ProfilingQuestionJobsToBeDoneAnswer.NotSure,
      },
    ],
  },
];

type OnboardingSurveyId =
  | 'ADMIN_PROFILING_V1'
  | 'TEAM_MEMBER_PROFILING_V1'
  | 'ADMIN_JTBD_V1';

const ONBOARDING_SURVEY_QUESTIONS = {
  ADMIN_PROFILING_V1: ADMIN_PROFILING_QUESTIONS,
  TEAM_MEMBER_PROFILING_V1: TEAM_MEMBER_PROFILING_QUESTIONS,
  ADMIN_JTBD_V1: undefined,
};

export const useOnboardingSurveys = (surveyId: OnboardingSurveyId) => {
  const [questions, setQuestions] = useState<OnboardingSurveyQuestion[]>([]);
  const [values, setValues] = useState<OnboardingSurveyQuestionAnswer[]>([]);

  useEffect(() => {
    if (ONBOARDING_SURVEY_QUESTIONS[surveyId]) {
      setQuestions(ONBOARDING_SURVEY_QUESTIONS[surveyId]);
      return;
    }

    setQuestions([]);
    Sentry.captureMessage(
      `[useOnboardingSurveys] Invalid survey id provided: "${surveyId}"`,
    );
  }, [surveyId]);

  const submitSurvey = useCallback(
    async (answers: OnboardingSurveyQuestionAnswer[]) => {
      setValues(answers);
      // TODO: Save to api
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('Done after 4 seconds');
        }, 2000);
      });
    },
    [],
  );

  return { questions, values, submitSurvey };
};
