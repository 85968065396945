import { dash_feed } from '@dropbox/api-v2-client';
import { Avatar } from '@dropbox/dig-components/avatar';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { getInitials } from '@mirage/shared/account';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { Trans } from '@mirage/translations';
import { SyntheticEvent, useRef, useState } from 'react';
import { OverlayList } from './OverlayList';

export const ActivityLabelSubject = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Text variant="label" color="standard" isBold>
      {children}
    </Text>
  );
};

// Creates a label for the actors in an activity
// If there are more than 3 actors, only the first 2 actors are displayed, along with the count of the rest of the actors
// EX: "John, Jane, and 3 others"
export const ActivityActorsLabel = ({
  actors,
}: {
  actors: dash_feed.ActivityUser[];
}) => {
  let extraActorsToDisplay: dash_feed.ActivityUser[] = [];
  let actorNamesToDisplay = actors
    .map((actor) => actor.name)
    .filter((name) => !!name) as string[];
  let extraActorsCount = 0;
  if (actors.length > 3) {
    // display 2 actors + rest of the count
    extraActorsCount = actors.length - 2;
    actorNamesToDisplay = actorNamesToDisplay.slice(0, 2);
    extraActorsToDisplay = actors.slice(2);
  }

  if (actorNamesToDisplay.length === 0) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_no_actor"
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  } else if (actorNamesToDisplay.length === 1) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_one_actor"
        values={{
          actorName1: actorNamesToDisplay[0],
        }}
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  } else if (actorNamesToDisplay.length === 2) {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_two_actors"
        values={{
          actorName1: actorNamesToDisplay[0],
          actorName2: actorNamesToDisplay[1],
          count: extraActorsCount,
        }}
        components={{
          subject: <ActivityLabelSubject />,
          subjectOverFlow: (
            <ActivityLabelSubjectOverflow extraActors={extraActorsToDisplay} />
          ),
        }}
      />
    );
  } else {
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_actors_three_actors"
        values={{
          actorName1: actorNamesToDisplay[0],
          actorName2: actorNamesToDisplay[1],
          actorName3: actorNamesToDisplay[2],
        }}
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  }
};

/**
 * Use for rendering the overflow of actors in an activity label such as "3 more".
 * This component is used in conjunction with ActivityActorsLabel.
 */
const ActivityLabelSubjectOverflow = ({
  extraActors,
  children,
}: {
  extraActors: dash_feed.ActivityUser[];
  children?: React.ReactNode;
}) => {
  const triggerRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const mouseLeaveTimeout = useRef<number | null>(null);

  const isHoverFriendly = useIsHoverFriendly();

  const handleMouseEnter = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowOverlay(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowOverlay(false);
    }, 200);
  };

  // handle touch events on mobile devices
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isHoverFriendly) {
      handleMouseEnter();
    }
  };

  return (
    <>
      <Box
        as="span"
        ref={triggerRef}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <ActivityLabelSubject>{children}</ActivityLabelSubject>
      </Box>
      <OverlayList
        triggerRef={triggerRef}
        show={showOverlay}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
      >
        {extraActors.map((user) => (
          <OverlayList.Item key={user.email}>
            <OverlayList.ItemAccessory>
              <Avatar src={user.avatar_img}>
                {getInitials(user.name || user.email || '')}
              </Avatar>
            </OverlayList.ItemAccessory>
            <OverlayList.ItemContent>
              <Text variant="label">
                <Truncate>{user.name || user.email}</Truncate>
              </Text>
            </OverlayList.ItemContent>
          </OverlayList.Item>
        ))}
      </OverlayList>
    </>
  );
};
