import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Continue_DashWelcomeModal } from '@mirage/analytics/events/types/continue_dash_welcome_modal';
import { PAP_Dismiss_DashWelcomeModal } from '@mirage/analytics/events/types/dismiss_dash_welcome_modal';
import { PAP_Shown_DashWelcomeModal } from '@mirage/analytics/events/types/shown_dash_welcome_modal';
import { useShouldShowWelcomeModal } from '@mirage/growth/onboarding/hooks/useShouldShowWelcomeModal';
import { InfoModal } from '@mirage/mosaics/Modals';
import { useWelcomeModalDismissed } from '@mirage/service-onboarding/hooks';
import i18n from '@mirage/translations';
import * as React from 'react';
import styles from './WelcomeModalStepper.module.css';

export type WelcomeModalItem = {
  title: string;
  message: string;
  image: string;
};

const MODAL_VERSION = 1;

// Always opens on its first render
// When dismissed it will never show again
export default function WelcomeModalStepper({
  steps,
  size,
}: {
  steps: WelcomeModalItem[];
  size: 'small' | 'standard';
}) {
  const [step, setStep] = React.useState(0);
  const titleRef = React.useRef<HTMLSpanElement>(null);
  const { title, message, image } = steps[step];
  const isLastStep = steps.length - 1 === step;
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { setDismissed } = useWelcomeModalDismissed(MODAL_VERSION);
  const open = useShouldShowWelcomeModal(MODAL_VERSION);
  const loggedExposure = React.useRef(false);

  const onCloseModal = () => {
    reportPapEvent(
      PAP_Dismiss_DashWelcomeModal({
        isCloseButton: true,
      }),
    );
    setDismissed();
  };

  React.useEffect(() => {
    if (!loggedExposure.current && open) {
      loggedExposure.current = true;
      reportPapEvent(PAP_Shown_DashWelcomeModal());
    }
  }, [open, reportPapEvent]);

  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [step]); // Focus updates when step changes

  const handleNextButton = () => {
    if (isLastStep) {
      reportPapEvent(PAP_Dismiss_DashWelcomeModal());
      setDismissed();
    } else {
      reportPapEvent(
        PAP_Continue_DashWelcomeModal({
          targetPageIndex: step + 1,
          isBackButton: false,
        }),
      );
      setStep((step) => step + 1);
    }
  };

  const handleBackButton = () => {
    reportPapEvent(
      PAP_Continue_DashWelcomeModal({
        targetPageIndex: step - 1,
        isBackButton: true,
      }),
    );
    setStep((step) => step - 1);
  };

  if (!open) {
    return null;
  }

  return (
    <InfoModal
      open={open}
      spotlight={
        <ThemeProvider mode="bright">
          <ThemeContainer>
            <div className={styles.spotlightContainer}>
              <IconButton
                variant="borderless"
                onClick={onCloseModal}
                className={styles.dismissButton}
                aria-label={i18n.t('close')}
              >
                <UIIcon src={CloseLine} />
              </IconButton>
              <img
                key={image}
                src={image}
                alt={i18n.t('welcome_modal_spotlight_alt')}
              />
            </div>
          </ThemeContainer>
        </ThemeProvider>
      }
      width={size}
      title={
        <span ref={titleRef} tabIndex={-1}>
          {title}
        </span>
      }
      titleSize="medium"
      body={
        <div className={styles.bodyContainer}>
          <Text size="large">{message}</Text>
        </div>
      }
      closeOnOverlayClick={false}
      actions={[
        <Text key="page-index" className={styles.pageIndex}>
          {i18n.t('query_suggestion_count', {
            startIndex: step + 1,
            endIndex: steps.length,
          })}
        </Text>,
        step > 0 ? (
          <Button key="back-btn" variant="opacity" onClick={handleBackButton}>
            {i18n.t('back')}
          </Button>
        ) : null,
        <Button key="next-btn" variant="primary" onClick={handleNextButton}>
          {isLastStep ? i18n.t('dismiss') : i18n.t('next')}
        </Button>,
      ]}
      onClose={onCloseModal}
    />
  );
}
