import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import * as jobs from '@mirage/shared/util/jobs';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import * as rx from 'rxjs';
import {
  ONE_DAY_IN_MILLIS,
  ONE_MINUTE_IN_MILLIS,
} from '../../shared/util/constants';

import type {
  dash_connector_metadata,
  dash_connectors,
} from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { Namespace } from '@mirage/service-operational-metrics';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const logger = tagged('service-connectors');

/** Regular refreshes for new connectors data. */
const REFRESH_CONNECTIONS_INTERVAL = ONE_MINUTE_IN_MILLIS;

/** Long cache for faster page loads. */
const TTL_MS = ONE_DAY_IN_MILLIS;

export type ConnectorConnection = dash_connectors.Connection & {
  connector?: dash_connectors.Connector;
};

export type Connector = dash_connectors.Connector;
export type Connectors = Connector[];

export type ConnectorConnectionsMap = {
  [uuid: string]: ConnectorConnection;
};

export type ConnectorConnectionsShape = {
  listConnectorsResponse?: dash_connector_metadata.ListConnectorsResponse;
  listConnectorsExpireMs?: number;
  listConnectionsResponse?: dash_connector_metadata.ListConnectionsAPIv2Response;
  listConnectionsExpireMs?: number;
  connectorConnections: ConnectorConnectionsMap;
};

export type ConnectorConnections = ConnectorConnection[];

export type Service = ReturnType<typeof connectors>;

const defaults: ConnectorConnectionsShape = {
  connectorConnections: {},
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface OperationalMetricsService {
  namespace(ns: string): Namespace;
}

export default function connectors(
  adapter: KVStorage<ConnectorConnectionsShape>,
  localConnectors: Connector[] = [],
  { callApiV2 }: DbxApiServiceContract,
  { namespace }: OperationalMetricsService,
  logoutService: LogoutServiceConsumerContract,
) {
  const metrics = namespace('service-connectors');
  // gives us a guarentee that the key is set up and we return a value if it hasn't been intialized
  const wrapped = new WithDefaults<ConnectorConnectionsShape>(
    adapter,
    defaults,
  );
  const emitter = new EventEmitter<{
    connections: ConnectorConnections[];
  }>();
  const connectionsChanged = getValueChangedFunc<ConnectorConnections>();

  async function getCachedConnectorConnections(): Promise<ConnectorConnections> {
    const connections = await wrapped.get('connectorConnections');
    return [...Object.values(connections), ...localConnectors];
  }

  async function setCachedConnections(
    connections: ConnectorConnections,
  ): Promise<void> {
    const connectorConnections: ConnectorConnectionsMap = {};
    for (const connection of connections) {
      const id = connection?.id_attributes?.connector?.id;
      if (id) connectorConnections[id] = connection;
    }
    const newConnectorConnections: ConnectorConnections = [
      ...connections,
      ...localConnectors,
    ];

    if (connectionsChanged(newConnectorConnections)) {
      await wrapped.set('connectorConnections', connectorConnections);
      emitter.emit('connections', newConnectorConnections);
    }
  }

  const listConnectionsCombined = getCombineAsyncRequestsFunc(() =>
    callApiV2('dashConnectorMetadataListConnections', {}),
  );

  async function listConnections() {
    const response = await listConnectionsCombined();
    await wrapped.set('listConnectionsResponse', response);
    await wrapped.set('listConnectionsExpireMs', Date.now() + TTL_MS);
    return response?.connections || [];
  }

  async function getCachedOrListConnections() {
    const expireMs = await wrapped.get('listConnectionsExpireMs');
    if (expireMs && Date.now() < expireMs) {
      const response = await wrapped.get('listConnectionsResponse');
      return response?.connections ?? [];
    }

    return await listConnections();
  }

  const listConnectorsCombined = getCombineAsyncRequestsFunc(() =>
    callApiV2('dashConnectorMetadataListConnectors', {}),
  );

  async function listConnectors() {
    const response = await listConnectorsCombined();
    await wrapped.set('listConnectorsResponse', response);
    await wrapped.set('listConnectorsExpireMs', Date.now() + TTL_MS);
    return response?.connectors || [];
  }

  async function getCachedOrListConnectors() {
    const expireMs = await wrapped.get('listConnectorsExpireMs');
    if (expireMs && Date.now() < expireMs) {
      const response = await wrapped.get('listConnectorsResponse');
      return response?.connectors ?? [];
    }

    return await listConnectors();
  }

  const listConnectionsAndConnectorsCombined = getCombineAsyncRequestsFunc(
    async (): Promise<[ConnectorConnections, Connectors]> => {
      // Refresh requests go straight to the server and never from cache.
      return await Promise.all([listConnections(), listConnectors()]);
    },
  );

  async function getEnrichedConnectionList() {
    const [connections, connectors] =
      await listConnectionsAndConnectorsCombined();

    // Create a lookup Map for connectors
    const connectorsMap = new Map(
      connectors.map((connector) => [
        connector.id_attrs?.type, // Use connector type (e.g. 'zendesk') as the key
        connector, // The connector object itself as the value
      ]),
    );

    return connections.map((connection) => {
      const connector = connectorsMap.get(
        connection.id_attributes?.connector?.type,
      );

      return { ...connection, connector };
    });
  }

  async function refreshConnectionsList(): Promise<void> {
    try {
      const enrichedConnections = await getEnrichedConnectionList();
      metrics.stats('sync/count', enrichedConnections.length);
      metrics.counter('sync/status', 1, { status: 'success' });
      return setCachedConnections(enrichedConnections);
    } catch (e) {
      metrics.counter('sync/status', 1, { status: 'error' });
      throw e;
    }
  }

  function startRefreshConnectionsJob() {
    jobs.register(
      'service-connectors/refresh-connections',
      REFRESH_CONNECTIONS_INTERVAL,
      /* runOnStart= */ true,
      refreshConnectionsList,
    );
  }

  function cancelRefreshConnectionsJob() {
    jobs.unregister('service-connectors/refresh-connections');
  }

  function listenForConnections() {
    return rx.fromEvent(
      emitter,
      'connections',
    ) as Observable<ConnectorConnections>;
  }

  async function tearDown() {
    wrapped.clear();
    cancelRefreshConnectionsJob();
  }

  logoutService.registerLogoutCallback(ServiceId.DASH_CONNECTORS, async () => {
    logger.debug('Handling logout in connectors service');
    await tearDown();
    logger.debug('Done handling logout in connectors service');
  });

  return services.provide(
    ServiceId.DASH_CONNECTORS,
    {
      listConnections,
      getCachedOrListConnections,
      listConnectors,
      getCachedOrListConnectors,
      getCachedConnectorConnections,
      refreshConnectionsList,
      startRefreshConnectionsJob,
      cancelRefreshConnectionsJob,
      listenForConnections,
      tearDown,
    },
    [ServiceId.DBX_API, ServiceId.OPERATIONAL_METRICS],
  );
}
