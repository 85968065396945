import { FolderLine } from '@dropbox/dig-icons/assets';
import { RefObject } from 'react';
import { getPathForDisplay } from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';

// this is a simplified PathMetadata component that will be used with the config-metadata approach
// behind the dash_2025_01_28_search_results_use_logical_type_config flag
// FilePathMetadata should be used otherwise
export type PathMetadataProps = {
  pathSegments: string[];
  isTooltipContent?: boolean;
  triggerRef?: RefObject<HTMLDivElement>;
};

export const PathMetadata = ({
  pathSegments,
  isTooltipContent,
  triggerRef,
}: PathMetadataProps) => {
  const metadataTitle = getPathForDisplay(pathSegments, isTooltipContent);

  return pathSegments.length > 0 ? (
    <MetadataItem
      title={metadataTitle}
      iconSrc={FolderLine}
      triggerRef={triggerRef}
    />
  ) : null;
};
