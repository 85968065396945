import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashSpellCorrect } from '@mirage/analytics/events/types/select_dash_spell_correct';
import { PAP_Shown_DashSpellCorrect } from '@mirage/analytics/events/types/shown_dash_spell_correct';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { SearchOptions } from '@mirage/service-search/service';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import {
  concatActiveFilterBindings,
  SearchFilter,
  stripStringOfPotentialFilterBindings,
  syncQueryWithFilters,
} from '@mirage/shared/search/search-filters';
import classNames from 'classnames';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchFilters from './SearchFilters';
import styles from './SearchPageHeader.module.css';
import { SearchSpellingSuggestion } from './SearchSpellingSuggestion';

import type { Connectors } from '@mirage/service-connectors/service';
import type { Connector as BrowserHistoryConnector } from '@mirage/service-dbx-api';

type Props = {
  isDesktop?: boolean;
  typeaheadQuery: string;
  setTypeaheadQuery: Dispatch<SetStateAction<string>>;
  handleSearch: (
    query: string,
    filters: SearchFilter[],
    options?: Partial<SearchOptions>,
  ) => Promise<void>;
  correctedQuery?: string;
  connectors: Connectors;
  browserHistoryConnectors: BrowserHistoryConnector[];
  filters: SearchFilter[];
  handleFilterChange: (filters: SearchFilter[]) => void;
};

export function SearchPageHeader({
  isDesktop,
  typeaheadQuery,
  setTypeaheadQuery,
  handleSearch,
  correctedQuery,
  connectors,
  browserHistoryConnectors,
  filters,
  handleFilterChange,
}: Props) {
  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';
  const isUsingSpellCorrectFeatureFlag =
    useFeatureFlagValue('dash_2025_01_31_spell_correct') === 'ON';
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();

  const [originalQuery, setOriginalQuery] = useState(
    isUsingSpellCorrectFeatureFlag && correctedQuery
      ? typeaheadQuery
      : undefined,
  );

  const reportSelectEvent = useCallback(
    (spellCorrectAction: 'corrected' | 'ignored') => {
      reportPapEvent(
        PAP_Select_DashSpellCorrect({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          searchQueryUuid,
          activeFilters: concatActiveFilterBindings(filters),
          query: typeaheadQuery,
          spellCorrectAction,
        }),
      );
    },
    [
      reportPapEvent,
      searchSessionManager,
      searchQueryUuid,
      filters,
      typeaheadQuery,
    ],
  );

  const reportShownEvent = useCallback(() => {
    reportPapEvent(
      PAP_Shown_DashSpellCorrect({
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchQueryUuid,
        activeFilters: concatActiveFilterBindings(filters),
        query: typeaheadQuery,
      }),
    );
  }, [
    reportPapEvent,
    searchSessionManager,
    searchQueryUuid,
    filters,
    typeaheadQuery,
  ]);

  const handleCorrectOriginalQuery = useCallback(() => {
    setTypeaheadQuery(correctedQuery!);
    setOriginalQuery(undefined);

    reportSelectEvent('corrected');
  }, [correctedQuery, setTypeaheadQuery, reportSelectEvent]);

  const handleSearchOriginalQuery = useCallback(() => {
    const syncedTypeaheadQuery = isUsingTypeaheadFiltersFeatureFlag
      ? syncQueryWithFilters(originalQuery!, filters)
      : originalQuery!;

    reportSelectEvent('ignored');

    handleSearch(syncedTypeaheadQuery, filters, {
      disableSpellCorrection: true,
    });
  }, [
    originalQuery,
    filters,
    isUsingTypeaheadFiltersFeatureFlag,
    handleSearch,
    reportSelectEvent,
  ]);

  // Utilizing ref to support React 18 compiler migration
  const lastCorrectedQuery = useRef<string | undefined>(undefined);
  useEffect(() => {
    if (
      !correctedQuery ||
      !isUsingSpellCorrectFeatureFlag ||
      lastCorrectedQuery.current === correctedQuery
    )
      return;

    const strippedTypeaheadQuery =
      stripStringOfPotentialFilterBindings(typeaheadQuery);

    setOriginalQuery(strippedTypeaheadQuery);

    reportShownEvent();

    lastCorrectedQuery.current = correctedQuery;
  }, [
    correctedQuery,
    reportShownEvent,
    typeaheadQuery,
    isUsingSpellCorrectFeatureFlag,
  ]);

  return (
    <div
      className={classNames({
        [styles.filtersContainerFullWidth]: isDesktop,
      })}
    >
      <SearchFilters
        connectors={connectors}
        browserHistoryConnectors={browserHistoryConnectors}
        activeFilters={filters}
        onFilterChange={handleFilterChange}
      />

      {isUsingSpellCorrectFeatureFlag && correctedQuery && originalQuery && (
        <SearchSpellingSuggestion
          correctedQuery={correctedQuery!}
          originalQuery={originalQuery!}
          onClickOriginalQuery={handleSearchOriginalQuery}
          onClickCorrectedQuery={handleCorrectOriginalQuery}
        />
      )}
    </div>
  );
}
