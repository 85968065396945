import { useExtensionUpsell } from '@mirage/shared/util/webExtensionsHelpers';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { recentBrowserHistory, recentEntities } from '..';
import { RecentData } from '../service';
import {
  RecentBrowserContent,
  RecentConnectorContent,
  RecentUpsellContent,
} from '../types';

export const useRecentBrowserHistory = (): RecentData<RecentBrowserContent> => {
  const [_recentData, setRecentData] = useState<
    RecentData<RecentBrowserContent>
  >({
    content: [],
    isLoading: true,
  });

  useEffect(() => {
    const sub = recentBrowserHistory().subscribe(setRecentData);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return _recentData;
};

export const useRecentEntities = (): RecentData<RecentConnectorContent> => {
  const [_recentData, setRecentData] = useState<
    RecentData<RecentConnectorContent>
  >({
    content: [],
    isLoading: true,
  });

  useEffect(() => {
    const sub = recentEntities().subscribe(setRecentData);

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return _recentData;
};

export const useRecentExtensionUpsell = (): RecentData<RecentUpsellContent> => {
  const [_recentData, setRecentData] = useState<
    RecentData<RecentUpsellContent>
  >({
    content: [],
    isLoading: true,
  });

  const { extensionInfo, isLoading } = useExtensionUpsell(
    'dash_2025_01_29_extension_upsell_on_recents',
  );

  useEffect(() => {
    if (isLoading) return;

    if (extensionInfo) {
      setRecentData({
        content: [
          {
            url: extensionInfo.installUrl,
            // make timestamp as future time so it shows up at the top
            visit_ms: Date.now() + 1000000,
            uuid: extensionInfo.browserName,
            title: i18n.t('get_browser_extension', {
              browserName: extensionInfo.browserName,
            }),
            icon: extensionInfo.icon,
            connectorInfo: {
              type: 'upsell',
              displayName: i18n.t('get_extension'),
            },
          },
        ],
        isLoading: false,
      });
    } else {
      setRecentData({
        content: [],
        isLoading: false,
      });
    }
  }, [extensionInfo, isLoading]);

  return _recentData;
};
