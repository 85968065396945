import { cmde } from '@dropbox/api-v2-client';
import {
  ChromeExternalLogo,
  FirefoxExternalLogo,
  MicrosoftEdgeExternalLogo,
  PuzzlePieceFill,
  SafariExternalLogo,
} from '@dropbox/dig-icons/assets';
import { callApiV2 } from '@mirage/service-dbx-api';
import { FeatureName } from '@mirage/service-experimentation/features';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import SvgChrome from '@mirage/shared/icons/connector-icons/Chrome';
import SvgEdge from '@mirage/shared/icons/connector-icons/Edge';
import SvgFirefox from '@mirage/shared/icons/connector-icons/Firefox';
import SvgSafari from '@mirage/shared/icons/connector-icons/Safari';
import {
  BrowserNameTypes,
  ExtensionUpsellInfo,
  WebExtensionProps,
} from '@mirage/shared/types';
import {
  CHROME_EXTENSION_WEB_STORE,
  EDGE_EXTENSION_WEB_STORE,
  FIREFOX_EXTENSION_WEB_STORE,
  SAFARI_EXTENSION_WEB_STORE,
} from '@mirage/shared/urls';
import { SHADOW_DOM_TAG_NAME } from '@mirage/shared/util/constants';
import { isIframe } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import semverCompare from 'semver/functions/compare';

const logger = tagged('webExtensionsHelpers');

export function useBrowserExtensionData(isExtensionAlpha = false) {
  const [browserExtensionData, setBrowserExtensionData] = useState<
    WebExtensionProps[] | undefined
  >(undefined);

  useEffect(() => {
    fetchBrowserExtensionData(isExtensionAlpha).then(setBrowserExtensionData);
  }, [isExtensionAlpha]);

  return browserExtensionData;
}

export async function fetchBrowserExtensionData(
  isExtensionAlpha = false,
): Promise<WebExtensionProps[]> {
  const currentBrowser = getCurrentBrowser() as BrowserNameTypes;
  const currentBrowserExtensionIsInstalled = isExtensionInstalled();

  function currentBrowserIsConnected(id: BrowserNameTypes) {
    return id === currentBrowser && currentBrowserExtensionIsInstalled;
  }

  // all extensions we have
  const allExtensions: { [key in WebExtensionProps['id']]: WebExtensionProps } =
    {
      chrome: {
        id: 'chrome',
        label: 'Chrome Extension',
        icon: SvgChrome({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('chrome'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      firefox: {
        id: 'firefox',
        label: 'Firefox Extension',
        icon: SvgFirefox({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('firefox'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      safari: {
        id: 'safari',
        label: 'Safari Extension',
        icon: SvgSafari({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('safari'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
      edge: {
        id: 'edge',
        label: 'Edge Extension',
        icon: SvgEdge({ size: 24 }),
        description: i18n.t('connectors_settings_browser_description'),
        connected: currentBrowserIsConnected('edge'),
        authenticated: true as const,
        isWebExtension: true as const,
        installUrl: '', // populated by API below
      },
    };

  // among them, select only ones that we're currently launching (returned by API)
  const supportedExtensions = await callApiV2(
    'cmdeGetSupportedBrowserListings',
    {},
  );
  const supportedBrowsers = Object.keys(supportedExtensions) as Array<
    keyof cmde.GetSupportedBrowserListingsResponse
  >;
  const result: WebExtensionProps[] = [];

  supportedBrowsers.forEach((browser) => {
    const info = supportedExtensions[browser];
    if (info) {
      result.push({
        // all basic stuff, like extension icon, description, etc
        ...allExtensions[browser],
        // extension URL (return from API)
        installUrl: (isExtensionAlpha ? info.internal_url : info.external_url)!,
      });
    }
  });

  return result;
}

export function getCurrentBrowser(): BrowserNameTypes | undefined {
  const userAgentString = navigator.userAgent;
  // userAgent of Edge returns the user family with Chrome at the beginning so
  // we should detect Edge first.
  if (userAgentString.includes('Edg')) {
    return 'edge';
  } else if (userAgentString.includes('Chrome')) {
    return 'chrome';
  } else if (userAgentString.includes('Safari')) {
    return 'safari';
  } else if (userAgentString.includes('Firefox')) {
    return 'firefox';
  }
  return undefined;
}

export const browserNameForCurrentBrowser = () => {
  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return 'Edge';
    case 'firefox':
      return 'Firefox';
    case 'safari':
      return 'Safari';
    default:
      return 'Chrome';
  }
};

export const logoForCurrentBrowser = (showGenericLogo: boolean) => {
  if (showGenericLogo) {
    return PuzzlePieceFill;
  }

  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return MicrosoftEdgeExternalLogo;
    case 'firefox':
      return FirefoxExternalLogo;
    case 'safari':
      return SafariExternalLogo;
    default:
      return ChromeExternalLogo;
  }
};

export function SvgForCurrentBrowserWebExtension({
  size = 100,
}: {
  size?: number;
}) {
  const browser = getCurrentBrowser();
  switch (browser) {
    case 'edge':
      return SvgEdge({ size });
    case 'firefox':
      return SvgFirefox({ size });
    case 'safari':
      return SvgSafari({ size });
    default:
      return SvgChrome({ size });
  }
}

export const extensionLinkForCurrentBrowser = () => {
  const browser = getCurrentBrowser();

  // TODO: add safari once available
  switch (browser) {
    case 'edge':
      return EDGE_EXTENSION_WEB_STORE;
    case 'firefox':
      return FIREFOX_EXTENSION_WEB_STORE;
    case 'safari':
      return SAFARI_EXTENSION_WEB_STORE;
    default:
      return CHROME_EXTENSION_WEB_STORE;
  }
};

// pass in openURL function from '@mirage/service-platform-actions'
export const openExtensionLinkForCurrentBrowser = (
  openURLFunc: (url?: string) => void,
) => {
  const browser = getCurrentBrowser();
  const extensionUrl = extensionLinkForCurrentBrowser();
  // Firefox opens a direct download link whereas the other browsers are navigated to their browser extension page,
  // Without this a blank window opens in Firefox.
  if (browser === 'firefox') {
    window.location.href = extensionUrl;
  } else {
    openURLFunc(extensionUrl);
  }
};

export const isExtensionInstalled = (): boolean => {
  const cookie = Cookies.get('DropboxDashBrowserExtensionInstalled');

  return (
    Boolean(cookie) ||
    document.getElementsByTagName(SHADOW_DOM_TAG_NAME).length > 0
  );
};

export const getExtensionVersion = (): string | null => {
  const cookie = Cookies.get('DashExtVersion');
  if (cookie) return cookie;

  const element = document.getElementsByTagName(SHADOW_DOM_TAG_NAME)[0];
  if (!element) return null;

  return element.getAttribute('data-version');
};

export const getExtensionChannel = (): string | null => {
  const cookie = Cookies.get('DashExtChannel');
  if (cookie) return cookie;

  const element = document.getElementsByTagName(SHADOW_DOM_TAG_NAME)[0];
  if (!element) return null;

  return element.getAttribute('data-channel');
};

export function hasMinExtensionVersion(version: string): boolean {
  const extensionVersion = getExtensionVersion();
  return Boolean(
    extensionVersion && semverCompare(extensionVersion, version) >= 0,
  );
}

/**
 * Returns true if the current browser's extension supports the new tab
 * takeover.
 */
export const isNewTabAvailable = (): boolean => {
  const browser = getCurrentBrowser();
  return browser === 'chrome' || browser === 'edge';
};

/** Note: This only works when the webapp is not inside an iframe. */
export const isNewTabEnabled = (): boolean | undefined => {
  const shadowDom = document.getElementsByTagName(SHADOW_DOM_TAG_NAME)[0];
  const newTabEnabled = shadowDom?.getAttribute('data-new-tab-enabled');
  // If newTabEnabled is null, extension is installed but too old to write the value, return undefined.
  // If newTabEnabled is undefined, extension is not installed so return false.
  return newTabEnabled === null
    ? undefined
    : newTabEnabled === 'true'
      ? true
      : false;
};

export const useIsExtensionInstalled = () => {
  const [isInstalled, setIsInstalled] = useState(isExtensionInstalled());
  useExtensionConnectionId();

  useEffect(() => {
    if (isInstalled) return;

    const intervalId = setInterval(() => {
      const installed = isExtensionInstalled();

      if (installed) {
        setIsInstalled(true);
        clearInterval(intervalId);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isInstalled]);

  return isInstalled;
};

export const useExtensionUpsell = (
  featureName: FeatureName,
): { extensionInfo: ExtensionUpsellInfo | undefined; isLoading: boolean } => {
  const featureEnabled = convertFeatureValueToBool(
    useFeatureFlagValue(featureName),
  );
  // TODO: potentially pass in supported browser list as input to make this more flexible
  const supportedBrowsers: BrowserNameTypes[] = ['chrome', 'edge'];
  const browserData = useBrowserExtensionData();
  const browser = getCurrentBrowser();
  const [extensionInfo, setExtensionInfo] = useState<
    ExtensionUpsellInfo | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const isExtensionInstalled = useIsExtensionInstalled();

  useEffect(() => {
    if (!browserData) return;
    if (
      featureEnabled &&
      browser &&
      supportedBrowsers.includes(browser) &&
      isLoading
    ) {
      const extension = browserData.find((ext) => ext.id === browser);
      if (extension && !extension.connected) {
        setExtensionInfo({
          label: extension.label,
          icon: extension.icon,
          installUrl: extension.installUrl,
          browserName: browserNameForCurrentBrowser(),
        });
      }
    }
    setIsLoading(false);
  }, [browser, supportedBrowsers, featureEnabled, browserData, isLoading]);

  // Detect if extension is installed and remove upsell
  useEffect(() => {
    // NOTE: This won't detect extension installed on existing start page tab in localhost webapp until we manually open new localhost tab.
    //   This won't be a problem in prod since newly installed extension should open new start page tab.
    //   For repeated testing, you may need to remove `DropboxDashBrowserExtensionInstalled` cookie after uninstalling extension.
    if (isExtensionInstalled) {
      setExtensionInfo(undefined);
      setIsLoading(false);
    }
  });

  return { extensionInfo, isLoading };
};

export const useExtensionConnectionId = () => {
  const [connectionId, setConnectionId] = useState<string | undefined>(
    undefined,
  );

  const readConnectionIdFromShadowDom = () => {
    // read from DOM element with tagName SHADOW_DOM_TAG_NAME
    const shadowDom = document.getElementsByTagName(SHADOW_DOM_TAG_NAME)[0];
    return shadowDom?.getAttribute('data-id');
  };

  useEffect(() => {
    if (connectionId) {
      return;
    }

    let timeoutId: NodeJS.Timeout;
    const connectionIdFromDom = readConnectionIdFromShadowDom();

    if (connectionIdFromDom) {
      setConnectionId(connectionIdFromDom);
    } else {
      // In case we have a race condition and the shadow DOM is not yet rendered
      // we'll redo the check 1s later.
      timeoutId = setTimeout(() => {
        const connectionId = readConnectionIdFromShadowDom();

        if (connectionId) {
          setConnectionId(connectionId);
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [connectionId]);

  return connectionId;
};

/**
 * True if inside an iframe (assumed to be inside an extension newtab) or the
 * protocol is not https (e.g. `chrome-extension:`)
 */
export function isInsideExtension() {
  // Allow localhost to work normally as well.
  return isIframe() || !window.location.protocol.startsWith('http');
}

/** Does not open a new tab. Replaces the top-level window url with newUrl. */
export function redirectOnTopFrame(newUrl: string) {
  if (window.top) {
    try {
      window.top.location.href = newUrl;
      return;
    } catch (e) {
      logger.warn(
        `Can't redirect on window.top to ${newUrl}, fallback to use current window`,
        e,
      );
    }
  }

  window.location.href = newUrl;
}
