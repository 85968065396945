// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2232
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.calendar_details'
// Description: Fires when a user clicks on the Edit event details button on a calendar event, which opens the event details on the calendar
// Owner: dash-web
export type Open_CalendarDetails = PAPEventGeneric<
  'dash',
  'open',
  'calendar_details',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    featureLine?: FeatureLine;
  }
>;

export function PAP_Open_CalendarDetails(
  properties?: Open_CalendarDetails['properties'],
): Open_CalendarDetails {
  return <Open_CalendarDetails>{
    class: 'dash',
    action: 'open',
    object: 'calendar_details',
    properties,
  };
}
