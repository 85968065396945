import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_ChatSuggestion } from '@mirage/analytics/events/types/click_chat_suggestion';
import { PAP_Interact_TextSelectionMenu } from '@mirage/analytics/events/types/interact_text_selection_menu';
import {
  getComposeSourcesCache,
  SourcesContentCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeCurrentSessionAction,
  ComposeCurrentSessionState,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionStates';
import { getAssistantResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm';
import {
  AssistantResponse,
  GetAssistantResponseParams,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import {
  ComposeArtifactMarkdownDraft,
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageActionContext,
  ComposeAssistantConversationMessageMessage,
  DEFAULT_PRECONFIGURED_VOICE_ID,
  getSourceTitle,
  getSourceUUID,
  isPreConfiguredVoiceID,
} from '@mirage/shared/compose/compose-session';
import {
  ComposeVoice,
  getVoiceModificationHistoryFromMessages,
} from '@mirage/shared/compose/compose-voice';
import Sentry from '@mirage/shared/sentry';
import { isErrorWithMessage } from '@mirage/shared/util/error';
import i18n from '@mirage/translations';
import { Dispatch, useCallback, useEffect, useRef } from 'react';

const logger = tagged('ComposeCurrentSessionPostMessage');

export interface PostUserMessageParams {
  text: string;
  rawPromptText?: string;
  mustGenerateDraft?: boolean;
  mustGenerateVoiceSamples?: boolean;
  mustIncludeSourceContents?: boolean;
  isFollowUpSuggestion?: boolean;
  isTextSelectionMenuAction?: boolean;
  actionContext?: ComposeAssistantConversationMessageActionContext;
  // for appending raw messages to the current session's history prior to the new message
  appendRawMessages?: ComposeAssistantConversationMessage[];
}

export function usePostMessageHandlers(
  state: ComposeCurrentSessionState,
  dispatch: Dispatch<ComposeCurrentSessionAction>,
  voices: ComposeVoice[] | undefined,
  sourcesContents: SourcesContentCache,
  transientSources: TransientSource[] | undefined,
  voiceSourceContents: SourcesContentCache,
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void,
  markdownArtifact: ComposeArtifactMarkdownDraft | undefined,
) {
  // Voices ref ensures ensure callbacks have access to the latest voices array
  // when saving a new voice. Without this, postUpdateDraftWithVoice occasionally
  // executes with stale voice data before saveVoice has time to update voices state.
  const voicesRef = useRef(voices);
  useEffect(() => {
    voicesRef.current = voices;
  }, [voices]);
  const dashSearchEnabled =
    useFeatureFlagValue('dash_assist_2024_10_12_chat_v1') === 'ON';

  const postUserMessage = useCallback(
    (messageParams: PostUserMessageParams, updatedVoiceId?: string) => {
      let history = state.currentSession.messagesHistory;
      if (messageParams.appendRawMessages) {
        for (const message of messageParams.appendRawMessages) {
          dispatch({
            type: 'addMessage',
            message,
            requiresResponse: false,
          });
        }
        history = [...history, ...messageParams.appendRawMessages];
      }

      postMessage(
        messageParams,
        history,
        markdownArtifact?.markdownContent,
        sourcesContents,
        transientSources,
        updatedVoiceId ||
          markdownArtifact?.draftConfig.voiceID ||
          DEFAULT_PRECONFIGURED_VOICE_ID,
        voiceSourceContents,
        voicesRef.current || [],
        dispatch,
        state.currentSession.id,
        logComposeEvent,
        { dashSearchEnabled },
      );
    },
    [
      dispatch,
      markdownArtifact?.draftConfig.voiceID,
      markdownArtifact?.markdownContent,
      sourcesContents,
      transientSources,
      state.currentSession.id,
      state.currentSession.messagesHistory,
      voiceSourceContents,
      logComposeEvent,
      dashSearchEnabled,
    ],
  );

  const postUpdateDraftWithVoice = useCallback(
    async (voiceID) => {
      let rewriteSourceContents = getVoiceRewriteSourceContents(
        voiceID,
        voicesRef.current || [],
        markdownArtifact?.draftConfig.voiceID,
        voiceSourceContents,
      );
      if (hasPendingSourceContents(rewriteSourceContents)) {
        logger.log('Waiting for voice source contents to load');
        dispatch({
          type: 'markWaitingForResponse',
        });
        rewriteSourceContents = await waitForPendingSourceContents(
          rewriteSourceContents,
        );
        logger.log('Done waiting for voice source contents to load');
      }
      if (
        markdownArtifact &&
        markdownArtifact.markdownContent.trim().length > 0
      ) {
        const text = getVoiceRewriteUserMessageString(
          voiceID,
          voicesRef.current || [],
        );

        postUserMessage(
          {
            text,
            rawPromptText: '',
            mustGenerateDraft: true,
          },
          voiceID,
        );
      }
      dispatch({
        type: 'setDraftConfig',
        config: {
          ...markdownArtifact?.draftConfig,
          voiceID,
        },
      });
    },
    [dispatch, markdownArtifact, postUserMessage, voiceSourceContents],
  );

  return { postUserMessage, postUpdateDraftWithVoice };
}

async function postMessage(
  {
    text,
    rawPromptText,
    mustGenerateDraft,
    mustIncludeSourceContents,
    isFollowUpSuggestion,
    isTextSelectionMenuAction,
    actionContext,
  }: PostUserMessageParams,
  messagesHistory: ComposeAssistantConversationMessage[],
  markdownContent: string | undefined,
  sourcesContents: SourcesContentCache,
  transientSources: TransientSource[] | undefined,
  voiceID: string,
  voiceSourceContents: SourcesContentCache,
  voices: ComposeVoice[],
  dispatch: Dispatch<ComposeCurrentSessionAction>,
  currentSessionID: string,
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void,
  features: { dashSearchEnabled: boolean },
) {
  const newMessage: ComposeAssistantConversationMessageMessage = {
    type: 'message',
    role: 'user',
    text,
    ts: Date.now(),
    rawPromptText,
    actionContext,
  };
  dispatch({
    type: 'addMessage',
    message: newMessage,
    requiresResponse: true,
  });

  if (hasPendingSourceContents(sourcesContents)) {
    logger.log('Waiting for source contents to load');
    dispatch({
      type: 'markWaitingForResponse',
    });
    sourcesContents = await waitForPendingSourceContents(sourcesContents);
    logger.log('Done waiting for source contents to load');
  }
  if (hasPendingSourceContents(voiceSourceContents)) {
    logger.log('Waiting for voice source contents to load');
    dispatch({
      type: 'markWaitingForResponse',
    });
    // Disable formatting here to avoid conflict between eslint and prettier
    // eslint-disable-next-line prettier/prettier
    voiceSourceContents =
      await waitForPendingSourceContents(voiceSourceContents);
    logger.log('Done waiting for voice source contents to load');
  }
  if (transientSources) {
    const merged = await waitForAndMergeTransientSources(
      transientSources,
      sourcesContents,
    );
    // we block on load errors for transient sources
    if (merged.hasLoadErrors) {
      logTransientSourceLoadError(transientSources);
      dispatch({
        type: 'addMessage',
        message: {
          type: 'message',
          role: 'system',
          text: i18n.t('compose_error_loading_content'),
          ts: Date.now(),
          actionContext: { type: 'error' },
        },
        requiresResponse: false,
      });
      dispatch({
        type: 'markMessageResponded',
        toSessionID: currentSessionID,
      });
      return;
    }
    sourcesContents = merged.mergedSourcesContents;
  }

  function handleResponse(response: AssistantResponse) {
    switch (response.type) {
      case 'write_doc':
        {
          dispatch({
            type: 'setMarkdownContent',
            content: response.content,
          });
        }
        break;
      case 'update_progress':
        {
          dispatch({
            type: 'updateProgress',
            progressString: response.progressString,
          });
        }
        break;
      case 'analyzed_search_results':
        for (const source of response.sources) {
          dispatch({
            type: 'addSource',
            source,
          });
        }
        break;
      case 'dash_searched':
      case 'message':
      case 'modify_voice':
      case 'read_source':
        // no-op, just need to add a message regarding the result
        break;
      default:
        response satisfies never;
        throw new Error(`Unknown response type: ${response}`);
    }
    const message = getMessageForAssistantResponse(response, newMessage);
    if (message) {
      dispatch({
        type: 'addMessage',
        message,
        requiresResponse: false,
      });
    }
  }

  const assistantResponseParams: GetAssistantResponseParams = {
    messagesHistory,
    newMessage,
    sourcesContents,
    mustIncludeSourceContents: mustIncludeSourceContents || false,
    featureFlags: features,
  };
  if (markdownContent !== undefined) {
    const voice = voices.find((v) => v.id === voiceID);
    const voiceModificationHistory = getVoiceModificationHistoryFromMessages(
      voice?.messagesHistory || [],
    );
    assistantResponseParams.composeParams = {
      markdownContent: markdownContent || '',
      voiceID,
      voiceSourceContents,
      voiceModificationHistory: voiceModificationHistory || [],
      mustGenerateDraft: mustGenerateDraft || false,
    };
  }
  getAssistantResponse(assistantResponseParams, handleResponse)
    .catch((e) => {
      logger.error('Failed to get response from assistant', e);
      logAssistantResponseError({ type: 'exception', error: e }, newMessage);
      dispatch({
        type: 'addMessage',
        message: {
          type: 'message',
          role: 'system',
          text: i18n.t('compose_generic_error'),
          ts: Date.now(),
          actionContext: { type: 'error' },
        },
        requiresResponse: false,
      });
      if (isFollowUpSuggestion) {
        logComposeEvent(
          PAP_Click_ChatSuggestion({
            eventState: 'failed',
            generatedQueryString: text,
          }),
          { actionSurfaceComponent: 'compose_chat_pane' },
        );
      } else if (isTextSelectionMenuAction) {
        logComposeEvent(
          PAP_Interact_TextSelectionMenu({
            eventState: 'failed',
            queryString: text,
          }),
          { actionSurfaceComponent: 'compose_editor_pane' },
        );
      }
    })
    .finally(() => {
      dispatch({
        type: 'markMessageResponded',
        toSessionID: currentSessionID,
      });
      if (isFollowUpSuggestion) {
        logComposeEvent(
          PAP_Click_ChatSuggestion({
            eventState: 'success',
            generatedQueryString: text,
          }),
          { actionSurfaceComponent: 'compose_chat_pane' },
        );
      } else if (isTextSelectionMenuAction) {
        logComposeEvent(
          PAP_Interact_TextSelectionMenu({
            eventState: 'success',
            queryString: text,
          }),
          { actionSurfaceComponent: 'compose_editor_pane' },
        );
      }
    });
}

function getMessageForAssistantResponse(
  response: AssistantResponse,
  userMessage: ComposeAssistantConversationMessageMessage,
): ComposeAssistantConversationMessage | undefined {
  switch (response.type) {
    case 'write_doc': {
      let rawPromptText = `[Message truncated for size] Draft generated: write_doc called with ${response.content.length} characters.`;
      if (response.responseText) {
        rawPromptText += `\n\n${response.responseText}`;
      }
      let text = i18n.t('compose_assistant_draft_generated');
      if (response.responseText) {
        text = `${text}\n\n${response.responseText}`;
      }
      if (response.draftModifications) {
        text = `${text}\n\n${response.draftModifications}`;
      }
      return {
        type: 'message',
        role: 'assistant',
        text,
        rawPromptText,
        ts: Date.now(),
        actionContext: { type: 'draft_generated' },
        followUpSuggestions: response.followUpSuggestions,
      };
    }
    case 'message': {
      logAssistantResponseError({ type: 'no_response' }, userMessage);
      return {
        type: 'message',
        role: 'assistant',
        text: response.responseText || i18n.t('compose_generic_error'),
        ts: Date.now(),
        actionContext: { type: response.responseText ? 'done' : 'error' },
      };
    }
    case 'read_source': {
      return {
        type: 'message',
        role: 'assistant',
        text: `${i18n.t('assistant_message_reading')} ${getSourceTitle(
          response.source.source,
        )}…`,
        ts: Date.now(),
        referencingSources: [response.source.source],
        actionContext: { type: 'reading' },
      };
    }
    case 'dash_searched':
      return {
        type: 'message',
        role: 'assistant',
        text: i18n.t('assistant_message_searched_dash'),
        ts: Date.now(),
        actionContext: { type: 'dash_search', queries: response.queries },
      };
    case 'analyzed_search_results':
      return {
        type: 'message',
        role: 'assistant',
        text: i18n.t('assistant_message_analyzed_content', {
          totalCount: response.sources.length,
        }),
        ts: Date.now(),
        actionContext: { type: 'dash_search_analyze' },
        referencingSources: response.sources,
      };
    case 'update_progress':
    case 'modify_voice':
      // no message needed, this is just updating the transient progress string
      return undefined;
    default:
      response satisfies never;
      throw new Error(`Unknown response type: ${response}`);
  }
}

function hasPendingSourceContents(sourceContents: SourcesContentCache) {
  return Object.values(sourceContents).some(
    (content) => content.state === 'loading',
  );
}

async function waitForPendingSourceContents(
  sourceContents: SourcesContentCache,
) {
  const completedSourceContents = { ...sourceContents };
  const pendingPromises: Promise<void>[] = [];
  for (const [uuid, content] of Object.entries(sourceContents)) {
    if (content.state === 'loading') {
      pendingPromises.push(
        content.loadingPromise.then((completedState) => {
          completedSourceContents[uuid] = completedState;
          return;
        }),
      );
    }
  }
  await Promise.all(pendingPromises);
  return completedSourceContents;
}

async function waitForAndMergeTransientSources(
  transientSources: TransientSource[] | undefined,
  sourceContents: SourcesContentCache,
) {
  const mergedSourcesContents = { ...sourceContents };
  const contentPromises = (transientSources || []).map((source) =>
    source.getContent(),
  );
  const contentResults = await Promise.all(contentPromises);
  let hasLoadErrors = false;
  for (const result of contentResults) {
    const uuid = getSourceUUID(result.source);
    if (uuid === undefined) {
      throw new Error('Failed to get UUID for transient source');
    }
    mergedSourcesContents[uuid] = result;
    hasLoadErrors = hasLoadErrors || result.state === 'error';
  }
  return { hasLoadErrors, mergedSourcesContents };
}

function getVoiceRewriteUserMessageString(
  voiceID: string,
  voices: ComposeVoice[],
): string {
  if (isPreConfiguredVoiceID(voiceID)) {
    switch (voiceID) {
      case 'preset_neutral':
        return i18n.t('compose_rewrite_message_tones_neutral');
      case 'preset_persuasive':
        return i18n.t('compose_rewrite_message_tones_persuasive');
      case 'preset_instructional':
        return i18n.t('compose_rewrite_message_tones_instructional');
      case 'preset_narrative':
        return i18n.t('compose_rewrite_message_tones_narrative');
      case 'preset_informal':
        return i18n.t('compose_rewrite_message_tones_informal');
      case 'preset_analytical':
        return i18n.t('compose_rewrite_message_tones_analytical');
      default:
        voiceID satisfies never;
        throw new Error(`Unsupported voice type: ${voiceID}`);
    }
  }
  const voice = voices.find((voice) => voice.id === voiceID);
  if (!voice) {
    throw new Error(`rewriting for unknown voice id: ${voiceID}`);
  }
  return i18n.t('compose_rewrite_message_tones_custom', {
    toneName: voice.name,
  });
}

function getVoiceRewriteSourceContents(
  voiceID: string,
  voices: ComposeVoice[],
  currentVoiceID: string | undefined,
  currentSourceContents: SourcesContentCache,
): SourcesContentCache {
  if (isPreConfiguredVoiceID(voiceID)) {
    return {};
  }
  if (voiceID === currentVoiceID) {
    return currentSourceContents;
  }
  const voice = voices.find((voice) => voice.id === voiceID);
  if (!voice) {
    throw new Error(`rewriting for unknown voice id: ${voiceID}`);
  }
  // user just switched to a different voice, so we need to fetch new source contents
  return getComposeSourcesCache(voice.sources);
}

function logTransientSourceLoadError(transientSources: TransientSource[]) {
  Sentry.withScope((scope) => {
    const uuids: string[] = [];
    for (const source of transientSources) {
      uuids.push(getSourceUUID(source.source) || 'N/A');
    }
    scope.setTag('transientSourceUUIDs', uuids.join(', '));
    Sentry.captureMessage(
      'Error loading transient source content',
      'error',
      {},
      scope,
    );
  });
}

function logAssistantResponseError(
  responseError: { type: 'exception'; error: Error } | { type: 'no_response' },
  userMessage: ComposeAssistantConversationMessageMessage,
) {
  Sentry.withScope((scope) => {
    scope.setTag('userMessageText', userMessage.text);
    scope.setTag('userMessageRawPromptText', userMessage.rawPromptText);
    scope.setTag('userMessageTimestamp', userMessage.ts.toString());

    switch (responseError.type) {
      case 'exception':
        {
          scope.setTag(
            'errorMessage',
            isErrorWithMessage(responseError.error)
              ? responseError.error.message
              : 'unknown',
          );
          Sentry.captureException(responseError.error);
          Sentry.captureMessage(
            '[Assistant] Assistant response exception',
            'error',
            {},
            scope,
          );
        }
        break;
      case 'no_response':
        {
          Sentry.captureMessage(
            '[Assistant] Assistant no_response error',
            'error',
            {},
            scope,
          );
        }
        break;
      default:
        responseError satisfies never;
        throw new Error(`Unknown response error type: ${responseError}`);
    }
  });
}
