// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3659
import type { PAPEventGeneric } from "../base/event";

// Event_name: 'dismiss.stacks_warn_duplicate_link_modal'
// Description: Dismissing the warning from the stacks duplicate item modal
// Owner: dash-web
export type Dismiss_StacksWarnDuplicateLinkModal = PAPEventGeneric<
  'stacks',
  'dismiss',
  'stacks_warn_duplicate_link_modal',
  {
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The stack id that represents the stack
    stackId?: string;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Used to identify if a stack is a published stack
    isPublished?: boolean;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
  }
>;

export function PAP_Dismiss_StacksWarnDuplicateLinkModal(properties?: Dismiss_StacksWarnDuplicateLinkModal['properties']): Dismiss_StacksWarnDuplicateLinkModal {
  return <Dismiss_StacksWarnDuplicateLinkModal>{
    class: 'stacks',
    action: 'dismiss',
    object: 'stacks_warn_duplicate_link_modal',
    properties,
  };
}
