import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import { PAP_Click_NewSessionButton } from '@mirage/analytics/events/types/click_new_session_button';
import {
  ComposeAssistantGrid,
  ComposeAssistantPageWrapper,
} from '@mirage/mosaics/ComposeAssistant/components/ComposeAssistantPageLayout';
import { FullScreenComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/components/layouts/FullScreenComposeEditorPane';
import { ToggleExpandButton } from '@mirage/mosaics/ComposeAssistant/components/layouts/ToggleExpandButton';
import { SideBarContainer } from '@mirage/mosaics/ComposeAssistant/components/side-bar/SideBarContainer';
import { SidePanelContainer } from '@mirage/mosaics/ComposeAssistant/components/side-panel/SidePanelContainer';
import { ComposeConversation } from '@mirage/mosaics/ComposeAssistant/containers/ComposeConversation';
import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import { ComposeSessionsPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeSessionsPane';
import {
  ComposeAnalyticsContextProvider,
  useComposeAnalyticsContext,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { ComposeSessionsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import { ComposeVoicesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  ComposeCurrentSessionContextProvider,
  useComposeCurrentSessionContext,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import { ComposeArtifact } from '@mirage/shared/compose/compose-session';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsSmallSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { usePrevious } from '@mirage/shared/util/hooks';
import i18n from '@mirage/translations';
import { isEqual } from 'lodash';
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { ChangelogButton } from '../components/changelog/ChangelogButton';

interface ComposeAssistantPageProps {
  variant?: 'default' | 'condensed';
  transientSources?: TransientSource[];
  children?: ReactNode;
}
export const ComposeAssistantPage = memo(
  ({ variant = 'default', transientSources }: ComposeAssistantPageProps) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const { dashActionSurface } = useDashActionSurface();
    const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
    const handleClickAdd = useCallback(() => {
      setIsAddSourceModalOpen(true);
      reportPapEvent(
        PAP_Click_AddSourcesButton({
          actionType: 'chat_pane',
          actionSurface: dashActionSurface,
          dashActionSurface,
          actionSurfaceComponent: 'compose_chat_pane',
          featureLine: 'assist' as FeatureLine,
        }),
      );
    }, [dashActionSurface, reportPapEvent, setIsAddSourceModalOpen]);
    return (
      <ComposeAnalyticsContextProvider>
        <ComposeSessionsContextProvider>
          <ComposeVoicesContextProvider>
            <ComposeCurrentSessionContextProvider
              transientSources={transientSources}
            >
              {variant === 'default' ? (
                <ComposeAssistantPageContent
                  setIsAddSourceModalOpen={setIsAddSourceModalOpen}
                  isAddSourceModalOpen={isAddSourceModalOpen}
                  handleClickAdd={handleClickAdd}
                />
              ) : (
                <ComposeAssistantPageContentCondensed
                  setIsAddSourceModalOpen={setIsAddSourceModalOpen}
                  isAddSourceModalOpen={isAddSourceModalOpen}
                  handleClickAdd={handleClickAdd}
                />
              )}
            </ComposeCurrentSessionContextProvider>
          </ComposeVoicesContextProvider>
        </ComposeSessionsContextProvider>
      </ComposeAnalyticsContextProvider>
    );
  },
);
ComposeAssistantPage.displayName = 'ComposeAssistantPage';

const ComposeAssistantPageContent = memo(
  ({
    setIsAddSourceModalOpen,
    isAddSourceModalOpen,
    handleClickAdd,
  }: {
    setIsAddSourceModalOpen: (isOpen: boolean) => void;
    isAddSourceModalOpen: boolean;
    handleClickAdd: () => void;
  }) => {
    const { currentSessionID, artifacts } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_side_bar',
      currentSessionID,
    });
    const isSmallSize = useIsSmallSizeForSidebar();
    const [keepSideBarExpanded, setKeepSideBarExpanded] = useState(
      isSmallSize ? false : true,
    );
    const [didJustSendFirstWriteCommand, setDidJustSendFirstWriteCommand] =
      useState(false);
    const [rightPaneExpanded, setRightPaneExpanded] = useState(() =>
      hasNonEmptyArtifacts(artifacts),
    );
    const handleArtifactsChanged = useCallback(() => {
      // auto-expand the right pane if artifacts changed + are non-empty
      setRightPaneExpanded(hasNonEmptyArtifacts(artifacts));
      // auto-close side bar when new draft is created
      if (didJustSendFirstWriteCommand) {
        setKeepSideBarExpanded(!hasNonEmptyArtifacts(artifacts));
        setDidJustSendFirstWriteCommand(false);
      }
    }, [artifacts, didJustSendFirstWriteCommand]);
    useArtifactContentsChangedEffect(artifacts, handleArtifactsChanged);

    const sidePanelButtons = !keepSideBarExpanded && (
      <>
        <ToggleExpandButton
          setExpanded={setKeepSideBarExpanded}
          variant="expand"
          logComposeEvent={logComposeEvent}
        />
        <NewSessionButton />
      </>
    );

    const additionalEditorToolbarButtons = isSmallSize ? (
      <div style={{ width: '30px' }} />
    ) : (
      <ToggleExpandButton
        setExpanded={setRightPaneExpanded}
        variant="collapse"
        logComposeEvent={logComposeEvent}
      />
    );
    const handleOpenArtifact = useCallback(() => {
      setRightPaneExpanded(true);
    }, []);

    return (
      <ComposeAssistantPageWrapper>
        <ComposeAssistantGrid
          didJustSendFirstWriteCommand={didJustSendFirstWriteCommand}
          keepSideBarExpanded={keepSideBarExpanded}
          setKeepSideBarExpanded={setKeepSideBarExpanded}
          sideBar={
            <ComposeAssistSideBar
              keepExpanded={keepSideBarExpanded}
              rightPaneExpanded={rightPaneExpanded}
              setKeepExpanded={setKeepSideBarExpanded}
            />
          }
          sidePane={
            <SidePanelContainer
              leftTitleActions={sidePanelButtons}
              rightPaneExpanded={rightPaneExpanded}
              handleClickAdd={handleClickAdd}
            >
              <ComposeConversation
                rightPaneExpanded={rightPaneExpanded}
                setDidJustSendFirstWriteCommand={
                  setDidJustSendFirstWriteCommand
                }
                onOpenArtifact={handleOpenArtifact}
                handleClickAdd={handleClickAdd}
                setIsAddSourceModalOpen={setIsAddSourceModalOpen}
                isAddSourceModalOpen={isAddSourceModalOpen}
              />
            </SidePanelContainer>
          }
          rightPane={
            rightPaneExpanded && (
              <ComposeEditorPane
                additionalToolbarButtons={additionalEditorToolbarButtons}
              />
            )
          }
          setExpandRightPane={setRightPaneExpanded}
          disableExpandRightPane={artifacts.length === 0}
          logComposeEvent={logComposeEvent}
        />
      </ComposeAssistantPageWrapper>
    );
  },
);
ComposeAssistantPageContent.displayName = 'ComposeAssistantPageContent';

const ComposeAssistantPageContentCondensed = memo(
  ({
    setIsAddSourceModalOpen,
    isAddSourceModalOpen,
    handleClickAdd,
  }: {
    setIsAddSourceModalOpen: (isOpen: boolean) => void;
    isAddSourceModalOpen: boolean;
    handleClickAdd: () => void;
  }) => {
    const [showArtifactsPane, setShowArtifactsPane] = useState(false);
    const handleOpenArtifact = useCallback(() => {
      setShowArtifactsPane(true);
    }, []);
    const handleCloseEditorPane = useCallback(() => {
      setShowArtifactsPane(false);
    }, []);
    return (
      <>
        <ComposeConversation
          variant="condensed"
          rightPaneExpanded={false}
          setDidJustSendFirstWriteCommand={undefined}
          onOpenArtifact={handleOpenArtifact}
          handleClickAdd={handleClickAdd}
          setIsAddSourceModalOpen={setIsAddSourceModalOpen}
          isAddSourceModalOpen={isAddSourceModalOpen}
        />
        {showArtifactsPane && (
          <FullScreenComposeEditorPane
            onCloseEditorPane={handleCloseEditorPane}
          />
        )}
      </>
    );
  },
);
ComposeAssistantPageContentCondensed.displayName =
  'ComposeAssistantPageContentCondensed';

function useArtifactContentsChangedEffect(
  artifacts: ComposeArtifact[],
  callback: () => void,
) {
  const previousArtifacts = usePrevious(artifacts);
  useEffect(() => {
    const currentMarkdownContents = artifacts.map((a) => a.markdownContent);
    const previousMarkdownContents = previousArtifacts
      ? previousArtifacts.map((a) => a.markdownContent)
      : [];
    if (isEqual(currentMarkdownContents, previousMarkdownContents)) {
      return;
    }
    callback();
  }, [artifacts, callback, previousArtifacts]);
}

interface ComposeAssistSideBarProps {
  keepExpanded: boolean;
  setKeepExpanded: (expanded: boolean) => void;
  rightPaneExpanded: boolean;
}
export const ComposeAssistSideBar = memo(
  ({
    keepExpanded,
    setKeepExpanded,
    rightPaneExpanded,
  }: ComposeAssistSideBarProps) => {
    const { currentSessionID } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_side_bar',
      currentSessionID,
    });
    const collapseSideBarButton = (
      <ToggleExpandButton
        logComposeEvent={logComposeEvent}
        setExpanded={setKeepExpanded}
        variant="collapse"
      />
    );
    return (
      <SideBarContainer
        rightPaneExpanded={rightPaneExpanded}
        headerActionLeft={keepExpanded ? collapseSideBarButton : undefined}
        headerActionRight={<NewSessionButton />}
        keepExpanded={keepExpanded}
        footer={<ChangelogButton />}
      >
        <ComposeSessionsPane />
      </SideBarContainer>
    );
  },
);
ComposeAssistSideBar.displayName = 'ComposeAssistSideBar';

export const NewSessionButton = memo(() => {
  const { logComposeEvent } = useComposeAnalyticsContext({
    actionSurfaceComponent: 'compose_side_bar',
  });
  const { newSession } = useComposeCurrentSessionContext();
  return (
    <IconButtonWithTooltip
      variant="borderless"
      tooltipProps={{
        title: i18n.t('compose_new_session'),
      }}
      onClick={() => {
        newSession([]);
        logComposeEvent(PAP_Click_NewSessionButton());
      }}
    >
      <UIIcon src={AddCircleLine} />
    </IconButtonWithTooltip>
  );
});
NewSessionButton.displayName = 'NewSessionButton';

function hasNonEmptyArtifacts(artifacts: ComposeArtifact[]) {
  return artifacts.some((artifact) => artifact.markdownContent.length > 0);
}
