import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { browserNameForCurrentBrowser } from '@mirage/shared/util/webExtensionsHelpers';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useState } from 'react';

const availableExtensionsAtom = atomWithStorage<string[]>(
  'availableExtensionsAtom',
  [],
);
const AVAILABLE_BROWSER_EXTENSIONS = ['Chrome', 'Edge'];

export default function useAvailableExtensions() {
  const [available, setAvailable] = useState(true);
  const [extensions, setExtensions] = useAtom(availableExtensionsAtom);
  const safariExtensionEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_09_show_safari'),
  );

  useEffect(() => {
    if (safariExtensionEnabled) {
      setExtensions([...AVAILABLE_BROWSER_EXTENSIONS, 'Safari']);
    } else {
      setExtensions(AVAILABLE_BROWSER_EXTENSIONS);
    }
  }, [safariExtensionEnabled, setExtensions]);

  useEffect(() => {
    const browser = browserNameForCurrentBrowser();
    setAvailable(extensions.includes(browser));
  }, [extensions]);

  return { extensions, extensionAvailableForBrowser: available };
}
