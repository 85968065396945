import { stacks } from '@dropbox/api-v2-client';
import { List } from '@dropbox/dig-components/list';
import { Box } from '@dropbox/dig-foundations';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { LinkComponent } from '@mirage/link-list/Link/Link';
import { ListItemSize } from '@mirage/link-list/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { stackItemGetName } from '@mirage/service-stacks/service/utils';
import { WorkingSetCardData } from '@mirage/working-set';
import { StackCardPopoverSectionHeader } from './StackCardPopoverSectionHeader';
import styles from './StackCardPopoverSectionItem.module.css';

export const StackSectionWithItems = ({
  sectionName,
  sectionItems,
  stackData,
}: {
  sectionName?: string;
  sectionItems: stacks.StackItemShortcut[];
  stackData: WorkingSetCardData;
}) => {
  const isDarkMode = useTheme();
  return (
    <Box>
      {sectionName && (
        <StackCardPopoverSectionHeader
          title={sectionName}
          count={sectionItems?.length || 0}
        />
      )}
      <Box className={styles.sectionItemContainer}>
        <List>
          {sectionItems?.map((item) => {
            if (!item.name || !item.name) return null;
            return (
              <List.Item key={`${sectionName}-${item.sort_key}`} padding={0}>
                <LinkComponent
                  link={{
                    title: stackItemGetName(item.name) ?? '',
                    url: item.url || '',
                    ...item,
                  }}
                  listItemSize={ListItemSize.Large}
                  openPapEvents={[
                    PAP_Open_DashLink({
                      ...stackData.stackDerivedPAPProps,
                      dashActionSurface: 'start_page',
                      actionSurfaceComponent: 'tab_panel',
                    }),
                  ]}
                  isDarkMode={isDarkMode}
                />
              </List.Item>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};
