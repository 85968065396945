import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkCircleLine,
  FailLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { sendResetConnectionToService } from '@mirage/service-browser-history-v2';
import { callApiV2 } from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import { useExtensionConnectionId } from '@mirage/shared/util/webExtensionsHelpers';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { clearBrowserHistorySuccessAtom } from './atoms';
import { getFilteredBrowserExtensionConnections } from './useCanClearBrowserHistory';

const logger = tagged('ClearBrowsingHistory');

export const ClearBrowsingHistory = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const connectionId = useExtensionConnectionId();
  const [clearSuccess, setClearSuccess] = useAtom(
    clearBrowserHistorySuccessAtom,
  );
  const clearBrowserHistoryVariant = useFeatureFlagValue(
    'dash_2024_12_09_clear_browser_history_variants',
    false,
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSubmit = async () => {
    try {
      switch (clearBrowserHistoryVariant) {
        case 'V1': {
          const browserExtensionConnections =
            await getFilteredBrowserExtensionConnections(true);

          if (browserExtensionConnections.length) {
            setLoading(true);

            // delete all matched browser extension connections in batches of 10
            // to avoid too many requests error
            for (let i = 0; i < browserExtensionConnections.length; i += 10) {
              const batch = browserExtensionConnections.slice(i, i + 10);
              await Promise.all(
                batch.map((c) => {
                  logger.info('Removing connection', {
                    connectionId: c.id_attributes?.id,
                  });
                  return callApiV2('dashConnectorMetadataRemoveConnection', {
                    connector_type: 'browser_extension',
                    connection_id: c.id_attributes?.id,
                    connector_platform: { '.tag': 'browser_extension' },
                  });
                }),
              );
            }
            setClearSuccess(true);
          }
          break;
        }
        case 'CONTROL':
        default: {
          if (connectionId) {
            setLoading(true);
            logger.info('Removing connection', { connectionId });
            await sendResetConnectionToService(connectionId);
            setClearSuccess(true);

            // Reload upon success to get the new connection ID
            window.location.reload();
          }
          break;
        }
      }
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setTag('connectionId', connectionId);
        scope.captureException(e, {
          data: {
            message: 'Failed to reset connection',
            connectionId,
          },
        });
      });
      showSnackbar({
        accessory: <UIIcon src={FailLine} />,
        title: i18n.t('settings_clear_history_error'),
        buttons: [
          {
            label: i18n.t('reload'),
            onClick: () => window.location.reload(),
          },
        ],
      });
    }

    setOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    // Display the success snackbar upon reload
    if (!loading && clearSuccess) {
      showSnackbar({
        accessory: <UIIcon src={CheckmarkCircleLine} />,
        title: i18n.t('settings_clear_history_success'),
      });
      setClearSuccess(false);
    }
  }, [loading, clearSuccess, setClearSuccess]);

  return (
    <>
      <Button variant="transparent" onClick={handleClick}>
        {i18n.t('settings_clear_history_button')}
      </Button>
      <Modal
        open={open}
        isCentered
        withCloseButton={i18n.t('close')}
        onRequestClose={handleClick}
      >
        <Modal.Header>
          <Modal.Title size="medium">
            {/* Use padding to force line break before title gets too close to Close button */}
            <Box paddingRight="24">
              {i18n.t('settings_clear_history_confirm_title')}
            </Box>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{i18n.t('settings_clear_history_confirm_text')}</Modal.Body>
        <Modal.Footer>
          <Button variant="opacity" size="medium" onClick={handleClick}>
            {i18n.t('cancel')}
          </Button>
          <Button
            variant="primary"
            size="medium"
            tone="destructive"
            onClick={handleSubmit}
            isLoading={loading}
          >
            {i18n.t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
