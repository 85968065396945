import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { List } from '@dropbox/dig-components/list';
import {
  Overlay,
  type OverlayPlacement,
} from '@dropbox/dig-components/overlay';
import { RefObject } from 'react';
import styles from './OverlayList.module.css';

export type OverlayListProps = {
  // the ref of the element that the overlay should be anchored to
  triggerRef: RefObject<HTMLElement>;
  // whether the overlay should be shown
  show: boolean;

  // the placement of the overlay relative to the trigger element, defaults to "bottom-start"
  placement?: OverlayPlacement;
  // callback when the pointer enters the overlay, typically used to set show to true
  onPointerEnter: () => void;
  // callback when the pointer leaves the overlay, typically used to set show to false
  onPointerLeave: () => void;

  // children to place inside the list, typically OverlayList.Item
  children: React.ReactNode;
};

/**
 * OverlayList renders a list of items in an overlay anchored to a trigger element.
 */
export const OverlayList = ({
  triggerRef,
  show,
  children,
  placement = 'bottom-start',
  onPointerEnter,
  onPointerLeave,
}: OverlayListProps) => {
  if (!show) {
    return null;
  }

  return (
    <ClickOutside onClickOutside={onPointerLeave}>
      <Overlay
        placement={placement}
        auto
        anchorRef={triggerRef}
        className={styles.overlayList}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        <div className={styles.overlayListContainer}>
          <List isSelectable={false} spacing="small">
            {children}
          </List>
        </div>
      </Overlay>
    </ClickOutside>
  );
};

/**
 * A styled List.Item for use in OverlayList
 */
const OverlayListItem = ({ children }: { children: React.ReactNode }) => {
  return <List.Item className={styles.overlayListItem}>{children}</List.Item>;
};

/**
 * A styled List.Content for use in OverlayList.Item
 */
const OverlayListItemContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <List.Content className={styles.overlayListItemContent}>
      {children}
    </List.Content>
  );
};

/**
 * A styled List.Accessory for use in OverlayList.Item
 */
const OverlayListItemAccessory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <List.Accessory>{children}</List.Accessory>;
};

OverlayList.Item = OverlayListItem;
OverlayList.ItemContent = OverlayListItemContent;
OverlayList.ItemAccessory = OverlayListItemAccessory;
