import { dash } from '@dropbox/api-v2-client';
import { Box } from '@dropbox/dig-foundations';
import { DashFacepile } from '@mirage/mosaics/DCLWrappers/DashFacepile';
import { CalendarEventAttendeesTooltip } from '@mirage/mosaics/StartPage/Calendar/CalendarEventComponent';
import { useRef, useState } from 'react';
import styles from './FacepileMetadata.module.css';
export type FacepileMetadataProps = {
  showableAttendees: dash.Author[];
  showPopover?: boolean;
};

const MAX_FACES_IN_FACEPILE = 4;

export const FacepileMetadata = ({
  showableAttendees,
  showPopover = true,
}: FacepileMetadataProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const mouseLeaveTimeout = useRef<number | null>(null);

  const handleMouseEnter = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 200);
  };

  return (
    <Box as="div">
      <Box
        as="div"
        className={styles.facepileContainer}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        ref={triggerRef}
      >
        <DashFacepile
          size="xsmall"
          members={showableAttendees}
          isInteractive={false}
          maxMembersToShow={MAX_FACES_IN_FACEPILE}
        />
      </Box>
      {showPopover && (
        <CalendarEventAttendeesTooltip
          triggerRef={triggerRef}
          show={showTooltip}
          attendees={showableAttendees}
          attendeeCount={showableAttendees.length}
          onPointerEnter={handleMouseEnter}
          onPointerLeave={handleMouseLeave}
          setSameWidthAsAnchor={false}
        />
      )}
    </Box>
  );
};
