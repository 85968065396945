// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1607
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSearchResultType } from "../enums/dash_search_result_type";
import type { SearchResultSource } from "../enums/search_result_source";
import type { DashSourceType } from "../enums/dash_source_type";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { LaunchMethod } from "../enums/launch_method";
import type { TypeaheadResultType } from "../enums/typeahead_result_type";
import type { FeatureLine } from "../enums/feature_line";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { DashAnnotationMode } from "../enums/dash_annotation_mode";
import type { SerpFinalRanking } from "../enums/serp_final_ranking";

// Event_name: 'open.dash_search_result'
// Description: Event captured when launching a search result.
// Owner: otc
export type Open_DashSearchResult = PAPEventGeneric<
  'dash',
  'open',
  'dash_search_result',
  {
    // Describe whether a search result came from upstream search
    fromUpstream?: boolean;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Legacy: dash_connector_id in Desktop
    // Note, this should have been a enum :(
    connector?: string;
    // Accumulated count of hit launch (locally on desktop) when result is shown.
    hitCount?: number;
    // Total result score of ranking.
    totalScore?: number;
    // Source result score of ranking.
    sourceScore?: number;
    // Title attribute related to the primitive
    title?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // The contents of a search query
    queryString?: string;
    // Number of results returned to user
    resultCount?: number;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Timestamp that encodes last time the search result was updated (in milliseconds).
    // i.e Document.upstreamUpdatedAt, Event.upstreamUpdatedAt, etc.
    // 1680559512000, 1680569512000, etc.
    resultUpdatedAtMs?: number;
    // App filters selected
    // e.g. “contact”, “contact,gsuite”
    // These values are going to be sorted, global, code defined, Dropbox owned data that tags the "type" of a search result.
    activeFilters?: string;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Boolean indicating when the right rail is visible (in Desktop)
    rightRailOpened?: boolean;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Source for the document such as connector, tip, application, local file.
    sourceType?: string;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Delay in results in milliseconds
    latency?: number;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // This defines where the document source is
    dashSourceType?: DashSourceType;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The method in which an action is launched
    launchMethod?: LaunchMethod;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
    // Type of result being acted upon within typeahead field
    typeaheadResultType?: TypeaheadResultType;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
    // Which algorithm the Dash client used while drawing SERP results onto the screen. This reflects the logic the client used to merge and rank server results, upstream results, and local file results.
    serpFinalRanking?: SerpFinalRanking;
    // The zero-index returned position of search result in a list of search results with the SERP CTA removed from the list
    resultPositionNoCta?: number;
    // In Dash typeahead, the unweighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreAppliedWeight?: number;
    // Whether or not the result in typeahead was "pinned" to a specific position (not necessarily p1)
    typeaheadScoringPinned?: boolean;
    // Determines if a search result has been "verified"
    dashIsResultVerified?: boolean;
    // Boolean if the search result was displayed within a Category
    categorizedResult?: boolean;
    // the position of the result in the list UI, used for categorized search experiment
    displayedPosition?: number;
  }
>;

export function PAP_Open_DashSearchResult(properties?: Open_DashSearchResult['properties']): Open_DashSearchResult {
  return <Open_DashSearchResult>{
    class: 'dash',
    action: 'open',
    object: 'dash_search_result',
    properties,
  };
}
