import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_FormatButton } from '@mirage/analytics/events/types/click_format_button';
import { ComposeContentUpdaterPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeContentUpdaterPlugin';
import { COMPOSE_TRANSFORMERS } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeMarkdownTransformers';
import { ExportButton } from '@mirage/mosaics/ComposeAssistant/components/editor/ExportButton';
import {
  SelectionAction,
  SelectionToolbarPlugin,
} from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { StaticToolbarPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/StaticToolbarPlugin';
import { CustomTableNode } from '@mirage/mosaics/ComposeAssistant/components/editor/table/CustomTableNode';
import { TableCellResizerPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableCellResizerPlugin';
import { TableHoverActionsPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableHoverActionsPlugin';
import { VoiceSelector } from '@mirage/mosaics/ComposeAssistant/components/editor/VoiceSelector';
import { WaitingForResponsePlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/WaitingForResponsePlugin';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { DEFAULT_PRECONFIGURED_VOICE_ID } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { useClipboardCopyListenerOnRef } from '@mirage/shared/hooks/useClipboardCopyListenerOnRef';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';
import styles from './ComposeEditor.module.css';
import { ComposeSelectionLoggerPlugin } from './ComposeSelectionLoggerPlugin';
import tableStyles from './table/table.module.css';

const logger = tagged('ComposeEditor');

const STATIC_TOOLBAR_HEIGHT = 58;
const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
    };
  },
];

interface ComposeEditorProps {
  isWaitingForResponse: boolean;
  markdownContent: string;
  onChangeContent: (markdownContent: string) => void;
  onTriggerSelectionAction: (action: SelectionAction) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  customVoices: ComposeVoice[] | undefined;
  currentVoiceID: string | undefined;
  onChangeVoiceID: (voiceID: string) => void;
  onCreateNewVoice: () => void;
  onOpenVoiceSettings: (voiceID: string | undefined) => void;
  disabled: boolean;
  additionalToolbarButtons?: React.ReactNode;
}
export const ComposeEditor = memo(
  ({
    isWaitingForResponse,
    markdownContent,
    onChangeContent,
    onTriggerSelectionAction,
    logComposeEvent,
    customVoices,
    currentVoiceID,
    onChangeVoiceID,
    onCreateNewVoice,
    onOpenVoiceSettings,
    disabled,
    additionalToolbarButtons,
  }: ComposeEditorProps) => {
    const handleCopy = useCallback(() => {
      logComposeEvent(
        PAP_Click_FormatButton({
          actionType: 'copy_from_keyboard',
        }),
      );
    }, [logComposeEvent]);
    const clipboardListener = useClipboardCopyListenerOnRef(handleCopy);
    const onRef = useCallback(
      (el: HTMLDivElement) => {
        if (el) {
          clipboardListener(el);
        }
      },
      [clipboardListener],
    );
    const supportsTables =
      useFeatureFlagValue('dash_assist_2025_01_09_assist_compose_tables') ===
      'ON';
    return (
      <LexicalComposer initialConfig={ComposeInitialConfig}>
        <StaticToolbarPlugin
          logComposeEvent={logComposeEvent}
          additionalButtons={
            <>
              <VoiceSelector
                currentVoiceID={
                  currentVoiceID || DEFAULT_PRECONFIGURED_VOICE_ID
                }
                customVoices={customVoices}
                onChangeVoiceID={onChangeVoiceID}
                onCreateNewVoice={onCreateNewVoice}
                onOpenVoiceSettings={onOpenVoiceSettings}
                disabled={disabled}
                logComposeEvent={logComposeEvent}
              />
              <div className={styles.spacer} />
              <ExportButton
                logComposeEvent={logComposeEvent}
                disabled={disabled}
              />
              {additionalToolbarButtons}
            </>
          }
        />
        <RichTextPlugin
          contentEditable={
            <div className={styles.contentEditableScroller}>
              <div style={{ position: 'relative' }} ref={onRef}>
                <ContentEditable className={styles.contentEditable} />
              </div>
            </div>
          }
          placeholder={
            <div className={styles.placeholderWrapper}>
              <div className={styles.placeholder}>
                <h2>{i18n.t('compose_editor_placeholder_title')}</h2>
                <p>{i18n.t('compose_editor_placeholder_body')}</p>
              </div>
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
        <ClickableLinkPlugin />
        <MarkdownShortcutPlugin
          transformers={supportsTables ? COMPOSE_TRANSFORMERS : TRANSFORMERS}
        />
        {supportsTables && (
          <>
            <TableHoverActionsPlugin />
            <TableCellResizerPlugin />
          </>
        )}
        {supportsTables && (
          <TablePlugin
            hasCellMerge={false}
            hasCellBackgroundColor={false}
            hasHorizontalScroll
          />
        )}
        <ComposeSelectionLoggerPlugin logComposeEvent={logComposeEvent} />
        <ComposeContentUpdaterPlugin
          supportsTables={supportsTables}
          markdownContent={markdownContent}
          onChangeContent={onChangeContent}
        />
        <SelectionToolbarPlugin
          supportsTables={supportsTables}
          offsetTop={STATIC_TOOLBAR_HEIGHT}
          onTriggerSelectionAction={onTriggerSelectionAction}
          logComposeEvent={logComposeEvent}
        />
        <WaitingForResponsePlugin isWaitingForResponse={isWaitingForResponse} />
      </LexicalComposer>
    );
  },
);
ComposeEditor.displayName = 'ComposeEditor';

const ComposeInitialConfig: InitialConfigType = {
  namespace: 'ComposeNewPost',
  onError: logger.error,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    TableRowNode,
    TableCellNode,
    CustomTableNode,
    {
      replace: TableNode,
      with: () => new CustomTableNode(),
      withKlass: CustomTableNode,
    },
  ],
  theme: {
    text: {
      bold: styles.editorTextBold,
      italic: styles.editorTextItalic,
      strikethrough: styles.editorTextStrikethrough,
      underline: styles.editorTextUnderline,
      underlineStrikethrough: styles.editorTextUnderlineStrikethrough,
    },
    table: tableStyles.table,
    tableSelection: tableStyles.tableSelection,
    tableSelected: tableStyles.tableSelected,
    tableCell: tableStyles.tableCell,
    tableCellHeader: tableStyles.tableCellHeader,
    tableCellSelected: tableStyles.tableCellSelected,
    tableCellPrimarySelected: tableStyles.tableCellPrimarySelected,
    tableScrollableWrapper: tableStyles.tableScrollableWrapper,
  },
};
