import { Chip } from '@dropbox/dig-components/chip';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Typeahead } from '@dropbox/dig-components/typeahead';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  CheckmarkLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { StackSendShareType } from '@mirage/analytics/events/enums/stack_send_share_type';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { AvatarMember } from '@mirage/stacks/ShareModal/Avatar';
import {
  SharingMember,
  SharingMemberKey,
  SharingStackPermission,
  SharingUserContactKey,
  StackPermission,
} from '@mirage/stacks/ShareModal/Types';
import {
  DELIMITED_CONTACT_REGEX,
  getSharingMemberIdentifier,
  sharingMemberKeysMatch,
  tokenizeDelimitedInput,
  tokenizeValidRawInput,
  validateEmail,
} from '@mirage/stacks/ShareModal/Utils';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';

export interface SharingTypeaheadProps {
  stackMemberKeys: SharingMemberKey[];
  isSharing: boolean;
  excludedMembers: SharingMemberKey[];
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  setInvitees?: React.Dispatch<
    React.SetStateAction<(SharingMember | SharingUserContactKey)[]>
  >;
  inviteesPermission?: SharingStackPermission;
  setInviteesPermission?: React.Dispatch<
    React.SetStateAction<SharingStackPermission>
  >;
  onMemberSelected?: (member: SharingMember | SharingUserContactKey) => void;
  onMemberSelectedEvent: (
    share_type: StackSendShareType,
    count: number,
  ) => void;
  allowExternalShare?: boolean;
  showAddIconInResultRow?: boolean;
  disableInvitePeopleViaEmail?: boolean;
  tooltipRef?: React.RefObject<HTMLElement>;
}

export const SharingTypeahead = ({
  stackMemberKeys,
  isSharing,
  excludedMembers,
  getSharingMembers,
  setInvitees,
  inviteesPermission = StackPermission.READ,
  setInviteesPermission,
  // Specifying onMemberSelected changes the mode in which the typeahead operates.
  // If onMemberSelected is specified, the typeahead will immediately call onMemberSelected
  // with the selected member and not add it to the selected members list.
  // Generally, one sets either this or setInvitees, but not both.
  onMemberSelected,
  onMemberSelectedEvent,
  allowExternalShare = false,
  showAddIconInResultRow = false,
  disableInvitePeopleViaEmail = false,
  tooltipRef,
}: SharingTypeaheadProps) => {
  const currentAccount = useDropboxAccount();

  const [shareText, setShareText] = useState('');
  const [isTypeaheadOpen, setIsTypeaheadOpen] = useState(false);
  const [sharingContactsResults, setSharingContactsResults] = useState<
    SharingMember[]
  >([]);
  const [isSearchingSharingContacts, setIsSearchingSharingContacts] =
    useState(false);
  const [selectedSharingMembers, setSelectedSharingMembers] = useState<
    (SharingMember | SharingUserContactKey)[]
  >([]);
  const isAllowedToPasteEmailList = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2025_01_14_paste_emails_to_share'),
  );
  const inputContainerRef = useRef<HTMLElement | null>(null);

  const showingSuggestedMembers = !onMemberSelected && !shareText;

  const updateSharingContacts = async () => {
    setIsSearchingSharingContacts(true);
    const members = await getSharingMembers(shareText);
    const filteredMembers = members
      .filter((member) => {
        return (
          !selectedSharingMembers.some((selectedMember) =>
            sharingMemberKeysMatch(selectedMember, member),
          ) &&
          !(member['.tag'] === 'user' && member.email === currentAccount?.email)
        );
      })
      .slice(0, 3);
    setSharingContactsResults(filteredMembers);
    setIsSearchingSharingContacts(false);
  };

  // Debounce request to update suggestions until user stops modifying it
  const debouncedUpdateSharingContacts = useDebounce(
    updateSharingContacts,
    200,
  );

  useEffect(() => {
    setInvitees?.(selectedSharingMembers);
  }, [setInvitees, selectedSharingMembers]);

  useEffect(() => {
    debouncedUpdateSharingContacts();
  }, [shareText, debouncedUpdateSharingContacts, excludedMembers]);

  useEffect(() => {
    return () => {
      debouncedUpdateSharingContacts.cancel();
    };
  }, [debouncedUpdateSharingContacts]);

  const isSharingMemberAlreadyAMember = useCallback(
    (result: SharingMember) => {
      const alreadyAMember =
        stackMemberKeys.some((memberKey) =>
          sharingMemberKeysMatch(memberKey, result),
        ) ||
        excludedMembers.some((memberKey) =>
          sharingMemberKeysMatch(memberKey, result),
        );
      return alreadyAMember;
    },
    [stackMemberKeys, excludedMembers],
  );

  const renderTypeaheadRow = useCallback(
    (result: SharingMember) => {
      const alreadyAMember = isSharingMemberAlreadyAMember(result);
      return (
        <Typeahead.Row
          key={getSharingMemberIdentifier(result)}
          value={result}
          withTitle={result.displayName}
          withSubtitle={
            <Text
              className={classNames({
                [styles.disabledTextColor]: alreadyAMember,
              })}
            >
              {result['.tag'] === 'user'
                ? result.email
                : `${i18n.t('company_accesslevel')} · ${i18n.t('num_members', {
                    count: result.memberCount,
                  })}`}
            </Text>
          }
          disabled={alreadyAMember}
          withLeftAccessory={<AvatarMember member={result} hasNoOutline />}
          withRightAccessory={
            alreadyAMember ? (
              <UIIcon src={CheckmarkLine} />
            ) : showAddIconInResultRow ? (
              <UIIcon src={AddLine} />
            ) : undefined
          }
        />
      );
    },
    [
      showAddIconInResultRow,
      isSharingMemberAlreadyAMember,
      sharingMemberKeysMatch,
    ],
  );

  const selectMember = useCallback(
    (member: SharingMember | SharingUserContactKey): void => {
      setShareText('');

      if (onMemberSelected) {
        onMemberSelected(member);
        return;
      }

      setSelectedSharingMembers((currentMembers) => {
        if (
          currentMembers.some((currentMember) =>
            sharingMemberKeysMatch(currentMember, member),
          )
        ) {
          return currentMembers;
        }
        return [...currentMembers, member];
      });

      debouncedUpdateSharingContacts();
    },
    [onMemberSelected, setShareText, debouncedUpdateSharingContacts],
  );

  const deselectMember = useCallback((member: SharingMemberKey): void => {
    setSelectedSharingMembers((currentMembers) =>
      currentMembers.filter(
        (currentMember) => !sharingMemberKeysMatch(currentMember, member),
      ),
    );
  }, []);

  const findSuggestedMember = useCallback(
    (memberKey: SharingUserContactKey) => {
      const member = sharingContactsResults.find((result) =>
        sharingMemberKeysMatch(result, memberKey),
      );

      if (allowExternalShare) {
        // Only accept a validated email address if we're allowing external sharing
        return member || memberKey;
      }
      return member;
    },
    [sharingContactsResults, allowExternalShare],
  );

  const handleInputChange = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      const targets = tokenizeDelimitedInput(value);
      const lastChar = value.slice(-1);

      if (isAllowedToPasteEmailList) {
        if (!onMemberSelected && lastChar.match(DELIMITED_CONTACT_REGEX)) {
          if (targets.length > 0) {
            const trimmedValue = value
              .replace(DELIMITED_CONTACT_REGEX, ' ')
              .trim();
            setShareText(trimmedValue);
            targets.forEach((target) => {
              // match against member
              const member = findSuggestedMember(target);
              if (member) {
                selectMember(member);
              }
            });
            onMemberSelectedEvent('email_paste', targets.length);
            return;
          }
        }
      } else {
        if (!onMemberSelected && (lastChar === ' ' || lastChar === ',')) {
          const trimmedValue = value.replace(',', '').trim();
          setShareText(trimmedValue);
          if (validateEmail(trimmedValue)) {
            const member = findSuggestedMember({
              '.tag': 'user',
              email: trimmedValue,
            });
            if (member) {
              selectMember(member);
              onMemberSelectedEvent('email_paste', 1);
              return;
            }
          }
        }
      }

      setShareText(value);
      debouncedUpdateSharingContacts();
    },
    [
      onMemberSelected,
      onMemberSelectedEvent,
      debouncedUpdateSharingContacts,
      isAllowedToPasteEmailList,
      findSuggestedMember,
      selectMember,
    ],
  );

  const handleKeyDown: React.KeyboardEventHandler = useCallback(
    (e) => {
      if (onMemberSelected) {
        return;
      }

      switch (e.key) {
        case 'Backspace':
          if (shareText === '') {
            setSelectedSharingMembers((currentMembers) => {
              if (currentMembers.length === 0) {
                return currentMembers;
              }
              const lastMember = currentMembers.slice(-1)[0];
              if ('displayName' in lastMember) {
                debouncedUpdateSharingContacts();
              }
              return currentMembers.slice(0, -1);
            });
          }
          break;
        case 'Enter': {
          const targets = tokenizeValidRawInput(shareText);

          if (isAllowedToPasteEmailList) {
            if (targets.length === 0) {
              // Searches that resolve to a suggestion will still be
              // selected by the typeahead.
              break;
            }
            targets.forEach((target) => {
              const member = findSuggestedMember(target);
              if (member) {
                selectMember(member);
                // prevent menu selection to avoid duplicate adds (one from the menu and one from the input+enter)
                e.preventDefault();
                e.stopPropagation();
              }
            });
            onMemberSelectedEvent('email_paste', targets.length);
          } else {
            if (!validateEmail(shareText)) {
              // Searches that resolve to a suggestion will still be
              // selected by the typeahead.
              break;
            }
            const member = findSuggestedMember({
              '.tag': 'user',
              email: shareText,
            });
            if (member) {
              selectMember(member);
              onMemberSelectedEvent('email_paste', 1);
              // prevent menu selection to avoid duplicate adds (one from the menu and one from the input+enter)
              e.preventDefault();
              e.stopPropagation();
            }
          }
          break;
        }
      }
    },
    [
      onMemberSelected,
      onMemberSelectedEvent,
      shareText,
      debouncedUpdateSharingContacts,
      isAllowedToPasteEmailList,
      findSuggestedMember,
      selectMember,
    ],
  );

  const handleSelectMemberFromTypeahead = useCallback(
    (value: SharingMember | SharingUserContactKey) => {
      selectMember(value);
      onMemberSelectedEvent(
        showingSuggestedMembers ? 'suggested_collaborator' : 'entered_by_user',
        1,
      );
    },
    [onMemberSelectedEvent, selectMember, showingSuggestedMembers],
  );

  return (
    <Typeahead.Wrapper
      onSelection={handleSelectMemberFromTypeahead}
      onToggle={({ isOpen }) => setIsTypeaheadOpen(isOpen)}
      // we need this to be true only when 1st row result is not disabled
      shouldHighlightFirstRow={
        sharingContactsResults.length > 0 &&
        !isSharingMemberAlreadyAMember(sharingContactsResults[0])
      }
      isPortaled={false}
      closeOnSelection={true}
    >
      {({ getTriggerProps, getContentProps, triggerRef }) => (
        <>
          <TextInput.Container
            size="large"
            isTransparent
            className={styles.typeaheadInputContainer}
            ref={inputContainerRef}
            onClick={() =>
              // Focus the input when the container is clicked
              // This enables clicks on the whitespace around a chip to focus the input
              triggerRef.current &&
              'focus' in triggerRef.current &&
              triggerRef.current.focus()
            }
          >
            <TextInput.Accessory position="start">
              <UIIcon src={SearchLine} className={styles.searchIcon} />
            </TextInput.Accessory>
            <TextInput.ChipsContainer>
              {onMemberSelected ||
                selectedSharingMembers.map(
                  (sharingMember: SharingMember | SharingMemberKey) => (
                    <Chip
                      key={getSharingMemberIdentifier(sharingMember)}
                      size="small"
                      variant={
                        sharingMember['.tag'] === 'user' &&
                        !validateEmail(sharingMember.email)
                          ? 'warning'
                          : undefined
                      }
                      onDelete={() => deselectMember(sharingMember)}
                    >
                      {'displayName' in sharingMember && (
                        <Chip.AvatarAccessory>
                          <AvatarMember
                            member={sharingMember as SharingMember}
                            avatarSize="small"
                          />
                        </Chip.AvatarAccessory>
                      )}
                      <Chip.Content>
                        {'displayName' in sharingMember
                          ? sharingMember.displayName
                          : (sharingMember as SharingUserContactKey).email}
                      </Chip.Content>
                    </Chip>
                  ),
                )}
              <TextInput.Input
                value={isSharing ? i18n.t('sharing_ellipses') : shareText}
                placeholder={
                  selectedSharingMembers.length === 0
                    ? i18n.t('invite_someone_via_email')
                    : undefined
                }
                disabled={isSharing || disableInvitePeopleViaEmail}
                data-testid="Stacks-shareStackByEmailInput"
                {...getTriggerProps({
                  onChange: handleInputChange,
                  onKeyDown: handleKeyDown,
                })}
              />
            </TextInput.ChipsContainer>
            {!onMemberSelected && (
              <div
                className={classNames(
                  styles.typeaheadPermissionsMenuContainer,
                  {
                    [styles.hidden]:
                      !isTypeaheadOpen && selectedSharingMembers.length === 0,
                  },
                )}
              >
                <PermissionsMenu
                  data-testid="permissionsMenu"
                  sharePermission={inviteesPermission}
                  onSelectSharePermissions={(permission) =>
                    setInviteesPermission?.(permission)
                  }
                  buttonProps={{
                    variant: 'borderless',
                    hasNoUnderline: true,
                    size: 'small',
                  }}
                  dfb
                />
              </div>
            )}
            <span ref={tooltipRef} />
          </TextInput.Container>
          {/* TODO: Remove this <div> once DSR-591 is resolved and apply the
                    className direclty to the Typeahead.Container */}
          <div
            className={
              onMemberSelected ? undefined : styles.typeaheadContainerContainer
            }
          >
            <Typeahead.Container
              isEmptyQuery={sharingContactsResults.length === 0}
              emptyPrompt={<EmptyPrompt shareText={shareText} />}
              {...getContentProps()}
              loading={isSearchingSharingContacts}
              triggerRef={inputContainerRef}
            >
              {showingSuggestedMembers && (
                <div className={styles.typeaheadContainerHeading}>
                  <Text variant="label" color="subtle" size="small" isBold>
                    {i18n.t('suggested_invites')}
                  </Text>
                </div>
              )}
              <Typeahead.Results
                results={sharingContactsResults}
                renderRow={renderTypeaheadRow}
              />
            </Typeahead.Container>
          </div>
        </>
      )}
    </Typeahead.Wrapper>
  );
};

const EmptyPrompt = ({ shareText }: { shareText: string }) => {
  const isAllowedToPasteEmailList = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2025_01_14_paste_emails_to_share'),
  );

  if (isAllowedToPasteEmailList) {
    const count = tokenizeValidRawInput(shareText).length;
    if (count > 0) {
      return (
        <Typeahead.EmptyPrompt
          placeholderText={i18n.t('sharing_typehead_paste_hint', {
            count,
          })}
        />
      );
    }
    return (
      <Typeahead.EmptyPrompt
        placeholderText={i18n.t('sharing_typeahead_empty_hint')}
      />
    );
  }
  if (!validateEmail(shareText)) {
    return (
      <Typeahead.EmptyPrompt
        placeholderText={i18n.t('sharing_typeahead_empty_hint')}
      />
    );
  }
  return (
    <Typeahead.Prompt>
      <Text>{i18n.t('sharing_typeahead_completion_hint')}</Text>
    </Typeahead.Prompt>
  );
};
