// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3663
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashActionSurface } from "../enums/dash_action_surface";

// Event_name: 'shown.dash_search_error'
// Description: Triggers when the user sees an error page searching in dash
// Owner: otc
export type Shown_DashSearchError = PAPEventGeneric<
  'dash',
  'shown',
  'dash_search_error',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Shown_DashSearchError(properties?: Shown_DashSearchError['properties']): Shown_DashSearchError {
  return <Shown_DashSearchError>{
    class: 'dash',
    action: 'shown',
    object: 'dash_search_error',
    properties,
  };
}
