import { stacks } from '@dropbox/api-v2-client';
import { Menu, WrapperContentPropsFn } from '@dropbox/dig-components/menu';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { SearchLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Initiate_DashSearch } from '@mirage/analytics/events/types/initiate_dash_search';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { Link } from '@mirage/link/Link';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import {
  stackItemComputeNameFromFields,
  stackItemGetName,
} from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { KeyCodes } from '@mirage/shared/util/constants';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { getTimeAgoString } from '@mirage/shared/util/time';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import {
  activeStackSearchItemsAtom,
  activeStackSearchItemsNoResultsQueryAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { FullWidthSearchBar } from '@mirage/stacks/FullWidthSearchBar';
import { useSortedStacks } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './SearchInStack.module.css';

interface SearchInStackMenuProps {
  items: stacks.StackItemShortcut[] | null;
  onOpenLink: () => void;
}

const filterContent = (
  items: stacks.StackItemShortcut[],
  input?: string,
  limit?: number,
) => {
  return input
    ? (items
        ?.filter((item) => {
          return stackItemComputeNameFromFields(item)
            .toLocaleLowerCase()
            .includes(input.toLocaleLowerCase());
        })
        .slice(0, limit) ?? [])
    : [];
};

export const SearchInStackMenu: React.FC<SearchInStackMenuProps> = ({
  items,
  onOpenLink,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const augustRevision = useStackPageAugustRevisionEnabled();
  const textInputRef = useRef<TextInputRefObject | null>(null);
  // Using `null` here as the default value to indicate when no search is present
  // and when the app should not show the search result UX
  const [inputString, setInputString] = useState<string | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useAtom(activeStackSearchItemsAtom);
  const setShadowSearchStackItemsNoResultsQuery = useSetAtom(
    activeStackSearchItemsNoResultsQueryAtom,
  );

  setShadowSearchStackItemsNoResultsQuery(
    searchResults?.length === 0 ? inputString : null,
  );

  useEffect(() => {
    if (items) {
      const trimmedInputString = inputString?.trim();
      if (trimmedInputString) {
        setSearchResults(
          filterContent(
            items,
            trimmedInputString,
            augustRevision ? undefined : 5,
          ),
        );
      } else if (trimmedInputString === '') {
        setSearchResults(null);
      }
    }
  }, [augustRevision, inputString, items, setSearchResults]);

  useEffect(() => {
    if (menuIsOpen) {
      reportPapEvent(
        PAP_Initiate_DashSearch({
          dashActionSurface: 'stack_details_page',
          featureLine: 'stacks',
        }),
      );
      textInputRef.current?.focus();
    } else {
      setInputString('');
    }
  }, [menuIsOpen, reportPapEvent]);

  const handleMenuClose = () => {
    setMenuIsOpen(false);
    setSearchResults(null);
  };

  if (!augustRevision) {
    return (
      <Menu.Wrapper
        onToggle={(event) => {
          setMenuIsOpen(event.isOpen);
          if (event.isOpen) {
            setInputString('');
          }
        }}
      >
        {({ getContentProps, getTriggerProps }) => (
          <>
            <IconButtonWithTooltip
              tooltipProps={{
                title: i18n.t('search_label'),
              }}
              variant="borderless"
              shape="standard"
              {...getTriggerProps()}
            >
              <UIIcon src={SearchLine} />
            </IconButtonWithTooltip>
            <SearchInStackMenuContent
              getContentProps={getContentProps}
              textInputRef={textInputRef}
              inputString={inputString ?? ''}
              setInputString={setInputString}
              searchResults={searchResults ?? []}
              onOpenLink={onOpenLink}
            />
          </>
        )}
      </Menu.Wrapper>
    );
  }

  return (
    <>
      <IconButtonWithTooltip
        tooltipProps={{
          title: i18n.t('search_label'),
        }}
        variant="opacity"
        shape="circular"
        onClick={() => {
          setMenuIsOpen(true);
        }}
      >
        <UIIcon src={SearchLine} />
      </IconButtonWithTooltip>
      {menuIsOpen && (
        <FullWidthSearchBar
          textInputRef={textInputRef}
          placeholder={i18n.t('search_in_stack')}
          inputString={inputString ?? ''}
          setInputString={setInputString}
          handleMenuClose={handleMenuClose}
        />
      )}
    </>
  );
};

interface SearchInStackMenuContentProps {
  getContentProps: WrapperContentPropsFn;
  textInputRef: React.MutableRefObject<TextInputRefObject | null>;
  inputString: string;
  setInputString: (input: string) => void;
  searchResults: stacks.StackItemShortcut[];
  onOpenLink: () => void;
}

const SearchInStackMenuContent: React.FC<SearchInStackMenuContentProps> = ({
  getContentProps,
  textInputRef,
  inputString,
  setInputString,
  searchResults,
  onOpenLink,
}) => {
  const linkRefs = useRef<(HTMLElement | null)[]>([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState<number | null>(
    null,
  );

  const isMobileSize = useIsMobileSize();
  const isHoverFriendly = useIsHoverFriendly();

  // This is a bit counter-intuitive. Make the font bigger for
  // mobile size because in iOS Safari, the browser will auto-zoom
  // the entire page when the input font is too small.
  const inputSize = isMobileSize ? 'large' : 'medium';

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === KeyCodes.arrowDown) {
        event.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex === null || prevIndex >= searchResults.length - 1
            ? 0
            : prevIndex + 1,
        );
      } else if (event.key === KeyCodes.arrowUp) {
        event.preventDefault();
        setSelectedResultIndex((prevIndex) =>
          prevIndex === 0 || prevIndex === null
            ? searchResults.length - 1
            : prevIndex - 1,
        );
      } else if (event.key === KeyCodes.tab) {
        event.stopPropagation(); // Stop tab keys from closing the popup.
      } else if (event.key === KeyCodes.enter) {
        if (selectedResultIndex !== null) {
          linkRefs.current[selectedResultIndex]?.click();
        }
      }
    },
    [searchResults.length, selectedResultIndex],
  );

  return (
    <Menu.Content
      {...getContentProps()}
      className={classnames(styles.wrapper, isHoverFriendly || styles.noHover)}
      placement="right-start"
      onKeyDown={handleKeyDown}
    >
      <div className={styles.box}>
        <div className={styles.searchInputContainer}>
          <TextInput
            wrapperProps={{ className: styles.searchTextInputWrapper }}
            isTransparent
            ref={textInputRef}
            size={inputSize}
            value={inputString}
            onChange={(e) => setInputString(e.target.value)}
            placeholder={i18n.t('search_in_stack')}
            withLeftAccessory={
              <UIIcon src={SearchLine} className={styles.searchIcon} />
            }
          />
        </div>
        {searchResults
          .sort(
            (a, b) =>
              (b.last_modified_time_utc_sec ?? 0) -
              (a.last_modified_time_utc_sec ?? 0),
          )
          .map((item, index) => (
            <SearchInStackResultItem
              key={index}
              setRef={(ref) => {
                linkRefs.current[index] = ref;
              }}
              item={item}
              isSelected={selectedResultIndex === index}
              onMouseEnter={() => setSelectedResultIndex(index)}
              onMouseLeave={() => setSelectedResultIndex(null)}
              onOpenLink={onOpenLink}
            />
          ))}
      </div>
    </Menu.Content>
  );
};

interface SearchInStackResultItemProps {
  setRef: (ref: HTMLElement | null) => void;
  item: stacks.StackItemShortcut;
  isSelected: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onOpenLink: () => void;
}

const SearchInStackResultItem: React.FC<SearchInStackResultItemProps> = ({
  setRef,
  item,
  isSelected,
  onMouseEnter,
  onMouseLeave,
  onOpenLink,
}) => {
  const themeInfo = useTheme();
  const allStacks = useSortedStacks();
  const isDarkMode = themeInfo?.mode === 'dark';

  return (
    <Link
      className={classnames(styles.resultContainer, {
        [styles.selected]: isSelected,
        [styles.darkMode]: isDarkMode,
      })}
      ref={setRef}
      href={nonNil(item.url, 'item.url')}
      role="menuitem"
      onOpen={onOpenLink}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <>
        <div className={styles.resultContent}>
          <FavIcon
            src={faviconSrcForSrcUrl(
              nonNil(item.url, 'item.url'),
              32,
              isDarkMode,
            )}
            size={ListItemSize.Small}
          />
          <Text className={styles.resultText}>
            {stackItemGetName(item, allStacks)}
          </Text>
          {item.last_modified_time_utc_sec ? (
            <Text color="faint" className={styles.timeAgoText}>
              {' '}
              · {getTimeAgoString(item.last_modified_time_utc_sec * 1000, true)}
            </Text>
          ) : null}
        </div>
      </>
    </Link>
  );
};
