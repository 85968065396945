import styled from '@emotion/styled';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Expand_DashSearchRightPanel } from '@mirage/analytics/events/types/expand_dash_search_right_panel';
import { PAP_Select_DashSearchResultSeeMore } from '@mirage/analytics/events/types/select_dash_search_results_see_more';
import { LoadingResultList } from '@mirage/search/SearchResults/LoadingResult';
import { getVerification } from '@mirage/shared/curations';
import { SearchTimeoutError } from '@mirage/shared/errors/classes/search';
import { categorizeResults } from '@mirage/shared/search/categorizeResults';
import i18n from '@mirage/translations';
import { Error } from './Error';
import { InitialSyncBanner } from './InitialSyncBanner';
import { NoResults } from './NoResults';
import ResultList from './ResultList';
import { ResultRow } from './ResultRow/ResultRow';
import { ResultSectionHeader } from './ResultSection/ResultSectionHeader';

import type { ResultActionConfig } from './ResultRow';
import type { dash } from '@dropbox/api-v2-client';
import type { ContentIconProps } from '@dropbox/dash-component-library';
import type { SearchResult } from '@mirage/service-dbx-api';
import type {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

const STAGGER_LOADING_RESULTS_MS = 83;
const RESULT_ANIMATION_LIMIT = 10;

type SearchResultsProps = {
  query: string;
  results: SearchResult[];
  loading: boolean;
  error: Error | null;
  getActionConfig: (
    result: SearchResult,
    results: SearchResult[],
  ) => ResultActionConfig;
  onListItemView?: (item: SearchResult) => void;
  onCopyResult: (result: SearchResult) => void;
  activeFilters?: SearchFilter[];
  annotationsEnabled?: boolean;
  iconSize?: ContentIconProps['size'];
  peekResultActionsEnabled?: boolean;
  onClickPerson?: (person: PersonObject) => void;
  resetAllFilters: () => void;
  applyFilters: (filter: SearchFilter[]) => void;
  onResubmitSearch: () => void;
  onAddToStack: (result: SearchResult) => void;
  onSummarize: (result: SearchResult) => void;
  cachedVerifications?: { [resultId: string]: dash.Curation | null };
  isCategoriesEnabled?: boolean;
};

export const SearchResults = ({
  query,
  results,
  loading,
  error,
  getActionConfig,
  onListItemView,
  onCopyResult,
  activeFilters = [],
  annotationsEnabled,
  iconSize,
  peekResultActionsEnabled = false,
  onClickPerson,
  resetAllFilters,
  applyFilters,
  onResubmitSearch,
  onAddToStack,
  onSummarize,
  cachedVerifications = {},
  isCategoriesEnabled = false,
}: SearchResultsProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleGetActionConfig = (result: SearchResult) => {
    return getActionConfig(result, results);
  };

  const logOpenResultDropdown = (isOpen: boolean) => {
    if (!isOpen) return;
    reportPapEvent(
      PAP_Expand_DashSearchRightPanel({
        featureLine: 'search',
        actionSurface: 'serp',
      }),
    );
  };

  const logCategorySeeMore = () => {
    reportPapEvent(
      PAP_Select_DashSearchResultSeeMore({
        actionSurface: 'serp',
        featureLine: 'search',
      }),
    );
  };

  const renderResultRow = (
    result: SearchResult,
    index: number,
    delayMs: number = 0,
  ) => {
    const resultActionConfig = handleGetActionConfig(result);
    return (
      <ResultRow
        key={result.uuid || Math.random().toString()}
        query={query}
        result={result}
        verification={
          result.uuid in cachedVerifications
            ? // If the result is cached (user just previously updated it), use the cached value
              (cachedVerifications[result.uuid] ?? undefined)
            : getVerification(result)
        }
        onLaunch={resultActionConfig.launchAction?.onClick}
        resultActionConfig={resultActionConfig}
        onOpenDropdown={logOpenResultDropdown}
        onShownResult={onListItemView}
        annotationsEnabled={annotationsEnabled}
        resultPosition={index}
        peekResultActionsEnabled={peekResultActionsEnabled && index == 0}
        iconSize={iconSize}
        activeFilters={activeFilters}
        onClickPerson={onClickPerson}
        onCopyResult={onCopyResult}
        delayMs={delayMs}
        animate={delayMs === 0 ? false : true}
        onAddToStack={onAddToStack}
        onSummarize={onSummarize}
      />
    );
  };

  const renderResults = () => {
    return results.map((result, i) =>
      renderResultRow(
        result,
        i,
        i < RESULT_ANIMATION_LIMIT ? i * STAGGER_LOADING_RESULTS_MS : 0,
      ),
    );
  };

  // render sections
  const renderSections = () => {
    const sections = categorizeResults(results);
    if (sections.length < 2 || activeFilters.length) {
      return renderResults();
    }
    if (sections.length === 2 && loading) {
      // render only the first section if we're loading and we don't have enough emails to prevent jank
      return (
        <div key={sections[0].categoryType}>
          <ResultSectionHeader
            section={sections[0]}
            applyFilters={applyFilters}
            logCategorySeeMore={logCategorySeeMore}
          />
          {sections[0].results.map((result, i) =>
            renderResultRow(
              result,
              i,
              i < RESULT_ANIMATION_LIMIT ? i * STAGGER_LOADING_RESULTS_MS : 0,
            ),
          )}
        </div>
      );
    }

    return sections.map((section, sectionIdx) => {
      return (
        <div key={section.categoryType}>
          <ResultSectionHeader
            section={section}
            applyFilters={applyFilters}
            logCategorySeeMore={logCategorySeeMore}
          />
          {section.results.map((result, i) =>
            renderResultRow(
              result,
              i,
              sectionIdx === 0 ? 0 : i * STAGGER_LOADING_RESULTS_MS,
            ),
          )}
        </div>
      );
    });
  };

  if (loading && !results.length) {
    return (
      <CenteredContainer>
        <LoadingResultList count={8} staggerMs={STAGGER_LOADING_RESULTS_MS} />
      </CenteredContainer>
    );
  }

  if (query == '' && !loading) {
    return <div></div>;
  }

  if (!error && !results.length && !loading) {
    return (
      <NoResults
        activeFilters={activeFilters}
        resetAllFilters={resetAllFilters}
      />
    );
  }

  return (
    <>
      {error?.name === SearchTimeoutError.name ? (
        <Error
          title={i18n.t('error')}
          subtitle={i18n.t('search_timed_out')}
          actionText={i18n.t('retry_search')}
          onActionClick={onResubmitSearch}
        />
      ) : (
        error && (
          <Error
            title={i18n.t('error')}
            subtitle={error?.toString()}
            actionText={i18n.t('retry_search')}
            onActionClick={onResubmitSearch}
          />
        )
      )}
      <ResultList>
        <InitialSyncBanner />
        {isCategoriesEnabled ? renderSections() : renderResults()}
        {loading && <LoadingResultList count={3} />}
      </ResultList>
    </>
  );
};

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 100%;
  overflow: auto;
`;
