import { Avatar } from '@dropbox/dig-components/avatar';
import { Box } from '@dropbox/dig-foundations';
import { getInitialsFromPerson } from '@mirage/shared/account';
import { PersonObject } from '@mirage/shared/search/search-filters';
import { AuthorInfo, CuratorUser } from '@mirage/shared/search/search-result';
import { StackItemUser } from '@mirage/shared/search/stack-item';
import { I18nCreateByKey } from '@mirage/shared/translations/I18nCreateByKey';
import { Trans } from '@mirage/translations';
import React, { Fragment } from 'react';
import styles from './PersonMetadata.module.css';

export type PersonMetadataProps = {
  i18nKey: I18nCreateByKey;
  person: AuthorInfo | StackItemUser | CuratorUser | undefined | null;
  onClickPerson?: (person: PersonObject) => void;
};

export const PersonMetadata = ({
  i18nKey,
  person,
  onClickPerson,
}: PersonMetadataProps) => {
  const displayName = person?.displayName || person?.email;
  const initials = getInitialsFromPerson({
    displayName: person?.displayName,
    email: person?.email || '',
  });

  const handleClickPerson = (person: AuthorInfo | undefined | null) => {
    const { email = '', displayName, profilePhotoUrl } = person || {};
    onClickPerson?.({
      email,
      displayName,
      profilePhotoUrl,
    });
  };

  return (
    <div className={styles.container}>
      <Avatar
        size="xsmall"
        hasNoOutline
        src={person?.profilePhotoUrl}
        alt={initials}
        className={styles.avatar}
      >
        {initials}
      </Avatar>
      <span>
        <Trans
          i18nKey={i18nKey}
          values={{ name: displayName }}
          components={{
            tag: onClickPerson ? (
              <Box
                as="button"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClickPerson(person);
                }}
                fontFamily="Text"
                color={{
                  default: 'Text Subtle',
                  hover: 'Text Base',
                }}
                className={styles.button}
              />
            ) : (
              <Fragment />
            ),
          }}
        />
      </span>
    </div>
  );
};
