import { Button } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine, UploadLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { SearchResult } from '@mirage/service-dbx-api';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import React, { memo, useRef, useState } from 'react';
import { FileError, processFile } from '../../utils/fileProcessing';
import styles from './ComposeUploadFileButton.module.css';

interface FileUploaderProps {
  onAddSource: (source: ComposeSource) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}

export const ASSISTANT_FILE_UPLOAD_CONNECTOR_ID = 'assistant_file_upload';
export function isAssistantFileUpload(result: SearchResult): boolean {
  return (
    result.connectorInfo?.connectorName === ASSISTANT_FILE_UPLOAD_CONNECTOR_ID
  );
}

export const ComposeUploadFileButton = memo(
  ({ onAddSource, logComposeEvent }: FileUploaderProps) => {
    const [error, setError] = useState<FileError>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const file = event.target.files?.[0];
      if (!file) return;

      setLoading(true);
      setError(null);

      const error = await processFile(file, onAddSource, logComposeEvent);
      setError(error);
      setLoading(false);
    };

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };

    const handleDismissError = () => {
      setError(null);
    };

    const handleTryAgain = () => {
      setError(null);
      handleButtonClick();
    };

    // Get error message based on error type
    const getCustomErrorMessage = () => {
      switch (error) {
        case 'size':
          return i18n.t('compose_assistant_file_uploader_error_size');
        case 'type':
          return i18n.t('compose_assistant_file_uploader_error_type');
        case 'generic':
          return i18n.t('compose_assistant_file_uploader_error_unexpected');
        default:
          return i18n.t('compose_assistant_file_uploader_error_unexpected');
      }
    };

    return (
      <div className={styles.FileUploader}>
        {loading ? (
          <div className={styles.FileUploaderSpinner}>
            <Spinner size="xsmall" />
          </div>
        ) : (
          <>
            <Button
              variant="transparent"
              size="medium"
              hasNoUnderline
              tone="neutral"
              onClick={handleButtonClick}
              className={styles.FileUploaderButton}
            >
              <UIIcon src={UploadLine} size="standard" />
              <Text
                size="small"
                tagName="span"
                color="standard"
                isBold
                className={styles.FileUploaderButtonText}
              >
                {i18n.t('compose_assistant_file_uploader_button_label')}
              </Text>
            </Button>
            <input
              className={styles.FileUploaderInput}
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept={[
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/plain',
                'text/csv',
              ].join(',')}
            />
          </>
        )}
        {error && (
          <Snackbar.Position>
            <Snackbar open preferComposition>
              <Snackbar.Content>
                <Snackbar.Accessory>
                  <UIIcon src={FailLine} />
                </Snackbar.Accessory>
                <Snackbar.Message>
                  {i18n.t('compose_assistant_file_uploader_error_title')}
                  &nbsp;
                  {getCustomErrorMessage()}
                </Snackbar.Message>
                <Snackbar.Actions>
                  <Button
                    variant="transparent"
                    size="medium"
                    tone="neutral"
                    onClick={handleDismissError}
                  >
                    {i18n.t('dismiss')}
                  </Button>
                  {error === 'type' && (
                    <Button
                      variant="transparent"
                      size="medium"
                      tone="neutral"
                      onClick={handleTryAgain}
                    >
                      {i18n.t('try_again')}
                    </Button>
                  )}
                </Snackbar.Actions>
              </Snackbar.Content>
            </Snackbar>
          </Snackbar.Position>
        )}
      </div>
    );
  },
);
ComposeUploadFileButton.displayName = 'ComposeUploadFileButton';
