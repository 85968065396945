import { stacks } from '@dropbox/api-v2-client';
import { LinkComponent, LinkComponentProps } from '@mirage/link-list/Link/Link';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { DraggableStackLink } from '@mirage/stacks/FullScreenStack/DragAndDrop/DraggableStackLink';
import React from 'react';
import { DroppableItemizedSection } from './DroppableItemizedSection';
import { WithDragIconHandle } from './WithDragIconHandle';

interface DragDroppableSectionLinkComponentProps {
  sectionId: string;
  index: number;
  item: stacks.StackItemShortcut;
  hasWritePermissions: boolean;
}

export const DragDroppableSectionLinkComponent: React.FC<
  LinkComponentProps & DragDroppableSectionLinkComponentProps
> = (props) => {
  const { hasWritePermissions } = props;
  const dndImprovementsEnabled = useFeatureFlagValueBool(
    'dash_web_2025_01_28_dnd_improvements',
  );

  return (
    <DroppableItemizedSection {...props}>
      <DraggableStackLink {...props}>
        {dndImprovementsEnabled && hasWritePermissions ? (
          <WithDragIconHandle>
            <LinkComponent {...props} enableDrag />
          </WithDragIconHandle>
        ) : (
          <LinkComponent {...props} enableDrag />
        )}
      </DraggableStackLink>
    </DroppableItemizedSection>
  );
};
