import { dash_feed, stacks } from '@dropbox/api-v2-client';
import { ActivityListItem } from '../ActivityList/ActivityListItem';

const DASH_IMG =
  'https://cfl.dropboxstatic.com/static/go/src/dropbox/dash/connectors/metadata/icons/dash.svg';

const DASH_IMG_DARK =
  'https://cfl.dropboxstatic.com/static/go/src/dropbox/dash/connectors/metadata/icons/dash_dark.svg';

type WelcomeStackActivityProps = {
  stack: stacks.Stack;
  isDarkMode?: boolean;
};

export const WelcomeStackActivity = ({
  stack,
  isDarkMode = false,
}: WelcomeStackActivityProps) => {
  const dashImg = isDarkMode ? DASH_IMG_DARK : DASH_IMG;

  const activity: dash_feed.FeedItem = {
    target: {
      url: stack.sharing_data?.shared_link?.url,
      name: stack.stack_data?.name,
      details: {
        object_details: {
          '.tag': 'full_stack',
          ...stack,
        },
      },
    },
    activities: [
      {
        ts: stack.created_time_utc_ms,
        actor: {
          name: 'Dropbox Dash',
          avatar_img: dashImg,
        },
        action: {
          action: {
            '.tag': 'share',
          },
        },
      },
    ],
  };
  return <ActivityListItem activity={activity} index={0} />;
};
