import { listenForAccountIds } from '@mirage/service-auth';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useIsExtensionInstalled } from '@mirage/shared/util/webExtensionsHelpers';
import React, { useEffect, useState } from 'react';
import { QuickLinks } from './QuickLinks';

export const QuickLinksModule: React.FC = () => {
  const [accountId, setAccountId] = useState<string>();
  const extensionInstalled = useIsExtensionInstalled();
  const isDesktop = EnvCtx.surface === 'desktop';

  useEffect(() => {
    listenForAccountIds().subscribe((user) => {
      setAccountId(user?.account_id);
    });
  }, []);

  if (!accountId) {
    return null;
  }

  if (!extensionInstalled || isDesktop) {
    return null;
  }

  return <QuickLinks accountId={accountId} />;
};
