import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useOnboardingSurveys } from '../hooks/useOnboardingSurveys';
import { OnboardingSurveyQuestionAnswer } from '../types/onboarding-surveys';
import styles from './OnboardingJobsToBeDoneStep.module.css';

type Props = {
  isAdmin: boolean;
  onValidationChange: (
    valid: boolean,
    answers: OnboardingSurveyQuestionAnswer[],
  ) => void;
};

const SEARCH_IMAGE =
  'https://assets.dropbox.com/images/dashweb/growth/onboarding/search.png';
const AI_TOOLS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/growth/onboarding/ai_tool.png';
const ANSWERS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/growth/onboarding/answers.png';

const JTBD_OPTIONS = [
  {
    value: 'search',
    title: i18n.t('self_serve_jtbd_search_answer_title'),
    description: i18n.t('self_serve_jtbd_search_answer_description'),
    image: SEARCH_IMAGE,
  },
  {
    value: 'answers',
    title: i18n.t('self_serve_jtbd_answers_answer_title'),
    description: i18n.t('self_serve_jtbd_answers_answer_description'),
    image: ANSWERS_IMAGE,
  },
  {
    value: 'ai_tools',
    title: i18n.t('self_serve_jtbd_ai_tools_answer_title'),
    description: i18n.t('self_serve_jtbd_ai_tools_answer_description'),
    image: AI_TOOLS_IMAGE,
  },
];

export const OnboardingJobsToBeDoneStep = forwardRef(
  ({ isAdmin, onValidationChange }: Props, ref) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const { submitSurvey } = useOnboardingSurveys('ADMIN_JTBD_V1');
    const [internalValues, setInternalValues] = useState<
      OnboardingSurveyQuestionAnswer[]
    >([]);
    const exposureLoggedRef = useRef(false);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => submitSurvey(internalValues),
    }));

    useEffect(() => {
      onValidationChange(internalValues.length > 0, internalValues);
    }, [internalValues, onValidationChange]);

    useEffect(() => {
      if (exposureLoggedRef.current) return;
      exposureLoggedRef.current = true;
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'jtbd',
        }),
      );
    }, [isAdmin, reportPapEvent]);

    const onJobToBeDoneChange = useCallback((value: string) => {
      setInternalValues((prevValues) => {
        const isSelected = prevValues.some((item) => item.value === value);

        return isSelected
          ? prevValues.filter((item) => item.value !== value)
          : [
              ...prevValues,
              {
                questionId: 'JOBS_TO_BE_DONE_V1',
                value,
              },
            ];
      });
    }, []);

    return (
      <Box
        className={classnames(
          styles.jtbdContainer,
          internalValues.length ? styles.jtbdContainerActive : '',
        )}
        display="flex"
        flexDirection="row"
      >
        {JTBD_OPTIONS.map((option) => (
          <Box
            className={classnames(
              styles.jtbdOption,
              internalValues.findIndex((v) => v.value === option.value) >= 0
                ? styles.selected
                : '',
            )}
            key={option.value}
            role="button"
            display="flex"
            flexDirection="column"
            tabIndex={0}
            onClick={() => onJobToBeDoneChange(option.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onJobToBeDoneChange(option.value);
              }
            }}
          >
            <Title size="medium" weightVariant="emphasized">
              {option.title}
            </Title>
            <Text
              className={styles.jtbdDescription}
              variant="label"
              size="medium"
            >
              {option.description}
            </Text>
            <Box
              className={styles.jtbdImgContainer}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={option.image} alt={option.title} />
            </Box>
          </Box>
        ))}
      </Box>
    );
  },
);

OnboardingJobsToBeDoneStep.displayName = 'OnboardingJobsToBeDoneStep';
