import {
  avatarColorPalette,
  AvatarSizes,
  Facepile,
  FacepileProps,
} from '@dropbox/dig-components/avatar';
import { getInitials } from '@mirage/shared/account';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { Member } from './types';
import { normalizeMembers } from './utils';

export interface DashFacepileProps {
  members: Member[];
  size?: AvatarSizes;
  itemClassName?: string;
  maxMembersToShow?: number;
  maxNumberToShow?: number;
}

const MAX_MEMBERS_TO_SHOW = 3;
const MAX_MEMBER_NUMBER_TO_SHOW = 99;

export const DashFacepile: React.FC<DashFacepileProps & FacepileProps> = ({
  members,
  itemClassName,
  size = 'medium',
  maxMembersToShow = MAX_MEMBERS_TO_SHOW,
  maxNumberToShow = MAX_MEMBER_NUMBER_TO_SHOW,
  ...props
}) => {
  const numExtraMembers = members.length - maxMembersToShow;

  const facepileMembers = normalizeMembers(members);

  return (
    <Facepile size={size} {...props}>
      {facepileMembers.slice(0, maxMembersToShow).map((member) => {
        const initials = getInitials(member.displayName || member.email);
        return (
          <DigTooltip key={member.email} title={member.displayName}>
            <Facepile.Item
              role="paragraph"
              tabIndex={-1}
              src={member.profilePhotoUrl}
              colorPalette={avatarColorPalette(
                member.email || member.displayName,
              )}
              className={itemClassName}
            >
              {initials}
            </Facepile.Item>
          </DigTooltip>
        );
      })}
      {numExtraMembers > 0 && (
        <DigTooltip title={i18n.t('num_others', { count: numExtraMembers })}>
          <Facepile.Item
            role="paragraph"
            tabIndex={-1}
            colorPalette={{
              background: 'var(--dig-color__opacity__surface)',
              foreground: 'var(--dig-color__text__base)',
            }}
            className={itemClassName}
          >
            {numExtraMembers > maxNumberToShow
              ? `${maxNumberToShow}+`
              : numExtraMembers}
          </Facepile.Item>
        </DigTooltip>
      )}
    </Facepile>
  );
};
