// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2736
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSearchResultType } from '../enums/dash_search_result_type';
import type { DashSourceType } from '../enums/dash_source_type';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { SearchResultSource } from '../enums/search_result_source';
import type { StackAccessLevel } from '../enums/stack_access_level';

// Event_name: 'add.dash_search_to_stack'
// Description: Fires when user adds a search result to stack. If user only clicks on the “Add to stack” button but does not end up selecting or creating a stack, then is_success=0. If user successfully adds the search result to a stack, then is_success=1, and the corresponding stack info should be logged
// Owner: otc
export type Add_DashSearchToStack = PAPEventGeneric<
  'dash',
  'add',
  'dash_search_to_stack',
  {
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // Legacy: dash_connector_id in Desktop
    // Note, this should have been a enum :(
    connector?: string;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // This defines where the document source is
    dashSourceType?: DashSourceType;
    // App filters selected
    // e.g. “contact”, “contact,gsuite”
    // These values are going to be sorted, global, code defined, Dropbox owned data that tags the "type" of a search result.
    activeFilters?: string;
    // Set to true when operation is successful, but set to false if unsuccessful.
    isSuccess?: boolean;
    // The stack id that represents the stack
    stackId?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // The contents of a search query
    queryString?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // the start time of the event in ms
    startTimeMs?: number;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Which Dash connector an event is associated with, e.g. Trello.
    dashConnectorId?: string;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // Number of results returned to user
    resultCount?: number;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // The zero-index returned position of search result in a list of search results with the SERP CTA removed from the list
    resultPositionNoCta?: number;
    // Determines if a search result has been "verified"
    dashIsResultVerified?: boolean;
    // Boolean if the search result was displayed within a Category
    categorizedResult?: boolean;
    // the position of the result in the list UI, used for categorized search experiment
    displayedPosition?: number;
  }
>;

export function PAP_Add_DashSearchToStack(
  properties?: Add_DashSearchToStack['properties'],
): Add_DashSearchToStack {
  return <Add_DashSearchToStack>{
    class: 'dash',
    action: 'add',
    object: 'dash_search_to_stack',
    properties,
  };
}
