import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import i18n, { Trans } from '@mirage/translations';
import styles from './DeleteVerificationModal.module.css';

type Props = {
  onDismiss?: () => void;
  onConfirmDeletion: () => void;
  onCancelDeletion: () => void;
  resultTitle?: string;
  open: boolean;
  processing: boolean;
  hasDeleteFailed: boolean;
};

export const DeleteVerificationModal = ({
  onDismiss,
  onConfirmDeletion,
  onCancelDeletion,
  open,
  processing,
  hasDeleteFailed,
  resultTitle,
}: Props) => {
  return (
    <Modal
      open={open}
      isCentered={true}
      withCloseButton={i18n.t('close_modal_aria')}
      onRequestClose={onDismiss}
    >
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title>{i18n.t('verify_content_remove_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="verify_content_modal_confirm_remove_body"
          values={{ title: resultTitle }}
          components={{ boldText: <Text isBold /> }}
        />
      </Modal.Body>
      <Modal.Footer preferComposition={true}>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          style={{ gap: '8px' }}
        >
          {processing && <Spinner size="small" monochromatic={true} />}
          <Button variant="opacity" onClick={onCancelDeletion}>
            {i18n.t('verify_content_modal_cancel')}
          </Button>
          <Button
            variant="primary"
            tone="destructive"
            onClick={onConfirmDeletion}
            disabled={processing}
          >
            {i18n.t('verify_content_modal_remove')}
          </Button>
        </Box>
        {hasDeleteFailed && (
          <Box width="100%" textAlign="right" marginTop="4">
            <Text color="error">{i18n.t('verify_content_remove_failed')}</Text>
          </Box>
        )}
      </Modal.Footer>
    </Modal>
  );
};
