import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { WithDragIconHandle } from '@mirage/stacks/FullScreenStack/DragAndDrop/WithDragIconHandle';
import { WorkingSetCardData } from '@mirage/working-set';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { PinnedStackCard } from './PinnedStackCard';
import { disableDraggingOnInternalImagesAndAnchors } from './utils';

const typeKey = Symbol('PinnedStackCard');
type DraggableData = {
  [typeKey]: true;
  index: number;
  namespaceId: string;
};

const isDraggableData = (
  data: Record<string | symbol, unknown>,
): data is DraggableData => {
  return (data as DraggableData)[typeKey] === true;
};

type State =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    };

export const DraggablePinnedStackCard = ({
  stackData,
  index,
  movePinnedStack,
  isEnabled,
  titleCardClassName,
  isDragAndDropEnabled,
  isHidden,
}: {
  stackData: WorkingSetCardData;
  index: number;
  movePinnedStack: (namespaceId: string, dstIndex: number) => void;
  isEnabled: boolean;
  titleCardClassName: string;
  isDragAndDropEnabled: boolean;
  isHidden?: boolean;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [state, setState] = useState<State>({ type: 'idle' });
  const [computedWidth, setComputedWidth] = useState<string | number | null>(
    null,
  );
  const dndImprovementsEnabled =
    useFeatureFlagValueBool('dash_web_2025_01_28_dnd_improvements') &&
    isEnabled;

  useEffect(() => {
    const element = ref.current;
    if (!element || !isDragAndDropEnabled) {
      return;
    }
    disableDraggingOnInternalImagesAndAnchors(element);

    const data: DraggableData = {
      [typeKey]: true,
      index,
      namespaceId: stackData.namespaceId,
    };

    return combine(
      draggable({
        element,
        canDrag: () => isEnabled,
        getInitialData: () => data,
        onDragStart: () => {
          setIsDragging(true);
          try {
            setComputedWidth(getComputedStyle(element)?.width);
          } catch (e) {
            setComputedWidth(null);
          }
        },
        onDrop: () => {
          setIsDragging(false);
        },
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          setCustomNativeDragPreview({
            render({ container }) {
              // Cause a `react` re-render to create your portal synchronously
              setState({ type: 'preview', container });
              // In our cleanup function, cause a `react` re-render to remove the portal
              return () => setState({ type: 'idle' });
            },
            nativeSetDragImage,
          });
        },
      }),
      dropTargetForElements({
        element,
        getData: ({ input, element }) => {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['left', 'right'],
          });
        },
        onDrag: ({ self, source }) => {
          if (!isDraggableData(self.data) || !isDraggableData(source.data)) {
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          const sourceIsBeforeSelf = source.data.index + 1 === self.data.index;
          const sourceIsAfterSelf = source.data.index - 1 === self.data.index;
          const isDropIndicatorHidden =
            source.data.namespaceId === self.data.namespaceId ||
            (sourceIsBeforeSelf && closestEdge === 'left') ||
            (sourceIsAfterSelf && closestEdge === 'right');
          setClosestEdge(isDropIndicatorHidden ? null : closestEdge);
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: ({ source, self: dest }) => {
          if (!isDraggableData(source.data) || !isDraggableData(dest.data)) {
            return;
          }
          if (source.data.index !== dest.data.index) {
            movePinnedStack(
              source.data.namespaceId,
              dest.data.index + (closestEdge === 'right' ? 1 : 0),
            );
          }
          setClosestEdge(null);
        },
      }),
    );
  }, [
    closestEdge,
    index,
    isDragAndDropEnabled,
    isEnabled,
    movePinnedStack,
    stackData,
  ]);

  if (!isDragAndDropEnabled) {
    return <PinnedStackCard stackData={stackData} hidden={isHidden} />;
  }

  const children =
    !isHidden && dndImprovementsEnabled ? (
      <WithDragIconHandle leftPosition={-16} topPosition={8} height={24}>
        <PinnedStackCard
          stackData={stackData}
          hidden={isHidden}
          hideSelectorClass={true}
        />
      </WithDragIconHandle>
    ) : (
      <PinnedStackCard
        stackData={stackData}
        hidden={isHidden}
        hideSelectorClass={true}
      />
    );

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        opacity: isDragging ? 0.3 : 1,
        paddingRight: '8px',
        paddingLeft: '8px',
        height: '100%',
      }}
      className={titleCardClassName}
    >
      {children}
      {closestEdge && <DropIndicator edge={closestEdge} />}
      {state.type === 'preview'
        ? createPortal(
            <div style={{ width: computedWidth || '180px' }}>{children}</div>,
            state.container,
          )
        : null}
    </div>
  );
};
