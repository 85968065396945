import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { firstValueFrom, Observable } from 'rxjs';
import { Service } from './service';
import { ConversationMessage, NewUserMessage } from './types';

const conversationService = services.get<Service>(ServiceId.CONVERSATION);

export default conversationService;

/**
 * Post a user message to the conversation.
 * @param newMessage The message to post.
 */
export function postUserMessage(newMessage: NewUserMessage): Promise<void> {
  return firstValueFrom(conversationService.postUserMessage(newMessage));
}

/**
 * Observe a conversation. This will emit the full conversation history as it changes.
 * @param conversationId The ID of the conversation to observe.
 * @returns An observable that emits the full conversation history.
 */
export const observeConversation: (
  conversationId: string,
) => Observable<ConversationMessage[]> =
  conversationService.observeConversation;
