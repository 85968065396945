import { ClickOutside } from '@dropbox/dig-components/click_outside';
import {
  Overlay,
  OverlayPlacement,
  OverlayProps,
} from '@dropbox/dig-components/overlay';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import styles from './CardWithPopover.module.css';

// Default placement for our popover component (expose this as a prop later when we want to extend this)
const DEFAULT_POPOVER_PLACEMENT: Pick<OverlayProps, 'offsetDistance'> & {
  placement: OverlayPlacement;
} = {
  placement: 'bottom',
  offsetDistance: { mainAxis: -25, crossAxis: 40 },
};

/**
 *  CardWithPopover is a HOC that wraps a card component as triggering surface
 * and renders a popover component that is offset from the card (ex. stack items for a stack card)
 * Note: currently this is only enable for hover friendly devices
 *
 * @param triggerComponent - The card component that will be the trigger for the popover
 * @param popoverComponent - The component that will be rendered as a popover
 * @param parentClassname - This is a classname passed in to target the parent element
 *    (ex. needed for selector class applied for useDynamicHook)
 */
export const CardWithPopover = ({
  triggerComponent,
  popoverComponent,
  styles,
  parentClassname,
}: {
  triggerComponent: React.ReactNode;
  popoverComponent: React.ReactNode;
  styles?: CSSProperties;
  parentClassname?: string;
}) => {
  const isHoverFriendly = useIsHoverFriendly();
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const mouseLeaveTimeout = useRef<number | null>(null);

  const handleMouseEnter = () => {
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    // TODO Improvement: let's immediate check to see if we need to hide the popoveer
    // by checking if the mouse is not over the trigger component or the popover component
    // since setTimeout gets scheduled for a min-time and seems to get called even later on the initial page load hover
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 50);
  };

  useEffect(() => {
    return () => {
      mouseLeaveTimeout.current &&
        window.clearTimeout(mouseLeaveTimeout.current);
    };
  }, []);

  // Currently CardWithPopover is only enable for hover friendly events, so if not enabled
  // let's just render the triggering component
  if (!isHoverFriendly) {
    return <>{triggerComponent}</>;
  }

  return (
    <div
      ref={triggerRef}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      style={styles}
      className={parentClassname}
    >
      {triggerComponent}
      <PopoverComponentWrapper
        triggerRef={triggerRef}
        show={showTooltip}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        popoverComponent={popoverComponent}
      />
    </div>
  );
};

const PopoverComponentWrapper = ({
  triggerRef,
  show,
  onPointerLeave,
  onPointerEnter,
  popoverComponent,
  initialPlacement = DEFAULT_POPOVER_PLACEMENT.placement,
  offsetDistance = DEFAULT_POPOVER_PLACEMENT.offsetDistance,
}: {
  triggerRef: RefObject<HTMLDivElement>;
  show: boolean;
  onPointerEnter: () => void;
  onPointerLeave: () => void;
  popoverComponent: React.ReactNode;
  initialPlacement?: OverlayPlacement;
  offsetDistance?: OverlayProps['offsetDistance'];
}) => {
  const [placement, setPlacement] =
    useState<OverlayPlacement>(initialPlacement);

  if (!show) {
    return null;
  }

  return (
    <ClickOutside onClickOutside={onPointerLeave}>
      <Overlay
        className={styles.popoverOverlay}
        placement={placement}
        auto
        offsetDistance={offsetDistance}
        anchorRef={triggerRef}
        onChangePlacement={setPlacement}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        {popoverComponent}
      </Overlay>
    </ClickOutside>
  );
};
