import { useInitNavigationAvatarState } from '@mirage/mosaics/NavigationAvatar/atoms';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { useMemo } from 'react';

export function useInitAvatarMenu() {
  // Show the download desktop option only on desktop webapp.
  const isMobileSize = useIsMobileSize();
  const isHoverFriendly = useIsHoverFriendly();
  const showDownloadDesktop = !isMobileSize && isHoverFriendly;
  const showDownloadExtension = !isMobileSize && isHoverFriendly;

  // Note: This value has to be a constant, else it will cause react to
  // re-render the component continuously.
  const avatarState = useMemo(() => {
    return {
      showSettings: true,
      showLogout: true,
      showCookieBanner: true,
      showDownloadDesktop,
      showDownloadExtension,
      showDownloadMobile: true,
    };
  }, [showDownloadDesktop, showDownloadExtension]);

  useInitNavigationAvatarState(avatarState);
}
