import {
  ContentIcon,
  getTooltipInCardProps,
} from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { TileCard } from '@mirage/mosaics/TileCard';
import {
  RecentConnectorContent,
  RecentContent,
  RecentUpsellContent,
} from '@mirage/service-recent-content/types';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import { CSSProperties, useState } from 'react';

const getRecentTileCardIcon = ({
  content,
  iconSrc,
}: {
  content: RecentContent;
  iconSrc: string;
}) => {
  const icon =
    content.connectorInfo.type === 'connector' ? (
      <FileContentIcon content={content as RecentConnectorContent} />
    ) : (
      <ContentIcon
        icon={<FavIcon src={iconSrc} pureImg />}
        size="medium"
        hasBackground
      />
    );

  return icon;
};

const METADATA_MIN_HEIGHT = 24; // used to ensure recent tile card and stack tile cards are the same height
const RecentMetadataComponent = ({ content }: { content: RecentContent }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipTextProps = getTooltipInCardProps(isTruncated);
  const serviceName =
    'displayName' in content.connectorInfo
      ? content.connectorInfo.displayName
      : urlToService(content.url) || urlToDomainName(content.url);

  return (
    <Box style={{ minHeight: METADATA_MIN_HEIGHT }}>
      <Text color="subtle" tagName="div" {...tooltipTextProps}>
        <DashTruncateWithTooltip
          lines={1}
          tooltipProps={{
            title: serviceName,
          }}
          onBeforeTruncate={setIsTruncated}
        >
          {serviceName}
        </DashTruncateWithTooltip>
      </Text>
    </Box>
  );
};

export const RecentTileCard = ({
  hidden,
  style,
  content,
  iconSrc,
  handleOnClick,
  selectorClassname,
}: {
  hidden?: boolean;
  style?: CSSProperties;
  content: RecentContent;
  iconSrc: string;
  handleOnClick: (content: RecentContent) => void;
  selectorClassname: string;
}) => {
  const contentIsUpsell = content.connectorInfo.type === 'upsell';
  const iconComponent = contentIsUpsell
    ? (content as RecentUpsellContent).icon
    : getRecentTileCardIcon({ content, iconSrc });

  return (
    <TileCard
      // Hide this card from Meticulous recordings
      className={`${selectorClassname} meticulous-redact-recording`}
      style={style}
      hidden={hidden}
      onClick={() => handleOnClick(content)}
      visibleUrl={content.url}
      title={content.title}
      titleLinesBeforeTruncate={2}
      icon={iconComponent}
      metadata={<RecentMetadataComponent content={content} />}
    />
  );
};
