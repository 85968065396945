import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useLocation } from 'react-router-dom';
import { useFeatureFlagValue } from './useFeatureFlagValue';
import { convertFeatureValueToBool } from './util';

export const useDualMode = () => {
  const { pathname } = useLocation();
  const isLauncher = pathname === '/launcher';
  const isDesktop = EnvCtx.surface === 'desktop';
  const isDualModeEnabled =
    convertFeatureValueToBool(
      useFeatureFlagValue('dash_2024_12_11_dual_mode'),
    ) && isDesktop;

  return {
    isDualModeEnabled,
    isDualModeLauncher: isDualModeEnabled && isLauncher,
    isDualModePersistent: isDualModeEnabled && !isLauncher,
  };
};
