import { getContent } from '@mirage/mosaics/ComposeAssistant/data/llm/tools/search-tool-apis';
import { tagged } from '@mirage/service-logging';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { useDeepCompareEffect } from '@react-hookz/web';
import { useRef, useState } from 'react';

const logger = tagged('ComposeSourcesCache');

export type ContentCacheState =
  | ContentCacheLoaded
  | ContentCacheLoading
  | ContentCacheError;
export interface ContentCacheLoaded {
  state: 'loaded';
  source: ComposeSource;
  content: string;
}
export interface ContentCacheLoading {
  state: 'loading';
  source: ComposeSource;
  loadingPromise: Promise<ContentCacheLoaded | ContentCacheError>;
}
export interface ContentCacheError {
  state: 'error';
  source: ComposeSource;
  error:
    | 'request_failed'
    | 'cannot_find_content'
    | 'no_body_content'
    | 'malformed_source';
}

export type SourcesContentCache = {
  [key: string]: ContentCacheState;
};

export function useComposeSourcesCache(sources: ComposeSource[]) {
  const [sourcesContents, setSourceContents] = useState<SourcesContentCache>(
    {},
  ); // map of uuid -> content
  const sourcesContentsRef = useRef(sourcesContents);
  sourcesContentsRef.current = sourcesContents;

  useDeepCompareEffect(() => {
    const sourcesUUIDs = new Set(
      sources.map((source) => getSourceUUID(source)),
    );

    // remove contents for sources that are no longer in the list from the cache
    const removedSources = Object.keys(sourcesContentsRef.current).filter(
      (uuid) => !sourcesUUIDs.has(uuid),
    );
    setSourceContents((prev) => {
      const updated = { ...prev };
      for (const uuid of removedSources) {
        delete updated[uuid];
      }
      return updated;
    });

    // check for which sources require fetching
    const newSources = sources.filter((source) => {
      const uuid = getSourceUUID(source);
      if (uuid && !sourcesContentsRef.current[uuid]) {
        return true;
      }
      return false;
    });
    if (newSources.length === 0) {
      return;
    }

    // kick off new fetches
    const newSourceFetches: {
      [uuid: string]: {
        source: ComposeSource;
        loadingPromise: Promise<ContentCacheLoaded | ContentCacheError>;
      };
    } = {};
    for (const source of newSources) {
      const uuid = getSourceUUID(source);
      if (!uuid) {
        return Promise.resolve();
      }
      newSourceFetches[uuid] = {
        source,
        loadingPromise: getSourceContent(uuid, source),
      };
    }

    // update new sources to loading states
    setSourceContents((prev) => {
      const updated = { ...prev };
      for (const [uuid, { source, loadingPromise }] of Object.entries(
        newSourceFetches,
      )) {
        updated[uuid] = {
          state: 'loading',
          source,
          loadingPromise,
        };
      }
      return updated;
    });

    // mark sources as done as they complete
    for (const [uuid, { loadingPromise }] of Object.entries(newSourceFetches)) {
      loadingPromise
        .then((content) => {
          setSourceContents((prev) => ({
            ...prev,
            [uuid]: content,
          }));
          return;
        })
        .catch((e) => {
          logger.error('unhandled error fetching content', e);
        });
    }
  }, [sources]);

  return sourcesContents;
}

async function getSourceContent(
  uuid: string,
  source: ComposeSource,
): Promise<ContentCacheLoaded | ContentCacheError> {
  if (source.type === 'local_file_content') {
    return {
      state: 'loaded',
      source,
      content: source.content ?? '',
    };
  }
  const response = await getContent(uuid);
  switch (response.type) {
    case 'error':
      return {
        state: 'error',
        source,
        error: response.error,
      };
    case 'success':
      return {
        state: 'loaded',
        source,
        content: response.content,
      };
  }
}

// unlike useComposeSourcesCache, this does a one-off load of the sources
export function getComposeSourcesCache(sources: ComposeSource[]) {
  const sourcesCache: SourcesContentCache = {};
  const sourceFetches: {
    [uuid: string]: {
      source: ComposeSource;
      loadingPromise: Promise<ContentCacheLoaded | ContentCacheError>;
    };
  } = {};
  for (const source of sources) {
    const uuid = getSourceUUID(source);
    if (!uuid) {
      continue;
    }
    const loadingPromise = getSourceContent(uuid, source);
    sourceFetches[uuid] = {
      source,
      loadingPromise,
    };
    sourcesCache[uuid] = {
      state: 'loading',
      source,
      loadingPromise,
    };
  }

  // mark sources as done as they complete
  for (const [uuid, { loadingPromise }] of Object.entries(sourceFetches)) {
    loadingPromise
      .then((content) => {
        sourcesCache[uuid] = content;
        return;
      })
      .catch((e) => {
        logger.error('unhandled error fetching content', e);
      });
  }

  return sourcesCache;
}
