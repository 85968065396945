/* eslint-disable jsx-a11y/no-autofocus */
import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import useSettings from '@mirage/service-settings/useSettings';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useState } from 'react';

import type {
  OverlayAnchorRef,
  OverlayOffset,
} from '@dropbox/dig-components/overlay';
import type { ControlPlacement } from '@dropbox/dig-components/tooltips';

export const RichTooltip = ({
  id,
  title,
  body,
  cta = i18n.t('acknowledge_button_cta'),
  offsetDistance,
  triggerRef,
}: {
  id: string;
  title: string;
  body: string;
  cta?: string;
  offsetDistance?: OverlayOffset;
  triggerRef: OverlayAnchorRef;
}) => {
  const { settings, set: setSettings } = useSettings(['dismissedTooltips']);
  const [placement, setPlacement] = useState<ControlPlacement>('right-start');
  const [hidden, setHidden] = useState(
    settings?.dismissedTooltips?.[id] ?? false,
  );

  const handleDismiss = () => {
    setHidden(true);
    setSettings('dismissedTooltips', {
      ...settings?.dismissedTooltips,
      [id]: true,
    });
  };

  const updatePlacement = (placement: ControlPlacement) => {
    setPlacement(placement);
  };

  return (
    <DigTooltip.Control
      variant="rich"
      open={!hidden}
      triggerRef={triggerRef}
      auto
      placement={placement}
      onChangePlacement={updatePlacement}
      offsetDistance={offsetDistance}
    >
      <Title style={{ marginTop: 0 }}>{title}</Title>
      <Box paddingBottom="Micro Large">
        <Text>{body}</Text>
      </Box>
      <Button variant="outline" onClick={handleDismiss} autoFocus>
        {cta}
      </Button>
    </DigTooltip.Control>
  );
};
