import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Tabs } from '@dropbox/dig-components/tabs';
import { Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { DashGlyph, GlyphLogo } from '@dropbox/dig-logos';
import { openURL } from '@mirage/service-platform-actions';
import {
  ANDROID_MOBILE_APP_STORE_URL,
  IPHONE_MOBILE_APP_STORE_URL,
} from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import * as React from 'react';
import { androidAppStoreQRCode, iosAppStoreQRCode } from './assets';
import styles from './MobileDownloadModal.module.css';

enum MobileAppDownloadTab {
  IOS = 'ios',
  ANDROID = 'android',
}

function DownloadAppPrompt({
  title,
  url,
  qrCodeSrc,
}: {
  title: string;
  url: string;
  qrCodeSrc: string;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingBottom="44"
    >
      <Box paddingTop="32" display="inline-flex">
        <Box
          style={{ width: '96px', height: '96px' }}
          borderColor="Border Subtle"
          borderWidth="1"
          borderStyle="Solid"
          padding="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GlyphLogo size={128} src={DashGlyph} />
        </Box>
        <Box
          style={{ width: '96px', height: '96px' }}
          borderColor="Border Subtle"
          borderWidth="1"
          borderRight="Solid"
          borderTop="Solid"
          borderBottom="Solid"
          padding="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={qrCodeSrc} alt="" className={styles.qrCode} />
        </Box>
      </Box>
      <Box textAlign="center" style={{ maxWidth: '208px' }}>
        <Title weightVariant="emphasized">{title}</Title>
      </Box>
      <Box paddingTop="6">
        <Button variant="primary" onClick={() => openURL(url)}>
          {i18n.t('open_app_store_cta')}
        </Button>
      </Box>
    </Box>
  );
}

export function MobileDownloadModal({ onClose }: { onClose: () => void }) {
  const [selectedTab, setSelectedTab] = React.useState<MobileAppDownloadTab>(
    MobileAppDownloadTab.IOS,
  );

  return (
    <Modal
      open={true}
      isCentered={true}
      withCloseButton={i18n.t('close_modal_aria')}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={true}
      onRequestClose={onClose}
    >
      <Modal.Body>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop="12"
        >
          <Tabs
            selectedTab={selectedTab}
            onSelection={(value) =>
              setSelectedTab(value as MobileAppDownloadTab)
            }
          >
            <Tabs.Group variant="justified">
              <Tabs.Tab id={MobileAppDownloadTab.IOS} className={styles.tab}>
                {i18n.t('iphone')}
              </Tabs.Tab>
              <Tabs.Tab
                id={MobileAppDownloadTab.ANDROID}
                className={styles.tab}
              >
                {i18n.t('android')}
              </Tabs.Tab>
            </Tabs.Group>
            <Tabs.Panel tabId={MobileAppDownloadTab.IOS}>
              <DownloadAppPrompt
                title={i18n.t('download_iphone_app')}
                url={IPHONE_MOBILE_APP_STORE_URL}
                qrCodeSrc={iosAppStoreQRCode}
              />
            </Tabs.Panel>
            <Tabs.Panel tabId={MobileAppDownloadTab.ANDROID}>
              <DownloadAppPrompt
                title={i18n.t('download_android_app')}
                url={ANDROID_MOBILE_APP_STORE_URL}
                qrCodeSrc={androidAppStoreQRCode}
              />
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Modal.Body>
    </Modal>
  );
}
