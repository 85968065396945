// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1554
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSearchResultType } from "../enums/dash_search_result_type";
import type { SearchResultSource } from "../enums/search_result_source";
import type { DashSourceType } from "../enums/dash_source_type";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { DashEntrypoint } from "../enums/dash_entrypoint";
import type { TypeaheadResultType } from "../enums/typeahead_result_type";
import type { FeatureLine } from "../enums/feature_line";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { DashAnnotationMode } from "../enums/dash_annotation_mode";
import type { SerpFinalRanking } from "../enums/serp_final_ranking";

// Event_name: 'shown.dash_search_result'
// Description: This event fires when for each search result observed by the user. It should also fire for every result that appears as the user scrolls.
// Owner: otc
export type Shown_DashSearchResult = PAPEventGeneric<
  'dash',
  'shown',
  'dash_search_result',
  {
    // Legacy: dash_connector_id in Desktop
    // Note, this should have been a enum :(
    connector?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Accumulated count of hit launch (locally on desktop) when result is shown.
    hitCount?: number;
    // Describe whether a search result came from upstream search
    fromUpstream?: boolean;
    // Total result score of ranking.
    totalScore?: number;
    // Source result score of ranking.
    sourceScore?: number;
    // Title attribute related to the primitive
    title?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // The contents of a search query
    queryString?: string;
    // Number of results returned to user
    resultCount?: number;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // App filters selected
    // e.g. “contact”, “contact,gsuite”
    // These values are going to be sorted, global, code defined, Dropbox owned data that tags the "type" of a search result.
    activeFilters?: string;
    // Timestamp that encodes last time the search result was updated (in milliseconds).
    // i.e Document.upstreamUpdatedAt, Event.upstreamUpdatedAt, etc.
    // 1680559512000, 1680569512000, etc.
    resultUpdatedAtMs?: number;
    // Boolean indicating when the right rail is visible (in Desktop)
    rightRailOpened?: boolean;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Source for the document such as connector, tip, application, local file.
    sourceType?: string;
    // The LLM-generated answer string returned from dash answers (Q&A) service.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    answerString?: string;
    // The number of sources associated with a dash answer. This will be a count, likely 1-3 sources with any given answer.
    numAnswerSources?: number;
    // The ID of the Dash answers session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashAnswersSessionId?: string;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Delay in results in milliseconds
    latency?: number;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // This defines where the document source is
    dashSourceType?: DashSourceType;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The entry point for a dash action. How a user got to a specific surface to perform an action.
    dashEntrypoint?: DashEntrypoint;
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    answerId?: string;
    // Dash Answers - number of questions asked in an answers session. This is a COUNT.
    numQuestionsAsked?: number;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
    // Type of result being acted upon within typeahead field
    typeaheadResultType?: TypeaheadResultType;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // unique id for a /dash/conversation query. Used by the ML team to get feedback on answer quality. This is used to associate backend events with user actions on the front end. This is an opaque ID that is just used for correlating actions and has no other significance.
    dashAnswerRequestId?: string;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
    // Which algorithm the Dash client used while drawing SERP results onto the screen. This reflects the logic the client used to merge and rank server results, upstream results, and local file results.
    serpFinalRanking?: SerpFinalRanking;
    // The zero-index returned position of search result in a list of search results with the SERP CTA removed from the list
    resultPositionNoCta?: number;
    // In Dash typeahead, the unweighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreAppliedWeight?: number;
    // Whether or not the result in typeahead was "pinned" to a specific position (not necessarily p1)
    typeaheadScoringPinned?: boolean;
    // LLM Generated query string for suggested questions. Returned from the context engine api and passed directly to the PAP event for model training data
    generatedQueryString?: string;
    // Determines if a search result has been "verified"
    dashIsResultVerified?: boolean;
    // Boolean if the search result was displayed within a Category
    categorizedResult?: boolean;
    // the position of the result in the list UI, used for categorized search experiment
    displayedPosition?: number;
  }
>;

export function PAP_Shown_DashSearchResult(properties?: Shown_DashSearchResult['properties']): Shown_DashSearchResult {
  return <Shown_DashSearchResult>{
    class: 'dash',
    action: 'shown',
    object: 'dash_search_result',
    properties,
  };
}
