import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Move_PinnedStack } from '@mirage/analytics/events/types/move_pinned_stack';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { sortPinnedStack } from '@mirage/service-stacks';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import { coerceSortKey, sortKeyCompare } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import { useDeepCompareEffect } from '@react-hookz/web';
import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing';
import { useCallback, useMemo, useState } from 'react';

const BATCH_SIZE = 5;

// if there are duplicates or missing sort keys, reset by generating new sort keys
const tryResetPinnedStackSortKeys = async (
  sortedPinnedStacks: WorkingSetCardData[],
) => {
  const oldSortKeys = sortedPinnedStacks.map((stack) => stack.pinnedSortKey);
  const uniqueSortKeys = new Set(
    oldSortKeys.filter((key) => key && key !== ''),
  );
  if (uniqueSortKeys.size === oldSortKeys.length) {
    return oldSortKeys;
  }
  const newSortKeys = generateNKeysBetween(
    null,
    null,
    sortedPinnedStacks.length,
  );
  for (let i = 0; i < sortedPinnedStacks.length; i += BATCH_SIZE) {
    const batch = sortedPinnedStacks.slice(i, i + BATCH_SIZE);
    await Promise.all(
      batch.map((stack, i) =>
        sortPinnedStack(stack.namespaceId, newSortKeys[i]),
      ),
    );
  }
  return newSortKeys;
};

const trySortingPinnedStacks = (pinnedStacks: WorkingSetCardData[]) => {
  const allHaveSortKeys = pinnedStacks.every(
    (stack) => stack.pinnedSortKey && stack.pinnedSortKey !== '',
  );
  return allHaveSortKeys
    ? pinnedStacks.sort((a, b) =>
        sortKeyCompare(a.pinnedSortKey || '', b.pinnedSortKey || ''),
      )
    : pinnedStacks;
};

export const useDraggablePinnedStacks = (
  allPinnedStacks: WorkingSetCardData[],
) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isDraggablePinnedStacksEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_organize_2025_01_10_drag_pinned_stacks'),
  );
  const [isResettingSortKeys, setIsResettingSortKeys] = useState(false);
  const [cachedSortedPinnedStacks, _setCachedSortedPinnedStacks] = useState<
    WorkingSetCardData[]
  >(() => trySortingPinnedStacks(allPinnedStacks));

  const setCachedSortedPinnedStacks = (pinnedStacks: WorkingSetCardData[]) => {
    _setCachedSortedPinnedStacks(trySortingPinnedStacks(pinnedStacks));
  };

  const allPinnedStacksNamespaceIds = useMemo(() => {
    return new Set(
      allPinnedStacks.map((stack) => stack.namespaceId + stack.pinnedSortKey),
    );
  }, [allPinnedStacks]);

  useDeepCompareEffect(() => {
    setCachedSortedPinnedStacks(allPinnedStacks);
  }, [allPinnedStacksNamespaceIds]);

  const movePinnedStack = useCallback(
    async (namespaceId: string, dstIndex: number) => {
      if (!isDraggablePinnedStacksEnabled || isResettingSortKeys) {
        return;
      }

      setIsResettingSortKeys(true);
      try {
        const sortKeys = await tryResetPinnedStackSortKeys(
          cachedSortedPinnedStacks,
        );
        const newSortKey = generateKeyBetween(
          coerceSortKey(sortKeys[dstIndex - 1]),
          coerceSortKey(sortKeys[dstIndex]),
        );
        setCachedSortedPinnedStacks(
          cachedSortedPinnedStacks.map((stack) =>
            stack.namespaceId === namespaceId
              ? { ...stack, pinnedSortKey: newSortKey }
              : stack,
          ),
        );
        const movedPinnedStack = cachedSortedPinnedStacks.find(
          (currStack) => currStack.namespaceId === namespaceId,
        );

        reportPapEvent(
          PAP_Move_PinnedStack({
            numberOfStacks: cachedSortedPinnedStacks.length,
            itemPosition: dstIndex,
            actionSurfaceComponent: 'stacks',
            featureLine: 'stacks',
            ...(movedPinnedStack?.stackDerivedPAPProps || {}),
          }),
        );
        await sortPinnedStack(namespaceId, newSortKey);
      } catch (e) {
        showSnackbar({ title: i18n.t('error_moving_pinned_stack') });
        Sentry.captureException(e, {
          data: {
            message: 'Error moving draggable pinned stack',
            stackCount: cachedSortedPinnedStacks.length,
          },
        });
      }
      setIsResettingSortKeys(false);
    },
    [
      isDraggablePinnedStacksEnabled,
      isResettingSortKeys,
      cachedSortedPinnedStacks,
      reportPapEvent,
    ],
  );

  return {
    sortedPinnedStacks: cachedSortedPinnedStacks,
    movePinnedStack,
    isEnabled: isDraggablePinnedStacksEnabled && !isResettingSortKeys,
  };
};
