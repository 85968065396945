import { PAPEvent } from '@mirage/analytics/events/base/event';
import { PAP_Click_AddSource } from '@mirage/analytics/events/types/click_add_source';
import { tagged } from '@mirage/service-logging';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import {
  isMimeTypeSupported,
  parseFileInWorker,
} from '@mirage/shared/worker/file-parser-client';
import { v4 as uuidv4 } from 'uuid';

const logger = tagged('file-uploader');
export const MAX_FILE_SIZE = 50 * 1024 * 1024;

export type FileError = 'size' | 'type' | 'generic' | null;

export async function processFile(
  file: File,
  onSuccess: (source: ComposeSource) => void,
  logComposeEvent: (event: PAPEvent) => void,
): Promise<FileError | null> {
  if (file.size > MAX_FILE_SIZE) {
    return 'size';
  }

  if (!isMimeTypeSupported(file.type)) {
    return 'type';
  }

  try {
    const content = await parseFileInWorker(file);

    onSuccess({
      type: 'local_file_content',
      uuid: uuidv4(),
      fileName: file.name,
      content,
      fileSize: file.size,
      mimeType: file.type,
    });

    logger.info('File content processed successfully:', {
      fileName: file.name,
    });
    logComposeEvent(
      PAP_Click_AddSource({
        actionType: 'local',
        eventState: 'success',
      }),
    );
    return null;
  } catch (err) {
    logger.error('Error processing file:', err);
    logComposeEvent(
      PAP_Click_AddSource({
        actionType: 'local',
        eventState: 'failed',
      }),
    );
    return 'generic';
  }
}
