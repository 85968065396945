import * as React from "react";

import { Card as DigCard } from "@dropbox/dig-components/card";
import classnames from "classnames";
import styles from "./ModuleCard.module.css";

export const OverlayLink = ({
  children,
  href,
  onClick,
  className,
}: {
  children: React.ReactNode;
  href: string;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  className?: string;
}) => {
  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    // Avoid the default behavior of the link
    e.preventDefault();

    const target = e.target as HTMLElement;

    // if clicked element (target) is not a child of the card (currentTarget)
    // don't do anything. this means we're likely doing a synthetic trigger
    // as a result of a portaled menu
    if (!(e.currentTarget as HTMLElement).contains(target)) {
      return;
    }

    onClick?.(e);
  };

  return (
    <DigCard.Link
      onClick={handleClick}
      href={href ?? "#"}
      className={classnames(styles.cardLink, className)}
      isOverlay
    >
      {children}
    </DigCard.Link>
  );
};
