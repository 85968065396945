import { Button } from '@dropbox/dig-components/buttons';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import { TextArea, TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { useState } from 'react';
import styles from './InviteModal.module.css';

export function InviteModal({ onClose }: { onClose: () => void }) {
  const [message, setMessage] = useState(
    `Hey team, Dash can let us stay connected across the company. Let's try it out.`,
  );

  return (
    <Modal open={true} withCloseButton="close" onRequestClose={onClose}>
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title>{'Some Placeholder Text for this Modal'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.inviteFormBody}>
        <Text variant="label" size="large" tagName="p" isBold>
          {'Invite Individually'}
        </Text>
        <TextInput placeholder={'Add email or name'} />
        <FormRow>
          <FormLabel htmlFor="invite-modal-optional-message">
            {'Optional Message'}
          </FormLabel>
          <TextArea
            id="invite-modal-optional-message"
            onChange={(e) => setMessage(e.currentTarget.value)}
            value={message}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer
        preferComposition={true}
        className={styles.inviteModalFooter}
      >
        <Button variant="opacity">{'Cancel'}</Button>
        <Button variant="primary">{'Invite'}</Button>
      </Modal.Footer>
    </Modal>
  );
}
