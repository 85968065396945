import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { TextInput, TextInputAffix } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { PictogramIcon } from '@dropbox/dig-icons';
import { ImagePictogram } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useOnboardingResponses } from '../hooks/OnboardingResponsesProvider';
import { OnboardingSurveyQuestionAnswer } from '../types/onboarding-surveys';
import styles from './OnboardingCompanyInfoStep.module.css';

type Props = {
  isAdmin: boolean;
  onValidationChange: (
    valid: boolean,
    answers: OnboardingSurveyQuestionAnswer[],
  ) => void;
};

const MAX_COMPANY_NAME_LENGTH = 20;

export const OnboardingCompanyInfoStep = ({
  isAdmin,
  onValidationChange,
}: Props) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const exposureLoggedRef = useRef(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { companyInfo, setCompanyName } = useOnboardingResponses();

  useEffect(() => {
    const companyName = companyInfo?.companyName ?? '';
    if (
      companyName.length > 0 &&
      companyName.length <= MAX_COMPANY_NAME_LENGTH
    ) {
      // Company logo is not required
      onValidationChange(true, []);
    }
  }, [companyInfo?.companyName, onValidationChange]);

  useEffect(() => {
    if (exposureLoggedRef.current) return;
    exposureLoggedRef.current = true;
    reportPapEvent(
      PAP_View_DashSelfServeOnboarding({
        selfServeIsTeamAdmin: isAdmin,
        onboardingStep: 'name_and_logo',
      }),
    );
  }, [isAdmin, reportPapEvent]);

  const handleLogoClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (file: File | null) => {
    if (!file) return;

    // Validate file size and type
    if (!file.type.startsWith('image/')) {
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      return;
    }

    // Generate a preview URL for the image
    const imageUrl = URL.createObjectURL(file);
    setImagePreview(imageUrl);

    // Clean up the object URL when the component is unmounted
    return () => URL.revokeObjectURL(imageUrl);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    handleFileChange(file);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        className={classnames(
          styles.logoDragDrop,
          isDragging ? styles.logoDragDropActive : '',
        )}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        role="button"
        onClick={handleLogoClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {imagePreview ? (
          <img
            className={styles.companyLogo}
            src={imagePreview}
            alt={i18n.t('self_serve_image_alt_text')}
          />
        ) : (
          <Box
            className={styles.placeholderImage}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            data-testid="placeholder-image"
          >
            <PictogramIcon width={42} src={ImagePictogram} />
          </Box>
        )}

        <Text
          variant="paragraph"
          size="large"
          className={styles.logoDragDropLabel}
        >
          {imagePreview ? (
            <span>&nbsp;</span>
          ) : (
            i18n.t('self_serve_add_logo_or_image')
          )}
        </Text>
      </Box>

      <input
        ref={fileInputRef}
        className={styles.fileInput}
        accept="image/*"
        data-testid="file-input"
        onChange={(e) => handleFileChange(e.target.files?.[0] ?? null)}
        type="file"
      />

      <FormRow>
        <FormLabel htmlFor="company name input">
          {i18n.t('self_serve_organization')}
        </FormLabel>
        <TextInput
          id="company name input"
          size="large"
          value={companyInfo?.companyName}
          placeholder={i18n.t('self_serve_enter_organization_name')}
          onChange={(e) => setCompanyName(e.target.value)}
          maxLength={MAX_COMPANY_NAME_LENGTH}
          withRightAccessory={
            <TextInputAffix>
              {Math.max(
                0,
                MAX_COMPANY_NAME_LENGTH -
                  (companyInfo?.companyName.length ?? 0),
              )}
            </TextInputAffix>
          }
        ></TextInput>
      </FormRow>
    </Box>
  );
};
