import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu, WrapperRenderFn } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, DragHandleLine } from '@dropbox/dig-icons/assets';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getElementForTableNode,
  $insertTableColumn,
  $insertTableRow,
  $isTableRowNode,
  TableNode,
} from '@lexical/table';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import styles from './TableHoverActionsHandles.module.css';

const END_CAP_HANDLE_SIZE = 20;

/**
 * Width of the border of the table cells. Needed to adjust the position of the handles to
 * align with the cell borders.
 */
const CELL_BORDER_WIDTH = 1.0;

const TABLE_MARGIN = {
  left: 50,
  top: 46,
};

interface AddColumnButtonHandleProps {
  tableNode: TableNode;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
}
export const AddColumnButtonHandle = memo(
  ({ tableNode, tableElement, anchorElement }: AddColumnButtonHandleProps) => {
    const [editor] = useLexicalComposerContext();
    const handleClick = useCallback(() => {
      editor.update(() => {
        const firstRow = tableNode.getFirstChild();
        if (!$isTableRowNode(firstRow)) {
          return;
        }
        $insertTableColumn(
          tableNode,
          firstRow.getChildrenSize() - 1 /* targetIndex */,
          true /* shouldInsertAfter */,
          1 /* columnCount */,
          $getElementForTableNode(editor, tableNode) /* table */,
        );
      });
    }, [editor, tableNode]);
    useElementsResizeRev([tableElement, anchorElement]);

    const tableRect = tableElement.getBoundingClientRect();
    return (
      <button
        className={classnames(styles.endCapHandle, styles.addColumn)}
        style={{
          left: tableRect.width + TABLE_MARGIN.left,
          top: TABLE_MARGIN.top - HANDLE_SIZE,
          height: tableRect.height + END_CAP_HANDLE_SIZE + HANDLE_SIZE,
        }}
        onClick={handleClick}
      >
        <UIIcon src={AddLine} size="small" />
      </button>
    );
  },
);
AddColumnButtonHandle.displayName = 'AddColumnButtonHandle';

interface AddRowButtonHandleProps {
  tableNode: TableNode;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
}
export const AddRowButtonHandle = memo(
  ({ tableNode, tableElement, anchorElement }: AddRowButtonHandleProps) => {
    const [editor] = useLexicalComposerContext();
    const handleClick = useCallback(() => {
      editor.update(() => {
        $insertTableRow(
          tableNode,
          tableNode.getChildrenSize() - 1 /* targetIndex */,
          true /* shouldInsertAfter */,
          1 /* rowCount */,
          $getElementForTableNode(editor, tableNode) /* table */,
        );
      });
    }, [editor, tableNode]);
    useElementsResizeRev([tableElement, anchorElement]);

    const tableRect = tableElement.getBoundingClientRect();
    return (
      <button
        className={classnames(styles.endCapHandle, styles.addRow)}
        style={{
          left: TABLE_MARGIN.left - HANDLE_SIZE,
          top: tableRect.height + TABLE_MARGIN.top,
          width: tableRect.width + END_CAP_HANDLE_SIZE + HANDLE_SIZE,
        }}
        onClick={handleClick}
      >
        <UIIcon src={AddLine} size="small" />
      </button>
    );
  },
);
AddRowButtonHandle.displayName = 'AddRowButtonHandle';

export type CellDispatchActionArg =
  | {
      action: 'addRow' | 'addColumn';
      order: 'before' | 'after';
    }
  | {
      action: 'deleteRow' | 'deleteColumn';
    }
  | {
      action: 'moveRow' | 'moveColumn';
      sourceIndex: number;
      targetIndex: number;
    };
type CellDispatchAction = (arg: CellDispatchActionArg) => void;

const ZONE_WIDTH_RADIUS = 2;

interface ColumnDividerHandleProps {
  placement: 'left' | 'right';
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
  dispatchAction: CellDispatchAction;
}
export const ColumnDividerHandle = memo(
  ({
    placement,
    cellElement,
    tableElement,
    anchorElement,
    dispatchAction,
  }: ColumnDividerHandleProps) => {
    useElementsResizeRev([cellElement, tableElement, anchorElement]);

    const cellRect = cellElement.getBoundingClientRect();
    const tableRect = tableElement.getBoundingClientRect();
    const left =
      cellRect.left +
      (placement === 'left' ? 0 : cellRect.width) -
      tableRect.left +
      TABLE_MARGIN.left -
      ZONE_WIDTH_RADIUS;
    const top = cellRect.top - tableRect.top - HANDLE_SIZE + TABLE_MARGIN.top;
    return (
      <div
        className={classnames(styles.columnDividerHandle, styles.dividerHandle)}
        style={{
          left,
          top,
        }}
      >
        <div className={styles.addButtonContainer}>
          <span className={styles.dot} />
          <IconButton
            size="small"
            variant="filled"
            shape="circular"
            className={styles.addButton}
            onClick={() => {
              dispatchAction({
                action: 'addColumn',
                order: placement === 'left' ? 'before' : 'after',
              });
            }}
          >
            <UIIcon src={AddLine} />
          </IconButton>
        </div>
        <div
          className={styles.addMarkerLine}
          style={{ height: tableRect.height + HANDLE_SIZE }}
        />
      </div>
    );
  },
);
ColumnDividerHandle.displayName = 'ColumnDividerHandle';

interface RowDividerHandleProps {
  placement: 'above' | 'below';
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
  dispatchAction: CellDispatchAction;
}
export const RowDividerHandle = memo(
  ({
    placement,
    cellElement,
    tableElement,
    anchorElement,
    dispatchAction,
  }: RowDividerHandleProps) => {
    useElementsResizeRev([cellElement, tableElement, anchorElement]);

    const cellRect = cellElement.getBoundingClientRect();
    const tableRect = tableElement.getBoundingClientRect();
    const left =
      cellRect.left - tableRect.left + TABLE_MARGIN.left - HANDLE_SIZE;
    const top =
      cellRect.top +
      (placement === 'above' ? 0 : cellRect.height) -
      tableRect.top +
      TABLE_MARGIN.top -
      ZONE_WIDTH_RADIUS;
    return (
      <div
        className={classnames(styles.rowDividerHandle, styles.dividerHandle)}
        style={{
          left,
          top,
        }}
      >
        <div className={styles.addButtonContainer}>
          <span className={styles.dot} />
          <IconButton
            size="small"
            variant="filled"
            shape="circular"
            className={styles.addButton}
            onClick={() => {
              dispatchAction({
                action: 'addRow',
                order: placement === 'above' ? 'before' : 'after',
              });
            }}
          >
            <UIIcon src={AddLine} />
          </IconButton>
        </div>
        <div
          className={styles.addMarkerLine}
          style={{ width: tableRect.width + HANDLE_SIZE }}
        />
      </div>
    );
  },
);
RowDividerHandle.displayName = 'RowDividerHandle';

const HANDLE_SIZE = 20;

interface ColumnActionHandleProps {
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
  dispatchAction: CellDispatchAction;
  index: number;
  totalCount: number;
}
export const ColumnActionHandle = memo(
  ({
    cellElement,
    tableElement,
    anchorElement,
    dispatchAction,
    index,
    totalCount,
  }: ColumnActionHandleProps) => {
    useElementsResizeRev([cellElement, tableElement, anchorElement]);

    const cellRect = cellElement.getBoundingClientRect();
    const tableRect = tableElement.getBoundingClientRect();
    const left = cellRect.left - tableRect.left + TABLE_MARGIN.left;
    // Note that getBoundingClientRect may only include half border width when border-collapse is set
    const top =
      cellRect.top -
      tableRect.top +
      TABLE_MARGIN.top -
      HANDLE_SIZE -
      CELL_BORDER_WIDTH;
    return (
      <ActionHandleMenuWrapper
        handleType="column"
        dispatchAction={dispatchAction}
        index={index}
        totalCount={totalCount}
      >
        {({ getTriggerProps }) => (
          <button
            className={classnames(
              styles.columnActionHandle,
              styles.actionHandle,
            )}
            style={{
              left,
              top,
              width: cellRect.width,
              height: HANDLE_SIZE,
            }}
            {...getTriggerProps()}
          >
            <UIIcon src={DragHandleLine} className={styles.icon} size="small" />
          </button>
        )}
      </ActionHandleMenuWrapper>
    );
  },
);
ColumnActionHandle.displayName = 'ColumnActionHandle';

interface RowActionHandleProps {
  cellElement: HTMLElement;
  tableElement: HTMLElement;
  anchorElement: HTMLElement;
  dispatchAction: CellDispatchAction;
  index: number;
  totalCount: number;
}
export const RowActionHandle = memo(
  ({
    cellElement,
    tableElement,
    anchorElement,
    dispatchAction,
    index,
    totalCount,
  }: RowActionHandleProps) => {
    useElementsResizeRev([cellElement, tableElement, anchorElement]);

    const cellRect = cellElement.getBoundingClientRect();
    const tableRect = tableElement.getBoundingClientRect();
    // Note that getBoundingClientRect may only include half border width when border-collapse is set
    const left =
      cellRect.left -
      tableRect.left +
      TABLE_MARGIN.left -
      HANDLE_SIZE -
      CELL_BORDER_WIDTH;
    const top = cellRect.top - tableRect.top + TABLE_MARGIN.top;
    return (
      <ActionHandleMenuWrapper
        handleType="row"
        dispatchAction={dispatchAction}
        index={index}
        totalCount={totalCount}
      >
        {({ getTriggerProps }) => (
          <button
            className={classnames(styles.rowActionHandle, styles.actionHandle)}
            style={{
              left,
              top,
              height: cellRect.height,
              width: HANDLE_SIZE,
            }}
            {...getTriggerProps()}
          >
            <UIIcon src={DragHandleLine} className={styles.icon} size="small" />
          </button>
        )}
      </ActionHandleMenuWrapper>
    );
  },
);
RowActionHandle.displayName = 'RowActionHandle';

interface ActionHandleMenuWrapperProps {
  children: WrapperRenderFn;
  dispatchAction: CellDispatchAction;
  handleType: 'row' | 'column';
  index: number;
  totalCount: number;
}

export const ActionHandleMenuWrapper = memo(
  ({
    children,
    dispatchAction,
    handleType,
    index,
    totalCount,
  }: ActionHandleMenuWrapperProps) => {
    return (
      <Menu.Wrapper>
        {(options) => (
          <>
            {children(options)}
            <Menu.Content {...options.getContentProps()} placement="bottom">
              <Menu.Segment>
                <Menu.ActionItem
                  onClick={() => {
                    dispatchAction({
                      action:
                        handleType === 'column' ? 'moveColumn' : 'moveRow',
                      sourceIndex: index,
                      targetIndex: index - 1,
                    });
                  }}
                  disabled={index === 0}
                >
                  {handleType === 'column'
                    ? i18n.t('compose_editor_move_column_left')
                    : i18n.t('compose_editor_move_row_up')}
                </Menu.ActionItem>
                <Menu.ActionItem
                  onClick={() => {
                    dispatchAction({
                      action:
                        handleType === 'column' ? 'moveColumn' : 'moveRow',
                      sourceIndex: index,
                      targetIndex: index + 1,
                    });
                  }}
                  disabled={index === totalCount - 1}
                >
                  {handleType === 'column'
                    ? i18n.t('compose_editor_move_column_right')
                    : i18n.t('compose_editor_move_row_down')}
                </Menu.ActionItem>
                <Menu.ActionItem
                  onClick={() => {
                    dispatchAction({
                      action:
                        handleType === 'column' ? 'deleteColumn' : 'deleteRow',
                    });
                  }}
                  tone="destructive"
                >
                  {i18n.t('delete')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  },
);
ActionHandleMenuWrapper.displayName = 'ActionHandleMenuWrapper';

function useElementsResizeRev(elements: HTMLElement[]) {
  const [rev, setRev] = useState(0);
  // avoid useDeepCompareEffect for elements -- it's unsafe to do deep equality check with HTML elements
  const memoizedElements = useMemoizedArray(elements);
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setRev((prev) => prev + 1);
    });
    memoizedElements.forEach((element) => observer.observe(element));
    return () => {
      observer.disconnect();
    };
  }, [memoizedElements]);
  return rev;
}

function useMemoizedArray<T>(elements: T[]) {
  const [prevElements, setPrevElements] = useState(elements);
  useEffect(() => {
    setPrevElements((prev) => {
      if (isEqual(elements, prev)) {
        return prev;
      }
      return elements;
    });
  }, [elements]);
  return prevElements;
}
