import {
  flyoutPanelContextAtom,
  flyoutPanelDataAtom,
  flyoutPanelViewAtom,
} from '@mirage/mosaics/FlyoutPanel/atoms';
import { ChatEntryPoint } from '@mirage/mosaics/SummaryQnaPanel/SummaryQna';
import { tagged } from '@mirage/service-logging';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  normalizeResultForSummary,
  type NormalizeResultInputType,
  type SummaryQnAEnum,
} from '../SummaryQnaPanel/utils';

const logger = tagged('useFlyoutPanel');

export const useFlyoutPanel = () => {
  const setFlyoutPanelData = useSetAtom(flyoutPanelDataAtom);
  const [flyoutPanelView, setFlyoutPanelView] = useAtom(flyoutPanelViewAtom);
  const [flyoutPanelContext, _setFlyoutPanelContext] = useAtom(
    flyoutPanelContextAtom,
  );
  const isDarkMode = useTheme();

  const summarize = useCallback(
    (
      result: NormalizeResultInputType,
      summaryQnAType: SummaryQnAEnum,
      entrypoint: ChatEntryPoint,
      resultPosition: number,
      resultPositionNoCta: number,
    ) => {
      const summarizable = normalizeResultForSummary(
        result,
        summaryQnAType,
        isDarkMode,
      );
      if (!summarizable) {
        logger.error('Failed to normalize summarizable', result);
        return;
      }
      setFlyoutPanelData({
        key: 'summary',
        value: {
          attachment: summarizable,
          isSummarize: true,
          pap: {
            entryPoint: entrypoint,
            resultUuid: summarizable.uuid,
            resultPosition,
            resultPositionNoCta,
            searchRequestId:
              'searchRequestId' in result ? result.searchRequestId : '',
          },
        },
      });
      setFlyoutPanelView('summary');
    },
    [setFlyoutPanelData, setFlyoutPanelView],
  );

  const closeFlyoutPanel = useCallback(() => {
    setFlyoutPanelView('closed');
  }, [setFlyoutPanelView]);

  const setFlyoutPanelContext = useCallback(
    (context: string) => {
      _setFlyoutPanelContext(context);
    },
    [_setFlyoutPanelContext],
  );

  return {
    summarize,
    closeFlyoutPanel,
    flyoutPanelContext,
    setFlyoutPanelContext,
    flyoutPanelView,
  };
};
