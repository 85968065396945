import { FolderLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { getShowableAttendees } from '@mirage/mosaics/StartPage/Calendar/utils';
import { SearchResult } from '@mirage/service-dbx-api';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import {
  DisplayType,
  isDesktopLocalFile,
  isRecordTypeTagEvent,
} from '@mirage/shared/search/search-result';
import { FacepileMetadataProps } from '../Metadata/FacepileMetadata';
import { MetadataItemProps } from '../Metadata/MetadataItem';
import { PathMetadataProps } from '../Metadata/PathMetadata';
import { PersonMetadataProps } from '../Metadata/PersonMetadata';
import { SnippetTextProps } from '../ResultRow/SnippetText';
import {
  getAppDisplayNameFromResult,
  getDuration,
  getEventTimeToDisplay,
  getLocalFilePathSegments,
  getPersonForMetadata,
  getTimeAgoStringFromTimestamp,
  getVirtualPathSegments,
  shouldDisplayVirtualPath,
} from './resultUtil';

export enum TitleField {
  ReferenceId = 'referenceId',
  Title = 'title',
}

export enum SubtitleField {
  Connector = 'connector',
  UpdatedDate = 'updatedDate',
  UpdatedBy = 'updatedBy',
  AssignedTo = 'assignedTo',
  Location = 'location',
  Path = 'path',
  Category = 'category',
  StartTime = 'startTime',
  Attendees = 'attendees',
  Duration = 'duration',
}

export enum BodyField {
  Preview = 'preview',
  Snippet = 'snippet',
}

export interface TextMetadataConfig extends MetadataItemProps {
  '.tag': 'text';
}

export interface PersonMetadataConfig extends PersonMetadataProps {
  '.tag': 'person';
}

export interface PeopleMetadataConfig extends FacepileMetadataProps {
  '.tag': 'people';
}

export interface PathMetadataConfig extends PathMetadataProps {
  '.tag': 'path';
}

export type MetadataConfig =
  | TextMetadataConfig
  | PersonMetadataConfig
  | PeopleMetadataConfig
  | PathMetadataConfig;

export interface SnippetConfig extends SnippetTextProps {
  '.tag': 'snippet';
}

export type BodyConfig = SnippetConfig; // to be extended when we get thumbnails, attachments, etc.

// full list here but subject to additions:
// https://github.com/dropbox-internal/server/blob/c10637ab5ca30c7d506e1f5d6a58ce9c17b20a55/go/src/dropbox/dash/connectors/schema/json/cloud_entities.go#L47
const displayTypeForConnectorLogicalType: Record<string, DisplayType> = {
  browser_tab: DisplayType.Knowledge,
  channel: DisplayType.Conversation,
  cloud_app: DisplayType.Knowledge,
  contact: DisplayType.Person,
  document: DisplayType.Knowledge,
  email_address: DisplayType.Person,
  event: DisplayType.Event,
  file: DisplayType.Drive,
  folder: DisplayType.Drive,
  label: DisplayType.Knowledge,
  message: DisplayType.Conversation,
  task: DisplayType.Task,
  thread: DisplayType.Conversation,
  transcript: DisplayType.VideoCall,
  user: DisplayType.Person,
  video: DisplayType.VideoCall,
  webpage: DisplayType.Knowledge,
  workspace: DisplayType.Knowledge,
};

export function getDisplayTypeForLogicalType(
  result: SearchResult,
  isVirtualPathEnabled?: boolean,
) {
  if (isDesktopLocalFile(result)) {
    return DisplayType.LocalFile;
  }
  if (isRecordTypeTagEvent(result)) {
    return DisplayType.Event;
  }
  if (
    isVirtualPathEnabled &&
    shouldDisplayVirtualPath(getVirtualPathSegments(result))
  ) {
    return DisplayType.Drive;
  }
  const logicalType = result.logicalType;
  return (
    displayTypeForConnectorLogicalType[logicalType ?? ''] ||
    DisplayType.Knowledge
  );
}

export function getTitleConfig(
  titleField: TitleField,
  result: SearchResult,
): string | null {
  switch (titleField) {
    case TitleField.ReferenceId:
      return result.referenceId || null;
    case TitleField.Title:
      return result.title;
    default:
      return null;
  }
}

export function getMetadataConfig(
  field: SubtitleField,
  result: SearchResult,
  onClickPerson?: (person: PersonObject) => void,
  activeFilters?: SearchFilter[],
): MetadataConfig | null {
  const appDisplayName = getAppDisplayNameFromResult(result);
  const { sender, creator, lastModifier, assignedTo, filteredModifier } =
    result;
  const { providerUpdateAtMs } = result;
  const timeAgoString = getTimeAgoStringFromTimestamp(providerUpdateAtMs, true);
  const locationDisplayName = result.parent?.displayName;
  const virtualPathSegments = getVirtualPathSegments(result);

  switch (field) {
    case SubtitleField.Connector:
      return {
        '.tag': 'text',
        title: appDisplayName,
      };

    case SubtitleField.UpdatedDate:
      return timeAgoString
        ? {
            '.tag': 'text',
            title: timeAgoString,
          }
        : null;
    case SubtitleField.UpdatedBy: {
      const { person, i18nKey } = getPersonForMetadata({
        sender,
        creator,
        lastModifier,
        activeFilters,
        filteredModifier,
      });
      if (!i18nKey || !person) {
        return null;
      }
      return {
        '.tag': 'person',
        i18nKey,
        person,
        onClickPerson,
      };
    }
    case SubtitleField.AssignedTo: {
      const { person, i18nKey } = getPersonForMetadata({
        sender,
        creator,
        lastModifier,
        assignedTo,
        activeFilters,
        filteredModifier,
      });
      if (!i18nKey || !person) {
        return null;
      }
      return {
        '.tag': 'person',
        i18nKey,
        person,
        onClickPerson,
      };
    }
    case SubtitleField.Location:
      return locationDisplayName
        ? {
            '.tag': 'text',
            title: locationDisplayName,
            iconSrc: FolderLine,
          }
        : null;
    case SubtitleField.Path:
      if (isDesktopLocalFile(result)) {
        return {
          '.tag': 'path',
          pathSegments: getLocalFilePathSegments(result),
        };
      }
      return shouldDisplayVirtualPath(virtualPathSegments)
        ? {
            '.tag': 'path',
            pathSegments: virtualPathSegments,
          }
        : null;
    case SubtitleField.Category:
      return result.category
        ? {
            '.tag': 'text',
            title: result.category,
          }
        : null;
    case SubtitleField.StartTime: {
      const timeToDisplay = getEventTimeToDisplay(result);
      return timeToDisplay
        ? {
            '.tag': 'text',
            title: timeToDisplay,
          }
        : null;
    }

    case SubtitleField.Attendees: {
      const showableAttendees = getShowableAttendees(
        result.creator || {},
        result.attendees || [],
      );
      return showableAttendees.length !== 0
        ? {
            '.tag': 'people',
            showableAttendees,
          }
        : null;
    }
    case SubtitleField.Duration: {
      const { startTime, endTime } = result;
      const durationToDisplay =
        startTime && endTime ? getDuration(startTime, endTime) : null;
      return durationToDisplay
        ? {
            '.tag': 'text',
            title: durationToDisplay,
          }
        : null;
    }
    default:
      return null;
  }
}

export function getBodyConfig(
  field: BodyField,
  result: SearchResult,
  query?: string,
): BodyConfig | null {
  switch (field) {
    case BodyField.Snippet: {
      const snippets = result.highlights?.body?.text || [];
      return snippets.length > 0
        ? {
            '.tag': 'snippet',
            textToHighlight: snippets[0],
            query,
          }
        : null;
    }
    default:
      return null;
  }
}

export type ResultConfig = {
  title: TitleField[];
  subtitle: SubtitleField[];
  body: BodyField[];
};

export const resultConfigForLogicalType: Record<DisplayType, ResultConfig> = {
  task: {
    title: [TitleField.ReferenceId, TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.AssignedTo,
      SubtitleField.UpdatedDate,
      SubtitleField.Location,
      SubtitleField.Category,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.LocalFile]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Path,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Drive]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Path,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Knowledge]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Location,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Project]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Location,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Job]: {
    title: [TitleField.ReferenceId, TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Sheet]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [],
  },
  [DisplayType.VideoCall]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Attendees,
      SubtitleField.StartTime,
    ],
    body: [BodyField.Preview, BodyField.Snippet],
  },
  [DisplayType.Conversation]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
  [DisplayType.Event]: {
    title: [TitleField.Title],
    subtitle: [SubtitleField.StartTime],
    body: [BodyField.Snippet],
  },
  [DisplayType.Person]: {
    title: [TitleField.Title],
    subtitle: [
      SubtitleField.Connector,
      SubtitleField.Path,
      SubtitleField.UpdatedBy,
      SubtitleField.UpdatedDate,
    ],
    body: [BodyField.Snippet],
  },
};

export function getResultConfigForDisplayType(displayType: DisplayType) {
  return (
    resultConfigForLogicalType[displayType] ||
    resultConfigForLogicalType[DisplayType.Knowledge]
  );
}
