import {
  deleteComposeApiSession,
  loadComposeApiSessions,
  loadComposeSessions,
  saveComposeApiSession,
  saveComposeSessions,
  syncComposeSessions,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import Sentry from '@mirage/shared/sentry';
import { isErrorWithMessage } from '@mirage/shared/util/error';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const logger = tagged('ComposeSessionsContext');

export interface ComposeSessionsContextInterface {
  sessions: ComposeSession[] | undefined;
  saveSession: (
    session: ComposeSession,
    updateCurrentSession: (session: ComposeSession) => void,
  ) => void;
  deleteSession: (session: ComposeSession) => void;
}
export const ComposeSessionsContext =
  createContext<ComposeSessionsContextInterface | null>(null);
export const useComposeSessionsContext = () => {
  const context = useContext(ComposeSessionsContext);
  if (!context) {
    throw new Error(
      'useComposeSessionsContext must be used within a ComposeSessionsContextProvider',
    );
  }
  return context;
};

interface ComposeSessionsContextProviderProps {
  children: React.ReactNode;
}

/**
 * Session queue designed to handle multiple save requests in quick succession.
 * Ensures that quick changes to UI avoid any race conditions where duplicate sessions are created
 * or sessions are saved with outdated data. Only one save request is processed at a time,
 * and any subsequent requests replace whatever request was previously queued.
 * Each caller receives a promise that resolves when its specific save operation completes
 * returning only the dataId of the saved session. Queues are created on a per-session basis
 * to ensure that slower requests do not get swallowed by requests on new sessions.
 */
class SessionOpQueue {
  private queuedOp?: {
    session: ComposeSession;
    resolve: (value: ComposeSession) => void;
    reject: (reason: unknown) => void;
  };
  private ongoingOp?: Promise<ComposeSession>;
  constructor(ongoingOp?: Promise<ComposeSession>) {
    this.ongoingOp = ongoingOp;
    if (ongoingOp) {
      this.handleOngoingOp(ongoingOp);
    }
  }
  private handleOngoingOp(op: Promise<ComposeSession>) {
    op.then((updatedSession) => {
      if (this.queuedOp) {
        const { session, resolve, reject } = this.queuedOp;
        this.queuedOp = undefined;
        const nextOp = saveComposeApiSession({
          ...session,
          dataId: updatedSession.dataId,
        });
        this.ongoingOp = nextOp;
        this.handleOngoingOp(nextOp);
        nextOp.then(resolve).catch(reject);
      } else {
        this.ongoingOp = undefined;
      }
      return updatedSession;
    }).catch((error) => {
      if (this.queuedOp) {
        this.queuedOp.reject(error);
        this.queuedOp = undefined;
      }
      logger.error('Error handling queued save session request', error);
    });
  }
  addOp(op: ComposeSession): Promise<ComposeSession> {
    return new Promise<ComposeSession>((resolve, reject) => {
      if (this.ongoingOp) {
        this.queuedOp = { session: op, resolve, reject };
      } else {
        const ongoingOp = saveComposeApiSession(op);
        this.ongoingOp = ongoingOp;
        this.handleOngoingOp(ongoingOp);
        ongoingOp.then(resolve).catch(reject);
      }
    });
  }
}

export const ComposeSessionsContextProvider = ({
  children,
}: ComposeSessionsContextProviderProps) => {
  const [sessions, setSessions] = useState<ComposeSession[] | undefined>(
    undefined,
  );
  const sessionOpQueuesRef = useRef<Map<string, SessionOpQueue>>(new Map());
  useEffect(() => {
    const loadSessions = async () => {
      try {
        const localSessions = await loadComposeSessions();
        if (localSessions.length > 0) {
          setSessions(localSessions);
        }
        const apiSessions = await loadComposeApiSessions();
        const { updated, deletedIds } = await syncComposeSessions(
          localSessions,
          apiSessions,
        );
        // apply updates from synced and deleted sessions
        setSessions((prevSessions) => {
          const sessionsMap = new Map(prevSessions?.map((s) => [s.id, s]));
          updated.forEach((session) => {
            sessionsMap.set(session.id, session);
          });
          deletedIds.forEach((id) => {
            sessionsMap.delete(id);
          });
          return Array.from(sessionsMap.values());
        });
      } catch (error) {
        logger.error('Error loading or synchronizing sessions', error);
        Sentry.withScope((scope) => {
          scope.setTag(
            'errorMessage',
            isErrorWithMessage(error) ? error.message : 'unknown',
          );
          Sentry.captureException(error);
          Sentry.captureMessage(
            '[Assistant] error loading or synchronizing sessions',
            'error',
            {},
            // Ensure scope is passed in here
            scope,
          );
        });
      }
    };
    loadSessions();
  }, []);

  const saveSession = useCallback(
    async (
      session: ComposeSession,
      updateCurrentSession: (session: ComposeSession) => void,
    ) => {
      const sessionData = {
        ...session,
        lastUpdated: Date.now(),
      };
      const sessionOpQueue = getSessionOpQueue(
        sessionOpQueuesRef.current,
        sessionData.id,
      );
      if (!session.dataId) {
        sessionOpQueue
          .addOp(sessionData)
          .then((savedSession) => {
            if (savedSession?.dataId) {
              const updatedSession = {
                ...sessionData,
                dataId: savedSession.dataId,
              };
              setSessions((prevSessions) => {
                if (prevSessions === undefined) return prevSessions;
                const updated = prevSessions.map((s) =>
                  s.id === session.id
                    ? { ...s, dataId: updatedSession.dataId }
                    : s,
                );
                updateCurrentSession(updatedSession);
                throttledSaveComposeSessions(updated);
                return updated;
              });
            }
            return;
          })
          .catch((error) => {
            logger.error('Error saving session to API', error);
          });
      } else {
        sessionOpQueue.addOp(sessionData);
      }

      setSessions((prevSessions) => {
        if (prevSessions === undefined) return prevSessions;
        const sessionsToSave = [];
        let found = false;
        for (const s of prevSessions) {
          if (s.id === sessionData.id) {
            found = true;
            if (!areSessionsDataEqual(s, session)) {
              sessionsToSave.push(sessionData);
            } else {
              sessionsToSave.push(s);
            }
          } else {
            sessionsToSave.push(s);
          }
        }
        if (!found) {
          sessionsToSave.push(sessionData); // New session, needs to be saved to API
        }
        throttledSaveComposeSessions(sessionsToSave);
        return sessionsToSave;
      });
    },
    [],
  );

  const deleteSession = useCallback(async (session: ComposeSession) => {
    setSessions((prevSessions) => {
      if (prevSessions === undefined) {
        logger.warn('Attempted to remove session before loading completes');
        return prevSessions;
      }
      const updated = prevSessions.filter((s) => s.id !== session.id);
      throttledSaveComposeSessions(updated);
      return updated;
    });
    if (session.dataId) {
      try {
        await deleteComposeApiSession(session);
      } catch (error) {
        logger.error('Error deleting session from API', error);
      }
    }
  }, []);

  return (
    <ComposeSessionsContext.Provider
      value={{ sessions, saveSession, deleteSession }}
    >
      {children}
    </ComposeSessionsContext.Provider>
  );
};

export function areSessionsDataEqual(
  a: Omit<ComposeSession, 'lastUpdated'>,
  b: Omit<ComposeSession, 'lastUpdated'>,
): boolean {
  return (
    a.id === b.id &&
    isEqual(a.messagesHistory, b.messagesHistory) &&
    isEqual(a.sources, b.sources) &&
    isEqual(a.artifacts, b.artifacts)
  );
}

const SAVE_THROTTLE_MS = 100;
const throttledSaveComposeSessions = throttle(
  saveComposeSessions,
  SAVE_THROTTLE_MS,
  { trailing: true },
);

function getSessionOpQueue(
  sessionOpQueues: Map<string, SessionOpQueue>,
  sessionID: string,
) {
  const sessionOpQueue = sessionOpQueues.get(sessionID);
  if (sessionOpQueue) {
    return sessionOpQueue;
  }
  const newOpQueue = new SessionOpQueue();
  sessionOpQueues.set(sessionID, newOpQueue);
  return newOpQueue;
}
