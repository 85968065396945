import { Text } from '@dropbox/dig-components/typography';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import useSettings from '@mirage/service-settings/useSettings';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import styles from './LaunchKeyboardShortcutBanner.module.css';

type LaunchKeyboardShortcutBannerProps = {
  isDarwin: boolean;
  className?: string;
};

export const shouldShowLaunchShortcutBanner = (
  surface: string,
  launchKeyboardShortcutUseCount: number,
) => {
  return launchKeyboardShortcutUseCount <= 3 && surface === 'desktop';
};

export const LaunchKeyboardShortcutBanner = ({
  isDarwin,
  className,
}: LaunchKeyboardShortcutBannerProps) => {
  const { isDualModeEnabled } = useDualMode();
  const { settings } = useSettings(['appShortcut', 'persistentAppShortcut']);
  return (
    <div className={classNames(styles.container, className)}>
      <PrettyShortcuts
        shortcut={settings?.appShortcut ?? ''}
        isDarwin={isDarwin}
      />
      <Text size={'small'} monospace className={styles.text}>
        {isDualModeEnabled
          ? i18n.t('app_shortcut_launcher')
          : i18n.t('open_dash_keyboard_shortcut')}
      </Text>
      {isDualModeEnabled && (
        <>
          <div />
          <PrettyShortcuts
            shortcut={settings?.persistentAppShortcut ?? ''}
            isDarwin={isDarwin}
          />
          <Text size={'small'} monospace className={styles.text}>
            {i18n.t('app_shortcut_persistent')}
          </Text>
        </>
      )}
    </div>
  );
};
