import { SettingsPage } from '@mirage/mosaics/SettingsPage';
import { ShowSettingsSections } from '@mirage/mosaics/SettingsPage/SettingsPage';
import { useCanClearBrowserHistory } from '@mirage/mosaics/SettingsPage/useCanClearBrowserHistory';
import { useDevFeatures } from '@mirage/service-auth/useDevFeatures';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { defaultSettings } from '@mirage/service-settings/service';
import useSettings, { DefaultKeys } from '@mirage/service-settings/useSettings';
import { isIframe } from '@mirage/shared/util/tiny-utils';
import {
  isExtensionInstalled,
  isNewTabAvailable,
  useExtensionConnectionId,
} from '@mirage/shared/util/webExtensionsHelpers';
import { useMemo } from 'react';

export const Settings: React.FC<{ selectedTab?: string }> = ({
  selectedTab,
}) => {
  const { settings } = useSettings(DefaultKeys);
  const enableDevFeatures = useDevFeatures();
  const hasExtensionConnectionId = Boolean(useExtensionConnectionId());
  const clearBrowsingHistoryEnabled = useCanClearBrowserHistory();

  const showLanguagePicker = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_21_language_picker'),
  );

  const showSettingsSections: ShowSettingsSections = useMemo(() => {
    return {
      general: {
        theme: true,
        locale: showLanguagePicker,
        // No need to get dynamic status of extension installation.
        webPreferences:
          // Assume that when inside an iframe, it is running with the browser
          // extension (most likely true).
          isIframe() ||
          // Show when the extension is installed and can show new tab
          (isExtensionInstalled() && isNewTabAvailable()) ||
          // Show when user is running the extension native webapp.
          EnvCtx.surface === 'extension',
        debug: enableDevFeatures,
        security: clearBrowsingHistoryEnabled && hasExtensionConnectionId,
      },
      apps: { showConnectorsList: true },
      internalFeatures: enableDevFeatures,
      internalDeveloper: enableDevFeatures,
      localFiles: false,
    };
  }, [
    clearBrowsingHistoryEnabled,
    enableDevFeatures,
    hasExtensionConnectionId,
    showLanguagePicker,
  ]);

  return (
    <SettingsPage
      settings={settings ?? defaultSettings}
      showSettingsSections={showSettingsSections}
      defaultTab={selectedTab}
    />
  );
};
