import {
  mapConnectorFilterToQueryFilter,
  mapContentTypeFilterToQueryFilter,
  mapLastUpdatedFilterToQueryFilter,
  mapPeopleFilterToQueryFilter,
  transformDashResultToMirage,
} from '@mirage/service-dbx-api/service/utils';
import {
  cacheLastSearchRequestArguments,
  cacheLastSearchRequestId,
} from '@mirage/service-relevance-snapshot/index';
import { DESKTOP_LOCAL_FILE_CONNECTOR_ID } from '@mirage/shared/connectors';
import {
  ConnectorFilter,
  ContentTypeFilter,
  isConnectorFilter,
  isContentTypeFilter,
  isLastUpdatedFilter,
  isPersonFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

import type { dcs } from '@dropbox/api-v2-client';
import type {
  AuthorInfo,
  BrandedSiteInfo,
  ConnectorInfo,
  DataSource,
  FileTypeInfo,
  IconResource,
  ItemLocation,
  SearchResult,
} from '@mirage/shared/search/search-result';

// TODO: remove this and update all imports to use shared if we like the strategy
// having it here was causing circular dependencies, and boundary import issues
export {
  SearchResult,
  ConnectorInfo,
  IconResource,
  FileTypeInfo,
  BrandedSiteInfo,
  DataSource,
  ItemLocation,
  AuthorInfo,
};

import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';

import type { APIv2CallableWithHeaders } from '..';

function isSearchResult(
  queryResult: dcs.query_result_union | undefined,
): queryResult is dcs.query_result_unionSearchResult {
  return queryResult !== undefined && queryResult['.tag'] === 'search_result';
}

export const getCorrectedQuery = (
  result: dcs.SearchApiv2Response,
): string | undefined => {
  return result.applied_corrected_query?.length
    ? result.applied_corrected_query
    : undefined;
};

export async function getSearchResults(
  api: APIv2CallableWithHeaders,
  query: string,
  filters: SearchFilter[] = [],
  disableSpellCorrection = false,
): Promise<{
  results: SearchResult[];
  correctedQuery: string | undefined;
}> {
  const isAllModifiersEnabled =
    (await getCachedOrFetchFeatureValue('dash_2025_01_31_all_modifiers')) ===
    'ON';

  const connectorFilters: Array<dcs.QueryFilter> = filters
    .filter(
      (filter): filter is ConnectorFilter =>
        isConnectorFilter(filter) &&
        filter.id !== DESKTOP_LOCAL_FILE_CONNECTOR_ID,
    )
    .map(mapConnectorFilterToQueryFilter);

  const lastUpdatedFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(mapLastUpdatedFilterToQueryFilter);

  const peopleFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is PersonFilter => isPersonFilter(filter))
    .map(mapPeopleFilterToQueryFilter);

  const contentTypeFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is ContentTypeFilter =>
      isContentTypeFilter(filter),
    )
    .map(mapContentTypeFilterToQueryFilter);

  const appliedFilters = [
    ...connectorFilters,
    ...lastUpdatedFilters,
    ...peopleFilters,
    ...contentTypeFilters,
  ];

  cacheLastSearchRequestArguments({
    query_text: query,
    filters: appliedFilters,
  });

  // TODO: API error handling. Should probably define this at the boundary and return predictable errors
  // currently just bubbling up to the caller

  // TODO - remove after #sev-speedy-carnelian-sandpiper
  const response = await api('dcsSearch', {
    query_text: query,
    filters: appliedFilters,
    query_options: {
      disable_spell_correction: disableSpellCorrection,
    },
  });

  const { headers, result } = response;

  const correctedQuery = getCorrectedQuery(result);

  if (result.results === undefined) {
    return {
      results: [],
      correctedQuery,
    };
  }

  const searchRequestId = result?.search_request_id || '';
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  // save the last search request payload and id in case of relevance snapshot
  cacheLastSearchRequestId(searchRequestId);

  const processedResults = (result.results || [])
    .map((result) => {
      const queryResult = result.query_result;
      if (!isSearchResult(queryResult)) {
        return null;
      }
      return transformDashResultToMirage(
        { ...queryResult, relevance_score: result.relevance_score },
        searchRequestId,
        analyticsTraceId,
        isAllModifiersEnabled,
      );
    })
    .filter((msr): msr is SearchResult => Boolean(msr));

  return {
    results: processedResults,
    correctedQuery,
  };
}
