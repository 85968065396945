import { Card as DigCard } from "@dropbox/dig-components/card";

import { Card as Container } from "./Container";
import { Contents } from "./Contents";
import { Header } from "./Header";
import { ModuleCard as Module } from "./ModuleCard";
import { OverlayLink } from "./OverlayLink";

export const Card = Object.assign(Container, {
  Contents,
  Header,
  Link: DigCard.Link,
  Module,
  OverlayLink,
});

export { getTooltipInCardProps } from "./utils";

export type { ContentsProps as CardContentsProps } from "./Contents";
