import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AppsLine,
  CloseLine,
  PuzzlePieceLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashColdStartNotification } from '@mirage/analytics/events/types/dismiss_dash_cold_start_notification';
import { PAP_Select_DashColdStartNotification } from '@mirage/analytics/events/types/select_dash_cold_start_notification';
import { PAP_Shown_DashColdStartNotification } from '@mirage/analytics/events/types/shown_dash_cold_start_notification';
import { GlobalBannerProps } from '@mirage/mosaics/GlobalBanners/GlobalBannersProps';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import { openURL } from '@mirage/service-platform-actions';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { BROWSER_EXTENSION_URL } from '@mirage/shared/urls';
import {
  isExtensionInstalled,
  openExtensionLinkForCurrentBrowser,
} from '@mirage/shared/util/webExtensionsHelpers';
import i18n, { I18nKey } from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useConnectorsUI } from '../SettingsPage/useConnectorsUI';
import styles from './ColdStartNotificationBanner.module.css';

const DISMISSED_COLD_START_NOTIFICATION = 'dismissedColdStartNotification';

export default function ColdStartNotificationBanner(props: GlobalBannerProps) {
  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('1d'), []);
  const coldStartBannerEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_18_cold_start_notification'),
  );
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISSED_COLD_START_NOTIFICATION,
    dismissBehavior,
  );
  const { openConnectors } = useConnectorsUI();
  const { connectors, hasFinishedInitialFetch } = useConnectors();
  const [hasConnectors, setHasConnectors] = useState<boolean>(false);
  const hasExtension = isExtensionInstalled();
  const [bannerText, setBannerText] = useState<I18nKey>(
    'cold_start_banner_default',
  );
  const { reportPapEvent } = useMirageAnalyticsContext();
  const exposureLoggedRef = useRef(false);

  useEffect(() => {
    if (!dismissed && !exposureLoggedRef.current) {
      exposureLoggedRef.current = true;
      reportPapEvent(
        PAP_Shown_DashColdStartNotification({
          actionSurfaceComponent: 'cold_start_notification',
          featureLine: 'onboarding',
        }),
      );
    }
  }, [dismissed, reportPapEvent]);

  useEffect(() => {
    setHasConnectors(hasFinishedInitialFetch && !!connectors?.length);
  }, [connectors?.length, hasFinishedInitialFetch]);

  useEffect(() => {
    if (hasConnectors && hasExtension) {
      setBannerText('cold_start_banner_with_extension_and_connectors');
    } else if (hasConnectors && !hasExtension) {
      setBannerText('cold_start_banner_with_connectors');
    } else if (!hasConnectors && hasExtension) {
      setBannerText('cold_start_banner_with_extension');
    } else if (!hasConnectors && !hasExtension) {
      setBannerText('cold_start_banner_default');
    }
  }, [hasConnectors, hasExtension]);

  const handleBannerClose = useCallback(() => {
    reportPapEvent(
      PAP_Dismiss_DashColdStartNotification({
        actionSurfaceComponent: 'cold_start_notification',
        featureLine: 'onboarding',
      }),
    );
    setDismissed(true);
  }, [reportPapEvent, setDismissed]);

  const handleViewExtension = useCallback(() => {
    reportPapEvent(
      PAP_Select_DashColdStartNotification({
        actionSurfaceComponent: 'cold_start_notification',
        featureLine: 'onboarding',
        coldStartAction: 'extension',
      }),
    );
    if (extensionAvailableForBrowser) {
      openExtensionLinkForCurrentBrowser(openURL);
    } else {
      openURL(BROWSER_EXTENSION_URL);
    }
  }, [extensionAvailableForBrowser, reportPapEvent]);

  const handleViewConnectors = useCallback(() => {
    reportPapEvent(
      PAP_Select_DashColdStartNotification({
        actionSurfaceComponent: 'cold_start_notification',
        featureLine: 'onboarding',
        coldStartAction: 'extension',
      }),
    );
    openConnectors();
  }, [openConnectors, reportPapEvent]);

  // Hide the banner after dismissal and only display on the search results page
  if (
    !coldStartBannerEnabled ||
    dismissed ||
    location.pathname.indexOf(RoutePath.SEARCH_RESULTS) !== 0
  )
    return null;

  return (
    <div className={props.bannerClass}>
      <Banner
        type="attention"
        className={styles.coldStartBanner}
        withLeftIcon={false}
      >
        <div className={styles.bannerContents}>
          <Banner.Message>
            <Text>{i18n.t(bannerText)}</Text>
          </Banner.Message>
          {!hasExtension && (
            <Button
              variant="outline"
              onClick={handleViewExtension}
              withIconStart={<UIIcon src={AppsLine} />}
            >
              {i18n.t('add_extension')}
            </Button>
          )}
          {!hasConnectors && (
            <Button
              variant="outline"
              onClick={handleViewConnectors}
              withIconStart={<UIIcon src={PuzzlePieceLine} />}
            >
              {i18n.t('add_apps')}
            </Button>
          )}
        </div>
        <IconButtonWithTooltip
          tooltipProps={{ title: 'Close' }}
          variant="transparent"
        >
          <UIIcon src={CloseLine} onClick={handleBannerClose} />
        </IconButtonWithTooltip>
      </Banner>
    </div>
  );
}
