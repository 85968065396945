// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3638
import type {PAPEventGeneric} from '../base/event';
import type {DashSurface} from '../enums/dash_surface';
import type {FeatureLine} from '../enums/feature_line';
import type {ActionSurfaceComponent} from '../enums/action_surface_component'; // Event_name: 'move.pinned_stack'

// Event_name: 'move.pinned_stack'
// Description: Track when user drags a pinned stack
// Owner: dash-web
export type Move_PinnedStack = PAPEventGeneric<
  'stacks',
  'move',
  'pinned_stack',
  {
    // The position of the clicked result
    position?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Number of existing user stacks
    numberOfStacks?: number;
    // zero-indexed position of an item in a list
    itemPosition?: number;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
  }
>;

export function PAP_Move_PinnedStack(
  properties?: Move_PinnedStack['properties'],
): Move_PinnedStack {
  return <Move_PinnedStack>{
    class: 'stacks',
    action: 'move',
    object: 'pinned_stack',
    properties,
  };
}
