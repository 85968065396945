import { dash } from '@dropbox/api-v2-client';
import { Button } from '@dropbox/dig-components/buttons';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { FileIcon } from '@dropbox/dig-content-icons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { Link } from '@mirage/link/Link';
import i18n from '@mirage/translations';
import { isEmpty, last, take, uniqBy } from 'lodash';
import { useCallback, useState } from 'react';
import styles from './ResultItem.module.css';

type ResultAttachmentProps = {
  attachments: dash.LinkAttachment[];
};
const MAX_ATTACHMENTS_TO_SHOW = 3;
export const ResultAttachment: React.FC<ResultAttachmentProps> = ({
  attachments,
}) => {
  const [expanded, setExpanded] = useState(false);
  const uniqueAttachement = uniqBy(
    attachments,
    (attachment) => `${attachment.title}-${attachment.url}`,
  );
  const shouldShowMore = uniqueAttachement.length > MAX_ATTACHMENTS_TO_SHOW;

  const handleExpandClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setExpanded(!expanded);
    },
    [expanded],
  );

  if (isEmpty(uniqueAttachement)) {
    return null;
  }

  return (
    <div className={styles.attachments}>
      {shouldShowMore && (
        <Button
          variant="borderless"
          withIconStart={
            <UIIcon src={expanded ? ChevronUpLine : ChevronDownLine} />
          }
          size="small"
          onClick={handleExpandClick}
        >
          {i18n.t(expanded ? 'show_less_answer' : 'show_more_answer')}
        </Button>
      )}
      <div className={styles.attachmentLinks}>
        {(expanded
          ? uniqueAttachement
          : take(uniqueAttachement, MAX_ATTACHMENTS_TO_SHOW)
        ).map((attachment, i) => {
          const extension = last(attachment.title?.split('.'));
          return (
            <Link
              key={i}
              target="_blank"
              href={attachment.url || ''}
              className={styles.attachmentButton}
            >
              {extension && (
                <FileIcon
                  extension={extension}
                  size="small"
                  hasWhitespace={false}
                  hasBackground={false}
                />
              )}
              <Text className={styles.attachmentTitle} size="small" isBold>
                <Truncate lines={1}>{attachment.title}</Truncate>
              </Text>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
