import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { DASH_SALES_CONTACT } from '@mirage/shared/urls';
import { redirectOnTopFrame } from '@mirage/shared/util/webExtensionsHelpers';
import i18n from '@mirage/translations';
import styles from './OnboardingLargeTeams.module.css';

export const OnboardingLargeTeams = () => {
  const handleContactSales = () => {
    redirectOnTopFrame(DASH_SALES_CONTACT);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
    >
      <Stack justify="center" align="center" display="flex">
        <Title
          size="large"
          weightVariant="emphasized"
          className={styles.header}
        >
          {i18n.t('self_serve_large_team_title')}
        </Title>
        <Text
          variant="paragraph"
          size="large"
          color="subtle"
          className={styles.subHeader}
        >
          {i18n.t('self_serve_large_team_description')}
        </Text>
        <Button
          className={styles.actionButton}
          data-testid="contactSalesButton"
          size="xlarge"
          variant="primary"
          onClick={handleContactSales}
        >
          <span className={styles.actionButtonLabel}>
            {i18n.t('self_serve_contact_sales')}
          </span>
        </Button>
      </Stack>
    </Box>
  );
};
