import type { LogLevel, LogObject } from 'consola';

export class DevLogProxyReporter {
  level: LogLevel;

  constructor(level: LogLevel) {
    this.level = level;
  }

  log(logObj: LogObject) {
    if (logObj.level > this.level) return;

    // this global function will be added by hornet in both the renderer and the main process
    // in all other surfaces this is a no-op
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (globalThis as any)?.sendLogToTheOtherSide?.(logObj.type, ...logObj.args);
  }
}
