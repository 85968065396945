import { Button } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { OnboardingStep } from '@mirage/analytics/events/enums/onboarding_step';
import { PAP_Continue_DashSelfServeOnboarding } from '@mirage/analytics/events/types/continue_dash_self_serve_onboarding';
import { PAP_Select_DashSelfServeOnboardingStepSkip } from '@mirage/analytics/events/types/select_dash_self_serve_onboarding_step_skip';
import {
  isAdminUser,
  useAccountIsDropboxer,
} from '@mirage/service-auth/useDropboxAccount';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ONBOARDING_VERSION } from '../flows/constants';
import { useFinishOnboarding } from '../flows/utils';
import {
  OnboardingCompanyInfo,
  OnboardingResponsesProvider,
} from '../hooks/OnboardingResponsesProvider';
import { OnboardingSurveyQuestionAnswer } from '../types/onboarding-surveys';
import styles from './SelfServeOnboarding.module.css';
import {
  SelfServeOnboardingItem,
  SelfServeOnboardingStep,
} from './SelfServeOnboardingItem';
import { SelfServeOnboardingLayout } from './SelfServeOnboardingLayout';

export enum ActionButtonType {
  Continue = 'continue',
  Skip = 'skip',
  Later = 'later',
  Back = 'back',
}

export type OnboardingStepItem = {
  step: SelfServeOnboardingStep;
  title: string;
  description?: string;
  secondaryAction?: ActionButtonType;
  primaryActions: ActionButtonType[];
  skipValidation?: boolean;
};

type SelfServeOnboardingProps = {
  routeParams?: Record<string, string | undefined>;
  steps: OnboardingStepItem[];
  onboardingVariant: ONBOARDING_VERSION;
};

const DEFAULT_COMPANY_INFO: OnboardingCompanyInfo = {
  companyLogo: '',
  companyName: '',
};

export const SelfServeOnboarding = ({
  steps,
  routeParams,
  onboardingVariant,
}: SelfServeOnboardingProps) => {
  const [currentStep, setCurrentStep] = useState<
    OnboardingStepItem | undefined
  >(undefined);
  const { account } = useAccountIsDropboxer();
  const isAdmin = isAdminUser(account);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [continueDisabled, setContinueDisabled] = useState<boolean>(true);
  const [continueLoading, setContinueLoading] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] =
    useState<OnboardingCompanyInfo>(DEFAULT_COMPANY_INFO);
  const [displayLargeTeams, setDisplayLargeTeams] = useState<boolean>(false);
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const fromDesktop = params.get('from') === 'desktop';
  // secret url param to use to test against the dev desktop app
  const useDevDesktopApp = params.get('useDevDesktopApp') === 'true';
  const finishOnboarding = useFinishOnboarding(
    reportPapEvent,
    onboardingVariant,
    true,
  );
  const onboardingStepRef = useRef();

  useEffect(() => {
    if (!routeParams) return;
    const { step } = routeParams;
    const index = steps.findIndex((s) => s.step === step);
    if (index >= 0) setCurrentStepIndex(index);
  }, [routeParams, search, steps]);

  useEffect(() => {
    setCurrentStep(steps[currentStepIndex]);
  }, [currentStepIndex, steps]);

  const handleContinue = useCallback(async () => {
    setContinueLoading(true);
    if (onboardingStepRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (onboardingStepRef.current as any).submitSurvey();
    }
    setContinueLoading(false);
    if (currentStepIndex === steps.length - 1) {
      finishOnboarding(fromDesktop, useDevDesktopApp);
      return;
    }

    const nextStep = steps[currentStepIndex + 1];
    navigate(getNavigatePathForStep(nextStep.step));
    setCurrentStepIndex(currentStepIndex + 1);
  }, [
    currentStepIndex,
    steps,
    navigate,
    finishOnboarding,
    fromDesktop,
    useDevDesktopApp,
  ]);

  const handleButtonAction = useCallback(
    (type: ActionButtonType) => {
      const onboardingStep = convertToOnboardingStep(currentStep?.step);

      switch (type) {
        case ActionButtonType.Skip:
        case ActionButtonType.Later:
          if (onboardingStep) {
            reportPapEvent(
              PAP_Select_DashSelfServeOnboardingStepSkip({
                selfServeIsTeamAdmin: isAdmin,
                onboardingStep,
              }),
            );
          }
          handleContinue();
          break;
        case ActionButtonType.Continue:
          if (onboardingStep) {
            reportPapEvent(
              PAP_Continue_DashSelfServeOnboarding({
                selfServeIsTeamAdmin: isAdmin,
                onboardingStep,
              }),
            );
          }
          handleContinue();
          break;
        case ActionButtonType.Back:
          if (currentStepIndex > 0) {
            const previousStep = steps[currentStepIndex - 1];
            navigate(getNavigatePathForStep(previousStep.step));
            setCurrentStepIndex(currentStepIndex - 1);
          }
          break;
        default:
          return;
      }
    },
    [
      currentStep?.step,
      currentStepIndex,
      handleContinue,
      isAdmin,
      navigate,
      reportPapEvent,
      steps,
    ],
  );

  const isLargeCompany = (answer: OnboardingSurveyQuestionAnswer) => {
    const selectedCompanySize = answer.value;
    return selectedCompanySize !== '1' && selectedCompanySize !== '2-19';
  };

  const handleStepValidationChange = useCallback(
    (valid: boolean, answers: OnboardingSurveyQuestionAnswer[]) => {
      setContinueDisabled(!valid);

      if (currentStep?.step === SelfServeOnboardingStep.PROFILING) {
        const companySizeQuestion = answers.find(
          (q) => q.questionId === 'COMPANY_SIZE_V1',
        );
        if (companySizeQuestion && isLargeCompany(companySizeQuestion)) {
          setDisplayLargeTeams(true);
        }
      }
    },
    [currentStep],
  );

  const getNavigatePathForStep = (step: SelfServeOnboardingStep): string => {
    return RoutePath.SETUP_ADMIN_SELF_SERVE.replace(':step', step);
  };

  const handleCompanyName = useCallback((name: string) => {
    // Function to update companyName
    setCompanyInfo((prevCompanyInfo) => ({
      ...prevCompanyInfo,
      companyName: name,
    }));
  }, []);

  const convertToOnboardingStep = (
    step: SelfServeOnboardingStep | undefined,
  ): OnboardingStep | null => {
    if (!step) return null;
    const stepMapping: Record<SelfServeOnboardingStep, OnboardingStep> = {
      [SelfServeOnboardingStep.COMPANY_INFO]: 'name_and_logo',
      [SelfServeOnboardingStep.PROFILING]: 'profiling',
      [SelfServeOnboardingStep.JTBD]: 'jtbd',
      [SelfServeOnboardingStep.ORGANIZATION_CONNECTORS]: 'org_connectors',
      [SelfServeOnboardingStep.CONNECTORS]: 'create_connectors',
      [SelfServeOnboardingStep.TEAM_INVITE]: 'team_invite',
      [SelfServeOnboardingStep.DOWNLOAD_UPSELL]: 'desktop_download',
      [SelfServeOnboardingStep.LARGE_TEAM]: 'profiling',
    };

    return stepMapping[step] || null;
  };

  return (
    <OnboardingResponsesProvider
      companyInfo={companyInfo}
      setCompanyName={handleCompanyName}
    >
      <SelfServeOnboardingLayout
        title={currentStep?.title}
        description={currentStep?.description}
        width={
          currentStep?.step === 'jtbd' || displayLargeTeams
            ? '900px'
            : undefined
        }
        displayNavigation={!displayLargeTeams}
        actionButtons={
          <SelfServeOnboardingActions
            primaryActions={currentStep?.primaryActions ?? []}
            secondaryAction={currentStep?.secondaryAction}
            onButtonAction={handleButtonAction}
            continueDisabled={continueDisabled}
            continueLoading={continueLoading}
            skipValidation={currentStep?.skipValidation}
          />
        }
        totalSteps={steps.length}
        currentStep={currentStepIndex}
      >
        <Box display="flex" flexDirection="column" width={'100%'} flexGrow={1}>
          <SelfServeOnboardingItem
            step={
              displayLargeTeams
                ? SelfServeOnboardingStep.LARGE_TEAM
                : currentStep?.step
            }
            isAdmin={isAdmin}
            onValidationChange={handleStepValidationChange}
            ref={onboardingStepRef}
          />
        </Box>
      </SelfServeOnboardingLayout>
    </OnboardingResponsesProvider>
  );
};

type SelfServeOnboardingActionsProps = {
  secondaryAction?: ActionButtonType;
  primaryActions: ActionButtonType[];
  onButtonAction: (type: ActionButtonType) => void;
  continueDisabled: boolean;
  continueLoading: boolean;
  skipValidation?: boolean;
};

const SelfServeOnboardingActions = ({
  secondaryAction,
  primaryActions,
  onButtonAction,
  continueDisabled,
  continueLoading,
  skipValidation = false,
}: SelfServeOnboardingActionsProps) => {
  const getButtonLabel = (type: ActionButtonType) => {
    switch (type) {
      case ActionButtonType.Continue:
        return i18n.t('continue_button');
      case ActionButtonType.Back:
        return i18n.t('back');
      case ActionButtonType.Skip:
        return i18n.t('self_serve_skip');
      case ActionButtonType.Later:
        return i18n.t('self_serve_download_later');
    }
  };

  const getButton = (type: ActionButtonType, disabled: boolean = false) => {
    const isContinueButton = type === ActionButtonType.Continue;
    return (
      <Button
        className={styles.actionButton}
        key={`onboarding-button-${type}`}
        data-testid={`onboarding-button-${type}`}
        size="xlarge"
        variant={isContinueButton ? 'primary' : 'borderless'}
        onClick={() => onButtonAction(type)}
        isLoading={isContinueButton && continueLoading}
        disabled={isContinueButton && !skipValidation ? disabled : false}
      >
        <span className={styles.actionButtonLabel}>{getButtonLabel(type)}</span>
      </Button>
    );
  };

  const secondaryActionCtrl = secondaryAction
    ? getButton(secondaryAction, false)
    : null;

  return (
    <Box display="flex" flexGrow={1} width={'100%'} flexDirection="row">
      <div className={styles.actionButtonSpacers}>{secondaryActionCtrl}</div>
      <Box display="flex" flexDirection="row">
        {primaryActions.map((actionType) =>
          getButton(actionType, continueDisabled),
        )}
      </Box>
      <span className={styles.actionButtonSpacers}></span>
    </Box>
  );
};
