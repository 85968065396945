import { ReportPapEventFn } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Finish_DashOnboarding } from '@mirage/analytics/events/types/finish_dash_onboarding';
import { tagged } from '@mirage/service-logging';
import {
  isUsersFirstLogin,
  markDashUserDfbOnboardingCompleteIfNecessary,
  markDashUserDfIOnboardingCompleteIfNecessary,
  markDashUserFirstUseTimestamp,
} from '@mirage/service-onboarding';
import {
  DEV_ONBOARDING_DESKTOP_URL,
  ONBOARDING_DESKTOP_URL,
} from '@mirage/shared/urls';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useNavigate } from 'react-router-dom';

import type { OnboardingVariant } from '@mirage/analytics/events/enums/onboarding_variant';

const logger = tagged('onboarding');

export const markUserDoneOnboarding = async (
  reportPapEvent: ReportPapEventFn,
  variant: string,
  is_dfb_onboarding?: boolean,
) => {
  try {
    await reportPapEvent(
      PAP_Finish_DashOnboarding({
        onboardingVariant: variant.toLowerCase() as OnboardingVariant,
      }),
      true,
    );

    if (is_dfb_onboarding) {
      await markDashUserDfbOnboardingCompleteIfNecessary(variant);
    } else {
      await markDashUserDfIOnboardingCompleteIfNecessary(variant);
    }
  } catch (e) {
    logger.error('Failed to mark user done onboarding: ', e);
  }
};

export const useFinishOnboarding = (
  reportPapEvent: ReportPapEventFn,
  onboardingVariant: string,
  is_dfb_onboarding?: boolean,
) => {
  const navigate = useNavigate();

  return async (
    withDesktopRedirect: boolean = false,
    useDevDesktopApp: boolean = false,
  ) => {
    await markUserDoneOnboarding(
      reportPapEvent,
      onboardingVariant,
      is_dfb_onboarding,
    );
    const firstTimeUser = await isUsersFirstLogin();
    if (firstTimeUser) await markDashUserFirstUseTimestamp();
    if (!withDesktopRedirect) {
      navigate(RoutePath.ROOT);
      return;
    }

    // Loading desktop app url scheme will trigger native popup for user to confirm opening external app, but it doesn't
    // navigate user away from current page.
    // Because of that, doing this does not interfere with `navigate()` call that happens inside `finishOnboarding()`.
    if (useDevDesktopApp) {
      window.location.href = DEV_ONBOARDING_DESKTOP_URL;
    } else {
      window.location.href = ONBOARDING_DESKTOP_URL;
    }
  };
};
