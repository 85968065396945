import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_RemoveSource } from '@mirage/analytics/events/types/click_remove_source';
import { ComposeSourceRow } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSourceRow';
import { SourceSearch } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/SourcesSearch';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';
import styles from './ComposeSources.module.css';

interface ComposeSourcesProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  onAddSource: (source: ComposeSource) => void;
  onRemoveSource: (source: ComposeSource) => void;
  sourcesTitleLabel?: ReactNode;
  searchTitleLabel?: ReactNode;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  hideEmptySources?: boolean;
  hasBorder?: boolean;
  hideSearchResultsLabel?: boolean;
  offsetEmptyMargin?: boolean;
  uploadButton?: ReactNode;
  emptyStateTitle?: string;
}
export const ComposeSources = memo(
  ({
    sources,
    sourcesContentCache,
    onAddSource,
    onRemoveSource,
    sourcesTitleLabel,
    searchTitleLabel,
    logComposeEvent,
    hideEmptySources,
    hasBorder,
    hideSearchResultsLabel,
    offsetEmptyMargin,
    uploadButton,
    emptyStateTitle = i18n.t('compose_sources_empty_state_title'),
  }: ComposeSourcesProps) => {
    const currentSourcesList =
      sources.length === 0 && hideEmptySources ? undefined : (
        <>
          {sourcesTitleLabel}
          {sources.length === 0 ? (
            <div
              className={classNames(styles.SourcesEmpty, {
                [styles.SourcesEmptyMargin]: offsetEmptyMargin,
              })}
            >
              <Text size="medium" color="subtle" tagName="div">
                {emptyStateTitle}
              </Text>
            </div>
          ) : (
            <div
              className={classNames(styles.SourceRows, {
                [styles.SourceRowsBordered]: hasBorder,
              })}
            >
              {sources.map((source, i) => {
                const sourceUUID = getSourceUUID(source);
                if (!sourceUUID) {
                  return null;
                }
                return (
                  <ComposeSourceRow
                    key={sourceUUID || i}
                    source={source}
                    action={{
                      icon: (
                        <UIIcon
                          src={CloseLine}
                          className={styles.SourceRowCloseIcon}
                        />
                      ),
                      variant: 'small',
                      onClick: () => {
                        logComposeEvent(PAP_Click_RemoveSource());
                        onRemoveSource(source);
                      },
                      label: i18n.t('compose_source_row_remove_button_label'),
                    }}
                    loadState={sourcesContentCache[sourceUUID]?.state}
                  />
                );
              })}
            </div>
          )}
        </>
      );
    return (
      <>
        {currentSourcesList}
        <div className={styles.SearchTitleRow}>
          {searchTitleLabel}
          {uploadButton}
        </div>
        <SourceSearch
          existingSources={sources}
          onAddSource={onAddSource}
          hasBorder={hasBorder}
          hideSearchResultsLabel={hideSearchResultsLabel}
          logComposeEvent={logComposeEvent}
        />
      </>
    );
  },
);
ComposeSources.displayName = 'ComposeSources';

const Divider = memo(() => {
  return <div className={styles.Divider} />;
});
Divider.displayName = 'Divider';
