import {
  ContentIcon,
  ContentRow,
  ContentRowSkeleton,
  Metadata,
} from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import {
  ReportPapEventFn,
  useMirageAnalyticsContext,
} from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { openURL } from '@mirage/service-platform-actions';
import {
  RecentConnectorContent,
  RecentContent,
} from '@mirage/service-recent-content/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { getTimeAgoString } from '@mirage/shared/util/time';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import i18n from '@mirage/translations';

export const RecentsFeed = ({
  isLoading,
  contentList,
}: {
  isLoading: boolean;
  contentList: RecentContent[];
}) => {
  const recentContentUrls = contentList.map((c) => c.url);
  const isDarkMode = useTheme();
  const urlMetadata = useMetadataForUrls(recentContentUrls) || {};
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <Stack gap="10">
      {isLoading
        ? Array.from({ length: 15 }).map((_, index) => (
            <ContentRowSkeleton key={index} paddingSize="medium" />
          ))
        : contentList.map((content) => (
            <RecentsFeedItem
              key={content.uuid}
              content={content}
              iconSrc={
                urlMetadata[content.url]?.faviconUrl ||
                faviconSrcForSrcUrl(content.url, 32, isDarkMode)
              }
              reportPapEvent={reportPapEvent}
            />
          ))}
    </Stack>
  );
};

const RecentsFeedItem = ({
  content,
  iconSrc,
  reportPapEvent,
}: {
  content: RecentContent;
  iconSrc: string;
  reportPapEvent: ReportPapEventFn;
}) => {
  const serviceName =
    'displayName' in content.connectorInfo
      ? content.connectorInfo.displayName
      : urlToService(content.url) || urlToDomainName(content.url);
  const icon =
    content.connectorInfo.type === 'connector' ? (
      <FileContentIcon
        content={content as RecentConnectorContent}
        size="large"
      />
    ) : (
      <ContentIcon
        icon={<FavIcon src={iconSrc} pureImg />}
        size="large"
        hasBackground
      />
    );
  const handleOpen = () => {
    if (content.url) {
      openURL(content.url);
      reportPapEvent(
        PAP_Open_DashLink({
          featureLine: 'recents',
          actionSurfaceComponent: 'recents',
          dashConnectorId:
            'connectorName' in content.connectorInfo
              ? content.connectorInfo.connectorName
              : undefined,
        }),
      );
    }
  };

  return (
    <ContentRow
      paddingSize="medium"
      onClick={handleOpen}
      withLabel={
        <Text size="medium" isBold tagName="div">
          <DashTruncateWithTooltip
            lines={1}
            tooltipProps={{
              title: content.title,
            }}
          >
            {content.title}
          </DashTruncateWithTooltip>
        </Text>
      }
      withMeta={
        <Metadata size="medium">
          <Metadata.Item>
            <Metadata.Label>{serviceName}</Metadata.Label>
          </Metadata.Item>
          <Metadata.Item>
            <Metadata.Label>
              {i18n.t('accessed_ago', {
                timeAgo: getTimeAgoString(content.visit_ms),
              })}
            </Metadata.Label>
          </Metadata.Item>
        </Metadata>
      }
      withStartAccessory={icon}
    />
  );
};
