import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { ProfilingQuestions } from '@mirage/growth/components/ProfilingQuestions/ProfilingQuestions';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useOnboardingSurveys } from '../hooks/useOnboardingSurveys';
import { OnboardingSurveyQuestionAnswer } from '../types/onboarding-surveys';

type Props = {
  isAdmin: boolean;
  onValidationChange: (
    valid: boolean,
    answers: OnboardingSurveyQuestionAnswer[],
  ) => void;
};

export const OnboardingProfilingStep = forwardRef(
  ({ isAdmin, onValidationChange }: Props, ref) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const { questions, submitSurvey } = useOnboardingSurveys(
      isAdmin ? 'ADMIN_PROFILING_V1' : 'TEAM_MEMBER_PROFILING_V1',
    );
    const [internalValues, setInternalValues] = useState<
      OnboardingSurveyQuestionAnswer[]
    >([]);
    const exposureLoggedRef = useRef(false);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => submitSurvey(internalValues),
    }));

    useEffect(() => {
      if (exposureLoggedRef.current) return;
      exposureLoggedRef.current = true;
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'profiling',
        }),
      );
    }, [isAdmin, reportPapEvent]);

    const handleProfilingAnswersChange = useCallback(
      (value: OnboardingSurveyQuestionAnswer[]) => {
        setInternalValues(value);
        onValidationChange(value.length === questions.length, value);
      },
      [onValidationChange, questions.length],
    );

    return (
      <ProfilingQuestions
        questions={questions}
        values={internalValues}
        onChange={(value) => handleProfilingAnswersChange(value)}
      />
    );
  },
);

OnboardingProfilingStep.displayName = 'OnboardingProfilingStep';
