import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { Error } from '@mirage/search/SearchResults/Error';
import { useGetVerifiedResults } from '@mirage/service-curations/useGetVerifiedResults';
import { DashSpinnerAutofitContainer } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback } from 'react';
import { PublishedContentEmptyState } from '../PublishedContentEmptyState';
import styles from './ListVerifiedResults.module.css';
import { VerifiedResultsTable } from './VerifiedResultsTable';

// We'll log this action surface for all verifcation-related PAP events on this page
// This is to differentiate between similar events logged on SERP.
const ACTION_SURFACE = 'published_content_verifications';

export const ListVerifiedResults = () => {
  const {
    data,
    loading,
    error,
    hasNextPage,
    fetchData,
    fetchMoreData,
    updateSavedVerification,
  } = useGetVerifiedResults();
  const isMobile = useIsMobileSize();

  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleShownEmptyState = useCallback(() => {
    reportPapEvent(
      PAP_Shown_DashEmptyStateComponent({
        dashActionSurface: ACTION_SURFACE,
      }),
    );
  }, [reportPapEvent]);

  if (error)
    return (
      <Error
        title={i18n.t('error')}
        subtitle={i18n.t('verifications_table_error')}
        actionText={i18n.t('verifications_error_retry')}
        onActionClick={fetchData}
      />
    );

  const hasData = data.length > 0;
  const showEmptyState = !loading && !hasData;

  return (
    <div>
      <div
        className={classnames(styles.subtitleContainer, {
          [styles.isMobile]: isMobile,
        })}
      >
        <Text>{i18n.t('verifications_table_title')}</Text>
      </div>

      {hasData && (
        <VerifiedResultsTable
          data={data}
          updateSavedVerification={updateSavedVerification}
          dashActionSurface={ACTION_SURFACE}
        />
      )}
      {showEmptyState && (
        <PublishedContentEmptyState
          text={i18n.t('verifications_table_empty_state')}
          onShownEmptyState={handleShownEmptyState}
        />
      )}
      {hasNextPage && (
        <div className={styles.loadMoreButtonContainer}>
          <Button onClick={fetchMoreData} variant="outline">
            {i18n.t('verifications_load_more')}
          </Button>
        </div>
      )}

      {loading && <DashSpinnerAutofitContainer />}
    </div>
  );
};
