import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import {
  isDesktopLocalFile,
  isRecordTypeTagEvent,
} from '@mirage/shared/search/search-result';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import { getMetadataConfig, SubtitleField } from '../util/config';
import {
  getCalendarSubtitle,
  getLocalFilePathSegments,
  getVirtualPathSegments,
  shouldDisplayVirtualPath,
} from '../util/resultUtil';
import { DefaultMetadata } from './DefaultMetadata';
import { FacepileMetadata } from './FacepileMetadata';
import { FilePathMetadata } from './FilePathMetadata';
import { MetadataItem } from './MetadataItem';
import { PathMetadata } from './PathMetadata';
import { PersonMetadata } from './PersonMetadata';
import styles from './ResultSubtitle.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

type ResultSubtitleProps = {
  result: SearchResult;
  subtitleFields?: SubtitleField[];
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

const MetadataContainer = ({
  isMobileSize,
  children,
}: PropsWithChildren<{ isMobileSize: boolean }>) => {
  return (
    <div
      className={classNames(styles.metadataContainer, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      {children}
    </div>
  );
};

export const ResultSubtitle = ({
  result,
  subtitleFields,
  onClickPerson,
  activeFilters,
}: ResultSubtitleProps) => {
  const isMobileSize = useIsMobileSize();

  const subtitleRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipHover, setTooltipHover] = useState(false);
  const [metadataHover, setMetadataHover] = useState(false);

  const useConfigDisplayType = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_2025_01_28_search_results_use_logical_type_config',
    ),
  );
  const isVirtualPathEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_11_13_search_results_virtual_path'),
  );
  const virtualPathSegments = getVirtualPathSegments(result);
  const shouldDisplayPath =
    isDesktopLocalFile(result) ||
    (isVirtualPathEnabled && shouldDisplayVirtualPath(virtualPathSegments));
  const handleResize = () => {
    if (!subtitleRef.current) return;
    const { scrollWidth, clientWidth } = subtitleRef.current;
    setIsOverflowing(scrollWidth > clientWidth);
  };
  useResizeObserver(subtitleRef, handleResize);

  const renderContent = (
    isTooltipContent?: boolean,
    triggerRef?: RefObject<HTMLDivElement>,
  ) => {
    if (isRecordTypeTagEvent(result)) {
      return <MetadataItem title={getCalendarSubtitle(result)} />;
    }
    if (isDesktopLocalFile(result)) {
      return (
        <FilePathMetadata
          result={result}
          pathSegments={getLocalFilePathSegments(result)}
          isTooltipContent={isTooltipContent}
          triggerRef={triggerRef}
        />
      );
    }
    if (isVirtualPathEnabled && shouldDisplayVirtualPath(virtualPathSegments)) {
      return (
        <FilePathMetadata
          result={result}
          pathSegments={virtualPathSegments}
          isTooltipContent={isTooltipContent}
          triggerRef={triggerRef}
          onClickPerson={onClickPerson}
        />
      );
    }
    if (useConfigDisplayType && subtitleFields) {
      return (
        <ConfigMetadata
          result={result}
          subtitleFields={subtitleFields}
          onClickPerson={onClickPerson}
          activeFilters={activeFilters}
          shouldDisplayPath={shouldDisplayPath}
          isTooltipContent={isTooltipContent}
          triggerRef={triggerRef}
          isOverflowing={isOverflowing}
        />
      );
    }

    return (
      <DefaultMetadata
        result={result}
        onClickPerson={onClickPerson}
        activeFilters={activeFilters}
      />
    );
  };

  return (
    <>
      {(isOverflowing || shouldDisplayPath) && (
        <DigTooltip.Control
          open={tooltipHover || metadataHover}
          triggerRef={subtitleRef}
          placement="top-start"
          openDelay={300}
          onMouseEnter={() => setTooltipHover(true)}
          onMouseLeave={() => setTooltipHover(false)}
          // Allows user to mvoe their mouse onto the tooltip without it disappearing
          style={{ background: 'transparent' }}
        >
          <MetadataContainer isMobileSize={isMobileSize}>
            {renderContent(true)}
          </MetadataContainer>
        </DigTooltip.Control>
      )}
      <div
        ref={shouldDisplayPath ? undefined : subtitleRef}
        onMouseEnter={() => setMetadataHover(true)}
        onMouseLeave={() => setMetadataHover(false)}
        className={classNames(styles.container, {
          [styles.fadeout]: isOverflowing,
        })}
      >
        <MetadataContainer isMobileSize={isMobileSize}>
          {shouldDisplayPath
            ? renderContent(false, subtitleRef)
            : renderContent(false)}
        </MetadataContainer>
      </div>
    </>
  );
};

type ConfigMetadataProps = {
  result: SearchResult;
  subtitleFields: SubtitleField[];
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
  shouldDisplayPath?: boolean;
  isTooltipContent?: boolean;
  triggerRef?: RefObject<HTMLDivElement>;
  isOverflowing?: boolean;
};

const ConfigMetadata = ({
  result,
  subtitleFields,
  onClickPerson,
  activeFilters,
  shouldDisplayPath = false,
  isTooltipContent,
  triggerRef,
  isOverflowing = false,
}: ConfigMetadataProps) => {
  return (
    <>
      {subtitleFields.map((subtitleField: SubtitleField) => {
        const metadataProps = getMetadataConfig(
          subtitleField,
          result,
          onClickPerson,
          activeFilters,
        );
        if (metadataProps === null) return null;
        if (metadataProps['.tag'] === 'text') {
          return <MetadataItem key={subtitleField} {...metadataProps} />;
        }
        if (metadataProps['.tag'] === 'person') {
          return (
            <MetadataItem
              key={subtitleField}
              title={<PersonMetadata {...metadataProps} />}
            />
          );
        }
        if (metadataProps['.tag'] === 'people') {
          return (
            <FacepileMetadata
              key={subtitleField}
              {...metadataProps}
              showPopover={!isOverflowing}
            />
          );
        }
        if (metadataProps['.tag'] === 'path' && shouldDisplayPath) {
          return (
            <PathMetadata
              key={subtitleField}
              isTooltipContent={isTooltipContent}
              triggerRef={triggerRef}
              {...metadataProps}
            />
          );
        }
        return null;
      })}
    </>
  );
};
