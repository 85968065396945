import { ComponentType } from 'react';
import { Location } from 'react-router-dom';

// Cannot be `const enum` as we are access it by index.
export enum PageType {
  NOT_FOUND = 'NOT_FOUND',
  HOME = 'HOME',
  SETTINGS = 'SETTINGS',
  SETTINGS_TAB = 'SETTINGS_TAB',
  // Connection specific confirmation redirect page from janus for oauth
  AUTHCONFIRM = 'AUTHCONFIRM',
  STACKS = 'STACKS',
  STACK = 'STACK',
  NEW_STACK = 'NEW_STACK',
  LOGIN = 'LOGIN',
  OAUTH = 'OAUTH',
  LOGOUT = 'LOGOUT',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  UXR = 'UXR',
  COMPOSE = 'COMPOSE',
  CHAT = 'CHAT',
  CONVERSATION = 'CONVERSATION',
  // Onboarding flows
  SETUP = 'SETUP',
  SETUP_SURVEY = 'SETUP_SURVEY',
  PUBLISHED_CONTENT = 'PUBLISHED_CONTENT',
}

export const enum RoutePath {
  ROOT = '/',
  LOGIN = '/login',
  OAUTH = '/oauth',
  LOGOUT = '/logout',
  NOT_FOUND = '/not_found',
  SETTINGS = '/settings',
  SETTINGS_TAB = '/settings/:tab',
  // Connection specific confirmation redirect page from janus for oauth
  AUTHCONFIRM = '/authconfirm',
  STACKS = '/stacks',
  NEW_STACK = '/stacks/new',
  SEARCH_RESULTS = '/search_results',
  COMPOSE = '/compose',
  CHAT = '/chat',
  CONVERSATION = '/conversation',
  EMBED_OPEN = '/embed/open',
  EMBED_PRE_WARM = '/embed/pre_warm',
  UXR = '/uxr',
  SETUP = '/setup',
  SETUP_SURVEY = '/setup/jtbd/:step',
  SETUP_TEAMS = '/setup/team/:step',
  SETUP_ADMIN_SELF_SERVE = '/setup/admin-self-serve/:step',
  SETUP_SELF_SERVE = '/setup/self-serve/:step',
  PUBLISHED_CONTENT = '/published_content',
}

export type AppRoute = {
  pageType: PageType;
  Component: ComponentType;
  requiresAuthentication: boolean;
  wholePage?: boolean;
  hideTopNav?: boolean;
  pattern: string;
  params: Record<string, string | undefined>;
  location: Location;
};

export type RouteDef = {
  path: string;
  requiresAuthentication: boolean;
  wholePage?: boolean;
  hideTopNav?: boolean;
  Component: ComponentType;
};
