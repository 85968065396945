// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3646
import type { PAPEventGeneric } from "../base/event";
import type { SpellCorrectAction } from "../enums/spell_correct_action";

// Event_name: 'select.dash_spell_correct'
// Description: A spell correct option is selected in Dash UI
// Owner: dash-exp-find-and-discover
export type Select_DashSpellCorrect = PAPEventGeneric<
  'dash',
  'select',
  'dash_spell_correct',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // content of a search
    query?: string;
    // App filters selected e.g. “contact”, “contact,gsuite”
    // These values are going to be sorted, global, code defined, Dropbox owned data that tags the "type" of a search result.
    activeFilters?: string;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // Enum of action taken on spell correction
    spellCorrectAction?: SpellCorrectAction;
  }
>;

export function PAP_Select_DashSpellCorrect(properties?: Select_DashSpellCorrect['properties']): Select_DashSpellCorrect {
  return <Select_DashSpellCorrect>{
    class: 'dash',
    action: 'select',
    object: 'dash_spell_correct',
    properties,
  };
}
