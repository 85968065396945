import { Tabs } from '@dropbox/dig-components/tabs';
import { Title } from '@dropbox/dig-components/typography';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ListPublishedStacks } from './ListPublishedStacks';
import styles from './PublishedContentPage.module.css';
import { ListVerifiedResults } from './Verifications/ListVerifiedResults';

const enum TAB_NAMES {
  STACKS = 'stacks',
  VERIFICATIONS = 'verifications',
}

export const PublishedContentPage = () => {
  const account = useDropboxAccount();
  const isTeamAdmin = account?.team?.team_user_permissions?.is_admin;

  const enablePublishedContent = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_24_published_content_is_admin'),
  );
  const showCompanyStacks = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_10_02_show_company_pinned_stacks'),
  );

  // Must be team admin to see verifications tab
  const showVerifications =
    convertFeatureValueToBool(
      useFeatureFlagValue('dash_2025_01_31_managed_verifications'),
    ) && isTeamAdmin;

  // Determine which tab should be selected by default
  const initialTab = showCompanyStacks
    ? TAB_NAMES.STACKS
    : showVerifications
      ? TAB_NAMES.VERIFICATIONS
      : '';
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const adminCanView = isTeamAdmin && (showCompanyStacks || showVerifications);
  const shouldShowPublishedContent = enablePublishedContent || adminCanView;

  // If user is not allowed, navigate away immediately
  if (!shouldShowPublishedContent) {
    return <Navigate to={RoutePath.NOT_FOUND} />;
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <Title weightVariant="emphasized" size="medium" tagName="h1">
          {i18n.t('published_content_title')}
        </Title>

        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
          <Tabs.Group>
            {showCompanyStacks && (
              <Tabs.Tab id={TAB_NAMES.STACKS}>
                {i18n.t('published_content_tab_title_stacks')}
              </Tabs.Tab>
            )}
            {showVerifications && (
              <Tabs.Tab id={TAB_NAMES.VERIFICATIONS}>
                {i18n.t('published_content_tab_title_verifications')}
              </Tabs.Tab>
            )}
          </Tabs.Group>
        </Tabs>

        {selectedTab === TAB_NAMES.STACKS && <ListPublishedStacks />}
        {selectedTab === TAB_NAMES.VERIFICATIONS && <ListVerifiedResults />}
      </div>
    </div>
  );
};
