import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import {
  type ContentTypeFilter,
  contentTypeToSearchFilter,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import styles from './ResultSectionHeader.module.css';

import type { ResultCategory } from '@mirage/shared/search/categorizeResults';

const emailFilter = contentTypeToSearchFilter({
  key: 'email',
  label: () => i18n.t('email_content_type'),
}) as ContentTypeFilter;

type Props = {
  section: ResultCategory;
  applyFilters?: (filters: ContentTypeFilter[]) => void;
  logCategorySeeMore: () => void;
};

export function ResultSectionHeader({
  section,
  applyFilters,
  logCategorySeeMore,
}: Props) {
  return (
    <div className={styles.sectionContainer}>
      <Text isBold>{section.title}</Text>
      <div className={styles.divider}></div>
      {section.categoryType === 'emails' && applyFilters && (
        <Button
          variant="borderless"
          size="small"
          onClick={() => {
            applyFilters([emailFilter]);
            logCategorySeeMore();
          }}
        >
          See more
        </Button>
      )}
    </div>
  );
}
