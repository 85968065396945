import { hotkeys } from '@mirage/shared/hotkeys';
import { BaseKeys } from '@mirage/shared/hotkeys/baseKeys';
import { KeyboardEvent } from 'react';
import {
  getKeyMaps,
  getMousetrapMap,
  getOsModifierKeys,
} from './hotkeysKeyMap';

import type { Handlers, KeyMap } from '.';

export const registerHotkeys = ({
  event,
  keyHandlers,
  hotkeyMapName,
  platform,
  isDesktop,
  isDualModeEnabled,
}: {
  hotkeyMapName: keyof BaseKeys;
  event: KeyboardEvent;
  keyHandlers: Handlers;
  platform: string;
  isDesktop: boolean;
  isDualModeEnabled: boolean;
}) => {
  const keyMap = getKeyMaps({ platform, isDesktop, isDualModeEnabled })[
    hotkeyMapName
  ] as KeyMap;
  const osModifiers = getOsModifierKeys(platform);
  const mapEventToMousetrap = getMousetrapMap(platform);

  return hotkeys(event, keyMap, keyHandlers, {
    osModifiers,
    mapEventToMousetrap,
  });
};
