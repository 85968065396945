import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { GlobeFill } from '@dropbox/dig-icons/dist/mjs/assets';
import styled from '@emotion/styled';
import useExtensionBasedBrowserHistory from '@mirage/service-browser-history-v1/useBrowserHistory';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './QuickLinks.module.css';

interface QuickLinksProps {
  accountId: string;
}

export const QuickLinks: React.FC<QuickLinksProps> = ({ accountId }) => {
  const isDarkMode = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [resultCount, setResultCount] = useState<number>(0);
  const { browserHistory, refreshBrowserHistory } =
    useExtensionBasedBrowserHistory(true, accountId);

  const browserHistoryList = useMemo(() => {
    const browserHistoryArray = Object.values(browserHistory.contentInfos);
    browserHistoryArray.sort(
      (a, b) => (b.visit_count ?? 0) - (a.visit_count ?? 0),
    );
    return browserHistoryArray.slice(0, 6);
  }, [browserHistory]);

  useEffect(() => {
    setLoading(true);
    refreshBrowserHistory();
  }, [accountId]);

  useEffect(() => {
    if (resultCount > 1 || browserHistoryList.length > 0) {
      setLoading(false);
    }
  }, [resultCount, browserHistoryList.length]);

  useEffect(() => {
    setResultCount(resultCount + 1);
  }, [browserHistory]);

  const placeholderArray = Array.from({
    length: 6 - browserHistoryList.length,
  });

  return (
    <div className={styles.quickLinksContainer}>
      <div className={styles.quickLinksTitle}>
        <Text isBold>{i18n.t('quick_links_title')}</Text>
      </div>
      {loading ? (
        <ResultContainer
          isDarkMode={isDarkMode}
          className={styles.flexContainer}
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <IconShimmer key={idx} size="40px" />
          ))}
        </ResultContainer>
      ) : (
        <div className={styles.flexContainer}>
          {browserHistoryList.map((item) => {
            return (
              <DigTooltip title={item.title} key={item.url} openDelay={150}>
                <Button
                  variant="outline"
                  size="large"
                  target="_blank"
                  href={item.url}
                  className={styles.button}
                >
                  {item.fav_icon_encoded_string || item.fav_icon_url ? (
                    <img
                      className={styles.faviconImg}
                      alt={item.title}
                      src={item.fav_icon_encoded_string || item.fav_icon_url}
                    />
                  ) : (
                    <GlobeFill className={styles.faviconImg} />
                  )}
                </Button>
              </DigTooltip>
            );
          })}
          {placeholderArray.map((_, idx) => {
            return (
              <div key={idx} className={styles.placeHolder}>
                &nbsp;
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ResultContainer = styled.div<{ isDarkMode: boolean }>`
  position: relative;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      ${(props) =>
        props.isDarkMode
          ? 'rgba(36, 33, 33, 0.2)'
          : 'rgba(255, 255, 255, 0.3)'},
      transparent
    );
    animation: shimmer 1.5s infinite;
  }
`;

const IconShimmer = styled.div<{ size?: string }>`
  flex-shrink: 0;
  width: ${({ size }) => size ?? '48px'};
  height: ${({ size }) => size ?? '48px'};
  border-radius: 4px;
  background-color: var(--dig-color__opacity__surface);
);
`;
