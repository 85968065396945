// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3645
import type { PAPEventGeneric } from "../base/event";

// Event_name: 'shown.dash_spell_correct'
// Description: Spell correction is shown in Dash UI
// Owner: dash-exp-find-and-discover
export type Shown_DashSpellCorrect = PAPEventGeneric<
  'dash',
  'shown',
  'dash_spell_correct',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // content of a search
    query?: string;
    // App filters selected e.g. “contact”, “contact,gsuite”
    // These values are going to be sorted, global, code defined, Dropbox owned data that tags the "type" of a search result.
    activeFilters?: string;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
  }
>;

export function PAP_Shown_DashSpellCorrect(properties?: Shown_DashSpellCorrect['properties']): Shown_DashSpellCorrect {
  return <Shown_DashSpellCorrect>{
    class: 'dash',
    action: 'shown',
    object: 'dash_spell_correct',
    properties,
  };
}
