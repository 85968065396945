import {
  ONE_HOUR_IN_MILLIS,
  ONE_MINUTE_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { utcToZonedTime } from 'date-fns-tz';

import type { dash } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getUpcomingCalendarEvents = (
  callApiV2: APIv2Callable,
): Promise<dash.SearchResult[] | undefined> => {
  // Get meetings that started 10 minutes ago to 12 hours from now
  const startTime = Date.now() - ONE_MINUTE_IN_MILLIS * 10;
  const endTime = Date.now() + ONE_HOUR_IN_MILLIS * 12;
  return callApiV2('dcsGetCalendarEvents', {
    start_datetime: new Date(startTime).toISOString(),
    end_datetime: new Date(endTime).toISOString(),
    include_ongoing: true,
    include_all_day: true,
    user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
    .then((r) => {
      return r.events
        ?.map((event) => {
          const [startTime, endTime] = event.is_all_day
            ? fixAllDayTimes(event.start_time || 0, event.end_time || 0)
            : [event.start_time || 0, event.end_time || 0];
          return {
            ...event,
            start_time: startTime,
            end_time: endTime,
          };
        })
        .sort((a, b) => {
          // Shorter events first
          if (a.start_time === b.start_time) {
            return a.end_time - b.end_time;
          }
          return a.start_time - b.start_time;
        });
    })
    .catch(() => undefined);
};

// TODO: Temporary fix until https://jira.dropboxer.net/browse/OTCKA-506 is
// resolved and the API provides all-day events with timestamps in the same
// offset as what is requested by user_time_zone. Currently the search is
// done with the correct offset, but the returned timestamps are still in
// the incorrect "shifted" UTC offset.
export function fixAllDayTimes(
  startTime: number,
  endTime: number,
): [number, number] {
  return [
    utcToZonedTime(startTime, 'UTC').getTime(),
    utcToZonedTime(endTime, 'UTC').getTime(),
  ];
}
