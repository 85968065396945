import { ConversationInput } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import { postUserMessage } from '@mirage/service-conversation';
import useConversation from '@mirage/service-conversation/hooks/useConversation';
import { ConversationMessage } from '@mirage/service-conversation/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { v4 } from 'uuid';

import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/compose-session';

const convertToComposeMessage = (
  message: ConversationMessage,
): ComposeAssistantConversationMessage => {
  if (message.message_type?.['.tag'] === 'user_message') {
    return {
      type: 'message',
      text: message.message_type.text ?? '',
      ts: message.timestamp
        ? new Date(message.timestamp).getTime()
        : Date.now(),
      role: 'user',
    };
  }
  if (message.message_type?.['.tag'] === 'assistant_message') {
    return {
      type: 'message',
      text: message.message_type.text ?? '',
      ts: message.timestamp
        ? new Date(message.timestamp).getTime()
        : Date.now(),
      role: 'assistant',
    };
  }
  if (message.message_type?.['.tag'] === 'system_message') {
    return {
      type: 'message',
      text: message.message_type.text ?? '',
      ts: message.timestamp
        ? new Date(message.timestamp).getTime()
        : Date.now(),
      role: 'system',
    };
  }
  throw new Error(`Unknown message type: ${message.message_type?.['.tag']}`);
};

export const ConversationAssistantPage = memo(() => {
  const isConversationAssistantEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_02_11_conversation_assistant'),
  );
  const [conversationId, setConversationId] = useState<string | undefined>(
    undefined,
  );
  const messages = useConversation(conversationId);
  const composeMessages = useMemo(
    () => messages.map(convertToComposeMessage),
    [messages],
  );
  const isWaitingForResponse = useMemo(() => {
    return messages.some((message) => message.status === 'pending');
  }, [messages]);

  useEffect(() => {
    setConversationId(v4());
  }, []);

  const handleSubmit = useCallback(
    (text: string) => {
      if (!conversationId) {
        return;
      }

      postUserMessage({
        conversationId,
        message: text,
      });
    },
    [conversationId],
  );

  // Minimal required props for analytics
  const logComposeEvent = useCallback(() => {
    // Will be implemented later with proper analytics
  }, []);

  const handleRemoveSource = useCallback(() => {
    // Will be implemented later if needed
  }, []);

  if (!isConversationAssistantEnabled) {
    return <Navigate to={RoutePath.CHAT} replace />;
  }

  return (
    <div>
      <ConversationMessages
        variant="default"
        messages={composeMessages}
        isWaitingForResponse={isWaitingForResponse}
        progressString={undefined}
        onRemoveSource={handleRemoveSource}
        isLastMessageFollowUpSuggestions={false}
        artifacts={[]}
        logComposeEvent={logComposeEvent}
        onOpenArtifact={() => {}}
      />
      <ConversationInput
        variant="default"
        sources={[]}
        onSubmit={handleSubmit}
        logComposeEvent={logComposeEvent}
        messages={composeMessages}
        showAddSourcesButton={false}
      />
    </div>
  );
});

ConversationAssistantPage.displayName = 'ConversationAssistantPage';
