import {
  DATA_ATTRIBUTE_SELECTION_ID,
  focusResultAtIdx,
  focusSearchInput,
  getAllSelectableItems,
  selectedItemAtom,
  userHasNotInputKeyAtom,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { KeyCodes } from '@mirage/shared/util/constants';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

export const useKeyboardNavigation = () => {
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const [userHasNotInputKey, setUserHasNotInputKey] = useAtom(
    userHasNotInputKeyAtom,
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === KeyCodes.arrowDown &&
        !selectedItem &&
        event.target === document.body
      ) {
        // We should move focus to the result list IF:
        // The focus is on the document body & there's no selected item yet
        focusResultAtIdx(selectedItem ? 1 : 0);
      }
    };
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem]);

  // Handle the up/down navigation between results and the search input
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const items = getAllSelectableItems();
      const currentFocus = document.activeElement as HTMLElement;
      const currentIndex = Array.from(items).indexOf(currentFocus);

      if (
        event.key === KeyCodes.arrowDown ||
        (event.key === KeyCodes.n && event.metaKey)
      ) {
        if (currentIndex >= 0 && currentIndex < items.length - 1) {
          const itemToFocus = items[currentIndex + 1];
          event.preventDefault();
          itemToFocus?.focus();
        }
      }

      if (
        event.key === KeyCodes.arrowUp ||
        (event.key === KeyCodes.p && event.metaKey)
      )
        if (currentIndex > 0) {
          const itemToFocus = items[currentIndex - 1];
          event.preventDefault();
          itemToFocus?.focus();
        } else if (currentIndex === 0) {
          focusSearchInput();
          setSelectedItem(undefined);
        }
    },
    [setSelectedItem],
  );

  const moveFocusToResults = useCallback(() => {
    // Check if an item is "selected" but not focused
    // (for when we fake-focus the first item when user foregrounds app)
    // It no item is selected, move focus to first item
    focusResultAtIdx(selectedItem ? 1 : 0);
  }, [selectedItem]);

  const resetSelectedItem = useCallback(() => {
    setSelectedItem(undefined);
  }, [setSelectedItem]);

  // Specifically used to select the first item without focusing it.
  const selectFirstSelectableItem = useCallback(() => {
    const items = getAllSelectableItems();
    const firstItem = items[0];
    const selectionId = firstItem?.getAttribute(DATA_ATTRIBUTE_SELECTION_ID);
    if (selectionId) {
      setSelectedItem({ selectionId });
    }
  }, [setSelectedItem]);

  return {
    moveFocusToResults,
    selectFirstSelectableItem,
    resetSelectedItem,
    userHasNotInputKey,
    setUserHasNotInputKey,
    handleKeyDown,
  };
};
